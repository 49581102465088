import axios from 'axios';

function getRecordingsMetaData(msgId) {
    return axios.get(`/comments/recording/${msgId}/meta-data`);
}
function getRecording(msgId) {
    return axios.get(`/comments/recording/${msgId}/file`);
}

function getCommentIds(caseId) {
    return axios.get(`/comments/${caseId}/ids`);
}

export function resetCommentTries(commentId) {
    return axios.post(`/comments/${commentId}/reset-tries`);
}

export function deleteFailedComment(commentId) {
    return axios.delete(`/comments/${commentId}`);
}

export default {
    getRecording,
    getRecordingsMetaData,
    getCommentIds,
    resetCommentTries,
};
