<template>
    <section class="login-container">
        <section class="outer-container">
            <section class="brand-box">
                <article class="brand-content">
                    <v-img :src="logo" class="logo-img fade-in-element" />
                    <span class="display-text fade-in-element">{{ displayText }}</span>
                </article>
            </section>

            <article v-if="currentPage === pages.LOGIN" class="login-box fade-in-element" :style="loginBoxStyle">
                <Transition mode="out-in" name="fade">
                    <section v-if="!loading" class="inner-content">
                        <h2 class="heading">{{ $t('login.signIn') }}</h2>
                        <section class="introduction"></section>
                        <v-form ref="loginform" v-model="valid" class="form" @submit.prevent="login">
                            <section class="form-group">
                                <secondary-input
                                    v-bind="usernameAttrs"
                                    id="username"
                                    v-model="username"
                                    data-cy-login="username"
                                />
                            </section>
                            <section class="form-group">
                                <secondary-input v-bind="passwordAttrs" id="password" v-model="password" />
                            </section>
                            <section class="form-actions">
                                <a class="forgot-password-link" @click="gotoHelp">
                                    {{ $t('login.forgotPassword') }}
                                </a>

                                <primary-button :disabled="buttonIsDisabled" class="login-button" @click="login">
                                    {{ $t('login.continue') }}
                                </primary-button>
                            </section>
                        </v-form>
                        <section class="divider-container">
                            <v-divider class="divider" />
                            <span class="divider-text">{{ $t('login.or') }}</span>
                            <v-divider class="divider" />
                        </section>
                        <section class="federated-login-container">
                            <FederatedLogin v-for="item of federatedLoginOptions" :key="item.identifier" :item="item" />
                        </section>
                        <footer v-if="isError" class="error-container">
                            <article class="error-message">
                                <v-icon size="18" color="white" class="mr-2">mdi-alert</v-icon>
                                {{ errorMessage }}
                            </article>
                        </footer>
                    </section>
                    <section v-if="loading" class="inner-loader-content">
                        <v-progress-circular class="loader" indeterminate color="primary" />
                        <span class="logged-in-message">{{ loggedInMessage }}</span>
                    </section>
                </Transition>
            </article>

            <article v-if="currentPage === pages.HELP" class="login-box fade-in-element" :style="loginBoxStyle">
                <section class="inner-content">
                    <h2 class="heading">{{ $t('login.getHelp') }}</h2>
                    <section class="introduction">
                        <span class="help-introduction">{{ $t('login.helpIntroduction') }}</span>
                    </section>
                    <section class="recover-options">
                        <section>
                            <secondary-input
                                v-bind="emailAttrs"
                                id="email"
                                v-model="resetUsername"
                                :disabled="passwordLoading"
                            />
                            <div class="label-container">
                                <span for="email">{{ isValidEmail }}</span>
                            </div>
                        </section>

                        <secondary-action-button
                            :disabled="!formValid"
                            :loading="passwordLoading"
                            :dense="false"
                            @click="reset"
                        >
                            {{ $t('login.resetPassword') }}
                        </secondary-action-button>

                        <section class="small-text">
                            {{ $t('login.passwordInfo') }}
                        </section>
                    </section>
                    <footer class="recover-footer">
                        <a class="forgot-password-link" @click="gotoLogin">
                            {{ $t('login.returnToLogin') }}
                        </a>
                    </footer>
                </section>
            </article>
        </section>
    </section>
</template>

<script>
    import { pages } from '@/enums/auth.enums';

    import { useEventListener } from '@vueuse/core';
    import { getGreetingKey } from '@/helpers/auth.helper';
    import { mapActions, mapState } from 'vuex';
    import FederatedLogin from './FederatedLogin.vue';

    export default {
        name: 'Login',

        components: {
            FederatedLogin,
        },

        data() {
            return {
                pages,
                federatedLoginOptions: [{ name: 'Microsoft', identifier: 'microsoft', icon: 'microsoft' }],
                logo: require('@/assets/General/C1_logo_text_white.svg'),
                valid: false,
                enterEvent: null,
                passwordLoading: false,

                username: '',
                password: '',

                emailAttrs: {
                    rules: [
                        (v) => !!v || this.$t('login.emailRequired'),
                        (v) => (v && v.length <= 50) || this.$t('login.emailMaxLength'),
                        (v) => /.+@.+\..+/.test(v) || this.$t('login.emailInvalidFormat'),
                    ],
                    autocomplete: 'email',
                    required: true,
                    'persistent-placeholder': true,
                    placeholder: this.$t('login.email'),
                    type: 'text',
                },

                usernameAttrs: {
                    rules: [
                        (v) => !!v || this.$t('login.usernameRequired'),
                        (v) => (v && v.length <= 50) || this.$t('login.usernameMaxLength'),
                    ],
                    autocomplete: 'email',
                    required: true,
                    'persistent-placeholder': true,
                    placeholder: this.$t('login.username'),
                    type: 'text',
                },

                passwordAttrs: {
                    rules: [
                        (v) => !!v || this.$t('login.passwordRequired'),
                        (v) => (v && v.length <= 50) || this.$t('login.passwordMaxLength'),
                    ],
                    autocomplete: 'current-password',
                    required: true,
                    'persistent-placeholder': true,
                    placeholder: this.$t('login.password'),
                    type: 'password',
                },
            };
        },

        computed: {
            ...mapState('Auth', {
                loginErrorMessage: 'loginErrorMessage',
                loading: 'loading',
                serviceLoggingOut: 'serviceLoggingOut',
                isLoggedIn: 'isLoggedIn',
                currentPage: 'currentPage',
                loggedInUser: 'userObject',
            }),

            loggedInMessage() {
                if (this.serviceLoggingOut.length > 0) {
                    return this.$t('login.loggingOutMessage');
                }

                return this.$t('login.loggedInMessage');
            },

            errorMessage() {
                return this.$t(this.loginErrorMessage);
            },

            resetUsername: {
                get() {
                    return this.$store.state.Auth.resetUsername;
                },
                set(value) {
                    this.$store.commit('Auth/SET_RESET_USERNAME', value);
                },
            },

            displayText() {
                return this.$t(getGreetingKey());
            },

            loginBoxStyle() {
                if (this.loading) {
                    return {
                        maxHeight: '200px',
                    };
                }
                return {
                    maxHeight: '700px',
                };
            },

            isValid() {
                return this.valid && this.$refs.loginform.validate();
            },
            isValidEmail() {
                for (const rule of this.emailAttrs.rules) {
                    if (rule(this.resetUsername) !== true) {
                        return rule(this.resetUsername);
                    }
                }
                return '';
            },
            formValid() {
                return this.isValidEmail === '';
            },
            buttonIsDisabled() {
                return !this.valid || this.loading;
            },

            isError() {
                return this.errorMessage !== '';
            },
        },
        watch: {
            'loggedInUser.Lang': {
                handler(newVal) {
                    this.$root.$i18n.locale = newVal;
                },
                immediate: true,
                deep: true,
            },
        },

        async created() {
            await this.init();
        },

        mounted() {
            this.loadLogo();
        },

        methods: {
            ...mapActions('Auth', [
                'loginCheck',
                'checkForFederatedLoginResponse',
                'checkForErrorResponse',
                'setCurrentPage',
                'resetPassword',
            ]),

            async reset() {
                try {
                    this.passwordLoading = true;
                    await this.resetPassword();
                } catch (error) {
                    this.passwordLoading = false;
                    return;
                } finally {
                    this.passwordLoading = false;
                }
                this.gotoLogin();
            },

            gotoHelp() {
                this.setCurrentPage(this.pages.HELP);
            },

            gotoLogin() {
                this.setCurrentPage(this.pages.LOGIN);
            },

            loadLogo() {
                const image = new Image();
                image.src = this.logo;

                image.addEventListener('load', () => {
                    const elements = this.$el.querySelectorAll('.fade-in-element');

                    if (elements.length === 0) return;

                    for (const element of elements) {
                        element.classList.add('fade-in');
                    }
                });
            },

            async init() {
                if (this.isLoggedIn === true) {
                    this.$router.push('/cases');
                } else {
                    localStorage.clear();
                    this.setupLanguage();
                    this.setupEnterEvent();
                }

                await this.checkForFederatedLoginResponse(this.$route.query.federatedLogin);
                this.checkForErrorResponse(this.$route.query.error);
            },

            login() {
                this.loginCheck({ username: this.username, password: this.password, valid: this.isValid });
            },

            setupLanguage() {
                const allowedLocales = ['sv', 'en'];
                const browserLang = navigator.language.slice(0, 2);
                if (!allowedLocales.includes(browserLang)) {
                    this.$root.$i18n.locale = 'en';
                } else {
                    this.$root.$i18n.locale = browserLang;
                }
            },

            setupEnterEvent() {
                this.enterEvent = (event) => {
                    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                        this.login();
                    }
                };
                useEventListener(window, 'keyup', this.enterEvent);
            },
        },
    };
</script>

<style scoped>
    .label-container {
        color: red;
        font-size: 0.8rem;
        margin-top: 4px;
        height: 16px;
    }
    .recover-footer {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        flex-direction: column;
    }
    .recover-options {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(1fr);
        padding: 32px 0px;
        gap: 16px;
        flex: 0;
    }
    .introduction {
        height: 64px;
    }
    .help-introduction {
        font-size: 1.1rem;
    }
    .forgot-password-link {
        text-decoration: underline;
        font-size: 0.9rem;
    }
    .form-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .error-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        margin-top: 48px;
    }
    .error-message {
        font-size: 0.9rem;
        padding-left: 16px;
        padding-right: 16px;
        background-color: var(--v-error-base);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        height: 40px;
    }
    .form {
        display: flex;
        flex-direction: column;
    }
    .login-button {
        align-self: end;
    }
    .loader {
        align-self: center;
        justify-self: center;
    }
    .inner-content {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        height: 100%;
        width: 100%;
    }

    .inner-loader-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 8px;

        height: 100%;
        width: 100%;
    }

    .logged-in-message {
        font-size: 1.2rem;
        font-weight: 200;
        color: var(--v-gray2-base);

        justify-self: center;
        align-self: center;
    }
    .display-text {
        font-size: 1.2rem;
        font-weight: 200;
        color: var(--v-gray2-base);
    }
    .federated-login-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 8px;
    }
    .outer-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        height: 100vh;
    }
    .heading {
        font-size: 2.4rem;
        font-weight: 400;
        color: var(--v-gray4-base);
        padding-bottom: 16px;
    }
    .login-container {
        display: flex;
        justify-content: center;
        align-items: center;

        background: linear-gradient(to bottom, black, var(--v-gray4-base));
    }

    .brand-box {
        align-self: center;
        justify-self: end;
        padding: 48px;
        max-width: 600px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .logo-img {
        max-width: 180px;
    }

    .login-box {
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
        padding: 48px;
        max-width: 500px;
        align-self: center;
        overflow: hidden;
        transition: all 0.3s ease-out;
        height: 100%;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        opacity: 0 !important;
    }

    .brand-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 24px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    label {
        font-weight: 400;
        font-size: 0.9rem;
        color: var(--v-gray2-base);
    }

    .divider-container {
        display: flex;
        align-items: center;
        margin-top: 48px;
        margin-bottom: 48px;
    }

    .divider {
        flex-grow: 1;
        margin: 0 10px;
    }

    .divider-text {
        font-weight: 300;
        font-size: 1.2rem;
        color: var(--v-gray2-base);
    }

    .fade-in {
        opacity: 1 !important;
    }
    .fade-in-element {
        opacity: 0;
        transition: opacity 0.3s ease-in;
    }

    .small-text {
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }
</style>

<i18n lang="json">
{
    "en": {
        "login": {
            "morning": "Good morning! Ready to sign in?",
            "afternoon": "Good afternoon! Time to log in?",
            "evening": "Good evening! Let's get you logged in!",
            "or": "Or",
            "continue": "Continue",
            "signIn": "Sign in",
            "username": "Username",
            "password": "Password",
            "mondayMorning": "Happy Monday morning! Ready to start the week?",
            "fridayAfternoon": "Fantastic Friday afternoon! Almost time for the weekend!",
            "loggedInMessage": "Checking credentials...",
            "loggingOutMessage": "Logging out...",
            "usernameRequired": "Username is required",
            "emailRequired": "Email is required",
            "emailMaxLength": "Email must be less than 50 characters",
            "emailInvalidFormat": "Invalid email format",
            "usernameMaxLength": "Username must be less than 50 characters",
            "forgotPassword": "Forgot password?",
            "getHelp": "Get help",
            "helpIntroduction": "If you're encountering difficulties with signing in, reset your password.",
            "returnToLogin": "Return to login",
            "resetPassword": "Reset password",
            "email": "Email",
            "passwordInfo": "You will get an email with a new password. With the new password you can log in to SwyxIt! and change it to a password of your choice."
        },

        "error": {
            "userDoesNotExist": "There is no user associated with this email address",
            "couldNotAuthenticate": "Could not authenticate user, please try again",
            "integrationIsNotActive": "Integration is not active",
            "integrationIsIncorrectlyConfigured": "Integration is incorrectly configured, please contact support",
            "internalServerError": "Internal server error",
            "timeout": "Your login timed out, please try again.",
            "invalidCredentials": "Invalid username or password",
            "couldNotParseTokens": "Could not parse response from server, please try again or contact support",
            "tooManyRequests": "Too many requests, please try again later",
            "fillAllFields": "Fill in all fields",
            "fieldIsTooLong": "One or more fields are too long",
            "fieldIsNotText": "The fields are not text",
            "incorrectAuthorizationHeader": "The authorization header is either empty or isn't Basic",
            "passwordExpired": "Reset your password by logging in to SwyxIt!"
        }
    },
    "sv": {
        "login": {
            "morning": "God morgon! Är du redo att logga in?",
            "afternoon": "God eftermiddag! Dags att logga in?",
            "evening": "God kväll! Nu kör vi inloggningen!",
            "or": "Eller",
            "continue": "Fortsätt",
            "signIn": "Logga in",
            "username": "Användarnamn",
            "password": "Lösenord",
            "mondayMorning": "God måndag morgon! Är du redo för veckan?",
            "fridayAfternoon": "Fantastisk fredag eftermiddag! Snart helg!",
            "loggedInMessage": "Kontrollerar inloggningsuppgifter...",
            "loggingOutMessage": "Loggar ut...",
            "usernameRequired": "Användarnamn krävs",
            "emailRequired": "E-post krävs",
            "emailMaxLength": "E-post måste vara mindre än 50 tecken",
            "emailInvalidFormat": "Ogiltigt e-postformat",
            "usernameMaxLength": "Användarnamn måste vara mindre än 50 tecken",
            "forgotPassword": "Glömt lösenord?",
            "getHelp": "Få hjälp",
            "helpIntroduction": "Om du har problem med att logga in, återställ ditt lösenord.",
            "returnToLogin": "Återgå till inloggning",
            "resetPassword": "Återställ lösenord",
            "email": "E-post",
            "passwordInfo": "Du kommer att få ett e-postmeddelande med ett nytt lösenord. Med det nya lösenordet kan du logga in i SwyxIt! och ändra det till ett lösenord som du väljer själv."
        },

        "error": {
            "userDoesNotExist": "Det finns ingen användare associerad med e-postadressen",
            "couldNotAuthenticate": "Kunde inte autentisera användaren, vänligen försök igen",
            "integrationIsNotActive": "Integrationen är inte aktiv",
            "integrationIsIncorrectlyConfigured": "Integrationen är felkonfigurerad, vänligen kontakta support",
            "internalServerError": "Internt serverfel",
            "timeout": "Inloggningen tog för lång tid, vänligen försök igen.",
            "invalidCredentials": "Fel användarnamn eller lösenord",
            "couldNotParseTokens": "Kunde inte tolka svaret från servern, vänligen kontakta support",
            "tooManyRequests": "För många försök, vänligen försök igen senare",
            "fillAllFields": "Fyll i alla fält",
            "fieldIsTooLong": "Ett eller flera fält är för långt",
            "fieldIsNotText": "Fälten är inte text",
            "incorrectAuthorizationHeader": "Authorization header är antingen tom eller inte Basic",
            "passwordExpired": "Återställ ditt lösenord genom att logga in i SwyxIt!"
        }
    }
}
</i18n>
