module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "templateView": {
          "selThisTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select this template"])},
          "noTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No templates found"])},
          "addGotoTempl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add by going to the template manager."])},
          "templateManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template manager"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
          "onlyActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only activated templates are visible"])},
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])}
        }
      },
      "sv": {
        "templateView": {
          "selThisTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj denna mall"])},
          "noTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mallar hittade."])},
          "addGotoTempl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till genom att gå till mall-hanteringen."])},
          "templateManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mall-hanteringen"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicera"])},
          "onlyActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast aktiverade mallar syns"])},
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsvisning"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"templateView":{"selThisTemplate":"Select this template","noTemplates":"No templates found","addGotoTempl":"Add by going to the template manager.","templateManager":"Template manager","search":"Search","apply":"Apply","onlyActivated":"Only activated templates are visible","preview":"Preview"}},"sv":{"templateView":{"selThisTemplate":"Välj denna mall","noTemplates":"Inga mallar hittade.","addGotoTempl":"Lägg till genom att gå till mall-hanteringen.","templateManager":"Mall-hanteringen","search":"Sök","apply":"Applicera","onlyActivated":"Endast aktiverade mallar syns","preview":"Förhandsvisning"}}}')
  delete Component.options._Ctor
  
}
