<template>
    <v-card class="container mb-5 py-5 px-3" flat>
        <!-- label="Sök" -->
        <v-text-field
            v-model="search"
            style="border-radius: 6px"
            full-width
            hide-details
            outlined
            dense
            :label="$t('templateView.search')"
        />
        <v-card-text class="px-0">
            <v-expansion-panels v-if="queriedTemplates.length > 0" flat>
                <template v-for="(item, i) in queriedTemplates">
                    <v-expansion-panel v-if="item.IsActive" :key="i" style="margin: 3px" @change="setImages(i)">
                        <v-expansion-panel-header
                            :style="{
                                'background-color':
                                    clickedTemplate == i
                                        ? 'var(--v-primary-base)'
                                        : hoveredTemplate == i
                                          ? 'var(--v-gray3-base)'
                                          : '',
                                color: clickedTemplate == i ? 'white' : '',
                            }"
                            class="listItem"
                            @mouseover="hoveredTemplate = i"
                            @mouseleave="hoveredTemplate = null"
                            @click="clickedTemplate = clickedTemplate == i ? null : i"
                        >
                            <div class="ma-0, pa-0">
                                {{ item.Name }}
                            </div>
                            <template #actions>
                                <v-icon :color="clickedTemplate == i ? 'white' : ''">$expand</v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div
                                v-if="mouseoverContent"
                                class="inBetweenText"
                                :style="{ color: 'var(--v-primary-base' }"
                            >
                                {{ $t('templateView.apply') }}
                            </div>
                            <div v-else class="inBetweenText">
                                {{ $t('templateView.preview') }}
                            </div>
                            <div
                                :key="i"
                                v-dompurify-html="item.Content"
                                v-ripple
                                style="cursor: pointer"
                                class="contentContainer"
                                :style="{
                                    border: mouseoverContent
                                        ? '1px solid var(--v-primary-base)'
                                        : '1px solid rgba(0,0,0,0.05)',
                                }"
                                @click="selectedTemplate(item.Content)"
                                @mouseover="mouseoverContent = true"
                                @mouseleave="mouseoverContent = false"
                            ></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </template>
            </v-expansion-panels>
            <div v-else>
                <!-- Det finns tyvärr inga mallar. -->
                {{ $t('templateView.noTemplates') }}
                <br />
                <!-- Lägg till genom att gå till mall hanteringen. -->
                {{ $t('templateView.addGotoTempl') }}
                <br />
                <!-- Endast aktiverade mallar syns -->
                {{ $t('templateView.onlyActivated') }}
                <br />
                <br />
                <v-divider />
                <br />
                <!-- <v-btn dense color="primary" @click="$router.replace('/Admin/Templates')">Lägg till mall</v-btn> -->
                <v-btn dense color="primary" elevation="0" @click="$router.replace('/Admin/Templates')">
                    {{ $t('templateView.templateManager') }}
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import { replaceTemplateValues } from '@/utils';
    import { mapState, mapActions } from 'vuex';
    import { getImagesInContent } from '../../../directives/shadowDom';

    export default {
        data() {
            return {
                search: '',
                queriedTemplates: [],
                searchTimeout: null,
                mouseoverContent: false,
                hoveredTemplate: null,
                clickedTemplate: null,
            };
        },
        computed: {
            ...mapState('Cases', ['selectedCase']),
            ...mapState('QueueManager', ['allQueues']),

            loggedInUser() {
                return this.$store.state.Auth.userObject;
            },
            client() {
                return this.$store.state.Cases.clientNowOnCard?.data || null;
            },
            templates() {
                const currentQueueId = this.selectedCase.case.queueId;
                const currentQueueLang = this.allQueues.find((q) => q.ID === currentQueueId)?.Language;

                let templates = null;
                if (currentQueueLang) {
                    templates = this.$store.state.Admin.templates.filter(
                        (template) => template.Language === currentQueueLang
                    );
                } else {
                    templates = this.$store.state.Admin.templates;
                }

                return templates.map((item) => {
                    return {
                        ...item,
                        Content: replaceTemplateValues(item.Content, {
                            client: this.client,
                            agent: this.loggedInUser,
                            sender: this.sender,
                        }),
                    };
                });
            },
            sender() {
                return this.$store.state.Cases.systemEmails[0] || '';
            },
        },
        watch: {
            search() {
                if (this.searchTimeout) clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {
                    this.filterTemplatesBySearch();
                }, 300);
            },
            templates: {
                handler() {
                    this.filterTemplatesBySearch();
                },
                deep: true,
                immediate: true,
            },
        },
        created() {
            this.getAllQueues();
        },

        methods: {
            ...mapActions('QueueManager', ['getAllQueues']),
            getImagesInContent,

            filterTemplatesBySearch() {
                if (this.templates) {
                    this.queriedTemplates = this.templates.filter((item) => {
                        const itemContent = item.Content.toLowerCase();
                        const itemTitle = item.Name.toLowerCase();
                        const searchWord = this.search.toLowerCase();
                        return (
                            (item.CategoryId == 1 || item.CategoryId == 4) &&
                            (itemContent.includes(searchWord) || itemTitle.includes(searchWord)) &&
                            item.IsActive == 1
                        );
                    });
                } else {
                    return [];
                }
            },
            hide() {
                this.$store.dispatch('Cases/sideBarView', 'default');
            },
            selectedTemplate(templateText) {
                this.$store.dispatch('Cases/appendTemplateToTextField', '');
                this.$nextTick(() => {
                    this.$store.dispatch('Cases/appendTemplateToTextField', templateText);
                });
            },
            async setImages(itemIndex) {
                for (let i = 0; i < this.queriedTemplates.length; i++) {
                    if (i !== itemIndex) continue;
                    const content = this.queriedTemplates[i].Content;
                    this.queriedTemplates[i].Content = await this.getImagesInContent(content);
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .listItem {
        border-radius: 6px;
        background-color: var(--v-gray5-base);
    }
    .inBetweenText {
        height: 20px;
        margin-top: 2px;
        padding: 0px;
    }
    .container {
        width: 400px;
        height: 600px;
        overflow-y: scroll;
    }
    .contentContainer {
        white-space: pre-line;
        max-height: 300px;
        overflow-y: scroll;
        background-color: var(--v-gray5-base);
        border-radius: 6px;
        padding: 8px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "templateView": {
            "selThisTemplate": "Select this template",
            "noTemplates": "No templates found",
            "addGotoTempl": "Add by going to the template manager.",
            "templateManager": "Template manager",
            "search": "Search",
            "apply": "Apply",
            "onlyActivated": "Only activated templates are visible",
            "preview": "Preview"
        }
    },
    "sv": {
        "templateView": {
            "selThisTemplate": "Välj denna mall",
            "noTemplates": "Inga mallar hittade.",
            "addGotoTempl": "Lägg till genom att gå till mall-hanteringen.",
            "templateManager": "Mall-hanteringen",
            "search": "Sök",
            "apply": "Applicera",
            "onlyActivated": "Endast aktiverade mallar syns",
            "preview": "Förhandsvisning"
        }
    }
}
</i18n>
