const getImage = function (integrationName) {
    switch (integrationName.toLowerCase()) {
        case 'zendesk': {
            return require('@/assets/General/Integrations/zendesk.svg');
        }
        case 'salesforce': {
            return require('@/assets/General/Integrations/salesforce.svg');
        }
        case 'dynamics365': {
            return require('@/assets/General/Integrations/dynamics365.svg');
        }
        case 'fortnox': {
            return require('@/assets/General/Integrations/fortnox.svg');
        }
        case 'servicenow': {
            return require('@/assets/General/Integrations/servicenow.svg');
        }
        case 'lime': {
            return require('@/assets/General/Integrations/lime.svg');
        }
        case 'outlook': {
            return require('@/assets/General/outlook_icon_img.png');
        }
        case 'gmail': {
            return require('@/assets/General/gmail_icon_img.png');
        }
        case 'exchange': {
            return require('@/assets/General/exchange_icon_img.png');
        }

        case 'chatgpt': {
            return require('@/assets/General/ChatGPT_logo.png');
        }
        case 'whisper': {
            return require('@/assets/General/chat-gpt-open-ai.png');
        }
        case 'compodium': {
            return require('@/assets/General/compodium_logo.jpeg');
        }
        case 'brilliant': {
            return require('@/assets/General/brilliant_logo.svg');
        }
        case 'bankid': {
            return require('@/assets/General/BankID_logo.svg');
        }
        case 'dedu': {
            return require('@/assets/General/dedu_logo.png');
        }
        case 'microsoft': {
            return require('@/assets/FederatedLogin/Icons/microsoft.svg');
        }
        case 'microsoft-tts': {
            return require('@/assets/General/Azure-Speech.png');
        }
        case 's2': {
            return require('@/assets/General/Integrations/S2_logo.png');
        }
        case 'freeswitch': {
            return require('@/assets/General/FreeSWITCH_logo.png');
        }
        case 'sinch': {
            return require('@/assets/General/Sinch_logo.svg');
        }
        case 'swyx': {
            return require('@/assets/General/Swyx_logo.png');
        }
        case 'inet': {
            return require('@/assets/General/inet_icon_logo.svg');
        }
        case 'intersport': {
            return require('@/assets/General/intersport_logo.png');
        }
        case 'pindeliver': {
            return require('@/assets/General/Integrations/pindeliver_logo.png');
        }
        default: {
            return require('@/assets/General/C1_icon.png');
        }
    }
};

const copyToClipboard = async function (str) {
    try {
        str = str.slice(2);
        const b = '-';
        str = [str.slice(0, 6), b, str.slice(6)].join('');
        const isIE = !!document.documentMode; /* @cc_on!@ */
        if (isIE) {
            copyForIE(str);
        } else {
            await normalCopy(str);
        }
    } catch (error) {
        console.error('Failed to copy to clipboard', error);
    }
};

const normalCopy = function (str) {
    return new Promise((resolve, reject) => {
        navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
            if (result.state === 'granted' || result.state === 'prompt') {
                navigator.clipboard.writeText(str).then(
                    function () {
                        resolve();
                    },
                    function () {
                        reject();
                    }
                );
            }
        });
    });
};

const copyForIE = function (str) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.append(el);
    el.select();
    document.execCommand('copy');
    el.remove();
};

export { copyToClipboard, getImage };
