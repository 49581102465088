import axios from 'axios';

const getCategorizationRules = () => axios.get('/rules/categorization');
const addCategorizationRule = (payload) => axios.post('/rules/categorization', payload);
const updateCategorizationRule = (payload) => axios.put(`/rules/${payload.ruleId}/categorization`, payload);
const deleteCategorizationRule = (ruleId) => axios.delete(`/rules/${ruleId}/categorization`);

export default {
    getCategorizationRules,
    addCategorizationRule,
    updateCategorizationRule,
    deleteCategorizationRule,
};
