import axios from 'axios';

function getInvoiceDocuments({ from, to, queue, channel, timeFormat, reportType }) {
    return axios.get(
        `/statistics/report/invoices?from=${from}&to=${to}&queueId=${queue}&channel=${channel}&timeFormat=${timeFormat}&reportType=${reportType}`
    );
}

export default {
    getInvoiceDocuments,
};
