import { closeOutsideClickItems, automaticSortItems, caseListVisibleOptions } from '@/templates/filter.template';
import { localStoragePlugin } from '../plugins';

const state = {
    settingsTemplates: {
        automaticSortItems,
        closeOutsideClickItems,
        caseListVisibleOptions,
    },
    settingsStates: {
        automaticSort: null,
        closeOutsideClick: null,
        isCaseListVisible: null,
    },
};

const getters = {};

const actions = {
    initiation({ dispatch, rootState, state }) {
        const { automaticSort, closeOutsideClick, isCaseListVisible } = state.settingsStates;

        // If the user has already set their settings, use those settings.
        if ((automaticSort || closeOutsideClick) && isCaseListVisible) return;

        // Otherwise, use the default global settings.
        const { defaultAutomaticSort, defaultCloseOutsideClick, defaultIsCaseListVisible } =
            rootState.System.userSettings.cases.filters;
        // Find the default settings in the settings templates.
        const automaticSortItem = state.settingsTemplates.automaticSortItems.find(
            (item) => item.value === defaultAutomaticSort.active
        );
        const closeOutsideClickItem = state.settingsTemplates.closeOutsideClickItems.find(
            (item) => item.value === defaultCloseOutsideClick.active
        );

        const isCaseListVisibleItem = state.settingsTemplates.caseListVisibleOptions.find(
            (item) => item.value === defaultIsCaseListVisible.active
        );

        // Set the default settings.
        dispatch('setSettingsState', { automaticSort: automaticSortItem });
        dispatch('setSettingsState', { closeOutsideClick: closeOutsideClickItem });
        dispatch('setSettingsState', { isCaseListVisible: isCaseListVisibleItem });
    },
    setSettingsState({ commit, state }, payload) {
        const states = { ...state.settingsStates, ...payload };
        commit('SET_SETTINGS_STATE', states);
    },
};

const mutations = {
    SET_SETTINGS_STATE(state, data) {
        state.settingsStates = data;
    },
};

const plugins = [
    localStoragePlugin([
        {
            key: 'casesFilterSettingsStates',
            stateProperty: 'settingsStates',
            mutationName: 'Filter/SET_SETTINGS_STATE',
        },
    ]),
];

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    plugins,
};
