import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{ref:"group",staticClass:"d-flex",class:_vm.getPositionClass(_vm.position),attrs:{"cy-caseQueue":_vm.item.queue || 'none'}},[(_vm.item.queue)?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VChip,_vm._g(_vm._b({staticClass:"rounded pl-3 mr-1 nohover",style:({
                        width: _vm.itemState == false ? '40px' : 'auto',
                        fontSize: _vm.isBig ? '12px' : '12px',
                    }),attrs:{"color":_vm.item.UnreadMessages > 0 ? 'gray3' : 'gray5',"text-color":"gray2","dense":"","outlined":""}},'v-chip',attrs,false),on),[_c(VIcon,{staticClass:"mr-2",attrs:{"size":_vm.isBig ? 15 : 12}},[_vm._v("mdi-account-multiple")]),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.item.queue))])],1)]}}],null,false,339159647)},[_c('span',[_vm._v(_vm._s(_vm.item.queue))])])],1):_c('div',{ref:"group"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VChip,_vm._g(_vm._b({staticClass:"rounded pl-3 mr-1 nohover",style:({
                        width: _vm.itemState == false ? '40px' : 'auto',
                        fontSize: _vm.isBig ? '12px' : '12px',
                    }),attrs:{"dense":"","color":_vm.item.UnreadMessages > 0 ? 'gray3' : 'gray5',"text-color":"white","outlined":""}},'v-chip',attrs,false),on),[_c(VIcon,{staticClass:"mr-2",style:({ color: 'var(--v-gray2-base' }),attrs:{"size":_vm.isBig ? 15 : 12}},[_vm._v(" mdi-account-multiple ")]),_c('span',{staticClass:"text-truncate",style:({ color: 'var(--v-gray2-base' })},[_vm._v(" "+_vm._s(_vm.$t('queueChip.noQueue'))+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('queueChip.noQueue')))])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }