module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "activeCasesInboxModule": {
          "activeCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your active cases"])},
          "noActiveCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any active cases."])}
        }
      },
      "sv": {
        "activeCasesInboxModule": {
          "activeCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dina aktiva ärenden"])},
          "noActiveCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inga aktiva ärenden."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"activeCasesInboxModule":{"activeCases":"Your active cases","noActiveCases":"You don\u0027t have any active cases."}},"sv":{"activeCasesInboxModule":{"activeCases":"Dina aktiva ärenden","noActiveCases":"Du har inga aktiva ärenden."}}}')
  delete Component.options._Ctor
  
}
