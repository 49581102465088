import router from '@/router';
import leaderboard from '@/api/leaderboard/leaderboard';

import { debouncer } from '@/utils';
import {
    getTimePeriods,
    timePeriods,
    layouts,
    directions,
    sources,
    showTotalOptions,
    showProfileOptions,
    fieldOptions,
    sortingDirections,
    headerLayoutTemplate,
} from '@/templates/leaderboard.template';
import { directionTypes, layoutTypes, sourceTypes, stateValues, timePeriodTypes } from '@/enums/leaderboard.enums';
import { localStoragePlugin } from '../plugins';
import { setFullScreen } from '../../utils/Layout';

const state = {
    lists: {
        leaderboardItems: [],
        leaderboardHeaders: [],
        leaderboardFractions: [],
        individualItems: [],
    },
    settings: {
        inverted: false,
        directions,
        timePeriod: timePeriods[4],
        layout: layouts[0],
        source: sources[0],
        groups: [],
        showTotal: showTotalOptions[0],
        showProfile: showProfileOptions[1],
        fields: null,
        sorting: null,
        sortingDirection: sortingDirections[0],
        fullScreen: false,
    },
    debouncers: {
        leaderboard: null,
        individualLeaderboard: null,
        integrationInterval: null,
    },
    states: {
        header: stateValues.DEFAULT,
        globalLeaderboard: stateValues.DEFAULT,
        individualLeaderboard: stateValues.DEFAULT,
    },
    search: {
        users: '',
        statistics: '',
    },
    templates: {
        timePeriods,
        layouts,
        directions,
        sources: [],
        groups: [],
        showTotalOptions,
        showProfileOptions,
        fields: [],
        sortingDirections,
    },
    intervals: {
        getLeaderboard: null,
        leaderboardIntegration: null,
    },
    timers: {
        leaderboardIntegration: null,
    },
    keys: {
        leaderboardFilterMenu: 0,
    },
    leaderboardInfo: {
        totalData: null,
        lastSync: null,
    },
    modals: {
        chooseDate: false,
    },
    headerLayout: headerLayoutTemplate,
    individualLeaderboardUser: {},
};

const getters = {
    isDirectionOutbound(state) {
        return state.settings.direction === directionTypes.OUTBOUND;
    },
    showPodium(state) {
        return state.settings.layout.value !== layoutTypes.LIST;
    },
    showList(state, getters) {
        return state.settings.layout.value !== layoutTypes.PODIUM && getters.listItems.length > 0;
    },
    listItems() {
        const PODIUM_ITEMS = 3;
        const { leaderboardItems } = state.lists;
        if (state.settings.layout.value === layoutTypes.LIST) {
            return leaderboardItems;
        }
        const clonedItems = structuredClone(leaderboardItems);
        return clonedItems.slice(PODIUM_ITEMS) || [];
    },
};

const actions = {
    /// ////////////////////////////////////////////////
    // -------------- INITIALIZE VIEWS ---------------//
    /// ////////////////////////////////////////////////
    async initializeHeader({ dispatch }) {
        try {
            // Set state to initiated
            dispatch('setState', { header: stateValues.INITIATED });

            // Fetch leaderboard header
            await dispatch('getLeaderboardHeader');
        } catch (error) {
            // Set state to error if something went wrong
            dispatch('setState', { header: stateValues.ERROR });
        }
    },

    async initializeGlobalLeaderboard({ dispatch, state }) {
        try {
            if (state.states.header === stateValues.DEFAULT) {
                await dispatch('initializeHeader');
            }
            // Set state to initiated
            dispatch('setState', { globalLeaderboard: stateValues.INITIATED });

            dispatch('initDebouncedLeaderboard');

            dispatch('initDebouncedIntegrationInterval');
            dispatch('getLeaderboardInterval');

            // Fetch leaderboard
            const LOADING = true;
            await dispatch('getLeaderboard', LOADING);

            await dispatch('setLayout');

            // Fetch leaderboard every 5 seconds
            const LEADERBOARD_INTERVAL_TIME = 5000;
            const interval = setInterval(async () => {
                await dispatch('getLeaderboard');
            }, LEADERBOARD_INTERVAL_TIME);

            // Set interval
            dispatch('setIntervals', { getLeaderboard: interval });
        } catch (error) {
            // Set state to error if something went wrong
            dispatch('setState', { globalLeaderboard: stateValues.ERROR });
        }
    },
    async initIndividualLeaderboard({ dispatch, commit }, userId) {
        try {
            // Set state to initiated
            dispatch('setState', { individualLeaderboard: stateValues.INITIATED });

            // Fetch user and set it as current user
            const user = await dispatch('Users/getUser', userId, { root: true });
            if (!user) {
                dispatch('setState', { individualLeaderboard: stateValues.ERROR });
                return;
            }
            commit('SET_INDIVIDUAL_LEADERBOARD_USER', user);

            // Fetch individual leaderboard
            dispatch('initDebouncedIndividualLeaderboard');
            dispatch('getIndividualLeaderboard');
        } catch (error) {
            // Set state to error if something went wrong
            dispatch('setState', { individualLeaderboard: stateValues.ERROR });
        }
    },

    /// ////////////////////////////////////////////////
    // --------------- FINALIZE VIEWS ----------------//
    /// ////////////////////////////////////////////////
    finalizeHeader({ dispatch }) {
        dispatch('setState', { header: stateValues.DEFAULT });
    },
    finalizeGlobalLeaderboard({ dispatch }) {
        dispatch('setState', { globalLeaderboard: stateValues.DEFAULT });
        dispatch('clearIntervals', { getLeaderboard: null });
        dispatch('setHeaderLayout', headerLayoutTemplate);
        dispatch('stopLeaderboardIntegrationTimer');
    },
    finalizeIndividualLeaderboard({ dispatch }) {
        dispatch('setState', { individualLeaderboard: stateValues.DEFAULT });
    },

    /// ////////////////////////////////////////////////
    // --------------- SET DEBOUNCERS ----------------//
    /// ////////////////////////////////////////////////
    initDebouncedIndividualLeaderboard({ dispatch }) {
        // Debounce individual leaderboard fetch
        const DEBOUNCER_TIME = 500;
        const individualLeaderboard = debouncer(() => dispatch('getIndividualLeaderboard'), DEBOUNCER_TIME);
        dispatch('setDebouncer', { individualLeaderboard });
    },

    initDebouncedLeaderboard({ dispatch }) {
        // Debounce leaderboard fetch
        const DEBOUNCER_TIME = 500;
        const leaderboard = debouncer((val) => dispatch('getLeaderboard', val), DEBOUNCER_TIME);
        dispatch('setDebouncer', { leaderboard });
    },

    initDebouncedIntegrationInterval({ dispatch }) {
        // Debounce integration interval fetch
        const DEBOUNCER_TIME = 500;
        const integrationInterval = debouncer(() => dispatch('getLeaderboardInterval'), DEBOUNCER_TIME);
        dispatch('setDebouncer', { integrationInterval });
    },

    /// ////////////////////////////////////////////////
    // --------------- FETCHING DATA -----------------//
    /// ////////////////////////////////////////////////
    async getLeaderboard({ state, dispatch, commit }, loader = false) {
        const NO_ITEMS_LENGTH = 0;
        const emptyGroups = state.settings.groups !== null && state.settings.groups.length === NO_ITEMS_LENGTH;
        const emptyFields = state.settings.fields !== null && state.settings.fields.length === NO_ITEMS_LENGTH;
        const emptyDirections =
            state.settings.directions !== null && state.settings.directions.length === NO_ITEMS_LENGTH;

        // If no groups or fields are selected, don't fetch leaderboard
        if (emptyGroups || emptyFields || emptyDirections) {
            dispatch('setLeaderboardInfo', { totalData: null });
            dispatch('setLists', { leaderboardItems: [] });
            dispatch('setLayout');
            return;
        }

        // If loader is true, set state to fetching
        if (loader) {
            dispatch('setState', { globalLeaderboard: stateValues.FETCHING });
        }

        // Fetch leaderboard
        const payload = {
            source: state.settings.source.value,
            from: state.settings.timePeriod.value.from,
            to: state.settings.timePeriod.value.to,
            types: state.settings.directions?.map((direction) => direction.value) || [],
            search: state.search.users,
            groups: state.settings.groups?.map((group) => group.value) || [],
            fields: state.settings.fields?.map((field) => field.value) || [],
            sorting: {
                key: state.settings.sorting?.value || '',
                direction: state.settings.sortingDirection.value,
            },
        };
        const { data } = await leaderboard.getLeaderboard(payload);
        const { users, availableFields, sorting, fields, lastSync } = data;

        // Remove total data from leaderboard items and set it separately
        const TOTAL_DATA_USER_ID = 0;
        const leaderboardTotalData = users.find((item) => item.userId === TOTAL_DATA_USER_ID);
        const leaderboardItems = users.filter((item) => item.userId !== TOTAL_DATA_USER_ID);

        // Set leaderboard items and total data
        dispatch('setLeaderboardInfo', { totalData: leaderboardTotalData, lastSync });
        // commit('SET_LEADERBOARD_TOTAL_DATA', leaderboardTotalData);
        dispatch('setLists', { leaderboardItems });

        // Set layout and fields
        dispatch('setLayout');
        dispatch('setFields', availableFields);

        // Set sorting and trigger filtermenu update
        let settings = {};
        let keys = {};
        if (!state.settings.sorting) {
            const sortedField = state.templates.fields.find((field) => field.value === sorting.key);
            settings = { sorting: sortedField };

            const INCREMENT_AMOUNT = 1;
            keys = { leaderboardFilterMenu: state.keys.leaderboardFilterMenu + INCREMENT_AMOUNT };
        }
        if (!state.settings.fields) {
            const currentFields = fields.map((field) => {
                return state.templates.fields.find((templateField) => templateField.value === field);
            });
            settings = { ...settings, fields: currentFields };

            const INCREMENT_AMOUNT = 1;
            keys = { leaderboardFilterMenu: state.keys.leaderboardFilterMenu + INCREMENT_AMOUNT };
        }
        dispatch('setSettings', settings);
        dispatch('setKeys', keys);

        // Set state to loaded
        dispatch('setState', { globalLeaderboard: stateValues.LOADED });
    },

    async getIndividualLeaderboard({ dispatch }) {
        // Set state to fetching
        dispatch('setState', { individualLeaderboard: stateValues.FETCHING });

        // Fetch individual leaderboard
        const filter = {
            userId: state.individualLeaderboardUser.userId,
            search: state.search.statistics,
            source: state.settings.source.value,
            from: state.settings.timePeriod.value.from,
            to: state.settings.timePeriod.value.to,
            groups: state.settings.groups?.map((group) => group.value) || [],
        };
        const { data } = await leaderboard.getIndividualLeaderboard(filter);
        dispatch('setLists', { individualItems: data });

        // Set state to loaded
        dispatch('setState', { individualLeaderboard: stateValues.LOADED });
    },

    async getLeaderboardHeader({ dispatch, state }) {
        // Set state to fetching
        dispatch('setState', { header: stateValues.FETCHING });

        // Fetch leaderboard header
        await dispatch('setInitialGroups');
        await dispatch('setInitialSources');
        await dispatch('setTimePeriods');

        if (state.settings.fullScreen) {
            setFullScreen(true);
        }

        // Set state to loaded
        dispatch('setState', { header: stateValues.LOADED });
    },

    async getLeaderboardSources() {
        try {
            const { data } = await leaderboard.getLeaderboardSources();
            return data;
        } catch (error) {
            return [];
        }
    },

    async getLeaderboardInterval({ state, dispatch }) {
        try {
            const { data } = await leaderboard.getLeaderboardInterval({ source: state.settings.source.value });
            dispatch('stopLeaderboardIntegrationTimer');
            if (!data) return;
            dispatch('startLeaderboardIntegrationTimer', data);
        } catch (error) {
            console.error('Failed to fetch leaderboard sources', error, new Date());
        }
    },

    // /////////////////////////////////////////////////
    // ------------------ SETTERS --------------------//
    // /////////////////////////////////////////////////
    startLeaderboardIntegrationTimer({ dispatch, state }, { interval, start }) {
        const currentTime = Date.now();
        const startTime = new Date(start).getTime();
        const elapsedTime = currentTime - startTime;
        const remainingTime = interval - (elapsedTime % interval);

        const initial = Math.round(remainingTime / 1000);
        const regular = Math.round(interval / 1000);

        dispatch('setTimers', { leaderboardIntegration: { value: initial, max: regular } });

        const leaderboardIntegrationInterval = setInterval(function () {
            let time = state.timers.leaderboardIntegration.value - 1;
            if (time < 0) {
                time = regular;
            }
            dispatch('setTimers', { leaderboardIntegration: { value: time, max: regular } });
        }, 1000);

        dispatch('setIntervals', { leaderboardIntegration: leaderboardIntegrationInterval });
    },
    stopLeaderboardIntegrationTimer({ dispatch }) {
        dispatch('clearIntervals', { leaderboardIntegration: null });
        dispatch('setTimers', { leaderboardIntegration: null });
    },
    toggleInverted({ dispatch }) {
        dispatch('setSettings', { inverted: !state.settings.inverted });
    },
    setFullScreen({ dispatch, state }, payload) {
        if (payload === state.settings.fullScreen) return;

        setFullScreen(payload);

        dispatch('setSettings', { fullScreen: payload });
    },

    setSelectedLayout({ dispatch }, payload) {
        dispatch('setSettings', { layout: payload });
    },
    setSelectedSource({ dispatch }, { data, view }) {
        // Set source and reset fields and sorting due to different fields for different sources
        const settings = { source: data, sorting: null, fields: null };
        dispatch('setSettings', settings);
        switch (view) {
            case 'GlobalLeaderboard': {
                const LOADER = true;
                state.debouncers.leaderboard(LOADER);
                state.debouncers.integrationInterval();
                break;
            }
            case 'IndividualLeaderboard': {
                state.debouncers.individualLeaderboard();
                break;
            }
            default: {
                break;
            }
        }
    },

    async setInitialGroups({ state, dispatch, rootState }) {
        // Fetch all groups and set them as selectable options
        await dispatch('CompanyGroups/getAllCompanyGroups', '', {
            root: true,
        });
        const { allGroups } = rootState.CompanyGroups;
        const groups = allGroups.map((group) => {
            return {
                text: group.Name,
                value: group.ID,
                icon: 'mdi-account-group',
            };
        });

        // If no groups are selected, set all groups as selected
        if (!state.settings.groups.length) {
            await dispatch('setSettings', { groups });
        }

        // Set groups as selectable options
        dispatch('setTemplates', { groups });
    },
    async setInitialSources({ dispatch, state }) {
        // Get leaderboard sources
        const leaderboardSources = await dispatch('getLeaderboardSources');

        // Filter out integrations that are not active and not in list of possible sources
        const filteredSources = sources.filter((source) => {
            const activeSource = leaderboardSources.some(
                (leaderboardSource) => leaderboardSource.name === source.value
            );
            const defaultSources = [sourceTypes.C1, sourceTypes.SWITCH];
            return activeSource || defaultSources.includes(source.value);
        });

        // Set sources as selectable options
        dispatch('setTemplates', { sources: filteredSources });

        // If current source is not in list of possible sources, set first source as current source
        const sourceExists = filteredSources.some((source) => source.value === state.settings.source.value);
        if (sourceExists) return;
        dispatch('setSettings', { source: filteredSources[0] });
    },
    setTimePeriods({ dispatch }) {
        dispatch('setTemplates', { timePeriods: getTimePeriods() });
    },
    setLayout({ dispatch, state }) {
        if (!state.lists.leaderboardItems.length) return;
        const headers = [{ textKey: 'name', widthFractions: 3 }];
        const fractions = [`minmax(300px, ${headers[0].widthFractions}fr)`];

        const item = state.lists.leaderboardItems[0]?.dataPoints;

        for (const dataPoint of item) {
            const { key, widthFractions, value } = dataPoint;
            headers.push({ textKey: key, widthFractions, value });
            fractions.push(widthFractions + 'fr');
        }
        dispatch('setLists', { leaderboardHeaders: headers, leaderboardFractions: fractions });
    },
    setSelectedTimePeriod({ state, dispatch }, { data, view }) {
        if (data.value === 'chooseDate') {
            dispatch('setModals', { chooseDate: true });
            return;
        }
        dispatch('setSettings', { timePeriod: data });
        switch (view) {
            case 'GlobalLeaderboard': {
                const LOADER = true;
                state.debouncers.leaderboard(LOADER);
                break;
            }
            case 'IndividualLeaderboard': {
                state.debouncers.individualLeaderboard();
                break;
            }
            default: {
                break;
            }
        }
    },
    setCustomTimePeriod({ dispatch }, dates) {
        const view = router.history.current.name;
        const { from, to } = dates;
        const templateVal = state.templates.timePeriods.find((timePeriod) => {
            return timePeriod.value.from === from && timePeriod.value.to === to;
        });
        if (templateVal) {
            dispatch('setSelectedTimePeriod', { data: templateVal, view });
            return;
        }

        const val = state.templates.timePeriods.find((timePeriod) => timePeriod.value === 'chooseDate');

        const timePeriod = { ...val, text: `${from} - ${to}`, textKey: null, value: dates };
        dispatch('setSelectedTimePeriod', { data: timePeriod, view });
    },
    setFields({ dispatch }, fields) {
        const choosableFields = fieldOptions.filter((field) => {
            return fields.includes(field.value);
        });
        dispatch('setTemplates', { fields: choosableFields });
    },

    setUserSearch({ dispatch, state }, payload) {
        dispatch('setSearch', { users: payload || '' });
        state.debouncers.leaderboard();
    },
    setStatisticsSearch({ state, dispatch }, payload) {
        dispatch('setSearch', { statistics: payload || '' });
        state.debouncers.individualLeaderboard();
    },
    setSelectedGroups({ dispatch }, payload) {
        dispatch('setSettings', { groups: payload || [] });
        state.debouncers.leaderboard();
    },
    setSelectedFields({ dispatch }, fields) {
        let { sorting } = state.settings;
        if (!fields.includes(sorting)) {
            sorting = fields[0] || null;
        }
        dispatch('setSettings', { fields: fields || [], sorting });

        state.debouncers.leaderboard();
    },
    setSelectedSorting({ dispatch }, payload) {
        dispatch('setSettings', { sorting: payload || null });
    },
    setSelectedDirection({ state, dispatch }, payload) {
        dispatch('setSettings', { directions: payload });
        const LOADER = true;
        state.debouncers.leaderboard(LOADER);
    },
    setSelectedSortingDirection({ state, dispatch }, payload) {
        dispatch('setSettings', { sortingDirection: payload });
        state.debouncers.leaderboard();
    },
    setShowTotal({ dispatch }, payload) {
        dispatch('setSettings', { showTotal: payload });
    },
    setShowProfile({ dispatch }, payload) {
        dispatch('setSettings', { showProfile: payload });
    },
    setSettings({ commit, state }, payload) {
        const stateObject = { ...state.settings, ...payload };
        commit('SET_SETTINGS', stateObject);
    },
    setModals({ commit, state }, payload) {
        const modals = { ...state.settings, ...payload };
        commit('SET_MODALS', modals);
    },
    setKeys({ commit, state }, payload) {
        const stateObject = { ...state.keys, ...payload };
        commit('SET_KEYS', stateObject);
    },
    setIndividualState({ commit, state }, payload) {
        const stateObject = { ...state.individualStates, ...payload };
        commit('SET_INDIVIDUAL_STATE', stateObject);
    },
    setLists({ commit, state }, payload) {
        const lists = { ...state.lists, ...payload };
        commit('SET_LISTS', lists);
    },
    setDebouncer({ commit, state }, payload) {
        const debouncers = { ...state.debouncers, ...payload };
        commit('SET_DEBOUNCERS', debouncers);
    },
    setSearch({ commit, state }, payload) {
        const search = { ...state.search, ...payload };
        commit('SET_SEARCH', search);
    },
    setTemplates({ commit, state }, payload) {
        const templates = { ...state.templates, ...payload };
        commit('SET_TEMPLATES', templates);
    },
    setState({ commit, state }, payload) {
        const states = { ...state.states, ...payload };
        commit('SET_STATES', states);
    },
    setIntervals({ commit, state }, payload) {
        const intervals = { ...state.intervals, ...payload };
        commit('SET_INTERVALS', intervals);
    },
    setTimers({ commit, state }, payload) {
        const timers = { ...state.timers, ...payload };
        commit('SET_TIMERS', timers);
    },
    clearIntervals({ commit, state }, payload) {
        const intervals = { ...state.intervals, ...payload };

        // Clear intervals for all keys in payload
        const keys = Object.keys(payload);
        for (const key of keys) {
            clearInterval(state.intervals[key]);
        }
        commit('SET_INTERVALS', intervals);
    },
    setShowHeader({ dispatch }, payload) {
        dispatch('setHeaderLayout', { show: payload });
    },
    setClarifyHeader({ dispatch }, payload) {
        dispatch('setHeaderLayout', { clarify: payload });
    },
    setHeaderLayout({ state, commit }, payload) {
        const headerLayout = { ...state.headerLayout, ...payload };
        commit('SET_HEADER_LAYOUT', headerLayout);
    },
    setLeaderboardInfo({ state, commit }, payload) {
        const leaderboardInfo = { ...state.leaderboardInfo, ...payload };
        commit('SET_LEADERBOARD_INFO', leaderboardInfo);
    },
    handleTimePeriodConversion({ commit, state }) {
        // This check is done due to the key "timePeriod.value" has changed structure in localStorage/store and needs to be converted to an object with "from" and "to"
        // otherwise the leaderboard will not load
        // this fixes this issue for now but similar issues may occur in the future
        // should implement a better solution (e.g. logging out all users to ensure everyone's local storage is cleared) - Jesper 17.1.23
        const oldTimePeriod = state.settings.timePeriod;
        const timePeriodValueIsNumber = typeof oldTimePeriod.value === 'number';

        if (!timePeriodValueIsNumber) return;

        const timePeriodTypeKey = Object.keys(timePeriodTypes).find(
            (key) => timePeriodTypes[key] === oldTimePeriod.value
        );

        const timePeriodIndexMap = {
            TODAY: 0,
            YESTERDAY: 1,
            THIS_WEEK: 2,
            LAST_WEEK: 3,
            THIS_MONTH: 4,
            LAST_MONTH: 5,
        };

        const timePeriodIndex = timePeriodIndexMap[timePeriodTypeKey];
        const newTimePeriod = timePeriods[timePeriodIndex];

        // If a matching index was found, get the value from the correct object in the timePeriods array
        if (newTimePeriod) {
            const newTimePeriodValue = newTimePeriod.value;
            commit('SET_SETTINGS', { timePeriod: { ...oldTimePeriod, value: newTimePeriodValue } });
        }
    },
};

const mutations = {
    SET_LEADERBOARD_INFO(state, data) {
        state.leaderboardInfo = data;
    },
    SET_INDIVIDUAL_STATE(state, data) {
        state.individualStates = data;
    },
    SET_INDIVIDUAL_LEADERBOARD_USER(state, data) {
        state.individualLeaderboardUser = data;
    },
    SET_LISTS(state, data) {
        state.lists = data;
    },
    SET_DEBOUNCERS(state, data) {
        state.debouncers = data;
    },
    SET_SEARCH(state, data) {
        state.search = data;
    },
    SET_TEMPLATES(state, data) {
        state.templates = data;
    },
    SET_SETTINGS(state, data) {
        state.settings = data;
    },
    SET_MODALS(state, data) {
        state.modals = data;
    },
    SET_KEYS(state, data) {
        state.keys = data;
    },
    SET_STATES(state, data) {
        state.states = data;
    },
    SET_INTERVALS(state, data) {
        state.intervals = data;
    },
    SET_TIMERS(state, data) {
        state.timers = data;
    },
    SET_HEADER_LAYOUT(state, data) {
        state.headerLayout = data;
    },
};

const plugins = [
    localStoragePlugin([
        {
            key: 'leaderboardState',
            stateProperty: 'settings',
            mutationName: 'Leaderboard/SET_SETTINGS',
        },
    ]),
];

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    plugins,
};
