<template>
    <section class="disconnect-overlay" :class="{ show: showOverlay }">
        <div v-show="showOverlay" class="disconnect-message">
            <v-icon class="info-icon" color="white" size="20">mdi-alert-circle</v-icon>
            <p>{{ $t('overlay.message', { messages: getOverlayMessage() }) }}</p>
        </div>
    </section>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    import { debouncer } from '@/utils';

    export default {
        name: 'DisconnectOverlay',

        data() {
            return {
                showOverlay: false,
                timeout: null,
                socketDebounceTimer: null,
                onlineStatusDebouncer: debouncer(this.handleOnlineStatusChange, 2_000),
            };
        },

        computed: {
            ...mapGetters({
                isConnected: 'App/isConnected',
                overlayMessageGetter: 'App/getOverlayMessage',
            }),
        },

        watch: {
            isConnected: {
                handler(newValue) {
                    clearTimeout(this.timeout);
                    if (newValue) {
                        this.showOverlay = false;
                        return;
                    }
                    this.timeout = setTimeout(() => {
                        this.showOverlay = true;
                    }, 100);
                },
            },
        },

        mounted() {
            this.handleOnlineStatusChange();

            window.addEventListener('online', this.onlineStatusDebouncer);
            window.addEventListener('offline', this.onlineStatusDebouncer);
        },

        beforeDestroy() {
            window.removeEventListener('online', this.onlineStatusDebouncer);
            window.removeEventListener('offline', this.onlineStatusDebouncer);
        },

        methods: {
            ...mapActions({
                setIsDisconnected: 'App/setIsDisconnected',
                setIsSocketDisconnected: 'App/setIsSocketDisconnected',
            }),

            handleOnlineStatusChange() {
                console.log('handleOnlineStatusChange', navigator.onLine, new Date());
                this.setIsDisconnected(!navigator.onLine);
                // If we are online again, try to reconnect the socket
                if (navigator.onLine && !this.$socket.connected) {
                    this.$socket.disconnect();
                    this.$socket.connect();
                }
            },

            getOverlayMessage() {
                return this.$t(this.overlayMessageGetter);
            },
        },

        sockets: {
            connect() {
                console.log('connect socket', new Date());
                if (this.socketDebounceTimer) {
                    clearTimeout(this.socketDebounceTimer);
                }
                this.setIsSocketDisconnected(false);
            },

            disconnect() {
                console.log('disconnect socket', new Date());
                if (this.socketDebounceTimer) {
                    clearTimeout(this.socketDebounceTimer);
                }
                this.socketDebounceTimer = setTimeout(() => {
                    this.setIsSocketDisconnected(true);
                }, 20_000);
            },
        },
    };
</script>

<style>
    .disconnect-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100svw;
        height: 100svh;
        z-index: 9999999;
        display: none;
        user-select: none;
    }

    .disconnect-overlay.show {
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        transition: opacity 0.5s;
    }

    .disconnect-message {
        text-align: center;
        padding: 20px;
        background: #000;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.5s;
        display: grid;
        grid-template-columns: auto 1fr;
    }

    .disconnect-overlay.show .disconnect-message {
        opacity: 0.6;
    }

    .disconnect-message p {
        color: #fff;
        margin: 0;
    }

    .info-icon {
        padding-right: 10px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "overlay": {
            "message": "The application is locked because of the following reason(s): {messages}.",
            "noInternet": "No internet connection",
            "noConnection": "No connection to the server"
        }
    },
    "sv": {
        "overlay": {
            "message": "Applikationen är låst på grund av följande anledning(ar): {messages}.",
            "noInternet": "Ingen internetanslutning",
            "noConnection": "Ingen anslutning till servern"
        }
    }
}
</i18n>
