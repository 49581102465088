<template>
    <section class="comment-signature-container">
        <section v-if="toggle" v-dompurify-html="signature" class="comment-signature"></section>
        <section>
            <v-chip class="signature-chip" outlined @click="toggleSignature">
                {{ toggle ? $t('signature.hideSignature') : $t('signature.showSignature') }}
            </v-chip>
        </section>
    </section>
</template>

<script>
    import { getImagesInContent } from '../../../directives/shadowDom';

    export default {
        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                toggle: false,
                signature: '',
            };
        },
        async created() {
            this.signature = await this.getImagesInContent(this.item.signature);
        },

        methods: {
            getImagesInContent,
            toggleSignature() {
                this.toggle = !this.toggle;
            },
        },
    };
</script>

<style scoped lang="scss">
    .comment-signature-container {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 16px;
        width: 100%;
    }
    .comment-signature {
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        font-size: 12px;
    }

    .signature-chip {
        font-size: 12px;
        color: var(--v-gray2-base);
    }
</style>

<i18n lang="json">
{
    "en": {
        "signature": {
            "showSignature": "Show Signature",
            "hideSignature": "Hide Signature"
        }
    },

    "sv": {
        "signature": {
            "showSignature": "Visa signatur",
            "hideSignature": "Dölj signatur"
        }
    }
}
</i18n>
