import openinghours from '@/api/openinghours/openinghours';
import access from '@/api/access/openinghours';
import helper from '@/helpers/openinghours/openinghours.helper';
import { exceptionTypes } from '@/constants/enums/openinghours.enums';
import { convertDigitalToSeconds } from '@/utils/openhours.util';
import i18n from '../../i18n';

const state = {
    // soundfiles of OpenHours
    files: [],
    chunk: [],

    // openinghours/exceptions
    multiQueueList: [],
    days: [],
    exceptions: [],
    openingHourData: {
        OHId: null,
        queueId: null,
        queueType: null,
        channelType: null,
    },
    dateRange: {},
    selectedQueue: null,
    selectedChannelType: 'call', // default value
    selectedWelcomeAudio: null,
    selectedClosedAudio: null,

    exceptionToGo: null,
    exceptionPaintBrush: {
        color: null,
        type: null,
    },
    exceptionCreatorModalShow: false,
};

// Getter functions
const getters = {
    getQueues(state) {
        const list = state.multiQueueList;
        return list.reduce((acc, cur) => {
            for (const queue of cur.queues) {
                acc.push({
                    queueId: queue.queueId,
                    queueName: queue.queueName,
                    queueType: queue.queueType,
                });
            }
            return acc;
        }, []);
    },
};

const actions = {
    setModalShow({ commit }, data) {
        commit('SET_MODAL_SHOW', data);
    },
    setExceptions({ commit }, data) {
        commit('SET_EXCEPTIONS', data);
    },

    findQueueAndAssignToSelected({ state, dispatch }, _queue) {
        // loop throught multiList, if we find a queue with the exception we wanna reach, assign it to selectedQueue
        for (const multiList of state.multiQueueList) {
            const queue = multiList.queues.find(
                (q) => _queue.queueId === q.queueId && _queue.queueType === q.queueType
            );

            if (!queue) return;

            dispatch('setSelectedQueue', {
                queueId: queue.queueId,
                queueType: queue.queueType,
                queueName: queue.queueName,
            });
            dispatch('setSelectedChannelType', _queue.channelType);
        }
    },
    async saveHours({ state, dispatch }) {
        const payload = {
            groupId: state.selectedQueue.queueId,
            queueType: state.selectedQueue.queueType,
            channelType: state.selectedChannelType,
            openHours: helper.timeValuesIntoDigital(state.days) || [],
            name: state.selectedQueue.queueName,
            welcome: state.selectedWelcomeAudio,
            closed: state.selectedClosedAudio,
            OHId: state.openingHourData.OHId || null,
        };

        await dispatch('setOpeningHours', payload);
        await dispatch('getOpenHours', {
            queueId: state.selectedQueue.queueId,
            queueType: state.selectedQueue.queueType,
            channelType: state.selectedChannelType,
        });
    },
    updateDay({ commit }, data) {
        commit('UPDATE_DAY', data);
    },
    setDays({ commit }, data) {
        commit('SET_DAYS', data);
    },
    setSelectedWelcomeAudio({ commit }, data) {
        commit('SET_SELECTED_WELCOME_AUDIO', data);
    },
    setSelectedClosedAudio({ commit }, data) {
        commit('SET_SELECTED_CLOSED_AUDIO', data);
    },
    setDaysDefault({ commit }) {
        commit('SET_DAYS_DEFAULT');
    },
    setSelectedChannelType({ commit }, data) {
        commit('SET_SELECTED_CHANNELTYPE', data);
        commit('SET_EXCEPTION_PAINT_BRUSH', { color: null, type: null });
    },
    setSelectedQueue({ commit }, data) {
        commit('SET_SELECTED_QUEUE', data);
    },
    changeExceptionPaintBrush({ commit, state, dispatch }, newType) {
        if (newType === state.exceptionPaintBrush.type) {
            newType = null;
        }

        const currentType = state.exceptionPaintBrush.type;

        if (!newType) {
            dispatch('applyPen', { currentType, newType: null });
            commit('SET_EXCEPTION_PAINT_BRUSH', { color: null, type: null });
            return;
        }

        dispatch('applyPen', { currentType, newType });

        const color = helper.getColorByExceptionType(newType);

        // apply new value to global paintType variable
        commit('SET_EXCEPTION_PAINT_BRUSH', { color, type: newType });
    },
    applyPen(_, data) {
        // small helper function to get the correct class name for the pen
        const getPenClass = (type) => {
            switch (type) {
                case exceptionTypes.CLOSED: {
                    return 'pen-cursor-closed';
                }
                case exceptionTypes.TRANSFER: {
                    return 'pen-cursor-transfer';
                }
                case exceptionTypes.OPEN: {
                    return 'pen-cursor-open';
                }
                case exceptionTypes.MESSAGE: {
                    return 'pen-cursor-talfil';
                }
                default: {
                    return '';
                }
            }
        };

        // if a current type is supplied, we remove the class
        if (data?.currentType) {
            document.querySelector('#exception_calendar').classList.remove(getPenClass(data.currentType));
        }

        // if we have a new type, we add the class
        if (data?.newType) {
            document.querySelector('#exception_calendar').classList.add(getPenClass(data.newType));
        }
    },

    //= ===================================================//
    //                  OPEN HOURS ACTIONS                //
    //= ===================================================//

    async createMultiQueueList({ commit }) {
        // let res = await createMultiQueueList(dispatch, this.state);
        const { data } = await access.getUserAccess(this.state.Auth.userObject.userId);

        commit('SET_MULTI_QUEUELIST', data);
    },

    setOpeningHoursDatas({ commit }, payload) {
        commit('SET_OH_DATA', payload);
    },

    getOpenHours({ commit, state, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            openinghours
                .getOpenHours(payload)
                .then(async (res) => {
                    commit('APPLY_OPENING_HOURS', res.data);
                    commit('SET_SELECTED_WELCOME_AUDIO', Number.parseInt(res.data.WelcomeSoundFileId) || 0);
                    commit('SET_SELECTED_CLOSED_AUDIO', Number.parseInt(res.data.ClosedSoundFileId) || 0);

                    await dispatch('setOpeningHoursDatas', {
                        queueType: state.selectedQueue.queueType, // is it OQ or C1 queue
                        OHId: res.data.ID, // the queues index ID
                        queueId: state.selectedQueue.queueId, // the queues QueueId
                        queue: state.selectedQueue, // optimize
                        openHours: res.data.OpenHours,
                        channelType: state.selectedChannelType,
                    });

                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setOpeningHours(_, payload) {
        return new Promise((resolve, reject) => {
            openinghours
                .setOpeningHours(payload)
                .then((res) => {
                    resolve(res.data);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    goToException({ commit }, payload) {
        commit('SET_EXCEPTION_TO_GO_TO', payload);
    },

    getOpeningHoursExceptions({ commit }, payload) {
        return new Promise((resolve, reject) => {
            openinghours
                .getOpeningHoursExceptions(payload)
                .then((res) => {
                    const exceptions = res.data.map((item, i) => {
                        const event = {
                            name: item.title,
                            color: helper.getColorByExceptionType(item.exceptionType), // this.getColorByIndex(i)
                            start: item.start,
                            end: item.end,
                            desc: item.description,
                            fileId: item.soundFileId,
                            timed: true,
                            dbID: item.id,
                            appliedToQueues: item.queues,
                            index: i,
                            queueId: item.queueId,
                            queueType: item.queueType,
                            exceptionType: item.exceptionType,
                            transferTo: item.transferTo || null,
                            reasonText: item.reasonText || null,
                        };

                        if (item.recurring) {
                            event.recurring = item.recurring;
                            event.recurring.active = true;
                        }
                        return event;
                    });

                    commit('SET_EXCEPTIONS', exceptions);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setOpeningHoursException({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            openinghours
                .setOpeningHoursException(payload)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch('createMultiQueueList'); // reconstruct multiQueueList for visual update
                        dispatch('getOpeningHoursExceptions', {
                            queueId: state.openingHourData.queueId,
                            queueType: state.openingHourData.queueType,
                            channelType: state.openingHourData.channelType,
                            dateRange: state.dateRange,
                        });
                        if (res.data.message) {
                            this._vm.$toasted.show(res.data.message, {
                                icon: 'check',
                                type: 'success',
                            });
                        }
                        resolve(res.data);
                    } else {
                        if (res.data.message) {
                            this._vm.$toasted.show(res.data.message, {
                                icon: 'cancel',
                                type: 'error',
                            });
                        }
                        reject(res.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteOpeningHoursException({ dispatch }, id) {
        return new Promise((resolve, reject) => {
            openinghours
                .deleteOpeningHoursException(id)
                .then((res) => {
                    dispatch('createMultiQueueList'); // reconstruct multiQueueList for visual update
                    dispatch('getOpeningHoursExceptions', {
                        queueId: state.openingHourData.queueId,
                        queueType: state.openingHourData.queueType,
                        dateRange: state.dateRange,
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getExceptionTemplates(_) {
        return new Promise((resolve, reject) => {
            openinghours
                .getExceptionTemplates()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    createExceptionTemplate(_, payload) {
        return new Promise((resolve, reject) => {
            openinghours
                .createExceptionTemplate(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteExceptionTemplate(_, payload) {
        return new Promise((resolve, reject) => {
            openinghours
                .deleteExceptionTemplate(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getFilesLibraryByLoggedInUser({ commit }) {
        return new Promise((resolve, reject) => {
            openinghours
                .getFilesLibraryByLoggedInUser(this.state.Auth.userObject.userId)
                .then((res) => {
                    commit('SET_FILES', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },

    getFile(_, name) {
        return new Promise((resolve, reject) => {
            openinghours
                .getFile(name)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    getAllFiles({ commit }) {
        return new Promise((resolve, reject) => {
            openinghours
                .getAllFiles()
                .then((res) => {
                    commit('SET_FILES', res.data.data);
                    resolve(res.data.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },

    uploadFile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            openinghours
                .uploadFile(payload)
                .then((res) => {
                    const [createdFile] = res.data;
                    commit('PUSH_FILE_TO_LIST', createdFile);
                    resolve(res.data);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    getFileUsageInQueues(_, id) {
        return new Promise((resolve, reject) => {
            openinghours
                .getFileUsageInQueues(id)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },

    removeFile(_, name) {
        return new Promise((resolve, reject) => {
            openinghours
                .removeFile(name)
                .then(async (res) => {
                    resolve(res.data.data);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.deleted'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    getExceptionSettings() {
        return new Promise((resolve, reject) => {
            openinghours
                .getExceptionSettings()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    setExceptionSettings(_, payload) {
        return new Promise((resolve, reject) => {
            openinghours
                .setExceptionSettings(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },

    updateFile(_, payload) {
        return new Promise((resolve, reject) => {
            openinghours
                .updateFileOpeningHoursFileMetaData(payload)
                .then(async (res) => {
                    await this.dispatch('Openinghours/getFilesLibraryByLoggedInUser');
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    getQueueOpeninghours(_, queueId) {
        return new Promise((resolve, reject) => {
            openinghours
                .getQueueOpeninghours(queueId)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('openinghoursStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
};

const mutations = {
    SET_MODAL_SHOW(state, data) {
        state.exceptionCreatorModalShow = data;
    },
    SET_FILES(state, data) {
        state.files = data;
    },
    PUSH_FILE_TO_LIST(state, data) {
        state.files.push(data);
    },
    UPDATE_FILE_ITEM(state, data) {
        const index = state.files.findIndex((item) => item.ID === data.ID);
        state.files[index] = data;
    },
    SET_EXCEPTION_TO_GO_TO(state, data) {
        state.exceptionToGo = data;
    },
    SET_EXCEPTIONS(state, data) {
        state.exceptions = data;
    },
    SET_OH_DATA(state, data) {
        state.openingHourData = data;
    },
    SET_MULTI_QUEUELIST(state, data) {
        state.multiQueueList = data;
    },
    SET_SELECT_DATERANGE(state, data) {
        state.dateRange = data;
    },
    SET_EXCEPTION_PAINT_BRUSH(state, data) {
        state.exceptionPaintBrush = data;
    },
    SET_SELECTED_QUEUE(state, data) {
        state.openingHourData.queueId = data?.queueId;
        state.openingHourData.queueType = data?.queueType;

        state.selectedQueue = data;
    },
    SET_SELECTED_CHANNELTYPE(state, data) {
        state.openingHourData.channelType = data;

        state.selectedChannelType = data;
    },
    SET_SELECTED_WELCOME_AUDIO(state, data) {
        state.selectedWelcomeAudio = data;
    },
    SET_SELECTED_CLOSED_AUDIO(state, data) {
        state.selectedClosedAudio = data;
    },
    SET_DAYS_DEFAULT(state) {
        state.days = [
            { id: 1, dayName: i18n.t('global.weekDays.monday'), value: [], checked: null },
            { id: 2, dayName: i18n.t('global.weekDays.tuesday'), value: [], checked: null },
            { id: 3, dayName: i18n.t('global.weekDays.wednesday'), value: [], checked: null },
            { id: 4, dayName: i18n.t('global.weekDays.thursday'), value: [], checked: null },
            { id: 5, dayName: i18n.t('global.weekDays.friday'), value: [], checked: null },
            { id: 6, dayName: i18n.t('global.weekDays.saturday'), value: [], checked: null },
            { id: 7, dayName: i18n.t('global.weekDays.sunday'), value: [], checked: null },
            // { id: 8, dayName: 'Lunchrast', value: [], checked: null }
        ];
    },
    APPLY_OPENING_HOURS(state, data) {
        if (!data.OpenHours.length) {
            // add default
            for (const d of state.days) {
                d.value = ['480', '1020'];
                d.checked = true;
                d.RelId = null;
            }
            return;
        }

        // reset all values, for reactivity
        state.days = state.days.map((item) => {
            item.value = [];
            item.checked = false;
            item.RelId = null;
            return item;
        });

        const days = data.OpenHours;

        if (days.length === 0) return;

        state.days = state.days.map((item) => {
            const correctDays = days.filter((d) => d.Type === item.id);
            if (!correctDays.length) {
                return item;
            }

            item.checked = Boolean(correctDays.some((d) => d.Active));

            for (const d of correctDays) {
                item.value.push(...convertDigitalToSeconds([d.Start, d.End]));
            }

            item.RelId = correctDays[0].RelId;

            return item;
        });
    },
    UPDATE_DAY(state, data) {
        const { id, value, checked } = data;
        const index = state.days.findIndex((item) => item.id === id);
        if (index === -1) return;

        state.days[index] = {
            ...state.days[index],
            value,
            checked,
        };
    },
    SET_DAYS(state, data) {
        state.days = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
