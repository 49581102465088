// target server/customer
export const target = ''; // what configuration to use
export const dev = process.env.NODE_ENV !== 'production'; // Automatically set to production when building for production
const finalTarget = dev ? (typeof dev === 'string' ? dev : 'development') : target.toLowerCase();

const { params } = require(`./cParams/husväxeln`);

// Set the customer URL to the current host, which is hostname + port
const customerURL = window.location.host;

// Set the customer first URL to use the current protocol and the customer URL
export const customerFirstUrl = `${window.location.protocol}//${customerURL}/`; // Must end with / AKA slash;

const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
export const freeSWITCHUrl = `${protocol}://${customerURL}/sip`;

// Same as customerFirstUrl
export const mediaUrl = customerFirstUrl;

export const revision = process.env.VUE_APP_VERSION;

export const swyxTimeOffsetInHours = 4; // hours !OBS -- THIS IS USED TO GET CORRECT TIME FROM SWYX, THIS IS PROBABLY DUE TO A BUG IN SWYX/WRONG IMPLEMENTATION OF TIMEZONE
// the real solution is to fix the offset from swyx, but until then this will have to exist.

export const vuexStrictMode = Boolean(dev); // if dev = 1 use strict mode in vuex, in production this is not recommended

export default {
    customerParams: params,
    customerFirstUrl,
    revision,
    mediaUrl,
    swyxTimeOffsetInHours,
    vuexStrictMode,
    freeSWITCHUrl,
};

export const customerParams = params;
