<template>
    <div v-if="displaySLA" :style="indicatorStyle" :class="{ pulse: urgent }">
        <div v-if="isCircularIndicator" class="stackElements">
            <v-progress-circular
                :class="{ inQueueAnimationTemp: isReversingInQueueAnimation }"
                rotate="-90"
                :size="size"
                :width="size / 2"
                class="circularSLAIndicator"
                :value="slaPercentagePrimary"
                :color="slaIndicatorColor"
            />
            <v-progress-circular
                :class="{ inQueueAnimationTemp: isReversingInQueueAnimation }"
                rotate="-90"
                :size="size"
                :width="size / 2"
                class="circularSLAIndicator secondaryIndicator"
                :value="slaPercentageSecondary"
                :color="slaIndicatorColor"
            />
        </div>
        <v-icon v-else-if="caseIsClosed" color="color2" :size="size / 2 + 2">mdi-check-bold</v-icon>
        <div v-else :style="indicatorStyle" class="pastSLA"></div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { statusTypes } from '@/templates/cases.template';
    import { caseStatuses } from '@/enums/cases.enums';
    import { millisecondsInMinute } from 'date-fns/constants';
    import { getServiceTime, getSpinnerPercentage } from '@/utils/Design';

    export default {
        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
            urgencyTime: {
                type: Number,
                default: 5 * millisecondsInMinute,
            },
            size: {
                type: Number,
                default: 20,
            },
        },

        computed: {
            ...mapState({
                dateNowRealTime: (state) => state.Cases.dateNow,
            }),
            indicatorStyle() {
                return {
                    display: 'grid',
                    'place-items': 'center',
                    height: `${this.size}px`,
                    width: `${this.size}px`,
                    'grid-template': `${this.size - 2}px / ${this.size - 2}px`,
                    'border-radius': `${this.size / 2 + 2}px`,
                    border: this.slaOutlineColor,
                };
            },
            caseIsClosed() {
                return this.item.status === caseStatuses.CLOSED;
            },
            serviceTime() {
                return this.getServiceTime(this.item.sla, this.item.dateTime, 'text', this.dateNowRealTime);
            },
            slaPercentagePrimary() {
                if (this.item.status === caseStatuses.IN_QUEUE) {
                    return this.getSpinnerPercentage(9, 0, Math.round(this.dateNowRealTime / 1000) % 9);
                }
                return this.getSpinnerPercentage(
                    new Date(this.item.sla).getTime(),
                    new Date(this.item.dateTime).getTime(),
                    this.dateNowRealTime
                );
            },
            slaPercentageSecondary() {
                if (this.item.status === caseStatuses.IN_QUEUE) {
                    return this.getSpinnerPercentage(9, 0, Math.round(this.dateNowRealTime / 1000) % 9, true);
                }
                return this.getSpinnerPercentage(
                    new Date(this.item.sla).getTime(),
                    new Date(this.item.dateTime).getTime(),
                    this.dateNowRealTime,
                    true
                );
            },
            urgent() {
                return (
                    new Date(this.item.sla).getTime() - this.dateNowRealTime < this.urgencyTime &&
                    (this.item.status === caseStatuses.OPEN || this.item.status === caseStatuses.ONGOING)
                );
            },
            isReversingInQueueAnimation() {
                return this.item.status === caseStatuses.IN_QUEUE && Math.round(this.dateNowRealTime / 1000) % 9 === 0;
            },
            displaySLA() {
                return this.serviceTime !== '' && this.item.status !== caseStatuses.DELETED;
            },
            isCircularIndicator() {
                return (
                    ((this.item.status === caseStatuses.OPEN || this.item.status === caseStatuses.ONGOING) &&
                        this.dateNowRealTime < new Date(this.item.sla).getTime()) ||
                    this.item.status === caseStatuses.IN_QUEUE ||
                    this.item.status === caseStatuses.SNOOZED
                );
            },
            slaIndicatorColor() {
                return this.item.status === caseStatuses.SNOOZED ? this.getSnoozeColor() : 'color3';
            },
            slaOutlineColor() {
                switch (this.item.status) {
                    case caseStatuses.SNOOZED: {
                        return `solid 1px ${this.getSnoozeColor()}`;
                    }
                    case caseStatuses.CLOSED: {
                        return 'solid 1px var(--v-color2-base)';
                    }
                    default: {
                        return (
                            'solid 1px ' +
                            (this.dateNowRealTime < new Date(this.item.sla).getTime()
                                ? 'var(--v-color3-base)'
                                : 'var(--v-accent5-base)')
                        );
                    }
                }
            },
        },

        methods: {
            getSpinnerPercentage,
            getServiceTime,
            getSnoozeColor() {
                return statusTypes.find((t) => t.value === caseStatuses.SNOOZED)?.color;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .secondaryIndicator {
        opacity: 60%;
    }
    .pastSLA {
        background-color: var(--v-accent5-base);
    }
    .stackElements {
        display: grid;
        grid-template: v-bind(innerDiameter) / v-bind(innerDiameter);
        place-items: center;
    }
    .stackElements * {
        grid-area: 1 / 1 / 2 / 2;
    }
    .pulse {
        animation: pulse 2s infinite;
    }
    .inQueueAnimationTemp {
        transform: scaleX(-1);
    }

    @keyframes pulse {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.3;
        }
    }
</style>
<style>
    .circularSLAIndicator .v-progress-circular__underlay {
        display: none;
    }
</style>
