import rules from '../../api/rules/rules';
import { states } from '../../enums/states.enums';
import i18n from '../../i18n';
import { getChangedKeysInObject } from '../../utils/index';

const state = {
    rules: [],
    ruleFormData: {
        categories: [],
        fields: [],
        keywords: [],
        queues: [],
    },
    initialRuleFormData: null,
    headersMetadata: {
        fields: [
            {
                value: 'from',
                text: i18n.t('rulesStore.from'),
            },
            {
                value: 'to',
                text: i18n.t('rulesStore.to'),
            },
            {
                value: 'subject',
                text: i18n.t('rulesStore.subject'),
            },
            {
                value: 'content',
                text: i18n.t('rulesStore.content'),
            },
            {
                value: 'cc',
                text: i18n.t('rulesStore.cc'),
            },
        ],
    },
    deleteRuleDialog: {
        title: i18n.t('rulesStore.deleteRuleTitle'),
        message: i18n.t('rulesStore.deleteRuleMessage'),
        confirmText: i18n.t('global.yes'),
        declineText: i18n.t('global.no'),
    },
    componentStates: {
        table: states.LOADING,
        form: states.LOADED,
    },
};

const getters = {
    getRulesData: (state) => {
        const { categoriesMetadata, queuesMetadata, fields: headerFields } = state.headersMetadata;
        if (!categoriesMetadata || !queuesMetadata || !headerFields) {
            return {};
        }
        return state.rules.reduce((acc, rule) => {
            const categories = rule.categories?.map((categoryId) => {
                return categoriesMetadata.find((category) => category.id === categoryId);
            });
            const queues = rule.queues?.map((queueId) => {
                return queuesMetadata.find((queue) => queue.ID === queueId);
            });
            const fields = rule.fields?.map((fieldValue) => {
                return headerFields.find((field) => field.value === fieldValue);
            });
            if (categories && queues && fields) {
                acc[rule.ruleId] = {
                    ...rule,
                    categories,
                    queues,
                    fields,
                };
            }
            return acc;
        }, {});
    },
    getRuleHasChanged: (state) => {
        return JSON.stringify(state.initialRuleFormData) !== JSON.stringify(state.ruleFormData);
    },
    getChangedFields: (state) => {
        const changedFields = {};
        for (const key in state.initialFormData) {
            if (state.initialFormData[key] !== state.currentFormData[key]) {
                changedFields[key] = state.currentFormData[key];
            }
        }
        return changedFields;
    },
    getFormValidity: (state) => {
        return Object.entries(state.ruleFormData).every(([key, value]) => key === 'ruleId' || value.length > 0);
    },
};

const actions = {
    async init({ dispatch }) {
        dispatch('setStates', { component: 'form', state: states.LOADING });
        await dispatch('getHeadersMetadata');
        await dispatch('getCategorizationRules');
        dispatch('setStates', { component: 'form', state: states.LOADED });
    },
    setStates({ commit }, payload) {
        commit('SET_STATE', { component: payload.component, newState: payload.state });
    },
    async getHeadersMetadata({ commit, dispatch, state }) {
        const [categoriesMetadata, queuesMetadata] = await Promise.all([
            dispatch('Cases/getCaseCategories', null, { root: true }),
            dispatch('getQueuesMetadata'),
        ]);
        const data = {
            ...state.headersMetadata,
            categoriesMetadata,
            queuesMetadata,
        };
        commit('SET_HEADERS_METADATA', data);
    },

    async getQueuesMetadata({ dispatch }) {
        const data = await dispatch('QueueManager/getQueues', {}, { root: true });
        const queues = data.map((queue) => {
            return { Name: queue.Name, ID: queue.ID };
        });
        queues.unshift({
            Name: !data ? i18n.t('casesStore.wrong') : i18n.t('casesStore.noQueue'),
            ID: 0,
        });
        return queues;
    },

    async getCategorizationRules({ commit, dispatch }) {
        try {
            await dispatch('setStates', { component: 'table', state: states.LOADING });
            const { data } = await rules.getCategorizationRules();
            commit('SET_RULES', data);
            await dispatch('setStates', { component: 'table', state: states.LOADED });
        } catch (error) {
            console.log(error);
            await dispatch('setStates', { component: 'table', state: states.ERROR });
        }
    },

    async addCategorizationRule({ dispatch, state }) {
        try {
            await rules.addCategorizationRule(state.ruleFormData);
        } catch (error) {
            dispatch('setStates', { component: 'form', state: states.ERROR });
            this._vm.$toasted.error(i18n.t('rulesStore.wrongCreating'), {
                duration: 10_000,
                action: {
                    text: i18n.t('global.close'),
                    onClick: (_, toast) => {
                        toast.goAway(0);
                    },
                },
            });
            throw error;
        }
    },
    async updateCategorizationRule({ state, dispatch }) {
        try {
            await dispatch('setStates', { component: 'form', state: states.LOADING });
            const changedKeys = getChangedKeysInObject(state.initialRuleFormData, state.ruleFormData);
            const payload = { ruleId: state.ruleFormData.ruleId, ...changedKeys };
            await rules.updateCategorizationRule(payload);
            await dispatch('getCategorizationRules');
            dispatch('setStates', { component: 'form', state: states.LOADED });
        } catch (error) {
            this._vm.$toasted.error(i18n.t('rulesStore.wrongUpdating'), {
                duration: 10_000,
                action: {
                    text: i18n.t('global.close'),
                    onClick: (_, toast) => {
                        toast.goAway(0);
                    },
                },
            });
            console.error(error);
        }
    },
    async deleteCategorizationRule({ dispatch, state }) {
        try {
            dispatch('setStates', { component: 'form', state: states.LOADING });
            await rules.deleteCategorizationRule(state.ruleFormData.ruleId);
            dispatch('setStates', { component: 'form', state: states.LOADED });
            await dispatch('getCategorizationRules');
        } catch (error) {
            console.log(error);
            this._vm.$toasted.error(i18n.t('rulesStore.wrongDeleting'), {
                duration: 10_000,
                action: {
                    text: i18n.t('global.close'),
                    onClick: (_, toast) => {
                        toast.goAway(0);
                    },
                },
            });
        }
    },
    setRuleFormData({ commit }, payload) {
        commit('SET_RULE_FORM_DATA', payload);
    },
    resetRuleFormData({ commit }) {
        commit('RESET_RULE_FORM_DATA');
    },
    updateFormData({ commit }, payload) {
        commit('UPDATE_FORM_DATA', payload);
    },
    setInitialRuleFormData({ commit }, payload) {
        commit('SET_INITIAL_RULE_FORM_DATA', payload);
    },
};
const mutations = {
    SET_INITIAL_RULE_FORM_DATA(state, rule) {
        state.initialRuleFormData = rule;
    },
    UPDATE_FORM_DATA(state, { key, value }) {
        state.ruleFormData[key] = value;
    },
    SET_RULES(state, rules) {
        state.rules = rules;
    },

    SET_STATE(state, { component, newState }) {
        if (state.componentStates.hasOwnProperty(component)) {
            state.componentStates[component] = newState;
        }
    },
    SET_HEADERS_METADATA(state, headersMetadata) {
        state.headersMetadata = headersMetadata;
    },

    SET_RULE_FORM_DATA(state, payload) {
        state.ruleFormData = payload;
    },
    RESET_RULE_FORM_DATA(state) {
        state.ruleFormData = {
            categories: [],
            fields: [],
            keywords: [],
            queues: [],
        };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
