// Import i18n instance
import client from '@/api/client/client';
import i18n from '../../i18n';
import { events, states } from '../../enums/client.enums';

const { modalStates } = require('../../enums/client.enums');
const { formTypes } = require('../../enums/creator.enums');

// State object
const state = {
    actionButtonsClient: [
        {
            textKey: 'creator.bindClient',
            icon: 'mdi-arrow-right',
            color: 'var(--v-gray2-base)',
            value: 'bindClient',
            tooltip: i18n.t('creator.bindClientTooltip'),
        },
        {
            textKey: 'creator.mergeClient',
            icon: 'mdi-arrow-left-right',
            color: 'var(--v-gray2-base)',
            value: 'mergeClient',
            tooltip: i18n.t('creator.bindClientTooltip'),
        },
    ],

    actionButtonsShadow: [
        {
            textKey: 'creator.mergeClient',
            icon: 'mdi-arrow-left-right',
            color: 'var(--v-gray2-base)',
            value: 'mergeClient',
            tooltip: i18n.t('creator.bindClientTooltip'),
        },
    ],

    actionButtonsContact: [
        {
            textKey: 'creator.bindContact',
            icon: 'mdi-arrow-right',
            color: 'var(--v-gray2-base)',
            value: 'bindContact',
            tooltip: i18n.t('creator.bindContactTooltip'),
        },
        {
            textKey: 'creator.mergeClient',
            icon: 'mdi-arrow-left-right',
            color: 'var(--v-gray2-base)',
            value: 'mergeClient',
            tooltip: i18n.t('creator.bindClientTooltip'),
        },
    ],

    modal: null,

    currentSelectedClient: null,

    clientCardState: localStorage.getItem('clientCardState') || states.OPEN,
};

// Getter functions
const getters = {
    selectedClientId: (state) => state.currentSelectedClient?.data?.clientId,
};

// Actions
const actions = {
    SOCKET_clientUpdate({ dispatch, commit, rootState }, payload) {
        console.log('SOCKET_clientUpdate', payload);

        if (payload.type === events.UPDATE_CLIENT) {
            // We need to block this in frontend if subscription would be wrong or if the client is not the same as the one on the card
            if (state.currentSelectedClient.data.clientId !== payload.data.client.data.clientId) return;

            commit('SET_CURRENT_SELECTED_CLIENT', payload.data.client);

            const { clientNowOnCard } = rootState.Cases;

            // If the selected client is the same as the client on the card update the client on the card
            if (
                state.currentSelectedClient?.data?.clientId &&
                clientNowOnCard?.data?.clientId &&
                state.currentSelectedClient.data.clientId === clientNowOnCard.data.clientId
            ) {
                const newClientOnCard = {
                    ...clientNowOnCard,
                    assignedTo: payload.data.assignedTo,
                    assignedToType: payload.data.assignedToType,
                    data: payload.data.client.data,
                    contacts: payload.data.client.contacts,
                };
                dispatch('Cases/setClientOnCard', newClientOnCard, { root: true });
                return;
            }

            const newClientOnCard = {
                ...clientNowOnCard,
                contacts: payload.data.client.contacts,
            };

            dispatch('Cases/setClientOnCard', newClientOnCard, { root: true });
        }

        if (payload.type === events.NEW_CLIENT) {
            commit('SET_CURRENT_SELECTED_CLIENT', payload.data.client);
            dispatch('Cases/setClientOnCard', payload.data.client, { root: true });
        }
    },

    unsubscribeToClient(_, oldVal) {
        if (oldVal?.clientId) {
            this._vm.$socket.emit('unsubscribeToClient', oldVal.clientId);
        }
    },

    subscribeToClient(_, newVal) {
        if (newVal?.clientId) {
            this._vm.$socket.emit('subscribeToClient', newVal.clientId);
        }
    },

    async getClientByCaseId(_, id) {
        try {
            const result = await client.getClientByCaseId(id);
            return result.data;
        } catch (error) {
            return null;
        }
    },

    async getClients(_, payload) {
        try {
            const result = await client.getClients(payload);
            return result.data;
        } catch (error) {
            return null;
        }
    },

    async getAddresses(_, payload) {
        try {
            const result = await client.getAddresses(payload);
            return result.data;
        } catch (error) {
            return null;
        }
    },

    async updateClient({ dispatch, rootState }, clientData) {
        try {
            delete clientData.type;
            await client.updateClientFields(clientData);

            this._vm.$toasted.show(i18n.t('client.updated'), {
                icon: 'mdi-content-save',
                type: 'success',
            });

            return clientData;
        } catch (error) {
            if (error?.response?.data?.name) {
                const message = i18n.t('client.clientAlreadyExists', { client: error.response.data.name });
                // A client already exists with the field we are trying to update and with this name
                this._vm.$toasted.show(message, {
                    icon: 'cancel',
                    type: 'error',
                });
                // If we fail to set the data we get the data again from the server
                dispatch('Cases/clientCard', rootState.Cases.selectedCase.case.caseId, { root: true });
                return null;
            }

            this._vm.$toasted.show(i18n.t('client.updatedError'), {
                icon: 'cancel',
                type: 'error',
            });

            return null;
        }
    },

    setCurrentSelectedClient({ commit }, client) {
        commit('SET_CURRENT_SELECTED_CLIENT', client);
    },

    handleAction({ dispatch, commit }, action) {
        switch (action.value) {
            case 'openCaseAssignModal': {
                commit('SET_MODAL_STATE', modalStates.CASE_ASSIGN);
                break;
            }

            case 'showContactPerson': {
                commit('SET_MODAL_STATE', modalStates.CONTACT_PERSON);
                break;
            }

            case 'bindClient': {
                const { clientId, name } = state.currentSelectedClient.data;
                dispatch(
                    'Creator/openForm',
                    { id: formTypes.BIND_CLIENT, keys: { from: { value: clientId }, name: { value: name } } },
                    { root: true }
                );
                break;
            }

            case 'mergeClient': {
                const { clientId, name } = state.currentSelectedClient.data;
                dispatch(
                    'Creator/openForm',
                    {
                        id: formTypes.MERGE_CLIENT,
                        keys: { from: { value: clientId }, to: { clientId }, name: { value: name } },
                    },
                    { root: true }
                );
                break;
            }

            case 'bindContact': {
                const { clientId, name } = state.currentSelectedClient.data;
                dispatch(
                    'Creator/openForm',
                    { id: formTypes.BIND_CONTACT, keys: { from: { value: clientId }, name: { value: name } } },
                    { root: true }
                );
                break;
            }

            case 'openCrm': {
                commit('SET_MODAL_STATE', modalStates.CRM);
                break;
            }

            case 'changeContent': {
                commit('SET_MODAL_STATE', modalStates.CONTENT);
                break;
            }

            case 'openSidebarViewRecordings': {
                commit('SET_MODAL_STATE', modalStates.RECORDINGS);
                break;
            }

            case 'queueIdentifierAssign': {
                commit('SET_MODAL_STATE', modalStates.QUEUE_ASSIGN);
                break;
            }
            case 'iframe': {
                commit('SET_MODAL_STATE', modalStates.IFRAME);
                break;
            }

            default: {
                commit('SET_MODAL_STATE', null);
                break;
            }
        }
    },

    toggleClientCard({ commit }) {
        commit('TOGGLE_CLIENT_CARD');
    },
};

// Mutations
const mutations = {
    SET_MODAL_STATE(state, modalState) {
        state.modal = modalState;
    },
    SET_CURRENT_SELECTED_CLIENT(state, client) {
        state.currentSelectedClient = client;
    },
    TOGGLE_CLIENT_CARD(state) {
        if (state.clientCardState === states.OPEN) {
            state.clientCardState = states.CLOSED;
            localStorage.setItem('clientCardState', states.CLOSED);
            return;
        }
        state.clientCardState = states.OPEN;
        localStorage.setItem('clientCardState', states.OPEN);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
