<template>
    <div class="mainDiv">
        <div
            id="bar"
            class="userBar d-flex flex-column justify-end align-center softShadow"
            @mouseover="openInfo()"
            @mouseleave="closeInfo()"
        >
            <Transition v-for="(item, index) of items" :key="index">
                <div
                    class="userBar__item"
                    :style="{
                        height: getHeight(item) + '% !important',
                        background: generateColor(item.channelType),
                        borderRadius: roundCorners(index),
                        borderTop: getBorder(index),
                    }"
                >
                    <div class="shimmer-effect"></div>
                </div>
            </Transition>
        </div>
        <div
            v-if="userSettings.userModal.showPotentialCommunications.active"
            class="userBar__menu softShadow text-center"
            :style="infoMenuSize"
            @mouseover="openInfo()"
            @mouseleave="closeInfo()"
        >
            <div class="softShadowHard">
                <v-tabs v-model="tab" fixed-tabs>
                    <v-tabs-slider color="primary" />
                    <v-tooltip bottom open-delay="200">
                        <template #activator="{ on, attrs }">
                            <v-tab
                                v-bind="attrs"
                                :style="{
                                    backgroundColor: 'white',
                                    color: tab === 0 ? 'black !important' : '',
                                    borderRadius: '8px 0 0 0',
                                    width: '50%',
                                    maxWidth: '50%',
                                }"
                                style="transition: all; transition-duration: 0.3s"
                                :class="tab === 0 ? 'softShadow' : ''"
                                class="no-uppercase"
                                v-on="on"
                            >
                                <h4 style="font-size: 14px; font-weight: 400">{{ $t('active') }}</h4>
                            </v-tab>
                        </template>
                        <span>{{ $t('amountActive') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom open-delay="200">
                        <template #activator="{ on, attrs }">
                            <v-tab
                                v-bind="attrs"
                                :style="{
                                    backgroundColor: 'white',
                                    color: tab === 1 ? 'black !important' : '',
                                    borderRadius: '0 8px 0 0',
                                    width: '50%',
                                    maxWidth: '50%',
                                }"
                                style="transition: all; transition-duration: 0.3s"
                                :class="tab === 1 ? 'softShadow' : ''"
                                class="no-uppercase"
                                v-on="on"
                            >
                                <h4 style="font-size: 14px; font-weight: 400">{{ $t('potential') }}</h4>
                            </v-tab>
                        </template>
                        <span>{{ $t('potentialBeforeFull') }}</span>
                    </v-tooltip>
                </v-tabs>
            </div>

            <div class="d-flex flex-wrap justify-space-between pa-2 mt-2" style="max-width: 300px">
                <v-tooltip v-for="(item, index) of capacityLeftArray" :key="index" bottom>
                    <template #activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            class="pa-2 ma-2 d-flex flex-column justify-center align-center rounded softShadowHard"
                            style="background: white; width: 25%"
                            v-on="on"
                        >
                            <div
                                class="userBar__item d-flex justify-center align-center"
                                :style="{
                                    height: '40px !important',
                                    width: '40px !important',
                                    background: generateColor(item.channel),
                                    borderRadius: '50%',
                                }"
                            >
                                <v-icon color="white" size="26">
                                    {{ caseTypeIcon(item.channel) }}
                                </v-icon>
                            </div>
                            <div class="d-flex">
                                <h4 class="mt-3" style="font-weight: 400">
                                    {{ tab === 0 ? item.active : item.totalCapacity }}
                                </h4>
                            </div>
                        </div>
                    </template>
                    <span>{{ tab === 0 ? $t('activeChannel') : $t('potentialChannel') }} {{ item.text }}</span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>
<script>
    import { generateColor } from '@/utils/Colors';
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                openOptions: false,
                closeInfoMenuTimeout: null,
                openInfoMenuTimeout: null,
                closing: false,
                tab: 0,
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),
            settings() {
                return this.$store.state.Users.divertSettings;
            },
            items() {
                const items = this.$store.state.Users.currentDialogs;
                // add channelData to each item
                return items.map((item) => {
                    const channelData = this.settings.find(
                        (channel) => channel.channelData.value === item.internalType
                    );
                    return {
                        ...item,
                        channelData,
                    };
                });
            },
            divertSettings() {
                return this.$store.state.Users.divertSettings;
            },
            capacityLeftArray() {
                return this.divertSettings.map((item) => {
                    const activeDialogs = this.items.filter((dialog) => {
                        return dialog.internalType === item.channelData.value;
                    });

                    return {
                        channel: item.channelData.value,
                        active: activeDialogs.length,
                        totalCapacity: this.calculateAmountBeforeFull(item),
                        text: item.channelData.nameTranslate[this.$i18n.locale],
                    };
                });
            },
            totalActiveDialogs() {
                return this.items.length;
            },

            infoMenuSize() {
                if (this.openOptions) {
                    return {
                        width: '300px',
                        height: '300px',
                    };
                }
            },
        },
        methods: {
            generateColor,
            calculateAmountBeforeFull(item) {
                if (!item) {
                    return 0;
                }

                const totalActiveWeight = this.items.reduce((acc, curr) => {
                    return acc + curr.channelData.weight;
                }, 0);
                const totalActive = this.items.filter((el) => el.internalType === item.channelType).length;

                const settingForType = this.divertSettings.find(
                    (setting) => setting.channelData.value === item.channelData.value
                );
                const max = Math.round(100 * settingForType.multiplier);
                const amountLeft = max - totalActiveWeight;

                const calculatedLeft = Math.floor(amountLeft / settingForType.weight);

                if (calculatedLeft < settingForType.amount - totalActive) {
                    return calculatedLeft;
                }

                return settingForType.amount - totalActive;
            },
            caseTypeIcon(type) {
                const obj = this.settings.find((channel) => channel.channelData.value === type);
                const icon = obj.channelData?.icon;
                if (icon == undefined) {
                    return 'mdi-help-circle';
                }
                return icon;
            },
            getHeight(item) {
                if (!item) {
                    return 0;
                }
                return Math.floor((item.channelData.weight / this.powerLevel(item)) * 100);
            },

            powerLevel(item) {
                return Math.round(100 * item.channelData.multiplier);
            },
            roundCorners(index) {
                if (index === this.items.length - 1) {
                    return '0px 0px 16px 16px';
                }
                if (index === 0) {
                    return '16px 16px 0px 0px';
                }
                return '';
            },
            getBorder(index) {
                if (index === 0) {
                    return '';
                }
                return '1px solid white';
            },
            openInfo() {
                if (this.closing) return;

                clearTimeout(this.closeInfoMenuTimeout);
                this.openInfoMenuTimeout = setTimeout(() => {
                    this.openOptions = true;
                }, 250);
            },
            closeInfo() {
                if (this.closing) return; // don't close if it's already closing
                clearTimeout(this.openInfoMenuTimeout);

                this.closeInfoMenuTimeout = setTimeout(() => {
                    this.openOptions = false;
                    this.tab = 0;
                    // only set closing to false if it wasn't set before
                    setTimeout(() => {
                        this.closing = false;
                    }, 500);
                    this.closing = true;
                }, 500);
            },
        },
    };
</script>
<style scoped lang="scss">
    .no-uppercase {
        text-transform: unset !important;
    }
    .mainDiv {
        bottom: 200px;
        transform: translateX(-150%);
    }
    .userBar {
        transition: all 0.5s ease;
        background: white;
        overflow: hidden;
        box-sizing: content-box;
        width: 16px;
        height: 300px;
        border-radius: 16px;
    }

    .userBar__item {
        box-sizing: border-box;
        position: relative;
        width: 16px;
        transition: all 0.5s ease;
        overflow: hidden;
    }

    .userBar__menu {
        box-sizing: border-box;
        position: fixed;
        bottom: 0px;
        height: 0px;
        width: 0px;
        padding: 0px;
        background: white;
        border-radius: 8px;
        transform: translateX(-110%);
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        overflow: hidden;
    }

    .shimmer-effect {
        --delay: calc(5s * random());
        position: relative;
        width: 100%;
        height: 100px;
        background-color: transparent;
    }

    .shimmer-effect:before {
        --delay: calc(2s * random());
        content: '';
        position: absolute;
        top: -100px;
        left: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(to top, transparent, rgba(255, 255, 255, 0.5), transparent);
        animation: shimmer 3s infinite;
        animation-delay: calc(var(--delay) * 1ms);
    }

    @keyframes shimmer {
        0% {
            top: 100%;
        }
        100% {
            top: -100px;
        }
    }
</style>
<i18n lang="json">
{
    "en": {
        "activeChannel": "Amount of active for channel",
        "potentialChannel": "Amount of potential for channel",
        "potentialBeforeFull": "Amount of potential dialogs before full",
        "potential": "Potential",
        "active": "Active",
        "amountActive": "Amount of active dialogs"
    },
    "sv": {
        "activeChannel": "Antal aktiva för kanal",
        "potentialChannel": "Antal potentiella för kanal",
        "potentialBeforeFull": "Antal potentiella dialoger innan fullt",
        "potential": "Potentiella",
        "active": "Aktiva",
        "amountActive": "Antal aktiva dialoger"
    }
}
</i18n>
