import axios from 'axios';

// * Activate AI on channel
function activateAIOnChannel(payload) {
    return axios.post(`/integrations/chatgpt/auto-dialog-settings`, payload);
}

function getAutoDialogSettings(queueId, channel) {
    return axios.get(`/integrations/chatgpt/auto-dialog-settings/queue/${queueId}/${channel}`);
}

// Datasources
function getSources() {
    return axios.get(`integrations/chatgpt/data-sources`);
}
function deleteSources(id, fileId) {
    return axios.delete(`integrations/chatgpt/data-source/${id}/file-id/${fileId}`);
}
function saveSource(payload) {
    return axios.post(`integrations/chatgpt/data-source`, payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

// Auto categorization
function getAutoCategorization() {
    return axios.get(`/integrations/chatgpt/text-utilizer/auto-categorization`);
}

function saveAutoCategorization(payload) {
    return axios.post(`/integrations/chatgpt/text-utilizer/auto-categorization`, payload);
}

// Manage instructions
function getInstructions() {
    return axios.get(`/integrations/chatgpt/instructions`);
}

function saveInstruction(payload) {
    return axios.post(`/integrations/chatgpt/instruction`, payload);
}

function deleteInstruction(id) {
    return axios.delete(`/integrations/chatgpt/instruction/${id}`);
}

// Manage prompts
function getAllDataSources() {
    return axios.get(`/integrations/chatgpt/prompts/data-sources`);
}

function deletePrompt(id) {
    return axios.delete(`/integrations/chatgpt/prompt/${id}`);
}
function savePrompt(payload) {
    return axios.post(`/integrations/chatgpt/prompt`, payload);
}

function getAllContexts() {
    return axios.get(`/integrations/chatgpt/context`);
}

function getSpecificPrompt(id) {
    return axios.get(`/integrations/chatgpt/prompt/${id}`);
}

function getAvailablePrompts(context) {
    return axios.get(`/integrations/chatgpt/prompts?context=${context}`);
}

// * Do the command to chatgpt
function command(payload) {
    return axios.post(`/integrations/chatgpt/command`, payload);
}

function smartSearch(payload) {
    return axios.post(`/integrations/chatgpt/smart-search`, payload);
}

function smartSearchConversation() {
    return axios.get(`/integrations/chatgpt/smart-search-conversation`);
}

export default {
    getAvailablePrompts,
    command,
    getAllContexts,
    getSpecificPrompt,
    savePrompt,
    deletePrompt,
    saveInstruction,
    getInstructions,
    deleteInstruction,
    getAutoCategorization,
    saveAutoCategorization,
    saveSource,
    deleteSources,
    getSources,
    smartSearch,
    smartSearchConversation,
    getAllDataSources,

    activateAIOnChannel,
    getAutoDialogSettings,
};
