<template>
    <div class="internal incoming">
        <header class="internal-header internal-section">
            <CommonAvatar
                v-if="item.userId"
                :key="item.userId"
                class="internal-avatar"
                :userId="item.userId"
                :size="32"
            />
            <SystemAvatar v-else :size="32" />

            <section class="internal-section-header">
                <div class="internal-info">
                    <div class="internal-from text-truncate">{{ item.from }}</div>
                    <div class="internal-date text-truncate incoming-chip">{{ $t('comment.internal') }}</div>
                    <div class="internal-top-field text-truncate"></div>
                    <div class="internal-date text-truncate">{{ formatDate(item.dateTime) }}</div>
                </div>
            </section>
        </header>
        <!-- Subject will be included later -->
        <!--
        <section v-if="item.subject" class="internal-title internal-section">{{ formatSubject(item.subject) }}</section>
        -->
        <section class="internal-content"><div v-dompurify-html="item.comment"></div></section>
    </div>
</template>
<script>
    import { getDay } from '@/utils/DateFormatter';
    import { format } from 'date-fns';
    import { mapState, mapActions } from 'vuex';

    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import SystemAvatar from '@/components/Global/SystemAvatar.vue';

    export default {
        components: {
            CommonAvatar,
            SystemAvatar,
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),
        },

        methods: {
            ...mapActions({
                setAttachChecked: 'Comments/setAttachChecked',
            }),

            formatSubject(subject) {
                const MAX_LENGTH = 300;
                const START_INDEX = 0;

                if (subject.length > MAX_LENGTH) {
                    return subject.slice(START_INDEX, MAX_LENGTH) + '...';
                }
                return subject;
            },

            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },

            getDay,
            format,
        },
    };
</script>
<style scoped lang="scss">
    .internal-section-header {
        display: flex;
        align-items: center;
        justify-content: stretch;
    }
    .internal {
        padding: 32px;
        border-radius: 12px;
        width: 100%;
        max-width: 800px;
        gap: 4px;
        caret-color: white !important;
        justify-self: flex-end;
        font-size: 1rem;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.05);
    }

    .internal-grid {
        display: grid;
        grid-template-columns: 1fr 1.4fr;
        border-bottom: 1px solid var(--v-gray3-base);
        border-radius: 4px;
        padding: 8px 0px;
    }

    .internal-grid-header {
        text-align: left;
        font-size: 12px;
        color: var(--v-gray2-base);
    }

    .internal-grid-text {
        font-size: 12px;
        color: var(--v-gray4-base);
    }

    .medium-heading {
        font-size: 14px;
        font-weight: 500;
        color: var(--v-gray4-base);
        text-align: center;
        margin-bottom: 16px;
    }

    .internal-avatar {
        color: white;
        border: 0px solid var(--v-accent2-base);
        margin-right: 8px;
    }

    .internal-header {
        display: grid;
        grid-template-columns: auto 1fr;
        padding-bottom: 8px;
        gap: 8px;
    }

    .avatar-image {
        background-position: center;
        object-fit: cover;
    }

    .internal-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        font-size: 0.8rem;
        color: var(--v-gray2-base);
        flex: 1;
    }

    .internal-date {
        justify-self: end;
        font-size: 12px;
    }

    .internal-from {
        color: var(--v-gray4-base);
        font-size: 1rem;
    }

    .internal-section {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .internal-content {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .internal-title {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 1.3rem;
        font-weight: 300;
    }
</style>

<i18n lang="json">
{
    "en": {
        "comment": {
            "to": "To:",
            "internal": "Internal"
        }
    },
    "sv": {
        "comment": {
            "to": "Till:",
            "internal": "Intern"
        }
    }
}
</i18n>
