<template>
    <section>
        <List :key="reloadContent" />
    </section>
</template>
<script>
    import List from '@/components/Cases/List.vue';

    export default {
        components: {
            List,
        },
        data() {
            return {
                reloadContent: 0,
            };
        },
        async mounted() {
            // get default email sender, in store
            await this.$store.dispatch('Cases/getSystemEmails');
            await this.$store.dispatch('Cases/getCaseCategories');
        },
        beforeDestroy() {
            this.$store.commit('Cases/SET_SELECTED_CASE', null);
            removeEventListener('focus', window);
        },
    };
</script>
<style scoped></style>
