<template>
    <div>
        <div v-if="isVergicChat" class="comments incoming vergic-chat">
            <div class="d-flex align-center">
                <InternalType :absolute="false" :item="{ internalType: 'chat' }" />
                <div class="ml-3" v-html="item.comment"></div>
                <span class="ml-2 comment-date comment-date-text">{{ getDay(item.dateTime) }}</span>
            </div>
        </div>
        <div v-else class="comments" :style="chatSize">
            <ConfirmationPromiseDialog ref="confirmChatClose" />
            <!-- Header -->
            <header>
                <div class="text-h6">{{ item.from || $t('global.unknown') }}</div>

                <div class="actions">
                    <v-btn icon @click="toggleSize()">
                        <v-icon size="20">{{ expandIcon }}</v-icon>
                    </v-btn>
                    <v-btn :disabled="isReadOnly || !chatOpen" icon @click="closeChat()">
                        <v-icon size="20">mdi-close</v-icon>
                    </v-btn>
                </div>
            </header>

            <!-- Content -->
            <section id="chat-window" class="chat-window">
                <!-- Messages -->

                <div class="messages-list">
                    <ChatMessageBubble
                        v-for="(message, index) in messages"
                        :key="message.ID"
                        :clientIsOnline="clientIsOnline"
                        :message="message"
                        :preview="message.ID === 'preview'"
                        :nextMessageSender="nextMessageSender(message)"
                        @setTextField="setAgentMessageField"
                    >
                        <template v-if="shouldShowBlockTime(message, index)" #blockTime>
                            <div class="block-time">{{ getDay(message.DateTime) }}</div>
                        </template>
                        <template #onHoverFunctions>
                            <!-- ChatGPT-->
                            <ChatGPT
                                v-if="showGptButton(message)"
                                context="chat"
                                :conversation="conversationHistoryArr"
                                :textInput="message.Message"
                                @result="setAgentMessageField"
                            />
                        </template>
                        <!-- If temporary message object for when client is typing-->
                        <template v-if="message.ID === 'clientWriting'" #messageContent>
                            <div class="ma-1">
                                <CurrentlyWriting />
                            </div>
                        </template>
                    </ChatMessageBubble>
                </div>
                <!-- Chat is closed-message -->
                <div v-if="!chatOpen" class="chat-close-message text-body-1">
                    <span>{{ $t('chat.chatClosedBy') }} {{ closedBy + ' ' + closeTime }}</span>
                </div>
            </section>
            <!-- </section> -->

            <!-- Chat Input -->
            <footer v-if="chatOpen && !isReadOnly">
                <v-textarea
                    ref="chatMessage"
                    v-model="agentMessage"
                    autofocus
                    :disabled="disableTextArea || !chat.connected"
                    :placeholder="chatPlaceholder"
                    filled
                    solo
                    rows="3"
                    no-resize
                    flat
                    dense
                    hide-details
                    class="chat-text-area"
                    color="white"
                    elevation="0"
                    outlined
                    @keyup.enter="
                        (event) => {
                            if (!event.shiftKey) sendChatMessage();
                        }
                    "
                    @keydown.enter.prevent="
                        (event) => {
                            if (event.shiftKey) {
                                agentMessage += '\n';
                            } else {
                                sendChatMessage();
                            }
                        }
                    "
                >
                    <template #append>
                        <div>
                            <ChatGPT disabled context="chat" :textInput="agentMessage" @result="setAgentMessageField" />
                            <ChatTemplates
                                :disabled="!chatOpen || isReadOnly || !chat.connected"
                                @template-selected="handleTemplateSelected"
                            />
                        </div>
                    </template>
                </v-textarea>
            </footer>
        </div>
    </div>
</template>
<script>
    import cfg from '@/app/config';
    import io from 'socket.io-client';
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';
    import { getDateFormat } from '@/utils/Design';
    import { urlRegex, replaceTemplateValues, debouncer } from '@/utils/index';

    import ConfirmationPromiseDialog from '@/components/Global/ConfirmationPromiseDialog.vue';
    import ChatGPT from '@/components/Global/ChatGPT/ChatGPT.vue';
    import InternalType from '@/components/Cases/List/InternalType.vue';
    import ChatTemplates from './ChatTemplates.vue';

    export default {
        components: {
            ConfirmationPromiseDialog,
            ChatTemplates,
            ChatGPT,
            ChatMessageBubble: () => import('@/components/Cases/Comments/ChatMessageBubble.vue'),
            CurrentlyWriting: () => import('@/components/Cases/Comments/CurrentlyWriting.vue'),
            InternalType,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                initializing: true,
                colors: this.$vuetify.theme.themes.light,
                messages: [],
                agentMessage: '',
                chat: {},
                clientWritingNow: false,
                timeout: null,
                closedBy: '',
                closeTime: '',
                activeInfo: '',
                bigChat: false,
                filterStyle: { width: '35em', height: '45em' },
                expandIcon: 'mdi-arrow-expand',
                chatOpen: true,
                active: 0,
                selectedTemplate: null,
                scrollToBottomDebouncer: null,
                sendMessageLoading: false,
                firstScroll: true,
            };
        },
        computed: {
            ...mapState({
                loggedInUser: (state) => state.Auth.userObject,
                selectedCase: (state) => state.Cases.selectedCase,
                readContent: (state) => state.Layout.readContent,
                readStateContent: (state) => state.Layout.readStateContent,
                clientNowOnCard: (state) => state.Clients.clientNowOnCard,
                emailFrom: (state) => state.Cases.emailFrom,
            }),
            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
            }),
            conversationHistoryArr() {
                // the last 25 chat messages for context
                const CONVERSATION_HISTORY_LENGTH = 25;

                return this.messages.slice(this.messages.length - CONVERSATION_HISTORY_LENGTH).map((message) => {
                    return {
                        role: 'user',
                        content: message.Message,
                    };
                });
            },
            clientIsWritingMessageObject() {
                return {
                    ID: 'clientWriting',
                    From: this.item.from,
                    AgentFullName: this.item.from,
                    AgentUserId: 0,
                };
            },
            clientIsOnline() {
                const ACTIVE_STATE = 2;
                return this.active >= ACTIVE_STATE;
            },

            chatPlaceholder() {
                if (!this.chat.connected) {
                    return this.$t('chat.noConn');
                }
                return !this.chatOpen ? this.$t('chat.disabledMessage') : this.$t('chat.writeMessage');
            },

            chatSize() {
                return {
                    maxWidth: this.bigChat ? '100%' : '600px',
                };
            },

            disableTextArea() {
                return !this.chatOpen || this.isReadOnly;
            },
            isVergicChat() {
                return this.selectedCase.case.caseId.includes('vergic');
            },
        },
        watch: {
            'chat.connected': function (newVal) {
                if (newVal) {
                    this.setReadChat('READ_WRITE');
                } else {
                    this.setReadChat('READ_ONLY');
                }
            },
            disableTextArea() {
                const { activeElement } = document;
                const tagNamesToIgnore = new Set(['TEXTAREA', 'INPUT', 'SELECT']);
                if (tagNamesToIgnore.has(activeElement.tagName)) {
                    return;
                }

                if (!activeElement.readOnly && Object.hasOwn(activeElement, 'readOnly')) {
                    return;
                }

                if (Object.hasOwn(activeElement, 'editor')) {
                    return;
                }

                if (this.disableTextArea) return;

                this.focusChatTextField();
            },

            chat: {
                deep: false,
                handler() {
                    this.initListenerChat();
                },
            },
            agentMessage() {
                this.saveCurrentMessageToLocalStorage();
                this.scrollToBottomDebouncer(200);

                if (this.agentMessage.length) {
                    this.handlePreviewMessage();
                } else if (!this.sendMessageLoading) {
                    this.removePreviewMessage();
                }
            },
            messages() {
                // retrieve the text input only after we finish initializing the chat
                if (!this.initializing && this.agentMessage === '') {
                    this.retrieveWrittenText();
                }
            },
            clientWritingNow(newVal) {
                // add or remove pseudo object to messages array to indicate client is writing
                newVal ? this.addClientIsWritingIndicator() : this.removeClientIsWritingIndicator();

                // scroll down to see the client is writing indicator if within 200px from bottom
                newVal && this.scrollToBottomDebouncer(200);
            },

            chatOpen(val) {
                if (!val && !this.selectedCase.case.caseId) {
                    console.log('Could not find a caseId for this case');
                }
            },
            firstScroll(newVal) {
                if (!newVal) {
                    setTimeout(() => this.scrollToBottomDebouncer, 1000);
                }
            },
        },

        created() {
            this.setChatState();
            this.scrollToBottomDebouncer = debouncer(this.scrollToBottom, 100);
        },

        beforeDestroy() {
            if (this.chat.disconnect) {
                this.chat.disconnect();
            }
            this.setShowNotification(true);
        },

        mounted() {
            if (this.isVergicChat) return;
            this.connect();
        },

        methods: {
            ...mapActions('Layout', ['setReadChat']),
            ...mapActions('Chat', ['setShowNotification']),
            replaceTemplateValues,
            getDay,
            getDateFormat,

            showGptButton(message) {
                return message.From !== 'agent' && message.ID !== 'clientWriting' && this.chatOpen;
            },
            addClientIsWritingIndicator() {
                this.messages.push(this.clientIsWritingMessageObject);
            },
            removeClientIsWritingIndicator() {
                this.messages = this.messages.filter((msg) => msg.ID !== 'clientWriting');
            },
            nextMessageSender(message) {
                return this.messages[this.messages.indexOf(message) + 1]?.From;
            },
            saveCurrentMessageToLocalStorage() {
                localStorage.setItem(`__chatText_${this.$router.currentRoute.path}`, this.agentMessage);
            },
            updatePreviewMessage(data) {
                const previewMessageIndex = this.getPreviewMessageIndex();
                this.$set(this.messages, previewMessageIndex, data);
            },
            handlePreviewMessage() {
                const previewObject = this.getPreviewMessage();
                const hasLength = this.agentMessage.trim().length >= 1;

                if (!hasLength) {
                    this.removePreviewMessage();
                    return;
                }
                previewObject ? (previewObject.Message = this.agentMessage) : this.addPreviewMessage();
            },
            getPreviewMessage() {
                return this.messages.find((msg) => msg.ID === 'preview');
            },
            getPreviewMessageIndex() {
                return this.messages.findIndex((msg) => msg.ID === 'preview');
            },
            movePreviewMessageToLastInArray() {
                // if we have a preview message we want to move it to the end of the array
                // when we get an incoming message
                const previewMessageIndex = this.getPreviewMessageIndex();
                const isLastMessageOfArray = previewMessageIndex === this.messages.length - 1;
                if (!isLastMessageOfArray) {
                    this.messages.push(this.messages.splice(previewMessageIndex, 1)[0]);
                }
            },
            removePreviewMessage() {
                this.messages = this.messages.filter((msg) => msg.ID !== 'preview');
            },
            addPreviewMessage() {
                this.messages.push({
                    ID: 'preview',
                    From: 'agent',
                    AgentFullName: this.loggedInUser.userName,
                    AgentUserId: this.loggedInUser.userId,
                    Message: this.agentMessage,
                    DateTime: new Date().toISOString(),
                });
            },
            finalizeMessageReception() {
                this.sendMessageLoading = false;
                this.clearWriting();
                this.scrollToBottomDebouncer();
            },

            // Handle incoming messages from clients
            handleClientMessage(data) {
                // If client is currently writing, remove the writing indicator first
                if (this.clientWritingNow) {
                    this.removeClientIsWritingIndicator();
                    this.clientWritingNow = false;
                }

                // Add the new message to the messages list
                this.messages.push(data);
                this.movePreviewMessageToLastInArray();
            },
            setAgentMessageField(result) {
                this.agentMessage = result;
            },
            shouldShowBlockTime(message, index) {
                // If it's the first message, show the time
                if (index === 0) {
                    return true;
                }
                if (message.ID === 'preview') {
                    return false;
                }

                const previousMessage = this.messages[index - 1];

                // Calculate the time difference in minutes
                const timeDifferenceInMinutes =
                    (new Date(message.DateTime) - new Date(previousMessage.DateTime)) / 60000;

                // If the time difference is more than 1 minute, show the time
                return timeDifferenceInMinutes > 3;
            },
            focusChatTextField() {
                this.$nextTick(() => {
                    this.$refs.chatMessage.$refs.input.focus();
                });
            },
            handleTemplateSelected(selectedTemplate) {
                const newContent = replaceTemplateValues(selectedTemplate.content, {
                    client: null,
                    agent: this.loggedInUser,
                    sender: this.emailFrom,
                });

                if (selectedTemplate?.content) {
                    this.agentMessage += newContent;
                } else {
                    this.setAgentMessageField('');
                }
            },
            generateLinkTag(message) {
                return message.replaceAll(urlRegex, (url) => {
                    if (!url.match('^https?://') && !url.includes('@')) {
                        url = `https://${url}`;
                    }
                    const isValidHttpUrl = this.isValidHttpUrl(url);
                    if (!isValidHttpUrl) {
                        return url;
                    }

                    return `<a target="_blank" rel="noopener noreferrer" href="${url}">${url}</a>`;
                });
            },

            isValidHttpUrl(string) {
                let url = null;
                try {
                    url = new URL(string);
                } catch (_) {
                    return false;
                }
                return url.protocol === 'http:' || url.protocol === 'https:';
            },

            async closeChat() {
                const confirmationDialog = this.$refs.confirmChatClose;
                if (!confirmationDialog) return;
                const { confirmed } = await confirmationDialog.show({
                    title: this.$t('chat.sureClose'),
                    confirmText: this.$t('global.btn.save'),
                    declineText: this.$t('global.btn.cancel'),
                });

                if (!confirmed) return;
                const tempInteractionObject = {
                    visitorId: this.item.clientId,
                    agentFullName: this.loggedInUser.name,
                    agentUserId: this.loggedInUser.userId,
                    rand: this.item.caseId,
                    from: 'agent',
                };
                this.chat.emit('chatClosed', tempInteractionObject);
                this.$toasted.show(this.$t('chat.chatClosed'));
                this.chatOpen = false;
                this.scrollToBottomDebouncer();
                this.$store.commit('Cases/setReloadCaseContent', true);
                localStorage.setItem(`__chatText_${this.$router.currentRoute.path}`, '');
            },
            retrieveWrittenText() {
                if (localStorage.getItem(`__chatText_${this.$router.currentRoute.path}`) != null) {
                    this.agentMessage = localStorage.getItem(`__chatText_${this.$router.currentRoute.path}`);
                }
            },

            async setChatState() {
                const res = await this.$store.dispatch('Chat/getChatStatus', this.item.caseId);
                this.chatOpen = res.result.open;
                this.closedBy = res.result.closedBy[0]?.Name || `${this.$t('chat.client')}.`;

                const terminated = res.result?.closedBy[0]?.Terminated || new Date().toISOString();
                this.closeTime = getDay(terminated);
            },
            toggleSize() {
                this.bigChat = !this.bigChat;
                if (this.bigChat) {
                    this.filterStyle = { width: '100%', height: '45em' };
                    this.expandIcon = 'mdi-arrow-collapse';
                } else {
                    this.filterStyle = { width: '35em', height: '45em' };
                    this.expandIcon = 'mdi-arrow-expand';
                }
            },
            setActiveState(active) {
                this.active = active;
                const ACTIVE_STATE = 2;
                if (active >= ACTIVE_STATE) {
                    this.activeInfo = '<span style="font-size:0.9em;">Aktiv</span>';
                } else {
                    this.activeInfo = '<span style="font-size:0.9em;">Offline</span>';
                }
            },

            sendChatMessage() {
                // Check if message is empty from customer
                const MIN_LENGTH = 1;
                if (this.agentMessage.trim().length < MIN_LENGTH) {
                    return;
                }
                let previewMessage = this.getPreviewMessage();
                // Create the object to send to server
                previewMessage = {
                    visitorId: this.$props.item.clientId,
                    agentFullName: this.loggedInUser.userName,
                    agentUserId: this.loggedInUser.userId,
                    message: this.agentMessage,
                    rand: this.$props.item.caseId,
                    from: 'agent',
                };
                this.sendMessageLoading = true;
                this.chat.emit('sendMessage', previewMessage);

                this.setAgentMessageField('');

                this.$store.dispatch('Cases/resetUnreadMessages', this.selectedCase.case.caseId);
                this.scrollToBottomDebouncer(null, true);
            },

            scrollToBottom(maxDistance = 0) {
                const container = this.$el.querySelector('.messages-list');

                if (!container) return;

                const { scrollHeight, scrollTop, clientHeight } = container;
                const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

                const shouldScroll = maxDistance === 0 || distanceFromBottom <= maxDistance;

                if (!shouldScroll) {
                    this.$store.dispatch('Chat/setShowNotification', true);
                    return;
                }

                container.scrollTo({
                    top: scrollHeight,
                    behavior: 'smooth',
                });

                this.$store.dispatch('Chat/setShowNotification', false);
            },

            clientWritingAnimation() {
                this.clientWritingNow = true;
                this.clearWriting();
                const WRITING_TIMEOUT = 4000;
                this.timeout = setTimeout(() => {
                    this.clientWritingNow = false;
                }, WRITING_TIMEOUT);
            },
            clearWriting() {
                clearTimeout(this.timeout);
            },
            initListenerChat() {
                this.chat.on('inititalConversation', (data) => {
                    this.initializing = true;
                    for (const message of data) {
                        message.Message = this.generateLinkTag(message.Message);
                    }
                    this.messages = data;
                    this.clientWritingNow = false;
                    this.clearWriting();
                    this.initializing = false;
                    this.firstScroll = false;
                });
                this.chat.on('clientWritingMessage', (data) => {
                    /* check length to avoid showing writing indicator dots
                         when client presses enter to send message */
                    data.length && this.clientWritingAnimation(data);
                });
                this.chat.on('totalClientsOnline', (data) => {
                    this.setActiveState(data.totalInChatRoom);
                });
                this.chat.on('messageObject', (data) => {
                    this.messages = data.reverse();
                    this.clientWritingNow = false;
                    this.clearWriting();
                });
                this.chat.on('chatInQueue', (data) => {
                    if (data.found) {
                        this.$toasted.show(this.$t('chat.caseInQueue'), {
                            icon: 'cancel',
                            type: 'error',
                        });
                        this.$store.commit('Cases/addCaseToCasesInQueue', data.id);
                        return;
                    }
                    this.$store.commit('Cases/removeCaseFromCasesInQueue', data.id);
                });

                this.chat.on('incrementMessage', (data) => {
                    data.Message = this.generateLinkTag(data.Message);

                    data.From === 'agent' ? this.updatePreviewMessage(data) : this.handleClientMessage(data);

                    this.finalizeMessageReception();
                });
                this.chat.emit('joinConversation', {
                    visitorId: this.$props.item.clientId,
                    agentUserId: this.loggedInUser.userId,
                    fromAgent: true,
                    rand: this.$props.item.caseId,
                });
                this.chat.on('connect', () => {
                    this.chat.emit('joinConversation', {
                        visitorId: this.$props.item.clientId,
                        agentUserId: this.loggedInUser.userId,
                        fromAgent: true,
                        rand: this.$props.item.caseId,
                    });
                    this.scrollToBottomDebouncer();
                });
                this.chat.on('chatIsNowClosed', () => {
                    this.chatOpen = false;
                    this.scrollToBottomDebouncer();
                    this.setChatState();
                });
            },
            connect() {
                this.chat = io(`${cfg.customerFirstUrl}chatmanager`, {
                    transports: ['websocket'],
                    autoConnect: true,
                    upgrade: true,
                    useConnectionNamespace: false,
                    path: '/api/chatmanager/socket.io',
                    query: {
                        token: this.$store.state.Auth.accessToken || '',
                    },
                });
                this.chatOpen = true;
                if (this.chat.connected) {
                    this.initListenerChat();
                } else {
                    this.chat.connect();
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    footer {
        padding: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    .chat-text-area {
        border-radius: 8px;
    }
    .chat-close-message {
        display: flex;
        height: 100px;
        justify-content: center;
        align-items: center;
        padding: 8px;
    }

    .comments {
        border-radius: 12px;
        width: 100%;
        gap: 4px;
        font-size: 1rem;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    header {
        padding: 1.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actions {
            display: flex;
            gap: 0.5em;
        }
    }

    .chat-window {
        height: 600px;
        transition: opacity 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2em;
        padding: 1.5em;

        .messages-list {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            padding: 0 1em;

            .block-time {
                width: 100%;
                text-align: center;
                font-size: 12px;
                color: gray;
                margin-block: 1.5em;
            }
        }
    }

    .vergic-chat {
        background-color: var(--v-gray3-base);
        border-radius: 12px;
        padding: 16px;
        margin-bottom: 16px;
        width: fit-content;
        max-width: 50%;
    }

    .comment-date {
        display: flex;
        align-items: center;
        justify-self: end;
        font-size: 12px;
        color: var(--v-gray2-base);
        white-space: nowrap;
        color: var(--v-gray2-base);
        padding-right: 8px;
        margin-right: 4px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "chat": {
            "noConn": "No connection",
            "chatClosed": "The chat is closed",
            "chatClosedBy": "The chat is closed by",
            "sureClose": "Are you sure you want to close the chat?",
            "caseInQueue": "The case is in the queue waiting to be picked up by the automatic assignment",
            "writeMessage": "Write message",
            "disabledMessage": "The chat is closed",
            "client": "client"
        }
    },
    "sv": {
        "chat": {
            "noConn": "Ingen anslutning",
            "chatClosed": "Chatten är nu stängd",
            "chatClosedBy": "Chatten är avslutad av",
            "sureClose": "Är du säker på att du vill stänga chatten?",
            "caseInQueue": "Ärendet är i kön och väntar på att plockas upp av automatisk tilldelning",
            "writeMessage": "Skriv meddelande",
            "disabledMessage": "Chatten är stängd",
            "client": "klient"
        }
    }
}
</i18n>
