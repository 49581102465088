<template>
    <dialog ref="dialog" class="confirmation-dialog">
        <section id="dialog" class="d-flex flex-column justify-space-between pa-6">
            <div class="d-flex justify-space-between pb-5">
                <div>
                    <span class="confirmation-title">{{ title || $t('confirmationDialogue.delete') }}</span>
                    <div class="mt-3 confirmation-message">
                        <div v-if="!message">
                            <slot name="body"></slot>
                        </div>

                        <div
                            v-else
                            v-dompurify-html="message || $t('confirmationDialogue.delete')"
                            class="dialog-body"
                        ></div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row justify-end">
                <div class="confirmation-button-container">
                    <!-- Use @keydown.enter to handle "Enter" key -->
                    <standard-button depressed color="white" @click="decline" @keydown.enter.prevent="decline">
                        {{ declineText }}
                    </standard-button>
                    <standard-button
                        v-for="button in extraButtons"
                        :key="`extra-button-${button.text}`"
                        depressed
                        class="ml-2 confirm"
                        :class="button.class"
                        @click="extraButtonAction(button.action)"
                    >
                        {{ button.text }}
                    </standard-button>
                    <!-- Use @keydown.esc to handle "Esc" key -->
                    <primary-button
                        depressed
                        autofocus
                        cy-data="confirm"
                        @click="confirm"
                        @keydown.enter.prevent="confirm"
                    >
                        {{ confirmText }}
                    </primary-button>
                </div>
            </div>
        </section>
    </dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog: null,
                title: '',
                message: '',
                confirmText: '',
                declineText: '',
                extraButtons: [],
            };
        },
        mounted() {
            this.dialog = this.$refs.dialog;
        },
        methods: {
            /**
             * Displays a confirmation dialog and returns a promise that resolves with an object containing a confirmed, declined and canceled property indicating whether the user confirmed, declined or canceled the dialog.
             *
             * @param {Object} options - The options for the confirmation dialog.
             * @param {string} options.title - The title of the confirmation dialog.
             * @param {string} options.message - The message to display in the confirmation dialog.
             * @param {string} options.confirmText - The text to display on the confirmation button.
             * @param {string} options.declineText - The text to display on the decline button.
             *
             * @returns {Promise<Object>} A promise that resolves with an object containing a `confirmed` property indicating whether the user confirmed or canceled the dialog.
             *
             * @example
             * <ConfirmationPromiseDialog ref="confirmationDialog" />
             *
             * const { confirmed } = await this.$refs.confirmationDialog.show({
             *   title: 'Delete',
             *   message: 'Are you sure you want to delete this item?',
             *   confirmText: 'Delete',
             *   declineText: 'No'
             * });
             *
             * if (!confirmed) {
             *   return;
             * }
             *
             * // Do something
             */
            /**
             * @note Cleaner way of doing confirmation dialogs, implement this and add more functionality if needed.
             * @description This is a generic confirmation dialog that returns a promise.
             * @example
             * <ConfirmationPromiseDialog ref="confirmationDialog" />
             * const {confirmed} = await this.$refs.confirmationDialog.show({
             *    title: 'Delete',
             *   message: 'Are you sure you want to delete this item?',
             *  confirmText: 'Delete',
             * declineText: 'No'
             * });
             *
             * if (!confirmed) return;
             *
             * -- Do something --
             *
             *
             */
            show({ title, message, confirmText, declineText, extraButtons = [] }) {
                this.populateDialog(title, message, confirmText, declineText, extraButtons);
                this.dialog.showModal();
                this.addListeners();

                return new Promise((resolve) => {
                    const cleanupAndResolve = (result) => {
                        // cleans up, closes dialog and sends back result
                        this.removeListeners();
                        this.dialog.close();
                        resolve(result);
                    };

                    this.$once('confirmed', () => {
                        const result = { confirmed: true, declined: false, canceled: false };
                        cleanupAndResolve(result);
                    });

                    this.$once('declined', () => {
                        const result = { confirmed: false, declined: true, canceled: false };
                        cleanupAndResolve(result);
                    });

                    this.$once('canceled', () => {
                        const result = { confirmed: false, declined: false, canceled: true };
                        cleanupAndResolve(result);
                    });
                    this.$once('confirmedExtra', () => {
                        const result = { confirmed: true, declined: false, canceled: false, confirmedExtra: true };
                        cleanupAndResolve(result);
                    });
                });
            },
            populateDialog(title, message, confirmText, declineText, extraButtons) {
                this.title = title;
                this.message = message;
                this.confirmText = confirmText;
                this.declineText = declineText;
                this.extraButtons = extraButtons;
            },

            confirm() {
                this.$emit('confirmed');
            },
            cancel() {
                this.$emit('canceled');
            },
            decline() {
                this.$emit('declined');
            },
            closeOnOutsideClick(event) {
                if (event.target === this.dialog) {
                    this.cancel();
                }
            },
            handleEscape(event) {
                if (event.key === 'Escape' || event.keyCode === 27) {
                    this.cancel();
                }
            },
            addListeners() {
                document.addEventListener('keydown', this.handleEscape);
                document.addEventListener('mouseup', this.closeOnOutsideClick);
            },
            removeListeners() {
                document.removeEventListener('mouseup', this.closeOnOutsideClick);
                document.removeEventListener('keydown', this.handleEscape);
            },
            selection({ title, message, confirmText, cancelText, items }) {
                this.title = title;
                this.message = message;
                this.confirmText = confirmText;
                this.cancelText = cancelText;
                this.items = items;

                this.dialog.showModal();
                this.addListeners();
                return new Promise((resolve) => {
                    this.$once('confirmed', () => {
                        this.removeListeners();
                        this.dialog.close();

                        resolve({ confirmed: true, items: this.selectedItems });
                    });

                    this.$once('canceled', () => {
                        this.removeListeners();
                        this.dialog.close();

                        resolve({ confirmed: false, items: [] });
                    });
                });
            },
            extraButtonAction(action) {
                this.$emit('confirmedExtra');
                action();
            },
        },
    };
</script>

<style scoped>
    .confirmation-title {
        color: var(--v-gray4-base);
        font-size: 22px !important;
        font-weight: 400;
    }
    .confirmation-message {
        color: var(--v-gray2-base) !important;
    }

    .dialog-body {
        word-break: break-word;
    }

    dialog {
        width: 80vw;
        max-width: 560px;
        border: none;
        transform: translate(-50%, -50%);
        border-radius: 12px;
        top: 50%;
        left: 50%;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    }
    dialog[open] {
        animation: show 0.2s ease-in-out normal;
    }

    .confirmation-dialog {
        border-radius: 24px;
        padding: 8px 0px 0px 8px;
    }

    .confirmation-button-container {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    @keyframes show {
        from {
            transform: translate(-50%, -50%) scale(0.8);
            opacity: 0;
        }
        to {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
</style>
