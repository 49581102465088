<template>
    <section class="search-container">
        <section class="search-bar">
            <search-input
                v-bind="attrs"
                :style="fieldStyle"
                :loading="loading"
                :value="searchQuery"
                :minCharacters="3"
                :debounceTime="1000"
                @focusin="handleFocus"
                @focusout="handleFocus"
                @input="handleInput"
            />
        </section>

        <section class="search-select">
            <standard-select
                :items="searchTypes"
                :value="currentSearch"
                :minimal="true"
                color="white"
                @click="handleClick"
                @change="handleChange"
            />
        </section>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { events } from '@/enums/events.enums';

    export default {
        name: 'ListSearch',
        data() {
            return {
                searchQuery: '',
                focus: false,
                focusedWidth: 500,
                unfocusedWidth: 200,
                attrs: {
                    'hide-details': true,
                    'single-line': true,
                    'background-color': 'white',
                    'label-color': 'var(--v-gray2-base)',
                    'input-color': 'var(--v-gray2-base)',
                    'placeholder-color': 'var(--v-gray2-base)',
                    'full-width': true,
                    color: 'var(--v-gray2-base)',
                    elevation: 0,
                    flat: true,
                    rounded: true,
                    dense: true,
                    filled: true,
                    solo: true,
                },
                loading: false,
            };
        },

        computed: {
            ...mapState({
                search: (state) => state.Header.search,
                searchTypes: (state) => state.Header.searchTypes,
                currentSearch: (state) => state.Header.currentSearch,
                caseFiltersState: (state) => state.Cases.caseFiltersState,
            }),

            fieldStyle() {
                return {
                    minWidth: this.focus ? this.focusedWidth + 'px' : this.unfocusedWidth + 'px',
                    transition: 'min-width 0.3s ease-out',
                };
            },
        },
        created() {
            // sets the search value to retain the past search filter if you have switched between views
            this.searchQuery = this.caseFiltersState.searchQuery;
        },

        methods: {
            ...mapActions({
                toggleSearch: 'Header/toggleSearch',
                setSearchState: 'Header/setSearchState',
                setSearchType: 'Cases/setSearchType',
                setSearchQueryAndGetCases: 'Cases/setSearchQueryAndGetCases',
                setSelectedFilters: 'Cases/setSelectedFilters',
            }),

            handleFocus(event) {
                this.focus = event.type === events.FOCUS_IN;
            },

            handleClick(event) {
                event.preventDefault();
            },

            onPaste(event) {
                this.$nextTick(() => {
                    this.searchQuery = event.srcElement._value;
                });
            },

            async startSearch() {
                const filter = structuredClone(this.caseFiltersState);
                filter.page = 1;
                filter.skipGetCases = true;
                filter.searchQuery = this.searchQuery;
                this.setSelectedFilters(filter);
                this.loading = true;
                await this.setSearchQueryAndGetCases(this.searchQuery);
                this.loading = false;
            },

            async handleChange(change) {
                this.setSearchState(change);
                if (change) {
                    this.setSearchType(change.at(0).value);
                }
                await this.startSearch();
            },

            async handleInput(value) {
                this.searchQuery = value;
                await this.startSearch();
            },
        },
    };
</script>

<style scoped>
    .search-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        min-width: 332px;
        gap: 8px;
    }
    .search-container .search-bar {
        flex: 1;
    }

    .search-select {
        flex: 0;
        min-width: 80px;
    }

    :deep(.v-text-field.v-text-field--solo .v-input__control input) {
        font-size: 0.9rem !important;
    }
</style>

<i18n lang="json">
{
    "en": {
        "search": {
            "searchHere": "Search",
            "minLetters": "Search with at least 3 characters",
            "searchAll": "Search all",
            "searchOnlyFilters": "Search only filters",
            "searchSettings": "Search settings",
            "searchOnlyCommentsAndFilters": "Search only comments and filters"
        }
    },
    "sv": {
        "search": {
            "searchHere": "Sök",
            "minLetters": "Sök med minst tre tecken",
            "searchAll": "Sök alla",
            "searchOnlyFilters": "Sök endast filter",
            "searchSettings": "Sökinställningar",
            "searchOnlyCommentsAndFilters": "Sök endast kommentarer och filter"
        }
    }
}
</i18n>
