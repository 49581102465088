/* eslint-disable unicorn/no-document-cookie */
import auth from '@/api/auth/auth';
import router from '@/router';
import * as Sentry from '@sentry/vue';
import { pages } from '@/enums/auth.enums';
import config from '@/app/config';
import AuthHelper from '../../auth/auth.helper';
import i18n from '../../i18n';

// State object
const state = {
    accessToken: '',
    init: false,
    userObject: JSON.parse(localStorage.getItem('__userObject')) || {},
    isLoggedIn: false,
    roles: [],
    prevNavAction: '',
    navigationAction: '',
    serviceLoggingIn: '',
    serviceLoggingOut: '',
    loginErrorMessage: '',
    loading: false,

    currentPage: pages.LOGIN,
    resetUsername: '',
    redirectTo: null,
};
// Getter functions
const getters = {
    isLoggedIn(state) {
        return state.isLoggedIn;
    },
    getToken(state) {
        return state.token;
    },
    getRoles(state) {
        return state.roles;
    },
    getNavAction() {
        return state.navigationAction;
    },
    getAccessToken() {
        return state.accessToken;
    },
};
// Actions
const actions = {
    setCurrentPage({ commit }, payload) {
        if (payload !== pages.HELP) {
            commit('SET_RESET_USERNAME', '');
        }
        commit('SET_CURRENT_PAGE', payload);
    },

    async resetPassword({ dispatch, commit }) {
        try {
            const data = await dispatch('resetPasswordRequest');
            if (data.status === 200) {
                dispatch('showSuccessMessage');
                commit('SET_RESET_USERNAME', '');
            } else {
                dispatch('showErrorMessage', i18n.t('global.requestResponses.somethingWrong'));
            }
        } catch (error) {
            dispatch('handleResetPasswordError', error);
            throw error;
        }
    },

    resetPasswordRequest({ state }) {
        return auth.resetPassword({ username: state.resetUsername });
    },

    showSuccessMessage() {
        this._vm.$toasted.show(i18n.t('global.requestResponses.success'), {
            icon: 'mdi-content-save',
            type: 'success',
        });
    },

    handleResetPasswordError({ dispatch }, error) {
        try {
            if (error.response.status === 429) {
                dispatch('showErrorMessage', i18n.t('global.requestResponses.tooManyRequests'));
                return;
            }

            switch (error.response.data) {
                case 'noUserFound': {
                    dispatch('showErrorMessage', i18n.t('global.requestResponses.noUserFound'));
                    break;
                }
                case 'fillAllFields': {
                    dispatch('showErrorMessage', i18n.t('global.requestResponses.fillAllFields'));
                    break;
                }
                case 'fieldIsNotText': {
                    dispatch('showErrorMessage', i18n.t('global.requestResponses.fieldIsNotText'));
                    break;
                }
                case 'fieldIsTooLong': {
                    dispatch('showErrorMessage', i18n.t('global.requestResponses.fieldIsTooLong'));
                    break;
                }
                default: {
                    dispatch('showErrorMessage', i18n.t('global.requestResponses.somethingWrong'));
                }
            }
        } catch (error) {
            dispatch('showErrorMessage', i18n.t('global.requestResponses.somethingWrong'));
        }
    },

    showErrorMessage(_, message) {
        this._vm.$toasted.show(message, {
            icon: 'mdi-alert-circle',
            type: 'error',
        });
    },

    async logoutClient({ state, commit, dispatch }, payload) {
        if (!state.isLoggedIn) {
            return;
        }
        commit('SET_SERVICE_LOGGING_IN', '');
        if (payload) {
            this._vm.$toasted.show(payload, {
                icon: 'mdi-cancel',
                type: 'error',
            });
        }
        await dispatch('App/disconnect', null, { root: true });
        commit('SET_ACCESS_TOKEN', '');
        commit('SET_LOGIN_STATE', false);
        setTimeout(async () => {
            await auth.logout();
            AuthHelper.removeToken();
            commit('SET_ACCESS_TOKEN', '');
            commit('SET_LOGIN_STATE', false);
        }, 500);
        let authenticationMethod = '';
        if (document.cookie.includes('authenticationMethod')) {
            // extract authenticationMethod from cookie
            [, authenticationMethod] = document.cookie
                .split('; ')
                .find((row) => row.startsWith('authenticationMethod'))
                .split('=');
        }
        if (!(authenticationMethod === 'basic' || authenticationMethod === '')) {
            document.cookie = 'authenticationMethod=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            commit('SET_SERVICE_IS_LOGGING_OUT', authenticationMethod);
            clearLoginData({ commit, dispatch });
            router.push('/Login?logout=' + authenticationMethod);
            auth.logoutClient(authenticationMethod);
        } else {
            clearLoginData({ commit, dispatch });
            router.push('/Login');
        }
        window.location.reload(); // reload the page to clear the authentication cookie
    },

    async loginClient({ dispatch }, payload) {
        const response = await auth.loginClient(payload);
        if (Number.parseInt(response.status) === 200) {
            AuthHelper.saveTokens(response.data);

            // this await is important, otherwise the user object is not set in time
            await dispatch('System/getSettings', ['admin_config', 'search_config', 'userdata_config'], {
                root: true,
            });
            return response;
        }
        throw response;
    },

    async loginCheck({ dispatch, commit, state }, { username, password, valid }) {
        commit('SET_LOADING', true);

        if (!valid) {
            commit('SET_LOADING', false);
            return;
        }

        localStorage.setItem('lastRevision', config.revision.trim());

        try {
            const loginPromise = dispatch('loginClient', { username, password });
            const delayPromise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 1500); // For animation and soft login
            });
            await Promise.all([loginPromise, delayPromise]);
            dispatch('System/setVuetifyColors', null, { root: true });
            await dispatch('System/getSettings', ['admin_config'], { root: true });

            if (state.redirectTo) {
                router.push(state.redirectTo);
                commit('SET_REDIRECT_TO', null);
                return;
            }
            router.push('/cases');
        } catch (error) {
            dispatch('handleLoginError', error);
        } finally {
            setTimeout(() => {
                commit('SET_LOADING', false);
            }, 500); // For animation and soft login
        }
    },

    handleLoginError({ commit }, error) {
        if (error?.response?.data === 'PasswordExpired: The password is expired.') {
            commit('SET_LOGIN_ERROR_MESSAGE', 'error.passwordExpired');
            return;
        }

        const responseData = error.response ? error.response.data : error.data;

        this.serverResponse = `error.${responseData || 'internalServerError'}`;

        commit('SET_LOGIN_ERROR_MESSAGE', this.serverResponse);
    },

    checkForFederatedLoginResponse({ dispatch, commit }, federatedLogin) {
        const cookieHasPublicAndPrivateToken =
            document.cookie.includes('private=') && document.cookie.includes('public=');
        if (federatedLogin && cookieHasPublicAndPrivateToken) {
            commit('SET_SERVICE_LOGGING_IN', federatedLogin);
            dispatch('federatedLoginClient', federatedLogin);
        }
    },
    checkForErrorResponse({ commit }, error) {
        if (error !== undefined && error.length > 0) {
            commit('SET_LOGIN_ERROR_MESSAGE', `error.${error}`);
        }
        if (error === 'couldNotAuthenticate') {
            document.cookie = 'connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }
    },

    async federatedLoginClient({ commit, state, dispatch }) {
        try {
            const tokenData = await dispatch('getPrivateAndPublicTokens');
            AuthHelper.saveTokens({
                private: tokenData.private,
                public: tokenData.public,
            });

            // this dispatch is important, otherwise the user object is not set in time
            const loginPromise = dispatch('System/getSettings', ['admin_config', 'search_config', 'userdata_config'], {
                root: true,
            });
            const delayPromise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 1500); // For animation and soft login
            });
            await Promise.all([loginPromise, delayPromise]);
            dispatch('System/setVuetifyColors', null, { root: true });

            this._vm.$socket._opts.query.token = state.accessToken;
            if (state.redirectTo) {
                router.push(state.redirectTo);
                commit('SET_REDIRECT_TO', null);
                return 'success';
            }
            router.push('/cases');
            return 'success';
        } catch (error) {
            commit('SET_SERVICE_LOGGING_IN', '');
            commit('SET_LOGIN_ERROR_MESSAGE', 'error.couldNotParseTokens');
            return 'error';
        }
    },
    // this is used for federated login
    getPrivateAndPublicTokens() {
        const cookies = document.cookie.split(';');
        let privateToken = '';
        let publicToken = '';
        const privateTokenName = 'private=';
        const publicTokenName = 'public=';
        for (const cookie in cookies) {
            if (cookies[cookie].includes(privateTokenName)) {
                privateToken = cookies[cookie].replace(privateTokenName, '');
                continue;
            }
            if (cookies[cookie].includes(publicTokenName)) {
                publicToken = cookies[cookie].replace(publicTokenName, '');
            }
        }
        // remove cookies when we have parsed them
        document.cookie = 'private=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'public=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        return {
            private: JSON.parse(decodeURIComponent(privateToken)),
            public: JSON.parse(decodeURIComponent(publicToken)),
        };
    },
    refreshToken() {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await auth.refreshToken();
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    },
    changePassword(_, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await auth.changePassword(payload);
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    },

    swyxGeneratePassword(_, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await auth.swyxGeneratePassword(payload);
                resolve(result.data);
            } catch (error) {
                reject(error);
            }
        });
    },
    setNavigationAction({ commit }, payload) {
        commit('SET_NAV_ACTION', payload);
    },
    clearNavigationAction({ commit }) {
        commit('CLEAR_NAV_ACTION');
    },
    setAccessToken({ commit }, payload) {
        commit('SET_ACCESS_TOKEN', payload);
    },
    setTapiToken({ commit }, payload) {
        if (!payload) return;
        commit('SET_TAPI_TOKEN', payload);
    },
    setUser({ commit }, payload) {
        commit('SET_USER', payload);
    },
    setUserProp({ commit }, payload) {
        commit('SET_USER_PROP', payload);
    },
    setLoginState({ commit }, payload) {
        commit('SET_LOGIN_STATE', payload);
    },
    setRoles({ commit }, payload) {
        commit('SET_ROLES', payload);
    },
    setUserObjectKey({ commit }, { key, value }) {
        commit('SET_USER_OBJECT_KEY', { key, value });
    },
    setRedirectTo({ commit }, payload) {
        commit('SET_REDIRECT_TO', payload);
    },
};
// Mutations
const mutations = {
    SET_RESET_USERNAME(state, data) {
        state.resetUsername = data;
    },
    SET_CURRENT_PAGE(state, payload) {
        state.currentPage = payload;
    },
    SET_LOGIN_STATE(state, data) {
        state.isLoggedIn = data;
    },

    SET_ACCESS_TOKEN(state, data) {
        state.accessToken = data;
    },
    SET_TAPI_TOKEN(state, data) {
        state.tapiToken = data;
    },
    SET_USER(state, data) {
        state.userObject = structuredClone({
            ...state.userObject,
            ...data,
        });
        Sentry.setUser({
            ...state.userObject,

            // this ensures we can get the user id in Sentry on the backend
            id: state.userObject?.userId,
            username: state.userObject?.userName,
            email: state.userObject?.email,
        });
    },
    SET_USER_PROP(state, data) {
        state.userObject = {
            ...state.userObject,
            [data.name]: data.value,
        };
        localStorage.setItem('__userObject', JSON.stringify(state.userObject));
    },
    SET_ROLES(state, data) {
        state.roles = data;
    },
    SET_NAV_ACTION(state, data) {
        state[data.name] = data.value;
    },
    CLEAR_NAV_ACTION(state, data) {
        state[data.name] = '';
    },
    /**
     * Sets a specific key-value pair in the userObject state object.
     *
     * @param {Object} state - The Vuex state object.
     * @param {Object} payload - The payload object containing the key-value pair to set.
     * @param {string} payload.key - The key to set in the userObject state object.
     * @param {*} payload.value - The value to set for the specified key in the userObject state object.
     */
    SET_USER_OBJECT_KEY(state, { key, value }) {
        state.userObject[key] = value;
    },
    SET_SERVICE_LOGGING_IN(state, data) {
        if (data === '') {
            state.loading = false;
        } else {
            state.loading = true;
        }
        state.serviceLoggingIn = data;
    },
    SET_SERVICE_IS_LOGGING_OUT(state, data) {
        if (data === '') {
            state.loading = false;
        } else {
            state.loading = true;
        }
        state.serviceLoggingOut = data;
    },
    SET_LOGIN_ERROR_MESSAGE(state, data) {
        state.loginErrorMessage = data;
    },

    SET_LOADING(state, isLoading) {
        state.loading = isLoading;
    },
    SET_REDIRECT_TO(state, data) {
        state.redirectTo = data;
    },
};

function clearLoginData({ dispatch }) {
    dispatch('System/resetVuetifyColors', null, { root: true });
    dispatch('Sip.auth/handleClientLogout', null, { root: true });
    dispatch('resetState', null, { root: true });
    localStorage.clear();
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
