// Description: This file contains the Layout Store
// Which is used to control which information is shown depending on priority of the information
// The stores keeps the states of all layout components and which information to be shown by priority
// For example: Disonnected have higher priority than searching errors and states and so on

import i18n from '../../i18n';

const state = {
    // Information map, used to store all information messages
    // Object: (id, text, icon, color, informationType)

    information: new Map(),

    // Information state types, used to determine which information to have priority in the view
    informationState: Object.freeze({
        SINGLE_COMPONENT: 1,
        MULTI_COMPONENT: 2,
        GLOBAL: 3,
    }),

    // States for information components
    topBar: 100,
    topBarState: Object.freeze({
        CLOSED: 100,
        OPEN: 101,
        HIDDEN: 102,
    }),

    // States for app components
    search: 100,
    searchState: Object.freeze({
        EMPTY: 100,
        SEARCHING: 101,
    }),

    app: 101,
    appState: Object.freeze({
        CONNECTED: 100,
        DISCONNECTED: 101,
    }),

    sideBar: 101,
    sideBarState: Object.freeze({
        CLOSED: 100,
        STANDARD: 101,
        BUILDER: 102,
        HIDDEN: 103,
    }),

    // Read only case comments (when other user is in case)
    readCaseComments: 100,
    readStateCaseComments: Object.freeze({
        READ_WRITE: 100,
        READ_ONLY: 101,
    }),

    // Read only client card (when other user is in case)
    readClientCard: 100,
    readStateClientCard: Object.freeze({
        READ_WRITE: 100,
        READ_ONLY: 101,
    }),

    // Read only create comments (when other user is in case)
    readCreateComments: 100,
    readStateCreateComments: Object.freeze({
        READ_WRITE: 100,
        READ_ONLY: 101,
    }),

    // Read only content (case in queue)
    readContent: 100,
    readStateContent: Object.freeze({
        READ_WRITE: 100,
        READ_ONLY: 101,
    }),

    // Read only chat (lost connection)
    readChat: 100,
    readStateChat: Object.freeze({
        READ_WRITE: 100,
        READ_ONLY: 101,
    }),

    // Colors for information messages
    colors: Object.freeze({
        ERROR: 'red',
        WARNING: 'rgb(207, 115, 224)',
        SUCCESS: 'green',
    }),
};

// Define a getter in your Vuex store
const getters = {
    isReadSelectedCaseStateReadWrite: (state) => (read) => {
        if (read) {
            return (
                state.readStateGlobal.READ_WRITE === read &&
                state.readStateCaseComments.READ_WRITE === read &&
                state.readStateClientCard.READ_WRITE === read
            );
        }
        return (
            state.readStateGlobal.READ_ONLY === read &&
            state.readStateCaseComments.READ_ONLY === read &&
            state.readStateClientCard.READ_ONLY === read
        );
    },
};

const actions = {
    // Actions for controlling the states of compontents

    setReadSelectedCase({ commit, state }, read) {
        if (read) {
            commit('SET_READ_CASE_COMMENTS', state.readStateCaseComments.READ_WRITE);
            commit('SET_READ_CLIENT_CARD', state.readStateClientCard.READ_WRITE);
            commit('SET_READ_CREATE_COMMENTS', state.readStateCreateComments.READ_WRITE);
        } else {
            commit('SET_READ_CASE_COMMENTS', state.readStateCaseComments.READ_ONLY);
            commit('SET_READ_CLIENT_CARD', state.readStateClientCard.READ_ONLY);
            commit('SET_READ_CREATE_COMMENTS', state.readStateCreateComments.READ_ONLY);
        }
    },

    setReadCaseComments({ commit, state }, type) {
        commit('SET_READ_CASE_COMMENTS', state.readStateCaseComments[type]);
    },

    setReadClientCard({ commit, state }, type) {
        commit('SET_READ_CLIENT_CARD', state.readStateClientCard[type]);
    },

    setReadCreateComments({ commit, state }, type) {
        commit('SET_READ_CREATE_COMMENTS', state.readStateCreateComments[type]);
    },

    setReadContent({ commit, state }, type) {
        commit('SET_READ_CONTENT', state.readStateContent[type]);
    },

    setReadChat({ commit, state }, type) {
        commit('SET_READ_CHAT', state.readStateChat[type]);
    },

    setSideBar({ commit, state }, type) {
        commit('SET_SIDE_BAR', state.sideBarState[type]);
    },

    setSearch({ dispatch, commit, state, rootState }, type) {
        commit('SET_SEARCH', state.searchState[type]);

        if (state.search === state.searchState.SEARCHING) {
            dispatch('sendInformation', {
                id: 'search',
                text: i18n.t('layoutStore.Searching'),
                icon: 'mdi-cancel',
                color: state.colors.WARNING,
                informationType: state.informationState.SINGLE_COMPONENT,
            });
            dispatch('Cases/unSubscribeToListenToCaseList', rootState.Cases.caseFiltersState, { root: true });
        }
        if (state.search === state.searchState.EMPTY) {
            dispatch('removeInformation', 'search');
        }
    },

    setConnected({ dispatch, commit, state }, type) {
        commit('SET_APP_STATE', state.appState[type]);

        if (state.app == state.appState.DISCONNECTED) {
            dispatch('sendInformation', {
                id: 'disconnected',
                text: i18n.t('layoutStore.Disconnected'),
                icon: 'mdi-connection',
                color: state.colors.ERROR,
                informationType: state.informationState.GLOBAL,
            });
        }

        if (state.app == state.appState.CONNECTED) {
            dispatch('removeInformation', 'disconnected');
        }
    },

    // Main actions for controlling the information map and dispatching messages to information components

    sendInformation({ dispatch, commit }, data) {
        commit('PUSH_INFORMATION_MAP', data);
        dispatch('dispatchMostImportantMessages');
    },

    removeInformation({ dispatch, commit }, id) {
        commit('DELETE_INFORMATION_MAP', id);
        dispatch('dispatchMostImportantMessages');
    },

    dispatchMostImportantMessages({ dispatch, state }) {
        if (state.information.size > 0) {
            const item = [...state.information.entries()].reduce((accumulator, element) => {
                return element[1].informationType > accumulator[1].informationType ? element : accumulator;
            });
            dispatch('TopInformationBar/setTopData', item[1], {
                root: true,
            });
            dispatch('setTopBar', 'OPEN');
        } else {
            dispatch('setTopBar', 'CLOSED');
        }
    },

    setTopBar({ commit, state }, type) {
        commit('SET_TOP_BAR', state.topBarState[type]);
    },
};

const mutations = {
    SET_APP_STATE(state, data) {
        state.app = data;
    },
    DELETE_INFORMATION_MAP(state, id) {
        state.information.delete(id);
    },
    PUSH_INFORMATION_MAP(state, data) {
        state.information.set(data.id, data);
    },
    SET_INFORMATION(state, data) {
        state.information = data;
    },
    SET_TOP_BAR(state, data) {
        state.topBar = data;
    },
    SET_SEARCH(state, data) {
        state.search = data;
    },
    SET_SIDE_BAR(state, data) {
        state.sideBar = data;
    },
    SET_READ_CASE_COMMENTS(state, data) {
        state.readCaseComments = data;
    },
    SET_READ_CLIENT_CARD(state, data) {
        state.readClientCard = data;
    },
    SET_READ_CREATE_COMMENTS(state, data) {
        state.readCreateComments = data;
    },
    SET_READ_CONTENT(state, data) {
        state.readContent = data;
    },
    SET_READ_CHAT(state, data) {
        state.readChat = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
