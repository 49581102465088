import * as Sentry from '@sentry/vue';

import { target as serverName, dev, customerFirstUrl } from '@/app/config';

function initSentry(Vue, router) {
    let environment = window.location.hostname;

    // if (dev) {
    //     environment = 'local_development';
    // }

    const enabled = process.env.NODE_ENV === 'production';

    Sentry.init({
        Vue,
        // sadly this has to be exposed publicly, This is taken from sentry's documentation
        dsn: 'https://01ef0e2c85cda4e2e2a73bbfb8006c17@o4506037829500928.ingest.us.sentry.io/4507445983576064',
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        environment,
        enabled,
        release: 'c1-frontend@' + process.env.VUE_APP_VERSION,
        trackComponents: true,

        tunnel: customerFirstUrl + 'api/monitor', // This should be changed to a relative path in the future when we move away from the customerFirstUrl
        tracePropagationTargets: ['localhost', /^\//],
        // Performance Monitoring
        sampleRate: 1, // Capture 100% of the transactions, reduce in production!
        // tracesSampleRate: 0.05, // Capture 100% of the transactions, reduce in production!
        tracesSampleRate: 0.05, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        // replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

export default initSentry;
