<template>
    <div
        id="actionModule"
        ref="target"
        class="justify-end py-4 action-bar d-flex flex-column align-end"
        :class="opened ? 'pr-4' : ''"
        :style="activeBar"
        @mouseover="() => (hovered = true)"
        @mouseleave="() => (hovered = false)"
    >
        <div class="justify-end bar-content softShadowLeft d-flex flex-column">
            <!-- ALL MODULES SHOWING OUTSIDE -->
            <v-tooltip bottom open-delay="250" close-delay="250">
                <template #activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        class="action-button show-outside softShadow"
                        :style="activeButton"
                        v-on="on"
                        @click="() => (opened = !opened)"
                    >
                        <v-badge
                            bordered
                            color="color1"
                            overlap
                            :content="getAllUnreadMessages"
                            offset-x="20"
                            offset-y="20"
                            :value="getAllUnreadMessages"
                        >
                            <v-icon
                                :class="getAllUnreadMessages > 0 ? 'jump-animation' : ''"
                                class="pa-3"
                                size="42"
                                style="background: white; border-radius: 99999px"
                            >
                                mdi-account-details
                            </v-icon>
                        </v-badge>
                    </button>
                </template>
                <span>{{ $t('shortcut') }}: {{ buttonsForShortcuts }}</span>
            </v-tooltip>
            <ActiveCommunicationsBar class="show-outside" />
            <div class="pt-2 px-2 pb-1 content">
                <!-- ALL MODULES INSIDE OF THE CONTENT -->
                <CallQueues v-if="userSettings.userModal.showQueues.active" class="mb-4" />
                <ActiveCasesInboxModule class="mb-4" />
                <ActiveCommunications class="mb-2" />
            </div>
            <div class="bar-footer">
                <div class="bar-footer-item">
                    <span class="mb-1">{{ $t('systemLanguage') }}</span>
                    <Language />
                </div>
                <div v-if="wrapupTimeActive" ref="wrapupTime" class="bar-footer-item">
                    <span class="mb-1">{{ $t('wrapupTime') }}</span>
                    <WrapupTime @menuRef="(v) => setWrapupMenuRef(v)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import ActiveCommunicationsBar from '@/components/Global/UserActionModule/Modules/ActiveCommunications/ActiveCommunicationsBar.vue';
    import ActiveCommunications from '@/components/Global/UserActionModule/Modules/ActiveCommunications/ActiveCommunications.vue';
    import ActiveCasesInboxModule from '@/components/Global/UserActionModule/Modules/ActiveCasesInboxModule.vue';
    import Language from '@/components/Global/Language.vue';
    import WrapupTime from '@/components/Global/WrapupTime.vue';

    export default {
        components: {
            ActiveCommunicationsBar,
            ActiveCommunications,
            ActiveCasesInboxModule,
            Language,
            WrapupTime,
            CallQueues: () => import('@/components/Global/UserActionModule/Modules/CallQueues/CallQueues.vue'),
        },
        data() {
            return {
                hovered: false,
                targetRef: null,
                wrapupMenuRef: null,
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),
            opened: {
                get() {
                    return this.$store.state.Users.userActionModuleOpen;
                },
                set(value) {
                    this.$store.commit('Users/SET_USER_ACTION_MODULE_OPEN', value);
                },
            },
            wrapupTimeActive() {
                return this.userSettings.queue.extendedWrapup.showWrapup.active;
            },

            activeUserCases() {
                return this.$store.state.Cases?.activeUserCases;
            },
            items() {
                return this.$store.state.Users.currentDialogs;
            },
            getAllUnreadMessages() {
                const unreadMessages = this.items.reduce((acc, item) => {
                    return acc + item.unreadMessages;
                }, 0);
                const activeUserCasesUnreadMessages = this.activeUserCases.reduce((acc, item) => {
                    // find if caseid exists in items
                    const found = this.items.find((i) => {
                        return i.caseId == item.CaseID;
                    });
                    if (found) {
                        return acc;
                    }
                    return acc + item.UnreadMessages;
                }, 0);
                return unreadMessages + activeUserCasesUnreadMessages;
            },
            clientIsUsingMac() {
                return /(mac|iphone|ipod|ipad)/i.test(navigator.userAgent);
            },
            buttonsForShortcuts() {
                return `(${this.clientIsUsingMac ? 'cmd' : 'ctrl'} + k)`;
            },
            activeBar() {
                if (this.opened) {
                    return {
                        width: '400px',
                    };
                }
                return {
                    width: '0px',
                };
            },
            activeButton() {
                if ((this.opened || this.getAllUnreadMessages === 0) && !this.hovered) {
                    return {
                        width: '6px',
                        paddingLeft: '12px',
                    };
                }
                return {};
            },
        },
        created() {
            window.addEventListener('keydown', this.keyDown);
        },
        mounted() {
            this.targetRef = this.$refs.target;
            document.addEventListener('mousedown', this.handleClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener('mousedown', this.handleClickOutside);
        },

        destroyed() {
            window.removeEventListener('keydown', this.keyDown);
        },
        methods: {
            handleClickOutside(event) {
                if (!this.targetRef || this.targetRef.contains(event.target)) return;

                if (this.wrapupMenuRef && !this.wrapupMenuRef.contains) return;
                this.handleModal();
            },
            keyDown(event) {
                const eventKey = this.clientIsUsingMac ? event.metaKey : event.ctrlKey;
                if (event.code === 'KeyK' && eventKey) {
                    this.opened = !this.opened;
                }
            },
            handleModal() {
                if (!this.opened) return;
                this.opened = false;
            },
            setWrapupMenuRef(ref) {
                this.wrapupMenuRef = ref;
            },
        },
    };
</script>
<style scoped lang="scss">
    .jump-animation {
        animation: animate-jump 3s infinite ease;
        position: relative;
    }

    @keyframes animate-jump {
        0% {
            bottom: 0;
        }

        90% {
            bottom: 0px;
        }

        92% {
            bottom: 5px;
        }

        94% {
            bottom: 0px;
        }

        96% {
            bottom: 5px;
        }

        98% {
            bottom: 0px;
        }

        100% {
            bottom: 0px;
        }
    }

    .content {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        min-height: 300px;
        max-height: 100%;
    }
    .action-bar {
        position: fixed;
        right: 0;
        z-index: 1000;
        bottom: 0;
        min-height: 300px;
        max-height: 100%;
        transition: all 0.5s ease;
    }
    .action-button {
        bottom: 100px;
        background: var(--v-primary-base);
        overflow: hidden;
        width: 80px;
        height: 80px;
        border-radius: 6px 0px 0px 6px;
        transition: all 0.5s ease;
    }
    .show-outside {
        transform: translateX(-100%);
        position: absolute;
        left: 0;
    }

    .bar-content {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        min-height: 300px;
        max-height: 100%;
        border-radius: 6px 6px 0px 0px;
        background: white;
    }

    .bar-footer-container {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        max-height: 100%;
        border-radius: 0px 0px 6px 6px;
        background: white;
    }
    .bar-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2%;
        padding: 8px;

        height: 100px;
    }

    .bar-footer-item {
        background-color: var(--v-gray3-base);
        color: var(--v-gray4-base);

        border-radius: 10px;
        padding-top: 12px;
        height: 100%;
        flex: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>
<i18n lang="json">
{
    "en": {
        "shortcut": "Shortcut",
        "systemLanguage": "System language",
        "wrapupTime": "Wrap-Up Time"
    },
    "sv": {
        "shortcut": "Genväg",
        "systemLanguage": "Systemspråk",
        "wrapupTime": "Bearbetningstid"
    }
}
</i18n>
