import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainDiv"},[_c('div',{staticClass:"userBar d-flex flex-column justify-end align-center softShadow",attrs:{"id":"bar"},on:{"mouseover":function($event){return _vm.openInfo()},"mouseleave":function($event){return _vm.closeInfo()}}},_vm._l((_vm.items),function(item,index){return _c('Transition',{key:index},[_c('div',{staticClass:"userBar__item",style:({
                    height: _vm.getHeight(item) + '% !important',
                    background: _vm.generateColor(item.channelType),
                    borderRadius: _vm.roundCorners(index),
                    borderTop: _vm.getBorder(index),
                })},[_c('div',{staticClass:"shimmer-effect"})])])}),1),(_vm.userSettings.userModal.showPotentialCommunications.active)?_c('div',{staticClass:"userBar__menu softShadow text-center",style:(_vm.infoMenuSize),on:{"mouseover":function($event){return _vm.openInfo()},"mouseleave":function($event){return _vm.closeInfo()}}},[_c('div',{staticClass:"softShadowHard"},[_c(VTabs,{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"primary"}}),_c(VTooltip,{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTab,_vm._g(_vm._b({staticClass:"no-uppercase",class:_vm.tab === 0 ? 'softShadow' : '',staticStyle:{"transition":"all","transition-duration":"0.3s"},style:({
                                backgroundColor: 'white',
                                color: _vm.tab === 0 ? 'black !important' : '',
                                borderRadius: '8px 0 0 0',
                                width: '50%',
                                maxWidth: '50%',
                            })},'v-tab',attrs,false),on),[_c('h4',{staticStyle:{"font-size":"14px","font-weight":"400"}},[_vm._v(_vm._s(_vm.$t('active')))])])]}}],null,false,891747501)},[_c('span',[_vm._v(_vm._s(_vm.$t('amountActive')))])]),_c(VTooltip,{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTab,_vm._g(_vm._b({staticClass:"no-uppercase",class:_vm.tab === 1 ? 'softShadow' : '',staticStyle:{"transition":"all","transition-duration":"0.3s"},style:({
                                backgroundColor: 'white',
                                color: _vm.tab === 1 ? 'black !important' : '',
                                borderRadius: '0 8px 0 0',
                                width: '50%',
                                maxWidth: '50%',
                            })},'v-tab',attrs,false),on),[_c('h4',{staticStyle:{"font-size":"14px","font-weight":"400"}},[_vm._v(_vm._s(_vm.$t('potential')))])])]}}],null,false,3245957777)},[_c('span',[_vm._v(_vm._s(_vm.$t('potentialBeforeFull')))])])],1)],1),_c('div',{staticClass:"d-flex flex-wrap justify-space-between pa-2 mt-2",staticStyle:{"max-width":"300px"}},_vm._l((_vm.capacityLeftArray),function(item,index){return _c(VTooltip,{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"pa-2 ma-2 d-flex flex-column justify-center align-center rounded softShadowHard",staticStyle:{"background":"white","width":"25%"}},'div',attrs,false),on),[_c('div',{staticClass:"userBar__item d-flex justify-center align-center",style:({
                                height: '40px !important',
                                width: '40px !important',
                                background: _vm.generateColor(item.channel),
                                borderRadius: '50%',
                            })},[_c(VIcon,{attrs:{"color":"white","size":"26"}},[_vm._v(" "+_vm._s(_vm.caseTypeIcon(item.channel))+" ")])],1),_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"mt-3",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.tab === 0 ? item.active : item.totalCapacity)+" ")])])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tab === 0 ? _vm.$t('activeChannel') : _vm.$t('potentialChannel'))+" "+_vm._s(item.text))])])}),1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }