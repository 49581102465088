<template>
    <main v-if="state === states.LOADED" :style="itemStyle">
        <section class="item" @mouseover="showSentiment = true" @mouseleave="showSentiment = false">
            <header>
                <section class="user">
                    <v-avatar v-if="item?.meta?.SoMeUserImage === null" color="var(--v-gray3-base)" size="24" />
                    <CommonAvatar v-if="item?.meta?.SoMeUserImage" :base64img="item.meta.SoMeUserImage" size="24" />
                    <section :class="isReaction ? 'reacted' : ''">
                        <span class="name" :style="textStyle">{{ soMeUserName }}</span>
                        <span v-if="isReaction" class="reaction" v-html="'&nbsp' + $t('social.reacted')"></span>
                    </section>
                </section>
                <section class="action-menu" :style="actionMenuStyle">
                    <div class="icon-container">
                        <v-tooltip v-if="item?.meta?.IsHidden" top>
                            <template #activator="{ on }">
                                <v-icon color="var(--v-gray1-base)" :size="sentimentSize" mdi-eye-off v-on="on">
                                    mdi-eye-off
                                </v-icon>
                            </template>
                            {{ $t('action.isHidden') }}
                        </v-tooltip>
                    </div>

                    <v-icon class="sentiment-icon" :color="sentimentColor" :size="sentimentSize">
                        {{ sentimentIcon }}
                    </v-icon>

                    <ActionMenu :item="item" />
                </section>
            </header>
            <section v-if="!isReaction" class="content" :style="contentStyle">
                <span :style="textStyle" v-html="highlightText(item?.meta?.Message, socialMediaChannelName)"></span>
            </section>
            <section class="bottom-menu">
                <v-progress-linear
                    v-if="actionLoader"
                    indeterminate
                    color="var(--v-gray1-base)"
                    class="progress-width"
                />
                <span class="state">
                    <section v-if="item?.meta?.Synced === 2 && isChannel" class="pending">
                        <v-progress-circular indeterminate color="gray1" size="10" width="2" class="mr-1" />
                        {{ $t('bottom.pending') }}
                    </section>
                    <section v-if="item?.meta?.Synced === 1 && isChannel">{{ $t('bottom.sent') }}</section>
                </span>
                <span class="date">{{ formatDate(item?.meta?.PublishedAt) }}</span>
            </section>
        </section>
    </main>

    <main v-else-if="state === states.LOADING" class="loader">
        <v-progress-circular indeterminate color="gray1" size="24" width="3" />
    </main>
</template>
<script>
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import ActionMenu from '@/components/Cases/Social/ActionMenu.vue';
    import { mapState } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';
    import { format } from 'date-fns';

    export default {
        name: 'SocialCaseComment',

        components: {
            CommonAvatar,
            ActionMenu,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                sentimentSize: 18,
                data: null,
                state: 'Init',
                states: Object.freeze({
                    INIT: 'Init',
                    LOADING: 'Loading',
                    LOADED: 'Loaded',
                }),
                showSentiment: false,
            };
        },

        computed: {
            ...mapState({
                actionLoader: (state) => state.Social.actionLoader,
                userSettings: (state) => state.System.userSettings,
            }),

            textStyle() {
                if (this.item.IsHidden) {
                    return {
                        color: 'var(--v-gray1-base)',
                    };
                }
                return {
                    color: 'var(--v-gray4-base)',
                };
            },

            isChannel() {
                return (
                    this.socialMediaChannelName === this.item?.meta?.SoMeName ||
                    this.soMeUserClientID === this.item?.meta?.SoMeName
                );
            },

            isReaction() {
                return this.item?.meta?.Type === 'reaction';
            },

            contentStyle() {
                if (this.item.outgoing) {
                    return {
                        borderRadius: '0px 16px 16px 16px',
                        backgroundColor: 'rgb(225, 241, 245)',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                    };
                }
                return {
                    backgroundColor: 'var(--v-gray3-base)',
                };
            },

            itemStyle() {
                if (this.item.outgoing) {
                    return {
                        alignItems: 'flex-end',
                    };
                }
                return {
                    alignItems: 'flex-start',
                };
            },

            socialMediaChannelName() {
                return this.selectedCase?.meta?.SocialMediaChannelName;
            },

            soMeUserClientID() {
                return this.selectedCase?.meta?.SoMeUserClientID;
            },

            selectedCase() {
                return this.$store.state.Cases.selectedCase;
            },

            soMeUserName() {
                if (this.item?.meta?.SoMeName === null) {
                    return this.$t('social.unknown');
                }
                return this.item?.meta?.SoMeName;
            },

            sentimentColor() {
                switch (this.item?.meta?.Sentiment) {
                    case 'positive': {
                        return 'var(--v-success-base)';
                    }
                    case 'negative': {
                        return 'var(--v-error-base)';
                    }
                    default: {
                        return 'var(--v-gray1-base)';
                    }
                }
            },

            sentimentIcon() {
                switch (this.item?.meta?.Sentiment) {
                    case 'positive': {
                        return 'mdi-emoticon-happy-outline';
                    }
                    case 'negative': {
                        return 'mdi-emoticon-sad-outline';
                    }
                    default: {
                        return 'mdi-emoticon-neutral-outline';
                    }
                }
            },

            sentimentText() {
                switch (this.item?.meta?.Sentiment) {
                    case 'positive': {
                        return this.$t('sentiment.positive');
                    }
                    case 'negative': {
                        return this.$t('sentiment.negative');
                    }
                    default: {
                        return this.$t('sentiment.neutral');
                    }
                }
            },

            actionMenuStyle() {
                if (!this.item?.meta?.CanHide) {
                    return {
                        gridTemplateColumns: '32px 32px',
                    };
                }
                return {
                    gridTemplateColumns: '32px 32px 32px',
                };
            },
        },

        created() {
            this.init();
        },

        methods: {
            getDay,
            format,

            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },

            highlightText(text, word) {
                if (typeof text !== 'string') {
                    return text;
                }
                const regex = new RegExp(`(${word})`, 'gi');
                const parts = text.split(regex);
                let highlightedText = '';
                for (const part of parts) {
                    if (part.match(regex)) {
                        if (this.selectedCase.meta?.Type === 'mentioned_media') {
                            highlightedText += `<mark><a href="${this.selectedCase?.meta?.SocialMediaChannelLink}" target="_blank" style="color: black !important;">${part}</a></mark>`;
                        } else {
                            highlightedText += `<a href="${this.selectedCase?.meta?.SocialMediaChannelLink}" target="_blank">${part}</a>`;
                        }
                    } else {
                        highlightedText += part;
                    }
                }
                return highlightedText;
            },

            init() {
                this.state = this.states.LOADING;
                this.state = this.states.LOADED;
            },

            openSoMe() {
                try {
                    const newWindow = window.open(this.selectedCase.meta?.SoMeLink, '_blank');
                    if (!newWindow) {
                        throw new Error('Failed to open URL in a new window. Please check your browser settings.');
                    }
                } catch (error) {
                    this.$toasted.show(this.$t('link.checkBrowser'), {
                        icon: 'alert',
                        type: 'error',
                        duration: 5000,
                    });
                }
            },
        },
    };
</script>
<style lang="scss">
    mark {
        background-color: var(--v-primary-base) !important;
        color: black !important;
    }
    .post-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        margin-bottom: 16px;
        background-color: white;
        border-radius: 8px;
    }
    .post-img {
        border-radius: 0px;
        max-width: 100%;
    }
    .post-video {
        width: 100%;
        border-radius: 8px;
        background-color: white;
        height: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--v-gray2-base);
    }
</style>
<style lang="scss" scoped>
    .progress-width {
        width: 25%;
    }
    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .action-menu {
        display: grid;

        grid-gap: 4px;
        align-items: center;
    }
    .pending {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .reacted {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .item {
        display: flex;
        flex-direction: column;
        max-width: 500px;
    }

    .bottom-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
        font-size: 12px;
        margin-left: 32px;
    }

    .state {
        color: var(--v-gray1-base);
    }
    .date {
        color: var(--v-gray1-base);
    }
    .type {
        padding-top: 16px;
        font-size: 1.2rem;
    }
    main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: fit-content;
        width: 100%;
        margin-bottom: 32px;
    }
    nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
    }
    .user {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .divider {
        margin-top: 18px;
    }
    .content {
        margin-top: 8px;
        margin-bottom: 6px;
        padding: 24px;
        border-radius: 0px 16px 16px 16px;
        background-color: rgba(245, 245, 247, 0.53);
        margin-left: 32px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }
    header {
        color: var(--v-gray4-base);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        padding-top: 16px;
    }
    header .v-avatar {
        margin-right: 8px;
    }
    header section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    header section .date {
        color: var(--v-gray1-base);
    }
    .comment-menu {
        display: grid;
        grid-template-columns: auto auto 1fr;
        grid-gap: 8px;
    }
    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
<i18n lang="json">
{
    "en": {
        "button": {
            "answer": "Answer"
        },
        "link": {
            "checkBrowser": "Failed to open URL in a new window. Please check your browser settings."
        },
        "social": {
            "comment": "Comment",
            "post": "Post",
            "mentioned": "Mentioned",
            "from": " from ",
            "on": " on ",
            "unknown": "Unknown",
            "reacted": "<b>reacted</b>"
        },
        "sentiment": {
            "positive": "Positive",
            "negative": "Negative",
            "neutral": "Neutral"
        },
        "bottom": {
            "pending": "Syncing...",
            "sent": "Synced"
        },
        "action": {
            "isHidden": "Comment is hidden"
        }
    },
    "sv": {
        "button": {
            "answer": "Svara"
        },
        "link": {
            "checkBrowser": "Misslyckades med att öppna URL i ett nytt fönster. Vänligen kontrollera dina webbläsarinställningar."
        },
        "social": {
            "comment": "Kommentar",
            "post": "Inlägg",
            "mentioned": "Nämndes",
            "from": " från ",
            "on": " på ",
            "unknown": "Okänd",
            "reacted": "<b>reagerade</b>"
        },
        "sentiment": {
            "positive": "Positiv",
            "negative": "Negativ",
            "neutral": "Neutral"
        },
        "bottom": {
            "pending": "Synkar...",
            "sent": "Synkad"
        },
        "action": {
            "isHidden": "Kommentaren är dold"
        }
    }
}
</i18n>
