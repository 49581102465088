module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "chat": {
          "noConn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No connection"])},
          "chatClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chat is closed"])},
          "chatClosedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chat is closed by"])},
          "sureClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close the chat?"])},
          "caseInQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The case is in the queue waiting to be picked up by the automatic assignment"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write message"])},
          "disabledMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chat is closed"])},
          "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client"])}
        }
      },
      "sv": {
        "chat": {
          "noConn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen anslutning"])},
          "chatClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatten är nu stängd"])},
          "chatClosedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatten är avslutad av"])},
          "sureClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill stänga chatten?"])},
          "caseInQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärendet är i kön och väntar på att plockas upp av automatisk tilldelning"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv meddelande"])},
          "disabledMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatten är stängd"])},
          "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klient"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"chat":{"noConn":"No connection","chatClosed":"The chat is closed","chatClosedBy":"The chat is closed by","sureClose":"Are you sure you want to close the chat?","caseInQueue":"The case is in the queue waiting to be picked up by the automatic assignment","writeMessage":"Write message","disabledMessage":"The chat is closed","client":"client"}},"sv":{"chat":{"noConn":"Ingen anslutning","chatClosed":"Chatten är nu stängd","chatClosedBy":"Chatten är avslutad av","sureClose":"Är du säker på att du vill stänga chatten?","caseInQueue":"Ärendet är i kön och väntar på att plockas upp av automatisk tilldelning","writeMessage":"Skriv meddelande","disabledMessage":"Chatten är stängd","client":"klient"}}}')
  delete Component.options._Ctor
  
}
