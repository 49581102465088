<template>
    <div v-if="selectedCase.case.parentCase" class="d-flex">
        <v-tooltip bottom>
            <template #activator="tooltip">
                <div v-bind="tooltip.attrs" v-on="tooltip.on">
                    <v-btn
                        class="mx-1 c1-btn"
                        fab
                        elevation="0"
                        height="32"
                        width="32"
                        color="primary"
                        @click="clicked(selectedCase.case.parentCase.caseId)"
                    >
                        <v-icon size="20">mdi-chevron-up</v-icon>
                    </v-btn>
                </div>
            </template>
            <span>{{ $t('gotToParentCase') }}</span>
        </v-tooltip>

        <v-tooltip
            v-if="
                selectedCase.case.originParent &&
                selectedCase.case.parentCase.caseId !== selectedCase.case.originParent.caseId
            "
            bottom
        >
            <template #activator="tooltip">
                <div v-bind="tooltip.attrs" v-on="tooltip.on">
                    <v-btn
                        class="mx-1 c1-btn"
                        fab
                        elevation="0"
                        height="32"
                        width="32"
                        color="primary"
                        @click="clicked(selectedCase.case.originParent.caseId)"
                    >
                        <v-icon size="20">mdi-chevron-double-up</v-icon>
                    </v-btn>
                </div>
            </template>
            <span>{{ $t('gotToSourceParentCase') }}</span>
        </v-tooltip>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        components: {},
        props: {
            selectedCase: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {};
        },
        methods: {
            ...mapActions('Cases', ['goToCase']),
            clicked(caseId) {
                this.goToCase({ caseId });
            },
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n lang="json">
{
    "en": {
        "gotToParentCase": "Go to parent case",
        "gotToSourceParentCase": "Go to origin parent case"
    },
    "sv": {
        "gotToParentCase": "Gå till förälderärende",
        "gotToSourceParentCase": "Gå till ursprungligt förälderärende"
    }
}
</i18n>
