export const modalStates = {
    CASE_ASSIGN: 'CASE_ASSIGN',
    CONTACT_PERSON: 'CONTACT_PERSON',
    CLIENT_ASSIGN: 'CLIENT_ASSIGN',
    CRM: 'CRM',
    CONTENT: 'CONTENT',
    RECORDINGS: 'RECORDINGS',
    QUEUE_ASSIGN: 'QUEUE_ASSIGN',
    IFRAME: 'iframe',
};

export const events = {
    UPDATE_CLIENT: 'UPDATE_CLIENT',
    NEW_CLIENT: 'NEW_CLIENT',
};

export const states = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
};

export const searchFields = Object.freeze({
    NAME: 'name',
    EMAIL: 'email',
    TELEPHONE: 'telephone',
    ADDRESS: 'address',
});

export const clientTypes = Object.freeze({
    CLIENT: 'client',
    CONTACT: 'contact',
    SHADOW: 'shadow',
});
