import axios from 'axios';
import DedupeRequest from '@/entity/DedupeRequest';

const oneSecond = 1000;
const fiveSeconds = 5000;

function getAdminList() {
    return axios.get('admin');
}
function getSidebarContent() {
    return axios.get('app/nav');
}

function saveChatMessage(payload) {
    return axios.post('/admin/chat/messages', payload);
}

function getChatMessages(payload) {
    return axios.get(`/admin/chat/messages/${payload}`);
}

//= ===================================================//
//                  TEMPLATE CALLS                    //
//= ===================================================//

function getTemplatesByIdentifier(query) {
    return axios({
        metod: 'get',
        url: `/templates/identifier/${query}`,
    });
}

const getTemplatesDedupe = new DedupeRequest((id) => {
    return axios.get(`templates/${id}`);
}, oneSecond);

function getTemplates(id = 0) {
    return getTemplatesDedupe.send(id);
}

function addOrChangeTemplate(payload) {
    return axios.post('/templates', payload);
}
function deleteTemplate(id) {
    return axios.delete(`/templates/${id}`);
}

function getGlobalSignature() {
    return getGlobalSignatureDedupe.send();
}

const getGlobalSignatureDedupe = new DedupeRequest(() => {
    return axios.get('/templates/signature/global');
}, fiveSeconds);

function setGlobalSignature(id) {
    return axios.put(`/templates/signature/global/${id}`);
}

function removeGlobalSignature() {
    return axios.delete('/templates/signature/global');
}

function getChatTemplates(filters) {
    return axios.get('/templates', {
        params: filters,
    });
}

//= ===================================================//
//                        ROLES                       //
//= ===================================================//

function getRoles() {
    return axios.get('/roles');
}

function saveRoleChange(payload) {
    return axios.put('/roles', payload);
}

//= ===================================================//
//                       Config                       //
//= ===================================================//
function getIntegrationConfig() {
    return axios.get('/admin/config');
}

function getSpecificIntegrationTopic(payload) {
    return axios.get(
        `/admin/config/specific?configId=${payload.configId}&key=${payload.key}&parentKey=${payload.parentKey}`
    );
}

function saveIntegrationConfig(payload) {
    return axios.post('/admin/config', payload);
}

//= ===================================================//
//                       SNOOZE                       //
//= ===================================================//

function getAllTimeAlternatives() {
    return axios.get('/admin/all/times');
}

function getSnoozeOptions(payload) {
    return axios.get(`/admin/snoozes/${payload}`);
}
function getSettingsSnoozes(payload) {
    return axios.get(`/admin/settings/snoozes?ChannelType=${payload.ChannelType}&QueueId=${payload.QueueId}`);
}
function addSLASnooze(payload) {
    return axios.post('/admin/settings/snoozes', payload);
}
function deleteSLASnooze(payload) {
    return axios.delete(`/admin/settings/snoozes/${payload}`);
}

export default {
    getAdminList,
    getSidebarContent,
    getTemplates,
    getTemplatesByIdentifier,
    addOrChangeTemplate,
    deleteTemplate,
    getChatTemplates,
    // getOpenHours,
    getIntegrationConfig,
    saveIntegrationConfig,
    getSpecificIntegrationTopic,
    saveRoleChange,
    getRoles,
    saveChatMessage,
    getChatMessages,
    // saveOpenHours
    getSnoozeOptions,
    getSettingsSnoozes,
    addSLASnooze,
    deleteSLASnooze,
    getAllTimeAlternatives,
    getGlobalSignature,
    setGlobalSignature,
    removeGlobalSignature,
};
