// This directive handles the shadow dom for comments. It is used in the comments component.
// The shadow dom is used to prevent the comments from inheriting the styles of the parent component.
// This is done by creating a shadow dom and appending the comments to it.
// We also use DOMPurify to sanitize the content of the comments.
// And we handle the load of the comments images inside the directive as well.

import DOMPurify from 'dompurify';
import Vue from 'vue';
import store from '@/store/modules/files.store';
import i18n from '../i18n';

// Configuration for the email sanitization
const emailSanitizationConfig = {
    ALLOWED_TAGS: [
        'b',
        'i',
        'u',
        'strong',
        'em',
        's',
        'a',
        'p',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'ul',
        'ol',
        'li',
        'blockquote',
        'pre',
        'code',
        'br',
        'hr',
        'div',
        'span',
        'img',
        'table',
        'thead',
        'tbody',
        'tr',
        'th',
        'td',
        'sub',
        'sup',
        'dd',
        'dl',
        'label',
    ],
    ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'style', 'class', 'data-src', 'width', 'height', 'colspan'],
    FORBID_TAGS: ['script', 'iframe', 'frame', 'frameset', 'object', 'embed', 'form', 'input', 'textarea', 'button'],
    FORBID_ATTR: ['onerror', 'onload', 'onclick', 'onmouseover', 'onmouseout', 'onchange', 'onsubmit'],
    WHOLE_DOCUMENT: false,
};

// Set the configuration for the email sanitization
DOMPurify.setConfig(emailSanitizationConfig);

const fileNotFound = require('@/assets/General/file_not_found.png');

const IDENTIFIER = 'cid:';

const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
};

const shadowDirective = {
    bind(el, binding) {
        // 1. Create the comment
        const { content, div } = createComment(el, binding);

        restyleCautionMessage(content, div);

        // * get all images in the content
        const images = div.querySelectorAll('img');

        if (!images.length) {
            return;
        }

        // 2. Create the observer
        const intersectionObserver = new IntersectionObserver(async (entries, observer) => {
            for (const entry of entries) {
                // * when image is in view we set the image data source
                const currentImage = entry.target;

                if (entry.isIntersecting) {
                    setImageDataSource(currentImage);

                    currentImage.style.maxWidth = '100%';
                    currentImage.style.height = 'auto';

                    currentImage.style.visibility = 'visible';
                    currentImage.style.opacity = 1;
                    continue;
                }
                // 4. Hide the rendered content (for performance reasons)
                currentImage.style.visibility = 'hidden';
            }
        }, options);

        // * add observer for each image
        for (const img of images) {
            img.style.opacity = 0;
            img.style.visibility = 'hidden';
            intersectionObserver.observe(img);
        }
    },

    unbind(el) {
        if (el._intersectionObserver) {
            el._intersectionObserver.disconnect();
            delete el._intersectionObserver;
        }
    },
};

// Helper functions

// Creates the comment
export const createComment = (el, binding) => {
    const shadow = el.attachShadow({ mode: 'open' });
    const content = binding.value;
    const div = document.createElement('div');

    div.innerHTML = DOMPurify.sanitize(content);

    shadow.addEventListener('click', (event) => {
        const targetElement = event.target.closest('a');
        if (targetElement && div.contains(targetElement)) {
            event.preventDefault();
            el.dispatchEvent(
                new CustomEvent('link-clicked', {
                    detail: { content: targetElement },
                })
            );
        }
    });

    applyBaseTextStyles(div);
    shadow.append(div);
    return { content, div };
};

const applyBaseTextStyles = (div) => {
    for (const element of div.querySelectorAll('*')) {
        // 1. Break word to avoid super long words without space overflowing
        element.style.wordBreak = 'break-word';
    }
};

// Restyles the caution message to support all languages our system supports
const restyleCautionMessage = (content, div) => {
    // 2. Find the div that contains the text "CAUTION:" (this is from Microsoft)
    const cautionDiv = Array.from(div.querySelectorAll('div')).find((element) =>
        element.textContent.trim().startsWith('CAUTION:')
    );

    if (cautionDiv) {
        // 3. Create a new div element for the combined message
        const combinedMessageDiv = document.createElement('div');
        combinedMessageDiv.style.display = 'flex';
        combinedMessageDiv.style.flexDirection = 'column';
        combinedMessageDiv.style.padding = '12px';
        combinedMessageDiv.style.borderRadius = '8px';
        combinedMessageDiv.style.backgroundColor = 'white';
        combinedMessageDiv.style.color = 'gray';
        combinedMessageDiv.style.fontFamily = 'Roboto, sans-serif';
        combinedMessageDiv.style.border = '1px solid #e0e0e0';

        // 4. Create a new div element for the header
        const headerDiv = document.createElement('div');
        headerDiv.textContent = i18n.t('comment.cautionHeader');
        headerDiv.style.textAlign = 'center';
        headerDiv.style.fontWeight = '500';
        headerDiv.style.fontSize = '12px';
        headerDiv.style.color = 'black';

        // 5. Create a new div element for the caution message
        const cautionMessageDiv = document.createElement('div');
        cautionMessageDiv.textContent = i18n.t('comment.cautionText');
        cautionMessageDiv.style.textAlign = 'center';
        cautionMessageDiv.style.fontSize = '12px';

        // 6. Append the header and caution message divs to the combined message div
        combinedMessageDiv.append(headerDiv);
        combinedMessageDiv.append(cautionMessageDiv);

        // 7. Replace the existing div with the new combined message div
        cautionDiv.parentNode.replaceChild(combinedMessageDiv, cautionDiv);
    }
};
/**
 *
 * Hydrate images in the content
 * @param {HTMLElement} content
 * @returns {HTMLElement}
 */
export const hydrateImages = async (element) => {
    if (!element) {
        return element;
    }

    // * loop through all images in the content
    const imageElements = element.querySelectorAll('img');

    // * for each image, set the image data source
    for (const image of imageElements) {
        await setImageDataSource(image);
    }

    return element;
};

/**
 * Set the image data source for an image element
 * @param {HTMLImageElement} imageElement
 */
export const setImageDataSource = async (imageElement) => {
    const srcIsFetched = imageElement.src.startsWith('data:image');

    // * if we dont have a data-src with cid we return
    if (!imageElement.dataset.src || !imageElement.dataset.src.includes('cid:') || srcIsFetched) return;

    // * cid is in data-set - fetch image by cid
    const imageFromDataSource = await getImageFromDataSource(imageElement.dataset.src);

    // 2. If the image is found in the data source, replace the src
    if (imageFromDataSource) {
        // * set the src to the image from the data source
        imageElement.setAttribute('src', getSrcUrl(imageFromDataSource));
    }
};

export const sanitizeImageSrc = (content) => {
    // Remove all src attributes that do not include the identifier
    const div = document.createElement('div');
    div.innerHTML = content;
    const images = div.querySelectorAll('img');
    for (const image of images) {
        // * remove src from images what has cid in data-src
        if (image?.data?.src.includes(IDENTIFIER)) {
            image.removeAttribute('src');
        }
    }

    return div.innerHTML;
};

// Gets the src url for the image
export const getSrcUrl = (imgData) => {
    if (!imgData) {
        return fileNotFound;
    }
    const { data, contentType } = imgData;
    return `data:${contentType};base64,${data}`;
};

// Gets the image from the data source
export const getImageFromDataSource = (src) => {
    // 1. Check if images includes cid:
    if (!src || !src.includes(IDENTIFIER)) {
        return null;
    }

    // 2. Get the content id
    const contentId = src.split(IDENTIFIER).pop();

    // 3. Get the image from the data source
    return store.actions.getAttachmentFileByContentId({}, contentId);
};

export const getImagesInContent = async (content) => {
    if (!content) {
        return content;
    }
    // get all images in content
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const images = doc.querySelectorAll('img');

    if (!images.length) {
        return content;
    }

    for (const target of images) {
        const imageFromDataSource = await getImageFromDataSource(target.dataset.src);
        if (!imageFromDataSource) {
            continue;
        }
        const srcUrl = getSrcUrl(imageFromDataSource);
        target.src = srcUrl;
    }

    return doc.body.innerHTML;
};

Vue.directive('shadow-dom', shadowDirective);

export default shadowDirective;
