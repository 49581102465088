export function isValidSwedishSSN(ssn) {
    const regexSSN =
        /^(19|20)?\d{2}[ -]?((0\d)|(10|11|12))[ -]?(([0-2]\d)|(3[01])|(([78]\d)|(6[1-9])|(9[01])))[ -]?\d{4}$/gm;
    /**
     * ! This regex validates the following:
     * ? 1. The first 2 digits are between 19 and 20 (optional) only if the year is 4 digits long
     * ? 2. The next 2 digits are between 01 and 12
     * ? 3. The next 2 digits are between 01 and 31
     * ? 4. The last 4 digits are between 0000 and 9999
     * ? 5. The SSN can be separated by either a space or a dash
     * ? 6. The SSN can be 10 or 12 digits long
     * ! So in other words, this regex validates the following formats:
     * ? 1. 19YYMMDD-XXXX
     * ? 2. 19YYMMDDXXXX
     * ? 3. 19YYMMDD XXXX
     * ? 4. 20YYMMDD-XXXX
     * ? 5. 20YYMMDDXXXX
     * ? 6. 20YYMMDD XXXX
     * ? 7. YYMMDD-XXXX
     * ? 8. YYMMDDXXXX
     * ? 9. YYMMDD XXXX
     */

    if (!regexSSN.test(ssn)) {
        return false;
    }
    ssn = ssn
        .replaceAll(/\D/g, '') // strip out all but digits
        .split('') // convert string to array
        .reverse() // reverse order for Luhn
        .slice(0, 10); // keep only 10 digits (i.e. 1977 becomes 77)

    // verify we got 10 digits, otherwise it is invalid
    if (ssn.length != 10) {
        return false;
    }
    const sum = ssn
        // convert to number
        .map(Number)
        // perform arithmetic and return sum
        .reduce(function (previous, current, index) {
            // multiply every other number with two
            if (index % 2) current *= 2;
            // if larger than 10 get sum of individual digits (also n-9)
            if (current > 9) current -= 9;
            // sum it up
            return previous + current;
        });
    // sum must be divisible by 12
    return sum % 10 === 0;
}

export function formatAndValidateSSN(ssn) {
    // if ssn length is 10, check if the first two digits are over current year and if so, add 19 to the beginning else add 20
    if (!isValidSwedishSSN(ssn)) {
        return false;
    }
    ssn = ssn.replaceAll(/\D/g, '');
    if (ssn.length === 10) {
        const currentYear = new Date().getFullYear();

        const ssnYear = +ssn.slice(0, 2);

        if (ssnYear > +currentYear.toString().slice(2, 4)) {
            ssn = '19' + ssn;
        } else {
            ssn = '20' + ssn;
        }
    }
    return ssn;
}
