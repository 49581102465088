<template>
    <v-tooltip :disabled="!buttonIsDisabled" :top="item.value !== emailEnum" :right="item.value === emailEnum">
        <template #activator="{ on, attrs }">
            <button
                v-bind="attrs"
                :disabled="buttonIsDisabled"
                class="communication-button text-truncate"
                :cy-data="item.value"
                :class="buttonIsDisabled ? 'communication-button-disabled' : 'communication-button-active'"
                depressed
                v-on="on"
                @click="handleClick({ type: item.dialogId })"
            >
                <v-icon
                    class="ma-0 pa-0 mb-0"
                    :color="buttonIsDisabled ? 'var(--v-gray1-base)' : 'var(--v-primary-base)'"
                >
                    {{ item.icon }}
                </v-icon>
                <span class="text-truncate">{{ item.textTranslate[$i18n.locale] }}</span>
            </button>
        </template>
        {{ noContentHint }}
    </v-tooltip>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import { formTypes } from '../../enums/creator.enums';
    import { caseTypes, communicationTypes } from '../../enums/cases.enums';

    export default {
        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            ...mapState({
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
                selectedCase: (state) => state.Cases.selectedCase,
                loggedInUserId: (state) => state.Auth.userObject.userId,
                userSettings: (state) => state.System.userSettings,
                comments: (state) => state.Comments,
                integrations: (state) => state['Integrations.handler'].currentStates,
            }),

            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
                selectedCaseActiveUserId: 'Cases/selectedCaseActiveUserId',
                isCallingActive: 'Integrations.handler/isCallingActive',
                isSmsActive: 'Integrations.handler/isSmsActive',
            }),

            buttonIsDisabled() {
                if (!this.item.active) {
                    return true;
                }

                const isCallback = this.selectedCase.case?.internalType === caseTypes.CALLBACK;
                const isActiveUser = this.selectedCaseActiveUserId === this.loggedInUserId;
                const clientData = this.currentSelectedClient?.data;
                const emailData = clientData?.primaryEmail || clientData?.secondaryEmail;
                const telephoneData = clientData?.primaryTelephone || clientData?.secondaryTelephone;

                // button is disabled if readOnly, except call button when case is callback
                if (this.isReadOnly && (!isCallback || this.item.value !== communicationTypes.CALL || !isActiveUser)) {
                    return true;
                }

                if (!clientData) {
                    return true;
                }

                switch (this.item.value) {
                    case communicationTypes.EMAIL: {
                        return !emailData;
                    }
                    case communicationTypes.SMS: {
                        return !telephoneData || !this.isSmsActive;
                    }
                    case communicationTypes.CALL: {
                        return !telephoneData || !this.isCallingActive;
                    }
                    case communicationTypes.VIDEO: {
                        return !emailData && !telephoneData;
                    }
                    default: {
                        return true;
                    }
                }
            },
            noContentHint() {
                switch (this.item.value) {
                    case communicationTypes.SMS: {
                        if (!this.isSmsActive) {
                            return this.$t('clientCard.noContentHintNoSms');
                        }
                        return this.$t('clientCard.noContentHintPhone');
                    }
                    case communicationTypes.CALL: {
                        if (!this.isCallingActive) {
                            return this.$t('clientCard.noContentHintNoCalling');
                        }
                        return this.$t('clientCard.noContentHintPhone');
                    }
                    case communicationTypes.EMAIL: {
                        return this.$t('clientCard.noContentHintEmail');
                    }
                    case communicationTypes.VIDEO: {
                        return this.$t('clientCard.noContentHintVideo');
                    }
                    default: {
                        return this.$t('clientCard.noContentHint');
                    }
                }
            },
            emailEnum() {
                return communicationTypes.EMAIL;
            },
        },
        methods: {
            ...mapActions({
                setCommunicationCreatorData: 'Cases/setCommunicationCreatorData',
                openForm: 'Creator/openForm',
            }),

            handleClick(item) {
                if (item.type === 1) {
                    this.retrieveWrittenSubject();
                    const email =
                        this.currentSelectedClient.data.primaryEmail || this.currentSelectedClient.data.secondaryEmail;

                    if (!email) {
                        this.$toasted.show(this.$t('clientCard.noEmail'), {
                            type: 'error',
                            duration: 3000,
                        });
                        return;
                    }

                    this.openForm({
                        id: formTypes.CREATE_EMAIL_TO_CASE,
                        keys: {
                            respondent: {
                                value: email ? [email] : null,
                            },
                            caseId: {
                                value: this.selectedCase.case.caseId,
                            },
                        },
                    });
                    return;
                }

                if (item.type === 3) {
                    const telephone =
                        this.currentSelectedClient.data.primaryTelephone ||
                        this.currentSelectedClient.data.secondaryTelephone;

                    if (!telephone) {
                        this.$toasted.show(this.$t('clientCard.noTelephone'), {
                            type: 'error',
                            duration: 3000,
                        });
                        return;
                    }
                    this.openForm({
                        id: formTypes.CREATE_SMS_TO_CASE,
                        keys: {
                            respondent: {
                                value: telephone,
                            },
                            caseId: {
                                value: this.selectedCase.case.caseId,
                            },
                        },
                    });
                    return;
                }

                this.setCommunicationCreatorData({ type: item.type });
            },

            retrieveWrittenSubject() {
                if (this.selectedCase?.event) {
                    return;
                }

                // 1. Check if the user has enabled the feature
                if (!this.userSettings.cases.editSubject.active) {
                    // Set the subject to the current subject of the case
                    this.comments.subject = this.selectedCase?.case.topic;
                    return;
                }

                // 2. get the comment with the highest date time
                try {
                    const latestEmailComment = this.selectedCase?.comments.find(
                        (comment) => comment.typeOfMessage === 'email'
                    );

                    if (latestEmailComment) {
                        this.comments.subject = latestEmailComment.subject;
                        return;
                    }
                } catch (error) {
                    console.error('Error in retrieveWrittenSubject', error, new Date());
                }

                // 3. Check if the case has a topic
                if (this.selectedCase?.case.topic) {
                    this.comments.subject = this.selectedCase.case.topic;
                    return;
                }

                // 4. Set the subject to empty as a fallback
                this.comments.subject = '';
            },
        },
    };
</script>
<style scoped lang="scss">
    $height: 85px;
    .communication-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        min-height: $height;
        width: 0px;
        flex-grow: 1;
        background-color: white !important;
        border-radius: 0px;
        text-transform: capitalize;
        transition: all 0.2s ease-in;
    }

    .communication-button-disabled {
        opacity: 0.5;
    }

    // hover
    .communication-button-active:hover {
        background-color: var(--v-gray5-base) !important;
    }

    // focus
    .communication-button:focus {
        background-color: var(--v-gray5-base) !important;
    }

    span {
        padding: 0 0.5em;
        margin-top: 0.5em;
        font-size: 12px;
        letter-spacing: 0px;
        font-weight: 400;
        color: var(--v-gray1-base);
    }

    :deep(.v-btn__content) {
        display: flex !important;
        flex-direction: column !important;
    }

    .disabled {
        opacity: 0.3;
        pointer-events: none;
    }
</style>
<i18n lang="json">
{
    "en": {
        "clientCard": {
            "noEmail": "No email found",
            "noTelephone": "No telephone found",
            "noContentHintPhone": "Client needs to have phone number filled in.",
            "noContentHintEmail": "Client needs to have email filled in.",
            "noContentHintVideo": "Client needs to have email or phone number filled in.",
            "noContentHint": "Missing client info.",
            "noContentHintNoCalling": "Sms integration not active.",
            "noContentHintNoSms": "Calling integration not active."
        }
    },
    "sv": {
        "clientCard": {
            "noEmail": "Ingen e-post hittades",
            "noTelephone": "Inget telefonnummer hittades",
            "noContentHintPhone": "Klienten behöver ha telefonnummer ifyllt.",
            "noContentHintEmail": "Klienten behöver ha e-post ifyllt.",
            "noContentHintVideo": "Klienten behöver ha e-post eller telefonnummer ifyllt.",
            "noContentHint": "Saknad klientinfo.",
            "noContentHintNoCalling": "Sms integration är inte aktiv.",
            "noContentHintNoSms": "Samtals integration är inte aktiv."
        }
    }
}
</i18n>
