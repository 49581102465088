<template>
    <section ref="toolBar" :style="toolbarStyle" class="tool-bar" @mousedown="startDrag" @dblclick="setMaxHeight">
        <header class="tool-bar-header">{{ toolbarHeader }}</header>
        <round-icon-button
            v-if="tiptapState !== 'full'"
            color="var(--v-primary-base)"
            iconColor="white"
            @click="fullTiptap"
        >
            mdi-arrow-expand
        </round-icon-button>
        <round-icon-button
            v-if="tiptapState !== 'open'"
            color="var(--v-primary-base)"
            iconColor="white"
            @click="openTiptap"
        >
            mdi-window-minimize
        </round-icon-button>

        <round-icon-button color="var(--v-primary-base)" iconColor="white" @click="close">mdi-close</round-icon-button>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { throttle } from '@/utils/index';

    export default {
        data() {
            return {
                maxHeight: 0,
                throttledHandleDrag: null,
            };
        },
        computed: {
            ...mapState({
                tiptapState: (state) => state.Comments.tiptapState,
                currentExternalState: (state) => state.Comments.currentExternalState,
                tiptapHeight: (state) => state.Comments.tiptapHeight,
                isForwarding: (state) => state.Comments.isForwarding,
                selectedCase: (state) => state.Cases.selectedCase,
            }),

            toolbarHeader() {
                if (!this.currentExternalState) {
                    return this.$t('toolbar.internal');
                }

                if (this.selectedCase?.case?.internalType === 'social') {
                    return this.$t('toolbar.social');
                }
                if (this.isForwarding) {
                    return this.$t('toolbar.forwarding');
                }
                if (this.currentExternalState) {
                    return this.$t('toolbar.external');
                }

                return this.$t('toolbar.newMessage');
            },

            toolbarStyle() {
                return {
                    cursor: this.tiptapState === 'open' ? 'ns-resize' : 'default',
                    transition: 'height 0.2s ease',
                };
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.maxHeight = this.getMaxHeight();
            });
        },

        methods: {
            ...mapActions({
                openTiptap: 'Comments/openTiptap',
                closeTiptap: 'Comments/closeTiptap',
                fullTiptap: 'Comments/fullTiptap',
                setTiptapHeight: 'Comments/setTiptapHeight',
                clearRepliedComment: 'Comments/clearRepliedComment',
            }),

            close() {
                this.closeTiptap();
                this.clearRepliedComment();
            },

            setMaxHeight() {
                this.setTiptapHeight(this.getMaxHeight());
                this.maxHeight = this.getMaxHeight();
            },

            getMaxHeight() {
                const NO_HEIGHT = 0;
                const commentGridContainer = document.querySelector('.comment-grid-container');

                if (!commentGridContainer) {
                    return NO_HEIGHT;
                }

                const commentHeader = document.querySelector('.comment-header');
                if (!commentHeader) {
                    return NO_HEIGHT;
                }

                return commentGridContainer.clientHeight - commentHeader.clientHeight;
            },

            setCommentPositionOnDrag() {
                const commentContentInner = document.querySelector('.comment-content-inner');

                if (!commentContentInner) return;

                const scrollPosition = commentContentInner.scrollTop;
                const scrollPositionPercentage = scrollPosition / this.tiptapHeight;

                this.$nextTick(() => {
                    commentContentInner.scrollTop = scrollPositionPercentage * this.tiptapHeight;
                });
            },

            startDrag(event) {
                const NO_HEIGHT = 0;

                if (this.tiptapState !== 'open') {
                    return;
                }

                if (event.clientY) {
                    // Capture initial mouse position
                    let initialY = event.clientY;

                    const handleDrag = (e) => {
                        // Calculate height difference
                        const heightDifference = initialY - e.clientY; // Inverted calculation

                        // Get element by id
                        const element = document.querySelector('#tiptap');

                        if (!element) return;

                        const newHeight = element.clientHeight + heightDifference;

                        if (newHeight < NO_HEIGHT) return;

                        if (newHeight > this.maxHeight) return;

                        // Set the new height to store
                        this.setTiptapHeight(newHeight);
                        this.maxHeight = this.getMaxHeight();

                        initialY = e.clientY;

                        this.setCommentPositionOnDrag();
                    };

                    // Throtted handledrag, i.e. it can only run every 50ms
                    this.throttledHandleDrag = throttle(handleDrag, 50);

                    const stopDrag = () => {
                        window.removeEventListener('mousemove', this.throttledHandleDrag);
                        window.removeEventListener('mouseup', stopDrag);
                    };

                    window.addEventListener('mousemove', this.throttledHandleDrag);
                    window.addEventListener('mouseup', stopDrag);
                }
            },
        },
    };
</script>

<style scoped>
    .tool-bar {
        display: grid;
        grid-template-columns: 1fr auto auto auto;
        padding: 8px;
        padding-left: 16px;
        background-color: var(--v-primary-base);
        overflow: auto;
        overflow-x: hidden;
    }

    .tool-bar > :first-child {
        font-size: 1rem;
        color: white;
    }

    .tool-bar > * {
        align-self: center;
    }

    .tool-bar-header {
        user-select: none;
    }
</style>

<i18n lang="json">
{
    "sv": {
        "toolbar": {
            "newMessage": "Nytt meddelande",
            "external": "Ny extern e-post",
            "internal": "Nytt internt meddelande",
            "social": "Nytt socialt meddelande",
            "forwarding": "Vidarebefordrar"
        }
    },

    "en": {
        "toolbar": {
            "newMessage": "New message",
            "external": "New external e-mail",
            "internal": "New internal message",
            "social": "New social message",
            "forwarding": "Forwarding"
        }
    }
}
</i18n>
