<template>
    <article :key="field" class="field-input">
        <label :for="field + '-contact-info'" class="text-truncate">
            <span :style="getLabelStyle(field, tempValue)">{{ labelTranslation(field) }}</span>
        </label>

        <div :id="field + '-contact-info'" :ref="field + '-contact-info'" class="client-fields-form">
            <secondary-input
                :disabled="field === 'clientId' || disabled"
                :value="value"
                background-color="transparent"
                dense
                @input="(value) => handleChange(field, value)"
                @blur="commitChange"
            />
        </div>
    </article>
</template>
<script>
    import { mailRegEx, internationalNumberRegex } from '@/utils/index';
    import { mapActions, mapState } from 'vuex';

    export default {
        props: {
            field: {
                type: String,
                required: true,
            },
            value: {
                type: [String, Number, Object],
                required: false,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            client: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // Validations
                phonenumberRulesContactInfo: [
                    (v) => !v || internationalNumberRegex.test(v) || this.$t('clientContactInfo.invalidFormat'),
                ],
                personNrLengthRule: [
                    (v) => !v || v.length === 12 || this.$t('clientContactInfo.twelveDigits'),
                    (v) => !v || /^\d+$/.test(v) || this.$t('clientContactInfo.needsDigits'),
                ],
                adressRules: [(v) => !v || v.length >= 5 || this.$t('clientContactInfo.longerAddress')],
                nameRule: [
                    (v) => !!v || this.$t('clientContactInfo.fillField'),
                    (v) => v.length >= 2 || this.$t('clientContactInfo.longerAddress'),
                ],
                // accept it to be empty also
                emailValidatioRule: [
                    (v) => !v || mailRegEx.test(v) || this.$t('clientContactInfo.validEMail'), // Check for valid email format
                ],
                valid: true,

                // Debounce client update
                clientDebounce: null,
                clientDebounceFunction: null,
                tempValue: this.value,
            };
        },

        computed: {
            ...mapState({
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
            }),
        },

        beforeDestroy() {
            this.commitChange();
        },

        methods: {
            ...mapActions('Client', ['updateClient']),

            handleChange(key, value) {
                if (value === '') {
                    value = null;
                }

                this.tempValue = value;

                clearTimeout(this.clientDebounce);

                // * the field we changed
                const changedField = {
                    [key]: value,
                };

                if (this.getKeyValid(key, value)) {
                    const { clientId, type } = this.client;
                    const fn = async () => {
                        await this.updateClient({
                            ...changedField,
                            clientId,
                            type,
                        });
                    };
                    this.queueChange(fn);
                }
            },

            queueChange(fn) {
                const CLIENT_DEBOUNCE_TIME = 2500;

                this.clientDebounceFunction = fn;
                this.clientDebounce = setTimeout(fn, CLIENT_DEBOUNCE_TIME);
            },

            commitChange() {
                clearTimeout(this.clientDebounce);
                const fn = this.clientDebounceFunction;
                this.clientDebounceFunction = null;
                fn?.();
            },

            getKeyValid(key, value) {
                return this.decideRules(key).every((rule) => rule(value) === true);
            },

            getLabelStyle(key, value) {
                return {
                    color: this.getKeyValid(key, value) ? 'var(--v-gray2-base)' : 'var(--v-error-base)',
                };
            },

            labelTranslation(key) {
                return this.$t(`label.${key}`);
            },

            decideRules(key) {
                switch (key) {
                    case 'primaryEmail': {
                        return this.emailValidatioRule;
                    }
                    case 'secondaryEmail': {
                        return this.emailValidatioRule;
                    }
                    case 'primaryTelephone': {
                        return this.phonenumberRulesContactInfo;
                    }
                    case 'secondaryTelephone': {
                        return this.phonenumberRulesContactInfo;
                    }
                    case 'socialSecurityNumber': {
                        return this.personNrLengthRule;
                    }
                    case 'adress': {
                        return this.adressRules;
                    }
                    case 'name': {
                        return this.nameRule;
                    }
                    default: {
                        return [];
                    }
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .field-input {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "clientContactInfo": {
            "chName": "Change name",
            "assignCase": "Assign this case to another customer",
            "showCrm": "Show CRM",
            "showCustomerNotes": "Show customer notes",
            "showContactPers": "Show contact persons",
            "showWebRecordings": "Show web recordings",
            "showExtCustInfo": "Show more",
            "hideExtCustInfo": "Hide more",
            "noVal": "No value",
            "fillField": "This field needs to be filled in.",
            "invalidFormat": "Invalid format. Use the format: +46-55-555-5555",
            "needsDigits": "Must contain numbers",
            "longerAddress": "The address must be longer",
            "validEMail": "Enter a valid E-mail address",
            "twelveDigits": "Must be 12 digits",
            "invalidLength": "The number has an icorrect length",
            "needCorrectInfo": "You must enter correct information.",
            "email1": "E-mail",
            "email2": "E-mail 2",
            "phoneNr1": "Phone number",
            "phoneNr2": "Phone number 2",
            "ssn": "Social security number",
            "address": "Address",
            "email": "Email",
            "mNumber": "Mobile number",
            "pNumber": "Phone number",
            "title": "Titel",
            "clientMatch": "Client matched against multiple clients in the system, click to select one."
        }
    },
    "sv": {
        "clientContactInfo": {
            "chName": "Ändra namn",
            "assignCase": "Knyt detta ärende till en annan kund",
            "showCrm": "Visa CRM",
            "showCustomerNotes": "Visa kundanteckningar",
            "showContactPers": "Visa kontaktpersoner",
            "showWebRecordings": "Visa webbinspelningar",
            "showExtCustInfo": "Visa fler",
            "hideExtCustInfo": "Dölj fler",
            "noVal": "Inget värde",
            "fillField": "Detta fält behöver vara ifyllt.",
            "invalidFormat": "Ogiltigt format. Använd format: +46-55-555-5555",
            "needsDigits": "Måste innehålla siffror",
            "longerAddress": "Adressen måste vara längre",
            "validEMail": "Skriv in en giltig E-post adress",
            "twelveDigits": "Måste vara 12 siffror",
            "invalidLength": "Felaktig längd på nummer",
            "needCorrectInfo": "Du behöver fylla i rätt information.",
            "email1": "Epost",
            "email2": "Epost 2",
            "phoneNr1": "Telefonnummer",
            "phoneNr2": "Telefonnummer 2",
            "ssn": "Personnummer",
            "adress": "Adress",
            "email": "Epost",
            "mNumber": "Mobilnummer",
            "pNumber": "Telefonnummer",
            "title": "Titel",
            "clientMatch": "Klient matchad mot flera klienter i systemet, klicka för att välja en."
        }
    }
}
</i18n>
