import axios from 'axios';
import DedupeRequest from '../../entity/DedupeRequest';

const oneSecond = 1000;
const fiveSeconds = 5000;
const dedupeAPIs = {
    getQueues: new DedupeRequest((filters) => {
        return axios.get('/queues', { params: filters });
    }, oneSecond),
    getQueueDefaultSignature: new DedupeRequest((id) => {
        return axios.get(`queues/${id}/signatures`);
    }, fiveSeconds),
};

const queueAPIs = {
    getPopupTriggerInfo(channelId) {
        return axios.get(`queue/notification/triggers/${channelId}`);
    },
    savePopupTriggerInfo(data) {
        return axios.post('queue/notification/triggers', data);
    },
    searchKeys() {
        return axios.get('queues/search/keys');
    },
    getLiveQueueManagerStatistics({ queueId, channelType, from, to }) {
        return axios.get(
            `/queue/current/statistics?queueId=${queueId}&channelType=${channelType}&from=${from}&to=${to}`
        );
    },

    getQueueCDR({ queueId, channelType, type }) {
        return axios.get(`/queue/current/cdr/?queueId=${queueId}&channelType=${channelType}&type=${type}`);
    },

    getQueues(filters) {
        return dedupeAPIs.getQueues.send(filters);
    },
    setQueuesOrder(payload) {
        return axios.put('/queues', payload);
    },
    getAllQueuesWithAllInfo(payload) {
        return axios.get(`/queues/explicit/${payload}`);
    },

    getUsersQueuesWithGroups(userId) {
        return axios.get(`/users/${userId}/queues/groups`);
    },
    getQueueOnIdentifier(identifier) {
        return axios.get(`/queues/groups/identifier/${identifier}`);
    },
    getAllQueuesChosenUser(userId) {
        return axios.get(`/queues/chosen/user/${userId}`);
    },
    getAllQueuesByLoggedInUser() {
        return axios.get('/queues/user');
    },
    getAllQueuesByLoggedInUserAdministrative() {
        return axios.get('/queues/user/administrative');
    },
    getQueuesByUserId(userId) {
        return axios.get(`/queues/user/${userId}`);
    },

    getQueueTooltipInfo(queueId) {
        return axios.get(`/queues/${queueId}/tooltip`);
    },

    getQueuesInfo({ queueId, channelType }) {
        return axios.get(`/queue/${queueId}/info/${channelType}`);
    },

    queueById(id) {
        return axios.get(`/queues/${id}`);
    },
    getQueueSettingsById(id) {
        return axios.get(`/queue/${id}/settings`);
    },

    saveQueue(payload) {
        return axios.put(`/queues/${payload.ID}`, payload);
    },
    createQueue(payload) {
        return axios.post('/queues', payload);
    },
    deleteQueue(payload) {
        return axios.delete(`/queues/${payload}`, { data: payload });
    },

    // Channels

    getAllChannels(queueId) {
        return axios.get(`/queues/${queueId}/channels`);
    },
    getChannelsByQueues(payload) {
        return axios.get(`/queues/${payload.queueIds}/channels`);
    },
    getActiveChannelsInQueue(queueId) {
        return axios.get(`/queue/${queueId}/channels/active`);
    },

    getSpecificChannel(payload) {
        return axios.get(`/queues/${payload.qId}/channels/${payload.cId}`);
    },
    explicitlyGetAllChannelsByQueue(payload) {
        return axios.get(`/queues/${payload}/channelsExplicit`);
    },
    getExternalSurveyUrl(payload) {
        /// queues/:queueId/channels/survey/:type
        return axios.get(`/queues/${payload.qId}/channels/survey/${payload.type}`);
    },
    updateChannel(payload) {
        return axios.put(`/queues/${payload.queueId}/channels`, payload);
    },
    getQueuesByActiveChannelType(channelType) {
        return axios.get(`/queues/active-channel/${channelType}`);
    },
    setSpecificChannelValue(payload) {
        return axios.put(`/queues/channel/${payload.id}`, payload.value);
    },
    deleteIdentifiers(ids) {
        return axios.delete('/queues/channel/identifiers', { data: ids });
    },

    getAgentsInQueue(queueId) {
        return axios.get(`/queues/${queueId}/agents`);
    },
    getAllAgents() {
        return axios.get('/queues/agents/all');
    },

    addGroupsToQueue(payload) {
        return axios.post(`/queues/${payload.queueId}/group`, payload);
    },
    updateAgents(payload) {
        return axios.put('/queues/agents', payload);
    },
    getCompanyGroupsInQueue({ id, type }) {
        return axios.get(`/queues/${id}/groups?type=${type || ''}`);
    },
    updateGroupChannelsInQueue(payload) {
        return axios.put(`/queues/${payload.queueId}/group`, payload);
    },
    deleteCompanyGroupFromQueue(payload) {
        return axios.delete(`/queues/${payload.queueId}/group/${payload.groupId}`);
    },

    loginLogoutToQueue(payload) {
        return axios.put(`/queues/agent/${payload.userId}`, payload);
    },

    channelLoginLogoutInQueue(payload) {
        return axios.put('/queues/channels/agent', payload);
    },

    // assign QIP

    getQueueIdentifierByQueueId(payload) {
        return axios.get(`/queues/${payload.queueId}/identifiers/?type=${payload.type}`);
    },
    getQueueIdentifierByQueueIdIntegration(payload) {
        return axios.get(`/queues/${payload.queueId}/identifiers/${payload.type}/integration`);
    },
    assignIdentifiersToQueue(payload) {
        return axios.post(`/queues/${payload.queueId}/identifiers/${payload.channelType}/${payload.identifierType}`, {
            identifiers: payload.identifiers,
        });
    },
    findIdentifiers({ queueId, identifiers, type, identifierType }) {
        return axios.get(`/queues/${queueId}/identifiers/exist/`, {
            params: {
                identifiers,
                type,
                identifierType,
            },
        });
    },

    getQueuesOverviewData(type) {
        return axios.get(`/queues/overview/${type}`);
    },

    // Social Media for Queues
    getSocialSettings(channelId) {
        return axios.get(`social/settings/${channelId}`);
    },
    setSocialSettings(payload) {
        return axios.post(`social/settings/${payload.tenant.channelId}`, payload);
    },
    getEmailToReadArray() {
        return axios.get('/emailsToRead');
    },
    getQueueDefaultSignature(id) {
        return dedupeAPIs.getQueueDefaultSignature.send(id);
    },
    addScheduledOverflow(payload) {
        return axios.post(`/queues/overflow/${payload.queueId}`, { body: payload });
    },
    getGlobalSettings() {
        return axios.get('/queues/global-settings');
    },
    updateGlobalSettings(payload) {
        return axios.put('/queues/global-settings', payload);
    },
    getAudioFilesDeletion(queueId) {
        return axios.get(`/queues/${queueId}/audio-files/deletion`);
    },
    setAudioFilesDeletion(payload) {
        return axios.post('/queues/audio-files/deletion', payload);
    },
    getDisplayNumbers() {
        return axios.get('/queues/display-numbers');
    },
    getLoggedInQueuesByUserId(userId) {
        return axios.get(`/queues/logged-in/${userId}`);
    },
};

// Overflow

export default queueAPIs;
