<template>
    <main class="main-container">
        <section class="sub-header-row">
            <article class="sub-header-item">
                <div>{{ $t('smsForm.preview') }}</div>
                <v-divider />
            </article>
            <article class="sub-header-item">
                <div>{{ $t('smsForm.message') }}</div>
                <v-divider />
            </article>
        </section>
        <div class="content-row">
            <section class="content-left">
                <SmsPreview :smsText="form.content" :smsFrom="$t('smsForm.sender')" height="500px" />
            </section>
            <section class="content-right">
                <v-form ref="smsform" v-model="valid" class="form">
                    <v-combobox
                        v-model="selectedNumber"
                        outlined
                        clearable
                        :label="$t('smsForm.number')"
                        required
                        return-object
                        item-text="Name"
                        :items="phoneNumbers"
                        flat
                        solo
                        :rules="selectedNumber?.Number ? [] : phonenumberRulesSmsForm"
                        style="flex: 0"
                    >
                        <template #selection="{ item }">
                            {{ comboboxSelectionDisplay(item) }}
                        </template>
                    </v-combobox>
                    <div ref="textContainer" class="text-area-container">
                        <v-textarea
                            v-model="form.content"
                            outlined
                            counter
                            required
                            no-resize
                            :rules="lengthRules"
                            :label="$t('smsForm.dotMessage')"
                            flat
                            solo
                            :height="textAreaHeight"
                        />
                    </div>

                    <v-divider class="mb-4" />
                    <article v-if="smsTemplates.length > 0">
                        <div class="templates-text">
                            <span>
                                {{ $t('smsForm.insertTemplate') }}
                            </span>
                        </div>
                        <v-slide-group
                            v-model="selectedTemplateIndex"
                            show-arrows
                            active-class="secondary"
                            class="mb-3"
                        >
                            <v-slide-item v-for="(template, t) in smsTemplates" :key="t">
                                <v-btn
                                    class="c1-btn-style mx-1"
                                    active-class="purple white--text"
                                    depressed
                                    @click="applyTemplate(template)"
                                >
                                    {{ template.Name }}
                                </v-btn>
                            </v-slide-item>
                        </v-slide-group>
                    </article>
                    <article v-else>
                        <div class="templates-text">
                            <div class="text-caption">
                                {{ $t('smsForm.noTemplatesAvailable') }}
                            </div>
                            <v-btn
                                v-if="isAdmin"
                                x-small
                                color="primary"
                                text
                                @click="$router.push('/Admin/Templates')"
                            >
                                {{ $t('smsForm.gotoTemplate') }}
                            </v-btn>
                        </div>
                    </article>
                </v-form>
                <v-divider class="mb-4" />
                <div class="d-flex justify-end">
                    <v-btn
                        ref="focuspoint"
                        class="c1-btn-style"
                        elevation="0"
                        color="primary"
                        :disabled="!valid"
                        @click="sendSMS()"
                    >
                        {{ $t('global.btn.send') }}
                    </v-btn>
                </div>
            </section>
        </div>
    </main>
</template>
<script>
    import { mapState } from 'vuex';
    import SmsPreview from '@/components/Admin/Marketing/SmsPreview.vue';
    import { internationalNumberRegex, replaceTemplateValues } from '@/utils';
    import { validatePhoneNumber, formatPhoneNumber } from '@/utils/pbx.utils';

    export default {
        components: {
            SmsPreview,
        },
        props: {
            smsTemplates: { type: Array, default: [] },
            smsNumber: {},
        },
        data() {
            return {
                selectedTemplateIndex: null,
                valid: true,
                form: {
                    number: '',
                    content: '',
                },
                phoneNumbers: [],
                selectedNumber: '',
                phonenumberRulesSmsForm: [
                    (v) => !!v || this.$t('smsForm.fillField'),
                    (v) => validatePhoneNumber(v, false) || this.$t('smsForm.validFormat'),
                    (v) => (v && v.length >= 10 && v.length <= 15) || this.$t('smsForm.wrongLength'),
                ],
                lengthRules: [
                    (v) => !!v || this.$t('smsForm.fillField'),
                    (v) => (v && v.length <= 5000) || this.$t('smsForm.lessThan500'),
                ],
                textAreaHeight: 0,
            };
        },
        computed: {
            ...mapState({
                clientOnCard: (state) => state.Cases.clientNowOnCard.data,
                loggedInUser: (state) => state.Auth.userObject,
            }),
            listOfClientNumbers() {
                return Object.keys(this.clientOnCard).reduce((acc, curr) => {
                    if (internationalNumberRegex.test(this.clientOnCard[curr])) {
                        return acc ? [...acc, this.clientOnCard[curr]] : [this.clientOnCard[curr]];
                    }
                    return acc;
                }, []);
            },
            isAdmin() {
                return this.$store.getters['Auth/getRoles'].includes(4);
            },
        },
        watch: {
            clientOnCard: {
                immediate: true,
                handler() {
                    this.getPhoneNumbers();
                },
            },
            selectedNumber: {
                immediate: false,
                deep: true,
                handler(newVal) {
                    if (!newVal) return;
                    if (newVal.Number) {
                        this.form.number = newVal.Number;
                    } else {
                        this.form.number = newVal;
                    }
                },
            },
        },
        mounted() {
            if (this.clientOnCard.Telephone) {
                this.form.number = this.clientOnCard.Telephone;
            }

            window.addEventListener('resize', this.resize);
            this.$nextTick(() => {
                this.resize();
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resize);
        },
        methods: {
            async applyTemplate(template) {
                await this.$store.dispatch('Cases/templateUsed', template.ID);
                this.form.content += replaceTemplateValues(template.Content, {
                    client: this.clientOnCard,
                    agent: this.loggedInUser,
                });
            },
            validateSMS() {
                if (this.$refs.smsform.validate()) {
                    const formattedPhoneNumber = formatPhoneNumber(this.form.number);
                    this.form.number = formattedPhoneNumber;
                    return true;
                }
                return false;
            },
            async sendSMS() {
                if (this.validateSMS()) {
                    const payload = {
                        comment: this.form.content,
                        caseId: this.$route.params.caseId,
                        type: 0,
                        from: this.loggedInUser.userName,
                        userId: this.loggedInUser.userId,
                        receiver: this.form.number,
                        receiverNumber: this.form.number,
                        typeOfMessage: 'sms',
                        outgoing: 1,
                        sent: 0,
                    };
                    const result = await this.$store.dispatch('Cases/sendComment', payload);
                    if (result.status === 200) {
                        this.dialog = false;
                    } else {
                        this.$toasted.show(this.$t('smsForm.noSms'), {
                            icon: 'alert',
                            type: 'error',
                            duration: 3000,
                        });
                    }
                    await this.$store.dispatch('Cases/openCommunication', this.dialog);
                }
            },
            comboboxSelectionDisplay(item) {
                if (item.Number) {
                    let number = item.Number;
                    if (item.Name != null) {
                        number += ' (' + item.Name + ')';
                    }
                    return number;
                }
                return item;
            },
            resize() {
                this.textAreaHeight = this.$refs.textContainer.clientHeight - 50 || 0;
            },
        },
    };
</script>
<style scoped>
    .main-container {
        padding: 2em !important;
        display: flex;
        flex-direction: column;
        width: 800px;
    }

    .sub-header-row {
        display: flex;
        padding-bottom: 20px;
    }

    .sub-header-item {
        color: var(--v-gray4-base);
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;
        padding-right: 20px;
    }
    .content-row {
        display: flex;
    }
    .content-left {
        width: 50%;
    }
    .content-right {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .text-area-container {
        flex: 1;
    }

    .form {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .templates-text {
        color: var(--v-gray4-base);
        margin: 0px 0px 16px 4px;
        display: flex;
        align-items: center;
    }
</style>
<i18n lang="json">
{
    "en": {
        "smsForm": {
            "preview": "Preview",
            "message": "Message",
            "sender": "Sender",
            "number": "Number...",
            "dotMessage": "Message...",
            "insertTemplate": "Click to insert template",
            "gotoTemplate": "Go to templates",
            "fillField": "This field must be filled in.",
            "validFormat": "Invalid format. Format used: +46-55-555-5555",
            "wrongLength": "Incorrect length of number",
            "lessThan500": "May not be longer than 500 letters.",
            "noSms": "Unfortunately, the SMS could not be sent. Try again!",
            "noTemplatesAvailable": "No templates available."
        }
    },
    "sv": {
        "smsForm": {
            "preview": "Förhandsgranska",
            "message": "Meddelande",
            "sender": "Avsändare",
            "number": "Nummer...",
            "dotMessage": "Meddelande...",
            "insertTemplate": "Klicka för att infoga mall",
            "gotoTemplate": "Gå till mallar",
            "fillField": "Detta fält behöver vara ifyllt.",
            "validFormat": "Ogiltigt format. Använd format: +46-55-555-5555",
            "wrongLength": "Felaktig längd på nummer",
            "lessThan500": "Får ej vara längre en 500 bokstäver.",
            "noSms": "Det gick tyvärr inte att skickas SMS. Försök igen!",
            "noTemplatesAvailable": "Inga mallar tillgängliga."
        }
    }
}
</i18n>
