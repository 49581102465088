<template>
    <header class="header-grid" :style="headerStyle">
        <section v-for="(column, index) in listHeaders" :key="index" class="header-grid-item header-text">
            <standard-button
                v-if="isSelectedHeader(column)"
                class="header-grid-item-selected"
                :icon="sortIcon(column)"
                @click="changeSortDirection"
            >
                {{ column.textTranslate[$i18n.locale] }}
            </standard-button>
            <span v-else class="item-text text-truncate">{{ column.textTranslate[$i18n.locale] }}</span>
        </section>
    </header>
</template>

<script>
    import { sort } from '@/enums/sort.enums';
    import { mapState } from 'vuex';

    export default {
        computed: {
            ...mapState({
                headersConfig: (state) => state.System.userSettings.cases.headers,
                listHeaders: (state) => state.Cases.listHeaders.headers,
            }),

            showSelect() {
                return this.$store.state.Cases.showSelect;
            },
            caseFiltersSelectedItems() {
                return this.$store.state.Cases.caseFiltersState;
            },
            sortField() {
                return this.caseFiltersSelectedItems.sortField;
            },
            sortDirection() {
                return this.caseFiltersSelectedItems.sortDirection;
            },
            headerStyle() {
                const totalWidth = this.listHeaders.reduce((acc, item) => acc + Number.parseInt(item.width), 0);

                const columnFractions = this.listHeaders.map((item) => `${Number.parseInt(item.width) / totalWidth}fr`);

                return {
                    'grid-template-columns': columnFractions.join(' '),
                };
            },
        },
        methods: {
            changeSortDirection() {
                const filter = structuredClone(this.caseFiltersSelectedItems);
                if (filter.sortDirection === sort.ASC) {
                    filter.sortDirection = sort.DESC;
                } else {
                    filter.sortDirection = sort.ASC;
                }
                this.$store.dispatch('Cases/setSelectedFilters', filter);
            },

            isSelectedHeader(column) {
                return this.caseFiltersSelectedItems.sortField === column.value;
            },

            sortIcon(column) {
                return this.sortDirection === sort.ASC && this.sortField === column.value
                    ? 'mdi-chevron-down'
                    : 'mdi-chevron-up';
            },
        },
    };
</script>

<style scoped>
    .item-text {
        pointer-events: none;
        user-select: none;
    }
    .header-text {
        color: var(--v-gray1-base);
        font-weight: 400;
        width: 100%;
        font-size: 0.9rem;
    }

    .header-grid-item-selected {
        font-size: 0.9rem !important;
    }

    .header-grid-item {
        min-width: 0px;
        overflow: none;
        flex: 0;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        padding-top: 8px;
    }

    .header-grid-item:last-child {
        justify-content: end;
    }

    .header-grid {
        display: grid;
        grid-template-rows: 1fr;
        height: 56px;
        gap: 16px;
        padding: 0px 16px;
    }

    .header-item {
        font-size: 0.7rem;
        color: var(--v-gray2-base);
        transition: background-color 0.1s ease-in-out;
        border-radius: 4px;
    }

    .header-button {
        font-size: 12px !important;
    }
</style>
<i18n lang="json">
{}
</i18n>
