import search from '@/api/search/search';
import { objectStringsToArray } from '@/utils';
import { isRingable, isEmail } from '@/helpers/search/search.helper';
import { sanitizeImageSrc } from '@/directives/shadowDom';
import i18n from '../../i18n';

// State object
const state = {
    contactPersons: [],
    dataArr: [],
    editMode: false,
    selectedListItem: 0,
    selectedContactPerson: null,
    contactPage: 0,
    navigationArea: 'List',
    searchSettings: null,
    calendarEvents: [],
    currentlySelectedArrayItem: {},
    modifier: {
        ctrl: false,
        alt: false,
        shift: false,
    },
    navigationListeners: true,
    allListeners: true,
    openContactList: false,
    selectedFilterObject: JSON.parse(localStorage.getItem('__searchMode')) || {},
    selectedObject: null,
    editableShortcutTemplate: null,
    groupInformation: {},
    disallowedKeys: ['Escape', 'Control', 'Shift', 'Alt'],
    confirmationDialog: false,
    type: null,
    currentSearchView: 0,
    searchKeys: {},
    fetchKeys: [],
    userData: {},
    userCardData: {},
    userQueues: [],
    navigationIndexes: {},
    loadingUserQueues: false,
    loadingUser: false,
    userRoles: [],
    searchContentHeight: '900',
    userTableKeys: [],
};
// Getter functions
const getters = {
    sortedKeys() {
        return Object.keys(state.searchKeys.keys).sort((a, b) => {
            return state.searchKeys.keys[a].sortingOrder - state.searchKeys.keys[b].sortingOrder;
        });
    },
    navigationIndexesLength() {
        return Object.keys(state.navigationIndexes).length;
    },
};
// Actions
const actions = {
    setSearchContentHeight({ commit }, payload) {
        commit('SET_SEARCH_CONTENT_HEIGHT', payload);
    },
    updateUserData({ commit }, payload) {
        commit('UPDATE_USER_DATA_FIELD', payload);
    },
    async getConnectedClientData({ commit }, payload) {
        try {
            const res = await search.getConnectedClientData(payload.UserId, payload.Type);
            commit('SET_GROUP_INFORMATION', res.data);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('searchStore.couldNotRetrieveData'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async search(_, payload) {
        try {
            const res = await search.search(payload);

            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('searchStore.couldNotRetrieveData'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async updateSearchObject({ commit, dispatch }) {
        // UserId required for insert
        // This is still required since it is not handled in the backend
        const visibleKeys = [];
        for (const key in state.searchKeys.keys) {
            if ((state.searchKeys.keys[key].visible && state.searchKeys.keys[key].userLevel < 5) || key == 'UserId') {
                visibleKeys.push(key);
            }
        }

        const payload = Object.fromEntries(
            Object.entries(state.userData)
                .filter(([key, value]) => value != null && visibleKeys.includes(key.toString()))
                .map(([key, value]) => {
                    const sanitizedValue = sanitizeImageSrc(value);
                    return [key, sanitizedValue];
                })
        );
        if (!state.currentlySelectedArrayItem) {
            this._vm.$toasted.show(i18n.t('usersStore.wrong'), 'No User Selected', {
                icon: 'cancel',
                type: 'error',
            });
        }

        payload.IsExternal = state.currentlySelectedArrayItem.IsExternal;
        payload.Name = state.currentlySelectedArrayItem.Name;
        payload.UserId = state.currentlySelectedArrayItem.UserId;
        payload.Type = state.currentlySelectedArrayItem.Type;

        const cleanPayload = objectStringsToArray(payload);

        try {
            const res = await search.updateSearchObject(cleanPayload);
            this._vm.$toasted.show(i18n.t('usersStore.userUpdated'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            commit('SET_EDIT_MODE', false);
            await dispatch('getUserData');
            return res;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('usersStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getUserData({ commit, dispatch }) {
        try {
            commit('SET_USER_LOADING', true);
            const { UserId, Type, IsExternal } = state.currentlySelectedArrayItem;

            // Get default user data
            const payload = {
                userId: UserId,
                type: Type,
            };
            const res = await search.getUserData(payload);
            const [userData, userRoles] = res.data;

            const extraDataPayload = {
                userId: UserId,
                data: Type === 'user' ? '*' : 'IsExternal',
                searchType: state.selectedFilterObject.searchType === 'searchExternal' ? 'external' : '*',
            };

            const extraUserData = await dispatch('Users/getSpecificUserInfo', extraDataPayload, { root: true });
            const finalExtraUserData = extraUserData[0];

            // If external user, add extra data to user data
            if (IsExternal) {
                for (const theData of extraUserData) {
                    finalExtraUserData[theData.Key] = theData.Value;
                    finalExtraUserData.UserId = theData.ObjectID;
                }
            }

            // finalextrauserdata = from users table, userdata = from search table
            const combinedResult = { ...finalExtraUserData, ...userData };

            commit('SET_USER_DATA', combinedResult);
            commit('SET_USER_ROLES', userRoles);

            // Navigation object is used to determine tabindexes in component
            commit('SET_NAVIGATION_OBJECT', combinedResult);

            commit('SET_USER_LOADING', false);

            // Set user's queues for GroupList
            let userQueues = [];
            if (!IsExternal) {
                commit('SET_QUEUES_LOADING', true);
                userQueues = await dispatch('QueueManager/getQueuesByUserId', UserId, {
                    root: true,
                });
                commit('SET_QUEUES_LOADING', false);
            }
            commit('SET_USER_QUEUES', userQueues);
        } catch (error) {
            this._vm.$toasted.show(i18n.t('systemStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    toggleAllListeners({ commit }, payload) {
        commit('SET_ALL_LISTENERS', payload);
    },
    toggleNavigationListener({ commit }, payload) {
        commit('SET_NAVIGATION_LISTENERS', payload);
    },
    registerKey({ commit }, payload) {
        const { key } = payload;
        if (state.disallowedKeys.includes(key)) {
            this._vm.$toasted.show(i18n.t('searchStore.disallowedKey'), {
                type: 'error',
                icon: 'mdi-alert-circle',
            });
            return;
        }
        commit('SET_SHORTCUT_KEY', payload);
    },
    updateTextField({ commit }, payload) {
        commit('SET_SHORTCUT_VALUE', payload);
    },
    setExistingShortcuts({ commit }) {
        commit('SET_EDITABLE_SHORTCUT_TEMPLATE');
        commit('SET_CONFIRMATION_DIALOG', false);
    },
    openConfirmationDialog({ commit }, type) {
        commit('SET_TYPE', type);
        commit('SET_CONFIRMATION_DIALOG', true);
    },
    addNewShortcut({ commit }) {
        commit('ADD_SHORTCUT');
    },

    async saveShortcuts({ commit, dispatch }) {
        await commit('SAVE_SHORTCUTS');
        await dispatch('System/updateDoc', state.searchSettings, { root: true });
        commit('SET_CONFIRMATION_DIALOG', false);
    },

    removeShortcut({ commit }, index) {
        commit('REMOVE_SHORTCUT', index);
    },

    setSearchSettings({ commit }, payload) {
        commit('SET_SEARCH_SETTINGS', payload);
    },
    setSearchKeys({ commit, rootState }, payload) {
        const { roles } = rootState.Auth;
        commit('SET_SEARCH_KEYS', payload);
        commit('SET_USER_TABLE_KEYS', { data: payload, roles });
    },

    setEditMode({ commit }, payload) {
        commit('SET_EDIT_MODE', payload);
    },
    setSelectedItem({ commit }, payload = 0) {
        commit('SET_SELECTED_LISTITEM', payload || 0);
    },
    async getSearchHandlerUsers(_, payload) {
        try {
            const res = await search.getSearchHandlerUsers(payload);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getSearchHandlerQueues(_, payload) {
        try {
            const res = await search.getSearchHandlerQueues(payload);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async updateUserShowInSearch(_, payload) {
        try {
            const res = await search.updateUserShowInSearch(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    setNavigationArea({ commit }, payload) {
        commit('SET_NAVIGATION_AREA', payload);
    },
    setModifier({ commit }, payload) {
        commit('SET_MODIFIER', payload);
    },
    resetModifier({ commit }) {
        commit('RESET_MODIFIER');
    },
    handleCalendarEvents({ commit }, payload) {
        commit('SET_CALENDAR_EVENTS', payload);
    },
    async toggleActiveFilterOption({ commit, dispatch }, payload) {
        commit('TOGGLE_ACTIVE_FILTER_OPTION', payload);
        await dispatch('System/updateDoc', state.searchSettings, { root: true });
    },
    toggleSearchField({ commit }, payload) {
        commit('TOGGLE_SEARCH_FIELD', payload);
    },
    toggleEventListener({ commit }, payload) {
        commit('SET_SEARCH_EVENT_LISTENER', payload);
    },
    setConfirmationDialog({ commit }, payload) {
        commit('SET_CONFIRMATION_DIALOG', payload);
    },
    updateGroupInformationField({ commit }, payload) {
        commit('UPDATE_GROUP_INFORMATION_FIELD', payload);
    },
    async saveGroupInformationChanges() {
        try {
            const data = state.groupInformation.items;
            if (!data) return;
            const { Type, UserId, IsExternal } = state.currentlySelectedArrayItem;
            data.Type = Type;
            data.UserId = UserId;
            data.IsExternal = IsExternal;
            await search.updateSearchObject(data);

            this._vm.$toasted.show(i18n.t('usersStore.userUpdated'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
        } catch (error) {
            this._vm.$toasted.show(i18n.t('usersStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async updateSearchViewOrder({ commit, dispatch }, dropEvent) {
        commit('SET_SEARCH_VIEW_ORDER', dropEvent);
        await dispatch('System/updateDoc', state.searchSettings, { root: true });
    },
    async toggleActiveSearchView({ commit, dispatch }, payload) {
        commit('SET_ACTIVE_SEARCH_VIEW', payload);
        await dispatch('System/updateDoc', state.searchSettings, { root: true });
    },
    updateCurrentSearchView({ commit }, payload) {
        commit('SET_CURRENT_SEARCH_VIEW', payload);
    },
    traverseSearchViews({ commit }) {
        commit('TRAVERSE_SEARCH_VIEWS');
    },
    async setCurrentlySelectedArrayItem({ commit, dispatch }, payload) {
        commit('SET_CURRENTLY_SELECTED_ARRAY_ITEM', payload);
        commit('SET_EDIT_MODE', false);

        // Clear values for extra safety
        if (!payload) {
            commit('SET_USER_DATA', {});
            commit('SET_USER_ROLES', []);
            commit('SET_USER_QUEUES', []);
            return;
        }
        await dispatch('getUserData');
    },
    async updateSearchViewOrder({ commit, dispatch }, dropEvent) {
        commit('SET_SEARCH_VIEW_ORDER', dropEvent);
        await dispatch('System/updateDoc', state.searchSettings, { root: true });
    },
    async toggleActiveSearchView({ commit, dispatch }, payload) {
        commit('SET_ACTIVE_SEARCH_VIEW', payload);
        await dispatch('System/updateDoc', state.searchSettings, { root: true });
    },
    updateCurrentSearchView({ commit }, payload) {
        commit('SET_CURRENT_SEARCH_VIEW', payload);
    },
    async updateFilterOptionOrder({ commit, dispatch }, dropEvent) {
        commit('SET_FILTER_OPTION_ORDER', dropEvent);
        await dispatch('System/updateDoc', state.searchSettings, { root: true });
    },
};

// Mutations
const mutations = {
    SET_USER_ROLES(state, data) {
        state.userRoles = data;
    },
    SET_USER_LOADING(state, data) {
        state.loadingUser = data;
    },
    SET_QUEUES_LOADING(state, data) {
        state.loadingUserQueues = data;
    },
    SET_USER_QUEUES(state, data) {
        state.userQueues = data;
    },
    TRAVERSE_SEARCH_VIEWS(state) {
        const { searchViews } = state.searchSettings.items;
        const currentIndex = searchViews.findIndex((view) => view.value === state.currentSearchView);

        if (currentIndex === searchViews.length - 1) {
            state.currentSearchView = searchViews[0].value;
            return;
        }

        state.currentSearchView = searchViews[currentIndex + 1].value;
    },
    SET_NAVIGATION_OBJECT(state, data) {
        let count = 0;
        state.navigationIndexes = {};

        for (const key of state.userTableKeys) {
            if (isRingable(data[key]) || isEmail(data[key])) {
                state.navigationIndexes[key] = count;
                count++;
            }
        }
    },
    SET_SEARCH_CONTENT_HEIGHT(state, value) {
        state.searchContentHeight = value;
    },
    UPDATE_USER_DATA_FIELD(state, { key, value }) {
        state.userData[key] = value;
    },
    SET_USER_DATA(state, payload) {
        state.userData = payload;
    },
    SET_CURRENT_SEARCH_VIEW(state, payload) {
        state.currentSearchView = payload;
    },
    SET_ACTIVE_SEARCH_VIEW(state, payload) {
        const { viewValue, active } = payload;
        state.searchSettings.items.searchViews.find((view) => view.value === viewValue).active = active;
    },
    SET_SEARCH_VIEW_ORDER(state, dropEvent) {
        const { addedIndex, removedIndex } = dropEvent;
        if (addedIndex === removedIndex) return; // do nothing if same position

        // Remove array item from array at removedIndex
        const removedArrayItem = state.searchSettings.items.searchViews.splice(removedIndex, 1)[0];
        // Insert removedArrayItem at addedIndex
        state.searchSettings.items.searchViews.splice(addedIndex, 0, removedArrayItem);
    },
    UPDATE_GROUP_INFORMATION_FIELD(state, payload) {
        const { field, value } = payload;
        state.groupInformation.items[field] = value;
    },
    SET_GROUP_INFORMATION(state, data) {
        state.groupInformation = data;
    },
    SET_CURRENT_SEARCH_VIEW(state, payload) {
        state.currentSearchView = payload;
    },
    SET_FILTER_OPTION_ORDER(state, dropEvent) {
        const { addedIndex, removedIndex } = dropEvent;
        if (addedIndex === removedIndex) return; // do nothing if the same position

        // Remove the filter option from the array at removedIndex
        const removedFilterOption = state.searchSettings.items.filterOptions.splice(removedIndex, 1)[0];
        // Insert the removedFilterOption at addedIndex
        state.searchSettings.items.filterOptions.splice(addedIndex, 0, removedFilterOption);
    },
    SET_ACTIVE_SEARCH_VIEW(state, payload) {
        const { viewValue, active } = payload;
        state.searchSettings.items.searchViews.find((view) => view.value === viewValue).active = active;
    },
    SET_SEARCH_VIEW_ORDER(state, dropEvent) {
        const { addedIndex, removedIndex } = dropEvent;
        if (addedIndex === removedIndex) return; // do nothing if same position

        // Remove array item from array at removedIndex
        const removedArrayItem = state.searchSettings.items.searchViews.splice(removedIndex, 1)[0];
        // Insert removedArrayItem at addedIndex
        state.searchSettings.items.searchViews.splice(addedIndex, 0, removedArrayItem);
    },
    SET_TYPE(state, type) {
        state.type = type;
    },
    SET_CONFIRMATION_DIALOG(state, payload) {
        state.confirmationDialog = payload;
    },
    REMOVE_SHORTCUT(state, index) {
        state.editableShortcutTemplate.splice(index, 1);
    },
    SAVE_SHORTCUTS(state) {
        state.searchSettings.items.shortcuts = state.editableShortcutTemplate;
    },
    SET_EDITABLE_SHORTCUT_TEMPLATE(state) {
        state.editableShortcutTemplate = structuredClone(state.searchSettings.items.shortcuts);
    },
    SET_SHORTCUT_KEY(state, payload) {
        const { index, key } = payload;
        state.editableShortcutTemplate[index].key = key;
    },
    SET_SHORTCUT_VALUE(state, payload) {
        const { i, value } = payload;
        state.editableShortcutTemplate[i].value = value;
    },
    ADD_SHORTCUT(state) {
        state.editableShortcutTemplate.push({
            key: '',
            value: '',
            column: false,
        });
    },
    SET_USER_TABLE_KEYS(state, { data, roles }) {
        const keys = Object.keys(data.keys).sort((a, b) => {
            return data.keys[a].sortingOrder - data.keys[b].sortingOrder;
        });

        state.userTableKeys = keys.filter((key) => {
            return (
                data.keys[key].visible && (roles.includes(data.keys[key].userLevel) || data.keys[key].userLevel == 0)
            );
        });
    },
    SET_SEARCH_KEYS(state, data) {
        state.searchKeys = data;
        const keys = Object.values(data.keys);
        state.fetchKeys = keys
            .filter((el) => {
                if (el.includedInSeparateFetch === true) {
                    return el.DisplayText;
                }
                return false;
            })
            .map((el) => {
                return el.DisplayText;
            });
    },
    SET_SEARCH_SETTINGS(state, payload) {
        state.searchSettings = payload;
        state.currentSearchView = payload.items.searchViews.find((view) => view.active).value;
    },
    TOGGLE_ACTIVE_FILTER_OPTION(state, { index, value }) {
        state.searchSettings.items.filterOptions[index].active = value;
    },
    TOGGLE_SEARCH_FIELD(state, { index, value }) {
        state.editableShortcutTemplate[index].column = value;
    },
    SET_CONTACT_PAGE(state, data) {
        state.contactPage = data;
    },
    TOGGLE_CONTACT_LIST(state, data) {
        state.openContactList = data;
    },
    SET_CONTACT_PERSONS(state, data) {
        state.contactPersons = data;
    },
    SET_CURRENTLY_SELECTED_ARRAY_ITEM(state, data) {
        state.currentlySelectedArrayItem = data;
    },
    SET_SELECTED_FILTER_OBJECT(state, data) {
        localStorage.setItem('__searchMode', JSON.stringify(data));
        state.selectedFilterObject = data;
    },
    SET_SELECTED_OBJECT(state, data) {
        state.selectedObject = data;
    },
    SET_DATA_ARR(state, data) {
        state.dataArr = data;
    },
    SET_EDIT_MODE(state, data) {
        state.editMode = data;
    },
    SET_SELECTED_LISTITEM(state, data) {
        state.selectedListItem = data;
    },
    SET_ALL_LISTENERS(state, data) {
        state.allListeners = data;
    },

    SET_NAVIGATION_LISTENERS(state, data) {
        state.navigationListeners = data;
    },
    SET_NAVIGATION_AREA(state, data) {
        state.navigationArea = data;
    },
    SET_CALENDAR_EVENTS(state, data) {
        state.calendarEvents = data;
    },
    SET_MODIFIER(state, data) {
        state.modifier = { ...state.modifier, [data.name]: data.val };
    },
    RESET_MODIFIER(state) {
        state.modifier = {
            ctrl: false,
            shift: false,
            alt: false,
        };
    },
    SET_SELECTED_CONTACT_PERSON(state, data) {
        state.selectedContactPerson = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
