<template>
    <v-card flat class="py-5">
        <v-card-text :style="{ height: '675px', overflowY: 'scroll' }" style="width: 1200px">
            <v-form ref="emailform" v-model="valid" lazy-validation>
                <!-- <v-autocomplete label="Från" dense outlined v-model="from" :items="companyEmails"></v-autocomplete> -->
                <v-autocomplete v-model="from" :label="$t('global.from')" dense outlined :items="companyEmails" />
                <!-- label="Välj från lista" -->
                <!-- label="Till:" -->
                <v-combobox
                    v-model="to"
                    height="30px"
                    multiple
                    type="email"
                    :items="emailAddresses"
                    chips
                    outlined
                    item-value="Email"
                    :rules="selectedUserRules"
                    item-text="Name"
                    :label="$t('emailForm.to')"
                >
                    <template #append-outer>
                        <v-tooltip top>
                            <template #activator="{ on }">
                                <v-btn icon v-on="on" @click="() => (ccs = !ccs)">
                                    <v-icon>mdi-file-send-outline</v-icon>
                                </v-btn>
                            </template>
                            {{ $t('emailForm.ccAndBcc') }}
                        </v-tooltip>
                    </template>
                </v-combobox>
                <div v-if="ccs">
                    <v-combobox
                        v-model="ccRecipients"
                        height="30px"
                        chips
                        multiple
                        :rules="[(v) => validateCCs(v) || $t('emailForm.validFormat')]"
                        outlined
                        type="email"
                        :label="$t('emailForm.copy')"
                    />
                    <v-combobox
                        v-model="bccRecipients"
                        height="30px"
                        chips
                        multiple
                        :rules="[(v) => validateCCs(v) || $t('emailForm.validFormat')]"
                        outlined
                        type="email"
                        :label="$t('emailForm.blindCopy')"
                    />
                </div>
                <!-- <v-text-field dense outlined clearable required label="Kopia: " v-model="copyTo"></v-text-field> -->
                <!-- <v-text-field dense outlined clearable required label="Ämne: " v-model="subject"></v-text-field> -->
                <v-text-field v-model="subject" dense outlined clearable required :label="$t('emailForm.subject')" />
                <v-card elevation="0" color="transparent" class="my-2">
                    <div style="position: relative" class="d-flex flex-column align-center">
                        <div
                            style="max-height: 100%; width: 100%"
                            class="pa-0 ma-0"
                            @dragover.prevent="dragover = true"
                            @dragenter.prevent="dragEnter($event)"
                            @dragleave.prevent="dragLeave($event)"
                            @drop.prevent="dropFile($event)"
                            @paste="pasteFile"
                        >
                            <div
                                v-if="dragover"
                                id="dragoverFile"
                                style="position: absolute; z-index: 1000; width: 100%; height: 100%"
                                class="d-flex flex-row align-center justify-center"
                            >
                                <div style="width: 100%; height: 100%; opacity: 0.4; background-color: white"></div>

                                <div
                                    id="dropArea"
                                    style="
                                        pointer-events: none;
                                        position: absolute;
                                        top: 40%;
                                        z-index: 1;
                                        border: 2px dotted var(--v-gray1-base);
                                        border-spacing: 2rem;
                                    "
                                    class="pa-6"
                                >
                                    <span :style="{ color: 'var(--v-gray2-base' }">
                                        <v-icon>mdi-attachment</v-icon>
                                        <!-- Släpp filer här -->
                                        {{ $t('emailForm.dropFiles') }}
                                    </span>
                                </div>
                            </div>
                            <TiptapEditor
                                :id="'tippytappy2'"
                                :key="tiptapDestroyer"
                                :caseId="$route.params.caseId"
                                textfieldColor="#f3f3f3"
                                height="300px"
                                :toolbar="toolbar"
                                :files="allFiles"
                                :signature="selectedSignature"
                                :template="mailTemplate"
                                :existingContent="initialContent"
                                :showChips="['signatureChip']"
                                :chipData="chipData"
                                @change="(v) => updateContent(v)"
                            />
                        </div>
                    </div>
                </v-card>
                <div class="d-flex">
                    <template v-for="(item, i) of emailIcons">
                        <v-tooltip :key="i" top>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    v-if="item.name == 'mailTemplate'"
                                    icon
                                    v-bind="attrs"
                                    @click="item.function ? item.function() : null"
                                    v-on="on"
                                >
                                    <v-menu offset-y>
                                        <template #activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>{{ item.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <!-- mail mall -->
                                            <v-list-item-group v-if="item.name == 'mailTemplate'">
                                                <template v-if="emailTemplates?.length > 0">
                                                    <v-list-item
                                                        v-for="(item, index) in emailTemplates"
                                                        :key="index"
                                                        @click="applyMailTemplate(item)"
                                                    >
                                                        <v-list-item-title>{{ item.Name }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                                <template v-else>
                                                    <v-list-item>
                                                        <!-- <v-list-item-title>Ingen data.</v-list-item-title> -->
                                                        <v-list-item-title>
                                                            {{ $t('emailForm.noData') }}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-menu>
                                </v-btn>
                                <v-btn
                                    v-else-if="item.name == 'signature'"
                                    icon
                                    v-bind="attrs"
                                    @click="item.function()"
                                    v-on="on"
                                >
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-btn>
                                <div v-else-if="item.name == 'attach'" v-bind="attrs" v-on="on">
                                    <v-file-input
                                        v-model="files"
                                        class="ma-0 pa-0"
                                        hide-input
                                        truncate-length="15"
                                        multiple
                                        :append-icon="item.icon"
                                    />
                                </div>

                                <v-btn
                                    v-else
                                    icon
                                    v-bind="attrs"
                                    @click="item.function ? item.function() : null"
                                    v-on="on"
                                >
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.tooltipDesc }}</span>
                        </v-tooltip>
                    </template>
                    <!-- signaturmall tab -->
                    <div v-if="toggleSignature" style="max-width: 900px">
                        <v-tabs v-if="signatureTemplates.length > 0" hide-slider>
                            <v-tab style="color: black" @click="selectedSignature = {}">
                                {{ $t('emailForm.noSignature') }}
                            </v-tab>
                            <v-tab
                                v-for="(signatureObj, index) in signatureTemplates"
                                :key="index"
                                :class="signatureTabClass(signatureObj.ID)"
                                @click="selectedSignature = signatureObj"
                            >
                                {{ signatureObj.Name }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs v-else>
                            <v-tab>{{ $t('emailForm.noData') }}</v-tab>
                        </v-tabs>
                    </div>
                </div>
            </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-end">
            <v-progress-linear v-if="sending" indeterminate />
            <v-btn
                ref="focuspoint"
                class="c1-btn-style ml-2"
                elevation="0"
                color="primary"
                :disabled="!valid && !fileUploading"
                @click="validate"
            >
                <!-- Skicka -->
                {{ $t('global.btn.send') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import { mapState } from 'vuex';
    import { mailRegEx, focusElementToForceUpdate, replaceTemplateValues } from '@/utils';
    import { fileReaderHandler } from '@/helpers/files/files.helper';
    import config from '@/app/config';
    import { seperateSignatureAndContent } from '../../../helpers/signature.helpers';
    import TiptapEditor from '../../Global/TiptapEditor.vue';

    export default {
        components: {
            TiptapEditor,
        },
        props: {
            emailTemplates: { type: Array, default: [] },
            open: { type: Boolean, default: '' },
        },
        data() {
            return {
                colors: this.$vuetify.theme.themes.light,
                config,
                mailRegEx,
                initialContent: '',
                caseId: '',
                formActive: false,
                bodyContent: '',
                valid: true,
                tiptapDestroyer: 0,
                files: [],
                fileUploading: false,
                sending: false,
                toolbar: true,
                to: [],
                ccRecipients: [],
                bccRecipients: [],
                subject: '',
                from: '',
                content: '',
                ccs: false,
                attachment: [{ name: '' }],
                sender: '',
                emailAddresses: [],
                selectedUserRules: [
                    (v) => !!v || 'Detta fält behöver vara ifyllt.',
                    (v) => this.validateMultiple(v) || 'Fel formaterat email.',
                ],
                mailTemplate: '',
                emailIcons: [
                    {
                        name: 'signature',
                        icon: 'mdi-file-sign',
                        function: () => {
                            this.toggleSignature = !this.toggleSignature;
                        },
                        tooltipDesc: this.$t('emailForm.chSignature'),
                    },
                    {
                        name: 'mailTemplate',
                        icon: 'mdi-playlist-plus',
                        function: null,
                        // tooltipDesc: 'Lägg till textmall.'
                        tooltipDesc: this.$t('emailForm.addTemplate'),
                    },
                    {
                        name: 'attach',
                        icon: 'mdi-paperclip',
                        function: null,
                        // tooltipDesc: 'Bifoga fil(er).'
                        tooltipDesc: this.$t('emailForm.attachFiles'),
                    },
                ],
                emailRules: [
                    // (v) => !!v || 'Detta fält behöver vara ifyllt.',
                    (v) => !!v || this.$t('emailForm.fillField'),
                    // (v) => mailRegEx.test(v) || 'Vänligen ha godkänt formatering av mail.'
                    (v) => mailRegEx.test(v) || this.$t('emailForm.validFormat'),
                ],
                allFiles: [],
                dragNdrop: null,
                dragover: false,
                toggleSignature: false,
            };
        },
        computed: {
            ...mapState({
                signatureTemplates: (state) => state.Admin.signatureTemplates,
                loggedInUser: (state) => state.Auth.userObject,
                clientOnCard: (state) => state.Cases.clientNowOnCard.data,
                selectedCase: (state) => state.Cases.selectedCase,
                companyEmails: (state) => state.Cases.systemEmails,
                userSettings: (state) => state.System.userSettings,
            }),
            selectedSignature: {
                get() {
                    return this.$store.state.Cases.selectedCaseSignature;
                },
                set(signatureObj) {
                    this.$store.commit('Cases/SET_SELECTED_SIGNATURE', signatureObj);
                },
            },
            signatureTabClass() {
                return (id) => (this.selectedSignature.ID == id ? 'active-signature-btn' : 'signature-btn');
            },
            chipData() {
                return {
                    color: this.colors.gray2,
                    backgroundColor: 'white',
                    size: 'small',
                };
            },
        },
        watch: {
            open: {
                immediate: true,
                handler(newVal) {
                    // update the information when we open or close the popup
                    this.caseId = this.$route.params.caseId;

                    if (!newVal) {
                        this.saveFormContent();
                        this.$store.dispatch('Cases/openCommunication', false);
                    } else {
                        this.populateEmailModel();
                    }
                },
            },
            async files() {
                if (!this.files || this.files.length == 0) return;
                this.allFiles.push(...this.files);
                this.createFileData();
                this.files = [];
            },
            allFiles(newVal, oldVal) {
                if (newVal.length < oldVal.length) {
                    // changed so this only runs when a file is removed in tiptap editor and not when a file is added because it would cause an infinite loop - Tim Aronsson 2023-01-23
                    this.createFileData();
                }
            },
            clientOnCard: {
                immediate: true,
                handler(newVal) {
                    this.getEmailAddresses();
                },
            },
        },
        mounted() {
            this.saveFormContent();
            this.caseId = this.$route.params.caseId;
        },
        beforeDestroy() {
            this.saveFormContent();
        },
        methods: {
            dragEnter(event) {
                this.dragNdrop = event.target;
                this.dragover = true;
                event.stopPropagation();
                event.preventDefault();
            },
            dragLeave(event) {
                if (this.dragNdrop == event.target) {
                    this.dragover = false;
                }
            },
            pasteFile(evt) {
                if (!evt.clipboardData.files || evt.clipboardData.files.length == 0) return;
                this.files = evt.clipboardData.files;
            },
            dropFile(e) {
                this.dragover = false;
                if (!e.dataTransfer.files || e.dataTransfer.files.length == 0) return;
                this.files = e.dataTransfer.files;
            },
            saveFormContent() {
                if (
                    (this.content.length != 42 && this.content.length > 21) ||
                    this.ccRecipients.length > 0 ||
                    this.subject.length > 0
                ) {
                    const payload = this.createFormDataObject();
                    // Only saves when closed, not if refreshed with dialog up
                    localStorage.setItem('__caseContentExtended_' + this.caseId, JSON.stringify(payload));
                } else {
                    localStorage.removeItem('__caseContentExtended_' + this.caseId);
                }
            },
            validateMultiple(emails) {
                if (emails.length == 0) {
                    return false;
                }
                for (const email of emails) {
                    if (!mailRegEx.test(email)) {
                        return false;
                    }
                }
                return true;
            },
            validateCCs(emails) {
                for (const mail of emails) {
                    if (!mailRegEx.test(mail)) {
                        return false;
                    }
                }
                return true;
            },
            applyMailTemplate(template) {
                this.mailTemplate = replaceTemplateValues(template.Content, {
                    client: this.clientOnCard,
                    agent: this.loggedInUser,
                    sender: this.from,
                })
                    .split('\n')
                    .join('<br>');
            },

            getCaseRespondant() {
                if (this.from != '' && this.from != null) {
                    return this.from;
                }

                if (
                    this.selectedCase.caseStart.CaseRespondant != null &&
                    this.selectedCase.caseStart.CaseRespondant != ''
                ) {
                    return this.selectedCase.caseStart.CaseRespondant;
                }

                if (this.companyEmails.length > 0) {
                    return this.companyEmails[0];
                }

                return '';
            },
            populateEmailModel() {
                const storageItem = localStorage.getItem('__caseContentExtended_' + this.caseId);
                if (storageItem) {
                    // if we have storedItem, use it
                    const { to, ccRecipients, subject, from, content } = JSON.parse(storageItem);
                    const filteredTo = to.filter(Boolean);
                    this.to = filteredTo || [];
                    this.ccRecipients = ccRecipients;
                    this.subject = subject;
                    this.from = from;
                    this.content = content;
                    this.initialContent = content;
                } else {
                    // else apply some standards
                    this.subject = this.selectedCase.caseStart.Topic;
                    this.from = this.getCaseRespondant();
                    const hasNoRecipient = !this.to?.length;
                    const clientEmail = this.getClientEmail(this.clientOnCard);

                    if (hasNoRecipient) {
                        this.to = clientEmail ? [clientEmail] : [];
                    }
                }
            },
            async validate() {
                if (this.$refs.emailform.validate()) {
                    await this.sendEmail();
                    this.valid = true;
                } else {
                    this.valid = false;
                }
            },
            createFormDataObject() {
                return {
                    to: this.to,
                    ccRecipients: this.ccRecipients,
                    subject: this.subject,
                    from: this.getCaseRespondant(),
                    content: this.content,
                    signature: this.selectedSignature || {},
                };
            },
            removeFile(value) {
                this.files = this.files.filter((item) => item.name != value.name);
            },
            async createFileData() {
                this.attachment = await fileReaderHandler(this.allFiles, this);
                this.allFiles = this.allFiles.filter((f) => this.attachment.find((a) => a.name == f.name)); // this filters out the files that were rejected by the fileReaderHandler - Tim Aronsson 2023-01-23
            },
            async sendEmail() {
                await focusElementToForceUpdate(this, 'focuspoint');
                if (!this.from) {
                    this.from = this.selectedCase.caseStart.CaseRespondant || this.companyEmails[0];
                }
                const recipient = this.to[0];

                let sentSignature = '';
                if (this.selectedSignature.Hidden || !this.userSettings.texteditor.showSignature.active) {
                    sentSignature = replaceTemplateValues(this.selectedSignature.Content, {
                        client: this.clientNowOnCard || null,
                        agent: this.loggedInUser,
                        sender: this.from,
                    });
                } else {
                    [this.content, sentSignature] = seperateSignatureAndContent(this.content);
                }
                const payload = {
                    close: false,
                    subject: this.subject,
                    comment: this.content,
                    CaseID: this.caseId,
                    ext: true,
                    from: this.from,
                    sender: this.loggedInUser.userName,
                    userId: this.loggedInUser.userId,
                    reciever: recipient,
                    toEmail: this.to,
                    ccs: this.ccRecipients,
                    bccs: this.bccRecipients,
                    attachment: this.attachment,
                    messageType: 'email',
                    msgId: 0,
                    advancedObject: {
                        emailFrom: this.from,
                        emailTo: recipient,
                        emailSubject: this.subject,
                        toEmail: this.to,
                        ccs: this.ccRecipients,
                        bccs: this.bccRecipients,
                    },
                    signature: sentSignature,
                };
                this.sending = true;
                await this.$store.dispatch('Cases/sendComment', payload);
                this.sending = false;
                this.tiptapDestroyer += 1;
                this.dialog = false;
                await this.$store.dispatch('Cases/openCommunication', this.dialog);
                this.clear();
                localStorage.removeItem('__caseContentExtended_' + this.caseId);
                localStorage.removeItem('__caseHiddenSignatureId_' + this.caseId);
            },
            getClientEmail(client) {
                for (const clientProp in client) {
                    if (this.mailRegEx.test(client[clientProp])) {
                        return client[clientProp];
                    }
                }
                return null;
            },
            hardBreakEnter() {
                this.content = this.content.slice(0, -4) + '<br></p>';
            },
            updateContent(v) {
                this.content = v.content;
                this.allFiles = v.files;
            },
            clear() {
                this.content = '';
                this.allFiles = [];
            },
            async getEmailAddresses() {
                const res = await this.$store.dispatch('Cases/getEmailAddresses', this.clientOnCard.clientId);
                this.emailAddresses = res;
                this.to = this.setDefaultToAddress(this.to, this.emailAddresses);
            },

            setDefaultToAddress(to, emailAddresses) {
                if (!to.length && emailAddresses.length) {
                    const firstAvailableAddress = emailAddresses[0];
                    return [firstAvailableAddress];
                }
                return to;
            },

            comboboxSelectionDisplay(item) {
                return item.Name + ' (' + item.Email + ')';
            },
        },
    };
</script>
<style scoped lang="scss">
    .signature-btn {
        color: var(--v-gray1-base);
    }
    .active-signature-btn {
        outline: 1px solid var(--v-primary-base);
        color: var(--v-primary-base) !important;
    }
</style>
<i18n lang="json">
{
    "en": {
        "emailForm": {
            "selFromList": "Select from list",
            "to": "To",
            "call": "Call",
            "dialog": "Dialog",
            "copy": "Copy",
            "subject": "Subject: ",
            "noData": "No data.",
            "flipEditMode": "Toggle text editing mode.",
            "chSignature": "Change signature.",
            "addTemplate": "Add text template.",
            "attachFiles": "Attach file(s).",
            "fillField": "This field must be filled in.",
            "validFormat": "Please enter a valid email address.",
            "blindCopy": "Blind copy",
            "ccAndBcc": "Copy and blind copy",
            "dropFiles": "Drop files here",
            "noSignature": "No signature"
        }
    },
    "sv": {
        "emailForm": {
            "selFromList": "Välj från lista",
            "to": "Till",
            "call": "Ring",
            "dialog": "Dialog",
            "copy": "Kopia",
            "subject": "Ämne: ",
            "noData": "Inget data.",
            "flipEditMode": "Växla textredigeringsläge.",
            "chSignature": "Ändra signatur.",
            "addTemplate": "Lägg till textmall.",
            "attachFiles": "Bifoga fil(er).",
            "fillField": "Detta fält behöver vara ifyllt.",
            "validFormat": "Vänligen ange en giltig mailadress.",
            "blindCopy": "Hemlig kopia",
            "ccAndBcc": "Kopia och hemlig kopia",
            "dropFiles": "Släpp filer här",
            "noSignature": "Ingen signatur"
        }
    }
}
</i18n>
