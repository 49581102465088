import { render, staticRenderFns } from "./Client.vue?vue&type=template&id=3bac58f0&scoped=true"
import script from "./Client.vue?vue&type=script&lang=js"
export * from "./Client.vue?vue&type=script&lang=js"
import style0 from "./Client.vue?vue&type=style&index=0&id=3bac58f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.31_css-loader@6.11.0_webpack@5.91.0__handlebars@4.7._kmeafvm526pk7qfemuvkc5quo4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bac58f0",
  null
  
)

export default component.exports