import chatgpt from '@/api/chatgpt/chatgpt';

const appStates = {
    SHOW: 'show',
    BOTTOM: 'bottom',
};

const responseStates = {
    NONE: '',
    LOADING: 'loading',
    CHAT: 'Chat',
};

const loadStates = {
    READY: 'ready',
    LOADING: 'loading',
};
const state = {
    // States
    searchStates: Object.freeze(appStates),
    searchState: appStates.BOTTOM,
    responseStates: Object.freeze(responseStates),
    responseState: responseStates.NONE,
    loadStates: Object.freeze(loadStates),
    loadState: loadStates.READY,

    // Data
    searchText: '',
    searchResults: {
        conversations: [],
    },

    types: [
        {
            id: 0,
            name: 'All',
            icon: null,
            active: true,
        },
        {
            id: 1,
            name: 'Test1',
            icon: 'mdi-close',
            active: false,
        },
        {
            id: 2,
            name: 'Test2',
            icon: 'mdi-close',
            active: false,
        },
    ],

    // Settings
    searchKey: 'S', // Only Mac compatible for now
    sendKey: 'Enter', // Only Mac compatible for now

    // Timeout
    searchTimeout: null,

    // Count
    count: 0,

    // Modal
    showModal: false,

    // Action Countdown
    actionCountdown: -1,
};

const getters = {};

const actions = {
    SOCKET_smartSearchAction({ dispatch, commit }, payload) {
        // Start action count down and count down 5 seconds
        commit('SET_ACTION_COUNTDOWN', 5);
        const interval = setInterval(() => {
            commit('SET_ACTION_COUNTDOWN', state.actionCountdown - 1);
            if (state.actionCountdown === -1) {
                clearInterval(interval);

                if (payload.content.type === 'close-search') {
                    dispatch('setAppState', appStates.BOTTOM);
                }
                if (payload.type === 'create-case') {
                    commit('SET_SHOW_MODAL', true);
                }
            }
        }, 1000);
    },

    SOCKET_smartSearchpartialResponse({ commit }, payload) {
        const role = payload?.content?.delta?.role || payload?.content?.delta?.type;
        // #1 Make the data match what we expect
        const message = payload.content.delta;
        message.type = role;
        message.created = new Date();
        message.id = 0;
        message.animationKey = Math.random();

        // #2 Add the message to the conversation
        if (payload.content.index === 0) {
            commit('CREATE_MESSAGE', message);
        } else {
            // #3 Replace the last message with the new one (because we are typing partial tokens)
            commit('REPLACE_MESSAGE', message);
        }
    },

    cancelAction({ commit }) {
        commit('SET_ACTION_COUNTDOWN', -2);
    },

    createMessage({ commit }, message) {
        commit('CREATE_MESSAGE', message);
    },

    async getConversations({ commit }) {
        const data = await chatgpt.smartSearchConversation();

        commit('SET_RESPONSE', data.data.result);
        commit('SET_RESPONSE_STATE', responseStates.CHAT);
    },

    setSearchText({ commit }, searchText) {
        commit('SET_SEARCH_TEXT', searchText);
    },

    setAppState({ commit }, appState) {
        commit('SET_APP_STATE', appState);
    },

    setResponseState({ commit }, responseState) {
        commit('SET_RESPONSE_STATE', responseState);
    },

    async search({ state, commit, dispatch }, text) {
        try {
            clearTimeout(state.searchTimeout);
            commit('SET_LOAD', state.loadStates.LOADING);
            dispatch('createMessage', {
                content: text,
                role: 'user',
                type: 'content',
                created: new Date(),
            });
            commit('SET_SEARCH_TEXT', '');

            const data = await chatgpt.smartSearch({ text });
            commit('SET_RESPONSE', data.data.result);
            dispatch('setResponseState', responseStates.CHAT);
            commit('SET_LOAD', state.loadStates.READY);
        } catch (error) {
            dispatch('getConversations');
        }
    },

    selectResult({ commit }, id) {
        commit('SET_SELECTED_RESULT', id);
    },

    scrollToBottom() {
        const element = document.querySelector('#smart-search-chat-container');
        element.scrollTop = element.scrollHeight;
    },
};

const mutations = {
    REPLACE_MESSAGE(state, payload) {
        state.searchResults.conversations = state.searchResults.conversations.map((obj) =>
            obj.id === 0 ? payload : obj
        );
    },
    SET_LOAD(state, payload) {
        state.loadState = payload;
    },

    CREATE_MESSAGE(state, message) {
        state.searchResults.conversations.push(message);
    },

    SET_APP_STATE(state, appState) {
        state.searchState = appState;
    },

    SET_SEARCH_TEXT(state, searchText) {
        state.searchText = searchText;
    },

    SET_RESPONSE(state, response) {
        state.searchResults = response;
    },

    SET_RESPONSE_STATE(state, responseState) {
        state.responseState = responseState;
    },

    SET_SELECTED_RESULT(state, id) {
        for (const type of state.types) {
            type.active = false;
        }
        state.types[id].active = true;
    },

    SET_RESPONSE_COUNT(state) {
        state.count++;
    },

    SET_SHOW_MODAL(state, payload) {
        state.showModal = payload;
    },
    SET_ACTION_COUNTDOWN(state, payload) {
        state.actionCountdown = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
