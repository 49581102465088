module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "ChatTemplates": {
          "couldNotFetchTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not fetch templates"])},
          "noTemplatesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No templates available"])}
        }
      },
      "sv": {
        "ChatTemplates": {
          "couldNotFetchTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte hämta mallar"])},
          "noTemplatesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mallar tillgängliga"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"ChatTemplates":{"couldNotFetchTemplates":"Could not fetch templates","noTemplatesAvailable":"No templates available"}},"sv":{"ChatTemplates":{"couldNotFetchTemplates":"Kunde inte hämta mallar","noTemplatesAvailable":"Inga mallar tillgängliga"}}}')
  delete Component.options._Ctor
  
}
