module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "smsForm": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
          "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number..."])},
          "dotMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message..."])},
          "insertTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to insert template"])},
          "gotoTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to templates"])},
          "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be filled in."])},
          "validFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid format. Format used: +46-55-555-5555"])},
          "wrongLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect length of number"])},
          "lessThan500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May not be longer than 500 letters."])},
          "noSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, the SMS could not be sent. Try again!"])},
          "noTemplatesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No templates available."])}
        }
      },
      "sv": {
        "smsForm": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsgranska"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelande"])},
          "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsändare"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer..."])},
          "dotMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelande..."])},
          "insertTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka för att infoga mall"])},
          "gotoTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till mallar"])},
          "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält behöver vara ifyllt."])},
          "validFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltigt format. Använd format: +46-55-555-5555"])},
          "wrongLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felaktig längd på nummer"])},
          "lessThan500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Får ej vara längre en 500 bokstäver."])},
          "noSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick tyvärr inte att skickas SMS. Försök igen!"])},
          "noTemplatesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mallar tillgängliga."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"smsForm":{"preview":"Preview","message":"Message","sender":"Sender","number":"Number...","dotMessage":"Message...","insertTemplate":"Click to insert template","gotoTemplate":"Go to templates","fillField":"This field must be filled in.","validFormat":"Invalid format. Format used: +46-55-555-5555","wrongLength":"Incorrect length of number","lessThan500":"May not be longer than 500 letters.","noSms":"Unfortunately, the SMS could not be sent. Try again!","noTemplatesAvailable":"No templates available."}},"sv":{"smsForm":{"preview":"Förhandsgranska","message":"Meddelande","sender":"Avsändare","number":"Nummer...","dotMessage":"Meddelande...","insertTemplate":"Klicka för att infoga mall","gotoTemplate":"Gå till mallar","fillField":"Detta fält behöver vara ifyllt.","validFormat":"Ogiltigt format. Använd format: +46-55-555-5555","wrongLength":"Felaktig längd på nummer","lessThan500":"Får ej vara längre en 500 bokstäver.","noSms":"Det gick tyvärr inte att skickas SMS. Försök igen!","noTemplatesAvailable":"Inga mallar tillgängliga."}}}')
  delete Component.options._Ctor
  
}
