<template>
    <div v-if="!loading">
        <span class="file-preview-container">
            <button class="d-flex flex-row align-center justify-space-between file-card" @click="previewOrDownload()">
                <div class="d-flex flex-row align-center py-3 justify-center">
                    <div class="ml-3 d-flex align-center icon-wrapper">
                        <v-tooltip
                            v-if="hoverable"
                            color="transparent"
                            content-class="custom-t"
                            z-index="1001"
                            open-delay="500"
                            top
                        >
                            <template #activator="{ on, attrs }">
                                <v-badge class="fileBadge" bottom offset-x="11" offset-y="11" color="white">
                                    <template #badge>
                                        <v-icon color="var(--v-gray2-base)" size="11">mdi-magnify</v-icon>
                                    </template>
                                    <div
                                        v-bind="attrs"
                                        class="outer"
                                        v-on="on"
                                        @mouseover="loadTooltipContent"
                                        @mouseleave="hover = false"
                                    >
                                        <v-card
                                            v-if="isFileImageMiniature && miniature"
                                            elevation="0"
                                            width="25px"
                                            height="25px"
                                            :style="imageStyle"
                                        />

                                        <v-icon
                                            v-else
                                            :color="hover ? 'var(--v-gray4-base)' : 'var(--v-gray2-base)'"
                                            size="20"
                                        >
                                            {{ fileIconByContentType(file.contentType) }}
                                        </v-icon>
                                    </div>
                                </v-badge>
                            </template>
                            <v-card class="softShadow" :width="width" :height="height" :style="imageStyle" />
                        </v-tooltip>
                        <div v-else class="d-flex align-center justify-center">
                            <v-icon size="25">
                                {{ fileIconByContentType(file.contentType) }}
                            </v-icon>
                        </div>
                    </div>
                </div>
                <v-sheet color="transparent" class="pl-3 d-flex align-center" min-width="100px">
                    <span
                        class="text-truncate text-size"
                        :style="{ color: textHover ? 'var(--v-gray4-base)' : 'var(--v-gray2-base)' }"
                        @mouseover="textHover = true"
                        @mouseleave="textHover = false"
                    >
                        {{ file.name }}
                    </span>
                </v-sheet>

                <v-menu
                    v-model="openOptions"
                    elevation="0"
                    :close-on-content-click="false"
                    :nudge-left="20"
                    transition="scale-transition"
                    offset-y
                    max-width="225px"
                    min-width="225px"
                >
                    <template #activator="{ on, attrs }">
                        <v-sheet
                            v-bind="attrs"
                            class="d-flex align-center pa-2"
                            color="transparent"
                            v-on="on"
                            @click="openOptions = !openOptions"
                            @mouseover="hoverIconBox = true"
                            @mouseleave="hoverIconBox = false"
                        >
                            <v-icon
                                :size="hoverIconBox ? '16' : '16'"
                                :color="hoverIconBox ? 'var(--v-gray4-base)' : 'var(--v-gray2-base)'"
                            >
                                mdi-dots-horizontal
                            </v-icon>
                        </v-sheet>
                    </template>
                    <div class="option-card">
                        <v-tooltip
                            color="transparent"
                            content-class="custom-t"
                            z-index="1001"
                            open-delay="100"
                            nudge-left="20px"
                            nudge-bottom="24px"
                            right
                        >
                            <template #activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    class="optionRow py-3 pl-3 pr-2 d-flex align-center justify-space-between"
                                    v-on="on"
                                >
                                    <div>
                                        <v-icon size="18">mdi-information-outline</v-icon>
                                        <span class="ml-2">
                                            {{ $t('global.information') }}
                                        </span>
                                    </div>
                                    <v-icon>mdi-chevron-right</v-icon>
                                </div>
                            </template>
                            <v-card>
                                <div class="pa-3 ml-1">
                                    <div class="d-flex">
                                        <v-icon size="20">
                                            {{ fileIconByContentType(file.contentType) }}
                                        </v-icon>
                                        <span class="ml-1">{{ file.name }}</span>
                                    </div>
                                    <div>{{ $t('global.size') }}: {{ fileSizeFormatted(file.size) }}</div>
                                    <div>{{ $t('global.fileType') }}: {{ file.contentType }}</div>
                                </div>
                            </v-card>
                        </v-tooltip>
                        <div
                            v-for="(item, i) in menuOptions"
                            :key="i"
                            class="optionRow pa-3 d-flex align-center"
                            @click="executeOption(item.value)"
                        >
                            <v-icon size="18">{{ item.icon }}</v-icon>
                            <span class="ml-2">
                                {{ getMenuText(item.value) }}
                            </span>
                        </div>
                    </div>
                </v-menu>
            </button>
        </span>

        <!-- display -->
        <div v-if="showDialog" id="app">
            <VueDragResize
                :isActive="lockDialog"
                :preventActiveBehavior="true"
                :w="previewWidth"
                :h="previewHeight"
                :y="previewTop"
                :x="previewLeft"
                :z="10000"
                dragHandle=".drag"
                class="position-fixed"
                @resizing="resize"
                @dragging="resize"
            >
                <div class="d-flex">
                    <v-sheet width="100%" class="position-relative">
                        <iframe id="viewer" frameborder="0" :style="iFrameStyle"></iframe>
                        <div
                            v-show="lockDialog"
                            class="drag lock-dialog position-absolute"
                            :style="{ height: previewHeight + 'px' }"
                        ></div>
                    </v-sheet>

                    <div class="drag">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    width="40"
                                    height="40"
                                    depressed
                                    fab
                                    class="ml-2 mt-3 dialog-action-btn"
                                    v-on="on"
                                    @click="showDialog = false"
                                >
                                    <v-icon size="25">mdi-close</v-icon>
                                </v-btn>
                            </template>
                            {{ $t('global.close') }}
                        </v-tooltip>
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    width="40"
                                    height="40"
                                    depressed
                                    fab
                                    class="ml-2 mt-1 dialog-action-btn"
                                    v-on="on"
                                    @click="lockDialog = !lockDialog"
                                >
                                    <v-icon size="25">{{ lockDialog ? 'mdi-lock' : 'mdi-lock-open' }}</v-icon>
                                </v-btn>
                            </template>
                            {{ lockDialog ? $t('filePreview.lockEdit') : $t('filePreview.unlockEdit') }}
                        </v-tooltip>
                        <SimpleTooltip :openDelay="0" :tooltipText="$t('filePreview.downloadFile')" top>
                            <v-btn
                                v-bind="attrs"
                                width="40"
                                height="40"
                                depressed
                                fab
                                class="ml-2 mt-1 dialog-action-btn"
                                v-on="on"
                                @click="downloadFileFunc"
                            >
                                <v-icon size="25">{{ 'mdi-download' }}</v-icon>
                            </v-btn>
                        </SimpleTooltip>
                    </div>
                </div>
            </VueDragResize>
        </div>
    </div>
</template>
<script>
    import {
        fileSizeFormatted,
        fileIconByContentType,
        downloadFile,
        getURLFromFile,
    } from '@/helpers/files/files.helper';
    import { jsPDF } from 'jspdf';
    import { mimeTypes, imageMimeTypes } from '@/enums/files.enums';
    import { waitForElement } from '@/helpers/dom.helper';

    const JavascriptPDF = jsPDF; // redefined jsPDF to align with linter rules

    export default {
        components: {
            VueDragResize: () => import('vue-drag-resize'),
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
        },
        props: {
            file: {
                type: Object,
                required: true,
            },
            cfg: {
                type: Object,
                required: true,
            },
            width: {
                type: Number,
                default: 600,
            },
            height: {
                type: Number,
                default: 300,
            },
            miniature: {
                type: Boolean,
                default: false,
            },
            disablePreview: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                hover: false,
                textHover: false,
                src: '',
                FILE_TYPE_NOT_SUPPORTED_ERROR_CODE: '415',
                fileResponse: null,
                loading: true,
                selectedFile: null,
                showDialog: false,
                model: true,
                objectURL: null,
                openOptions: false,
                bigClick: false,
                menuOptions: [
                    {
                        value: 'download',
                        icon: 'mdi-download',
                    },
                ],
                hoverIconBox: false,
                previewWidth: 0,
                previewHeight: 0,
                previewTop: 0,
                previewLeft: 0,
                imageWidth: 0,
                imageHeight: 0,
                lockDialog: false,
                tooltipOpen: false,
                mainImageFormats: [mimeTypes.JPG, mimeTypes.JPEG, mimeTypes.PNG, mimeTypes.GIF, mimeTypes.WEBP],
            };
        },
        computed: {
            isImage() {
                return Object.values(imageMimeTypes).includes(this.file.contentType);
            },
            isPDF() {
                return this.file.contentType === mimeTypes.PDF;
            },
            isOffice() {
                return this.file.contentType.includes('application');
            },
            userSettings() {
                return this.$store.state.System.userSettings;
            },
            isFileImageMiniature() {
                return this.$store.state.System.userSettings.cases.imagePreviewMiniature.active;
            },
            preview() {
                const previewableFormats = [...this.mainImageFormats, mimeTypes.PDF];
                return previewableFormats.includes(this.file.contentType);
            },
            hoverable() {
                const hoverableFormats = [...this.mainImageFormats, mimeTypes.SVG, mimeTypes.BMP];
                return hoverableFormats.includes(this.file.contentType);
            },
            imageStyle() {
                return {
                    position: 'relative',
                    backgroundImage: `url(${this.src})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            },
            iFrameStyle() {
                return {
                    // add +1 to avoid scroll bars from appearing on first load
                    width: this.previewWidth + 1 + 'px',
                    height: this.previewHeight + 1 + 'px',
                    zIndex: this.showDialog ? '0' : '10002',
                };
            },
        },
        watch: {
            async tooltipOpen(val) {
                if (val) {
                    await this.updateOpen();
                }
            },
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleWidth);
        },
        async mounted() {
            this.loading = true;
            window.addEventListener('resize', this.handleWidth);
            this.handleWidth();
            if (this.miniature && this.isFileImageMiniature) {
                await this.getAttachment();
                this.loading = false;
                // return;
            }
            this.loading = false;
            if (this.preview) {
                if (!this.disablePreview) {
                    this.menuOptions.unshift({
                        value: 'previewIframe',
                        icon: 'mdi-file-find',
                    });
                }
                this.menuOptions.unshift({
                    value: 'previewNewTab',
                    icon: 'mdi-file-find',
                });
            }
        },
        methods: {
            fileSizeFormatted,
            fileIconByContentType,
            async updateOpen() {
                if (!this.fileResponse) {
                    await this.getAttachment();
                }
            },
            async getAttachment() {
                this.fileResponse = await this.$store.dispatch('Files/getAttachmentFile', this.file.id);
                this.src = `data:${this.fileResponse.contentType};base64,${this.fileResponse.data}`;
            },
            handleWidth() {
                // set the preview to the same size as the image
                // if it is pdf instead of image, set the preview to the window size 80% of the width and 80% of the height
                const width = this.imageWidth || 0.8 * window.innerWidth;
                const height = this.imageHeight || 0.8 * window.innerHeight;
                // set top to 10% of the height and left to 10% of the width
                const top = window.innerHeight / 2 - height / 2;
                const left = window.innerWidth / 2 - width / 2;

                this.previewWidth = width;
                this.previewHeight = height;
                this.previewTop = top;
                this.previewLeft = left;
            },

            resize(newRect) {
                this.previewWidth = newRect.width;
                this.previewHeight = newRect.height;
                this.previewTop = newRect.top;
                this.previewLeft = newRect.left;
            },
            previewOrDownload() {
                this.fileResponse = null;

                if (this.preview) {
                    if (this.userSettings.cases.filePreviewNewTab.active || this.disablePreview) {
                        this.showPreviewNewTab();
                    } else {
                        this.showPreviewIframe();
                    }
                } else {
                    this.downloadFileFunc();
                }
            },
            executeOption(option) {
                switch (option) {
                    case 'previewIframe': {
                        this.showPreviewIframe();
                        break;
                    }
                    case 'previewNewTab': {
                        this.showPreviewNewTab();
                        break;
                    }
                    case 'download': {
                        this.downloadFileFunc();
                        break;
                    }
                    default: {
                        break;
                    }
                }
            },
            async downloadFileFunc() {
                if (!this.fileResponse) {
                    await this.getAttachment();
                }
                downloadFile(this.fileResponse.data, this.fileResponse.contentType, this.fileResponse.name);
            },
            async showPreviewIframe() {
                try {
                    if (!this.fileResponse) {
                        await this.getAttachment();
                    }
                    const buffArray = Buffer.from(this.fileResponse.data, 'base64');
                    const newFile = new File([buffArray], this.fileResponse.name, {
                        type: this.fileResponse.contentType,
                    });
                    const objectUrl = await this.getURLFromFile(newFile);

                    this.showDialog = true;
                    const iframe = await waitForElement('#viewer', this.$el);
                    if (!iframe) return;
                    iframe.setAttribute('src', objectUrl);
                } catch (error) {
                    // must be the same as the error thrown in getDownloadedFileType
                    if (error.message === this.FILE_TYPE_NOT_SUPPORTED_ERROR_CODE) {
                        this.$toasted.show(this.$t('filePreview.filetypeNotSupported'), {
                            type: 'error',
                            duration: 5000,
                        });
                    } else {
                        this.$toasted.show(this.$t('filePreview.couldNotPreview'), {
                            type: 'error',
                            duration: 5000,
                        });
                    }
                }
            },

            async showPreviewNewTab() {
                if (!this.fileResponse) {
                    await this.getAttachment();
                }

                const supportedFileTypes = [...Object.values(imageMimeTypes), mimeTypes.PDF];

                const buffArray = Buffer.from(this.fileResponse.data, 'base64');
                const newFile = new File([buffArray], this.fileResponse.name, {
                    type: this.fileResponse.contentType,
                });

                // Check if the file type is supported
                if (!supportedFileTypes.includes(newFile.type)) {
                    this.$toasted.show(this.$t('filePreview.filetypeNotSupported'), {
                        type: 'error',
                        duration: 5000,
                    });
                    return;
                }

                try {
                    const objectUrl = URL.createObjectURL(newFile);
                    window.open(objectUrl, '_blank');
                } catch (error) {
                    this.$toasted.show(this.$t('filePreview.couldNotPreview'), {
                        type: 'error',
                        duration: 5000,
                    });
                }
            },

            getURLFromFile(newFile) {
                return getURLFromFile(newFile, this);
            },

            base64ToArrayBuffer(base64) {
                const binary_string = window.atob(base64);
                const len = binary_string.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binary_string.codePointAt(i);
                }
                return bytes.buffer;
            },
            getMenuText(value) {
                switch (value) {
                    case 'download': {
                        return this.$t('global.download');
                    }
                    case 'previewIframe': {
                        return this.$t('filePreview.previewIframe');
                    }
                    case 'previewNewTab': {
                        return this.$t('filePreview.previewNewTab');
                    }
                    default: {
                        return '';
                    }
                }
            },
            async loadTooltipContent() {
                this.hover = true;
                if (this.fileResponse) return;
                await this.getAttachment();
            },
        },
    };
</script>
<style scoped>
    .text-size {
        font-size: 12px;
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .file-preview-container {
        transition: all 0.3s ease-in-out;
    }

    .file-card {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        transition: all 0.2s ease-in-out;
    }

    .file-card:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .option-card {
        background-color: white;
    }

    .custom-t {
        opacity: 1 !important;
    }
    .optionRow {
        cursor: pointer;
        height: 50px;
        line-height: 1em !important;
    }
    .optionRow:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    :deep(.fileBadge .v-badge__badge) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 16px !important;
        max-width: 16px !important;
        height: 16px !important;
    }
    .icon-wrapper {
        height: 30px;
        width: 30px;
    }
    .position-relative {
        position: relative;
    }
    .position-absolute {
        position: absolute;
    }
    .position-fixed {
        position: fixed;
    }

    .lock-dialog {
        z-index: 10001;
        top: 0;
        left: 0;
        width: 100%;
    }
    .dialog-action-btn {
        background-color: var(--v-gray4-base) !important;
        color: white !important;
    }
</style>
<i18n lang="json">
{
    "sv": {
        "filePreview": {
            "previewIframe": "Förhandsgranska (ram)",
            "previewNewTab": "Förhandsgranska (ny flik)",
            "lockEdit": "Lås redigering",
            "unlockEdit": "Lås upp redigering",
            "downloadFile": "Ladda ner fil",
            "couldNotPreview": "Kunde inte förhandsgranska filen. Testa att ladda ned den istället.",
            "filetypeNotSupported": "Filtypen stöds inte av förhandsgranskningen."
        }
    },
    "en": {
        "filePreview": {
            "previewIframe": "Preview (iframe)",
            "previewNewTab": "Preview (new tab)",
            "lockEdit": "Lock edit",
            "unlockEdit": "Unlock edit",
            "downloadFile": "Download file",
            "couldNotPreview": "Could not preview file. Try to download it instead.",
            "filetypeNotSupported": "The file type is not supported by the preview."
        }
    }
}
</i18n>
