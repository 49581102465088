module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "smsPreview": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])}
        }
      },
      "sv": {
        "smsPreview": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomt"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"smsPreview":{"empty":"Empty"}},"sv":{"smsPreview":{"empty":"Tomt"}}}')
  delete Component.options._Ctor
  
}
