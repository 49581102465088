import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"internal-type-container",class:_vm.getPositionClass(_vm.position)},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticStyle:{"flex":"1"},attrs:{"cy-value":_vm.item.internalType}},'div',attrs,false),on),[_c('Transition',{attrs:{"name":"fade"}},[(
                            _vm.getSliderPercentage(_vm.item.sla, _vm.item.dateTime, _vm.returnDateNow) < 100 &&
                            _vm.item.agentName == '' &&
                            _vm.getSliderPercentage(_vm.item.sla, _vm.item.dateTime, _vm.returnDateNow) != -1 &&
                            _vm.item.status != 'Closed'
                        )?_c(VBadge,{attrs:{"bordered":"","color":"color1","content":_vm.item.unreadMessages,"overlap":"","value":_vm.item.unreadMessages > 0,"dot":_vm.small}},[_c(VAvatar,{attrs:{"color":_vm.buttonColor,"size":_vm.getAvatarSize()}},[(_vm.item.internalType !== 'callback')?_c(VIcon,{attrs:{"size":_vm.getIconSize(),"color":"white"}},[_vm._v(" "+_vm._s(_vm.caseTypeIcon(_vm.item.internalType))+" ")]):_c(VIcon,{attrs:{"size":_vm.getIconSize(),"color":"white"}},[_vm._v(" "+_vm._s(_vm.caseTypeIcon(_vm.item.internalType))+" ")])],1)],1):_c(VBadge,{attrs:{"bordered":"","color":"color1","content":_vm.item.unreadMessages,"overlap":"","value":_vm.item.unreadMessages > 0,"dot":_vm.small}},[_c(VAvatar,{attrs:{"color":_vm.buttonColor,"size":_vm.getAvatarSize()}},[(_vm.item.internalType !== 'callback')?_c(VIcon,{attrs:{"size":_vm.getIconSize(),"color":_vm.item.unreadMessages > 0 ? 'white' : 'white'}},[_vm._v(" "+_vm._s(_vm.caseTypeIcon(_vm.item.internalType))+" ")]):_c(VIcon,{attrs:{"size":_vm.getIconSize(),"color":_vm.item.unreadMessages > 0 ? 'white' : 'white'}},[_vm._v(" "+_vm._s(_vm.caseTypeIcon(_vm.item.internalType))+" ")])],1)],1)],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getType(_vm.item.internalType)))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }