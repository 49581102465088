module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "activeChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of active for channel"])},
        "potentialChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of potential for channel"])},
        "potentialBeforeFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of potential dialogs before full"])},
        "potential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potential"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "amountActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of active dialogs"])}
      },
      "sv": {
        "activeChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal aktiva för kanal"])},
        "potentialChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal potentiella för kanal"])},
        "potentialBeforeFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal potentiella dialoger innan fullt"])},
        "potential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potentiella"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva"])},
        "amountActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal aktiva dialoger"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"activeChannel":"Amount of active for channel","potentialChannel":"Amount of potential for channel","potentialBeforeFull":"Amount of potential dialogs before full","potential":"Potential","active":"Active","amountActive":"Amount of active dialogs"},"sv":{"activeChannel":"Antal aktiva för kanal","potentialChannel":"Antal potentiella för kanal","potentialBeforeFull":"Antal potentiella dialoger innan fullt","potential":"Potentiella","active":"Aktiva","amountActive":"Antal aktiva dialoger"}}')
  delete Component.options._Ctor
  
}
