import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"container mb-5 py-5 px-3",attrs:{"flat":""}},[_c(VTextField,{staticStyle:{"border-radius":"6px"},attrs:{"full-width":"","hide-details":"","outlined":"","dense":"","label":_vm.$t('templateView.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VCardText,{staticClass:"px-0"},[(_vm.queriedTemplates.length > 0)?_c(VExpansionPanels,{attrs:{"flat":""}},[_vm._l((_vm.queriedTemplates),function(item,i){return [(item.IsActive)?_c(VExpansionPanel,{key:i,staticStyle:{"margin":"3px"},on:{"change":function($event){return _vm.setImages(i)}}},[_c(VExpansionPanelHeader,{staticClass:"listItem",style:({
                            'background-color':
                                _vm.clickedTemplate == i
                                    ? 'var(--v-primary-base)'
                                    : _vm.hoveredTemplate == i
                                      ? 'var(--v-gray3-base)'
                                      : '',
                            color: _vm.clickedTemplate == i ? 'white' : '',
                        }),on:{"mouseover":function($event){_vm.hoveredTemplate = i},"mouseleave":function($event){_vm.hoveredTemplate = null},"click":function($event){_vm.clickedTemplate = _vm.clickedTemplate == i ? null : i}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":_vm.clickedTemplate == i ? 'white' : ''}},[_vm._v("$expand")])]},proxy:true}],null,true)},[_c('div',{staticClass:"ma-0, pa-0"},[_vm._v(" "+_vm._s(item.Name)+" ")])]),_c(VExpansionPanelContent,[(_vm.mouseoverContent)?_c('div',{staticClass:"inBetweenText",style:({ color: 'var(--v-primary-base' })},[_vm._v(" "+_vm._s(_vm.$t('templateView.apply'))+" ")]):_c('div',{staticClass:"inBetweenText"},[_vm._v(" "+_vm._s(_vm.$t('templateView.preview'))+" ")]),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(item.Content),expression:"item.Content"},{def: Ripple,name:"ripple",rawName:"v-ripple"}],key:i,staticClass:"contentContainer",staticStyle:{"cursor":"pointer"},style:({
                                border: _vm.mouseoverContent
                                    ? '1px solid var(--v-primary-base)'
                                    : '1px solid rgba(0,0,0,0.05)',
                            }),on:{"click":function($event){return _vm.selectedTemplate(item.Content)},"mouseover":function($event){_vm.mouseoverContent = true},"mouseleave":function($event){_vm.mouseoverContent = false}}})])],1):_vm._e()]})],2):_c('div',[_vm._v(" "+_vm._s(_vm.$t('templateView.noTemplates'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('templateView.addGotoTempl'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('templateView.onlyActivated'))+" "),_c('br'),_c('br'),_c(VDivider),_c('br'),_c(VBtn,{attrs:{"dense":"","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.$router.replace('/Admin/Templates')}}},[_vm._v(" "+_vm._s(_vm.$t('templateView.templateManager'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }