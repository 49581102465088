<template>
    <div class="comments" :style="commentStyle" :class="commentClass">
        <header class="comment-header comment-section">
            <div class="comment-avatar">
                <InternalType :absolute="false" :item="{ internalType: 'form' }" />
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <div v-on="on">
                            <CommonAvatar v-if="item.userId" :userId="item.userId" :size="32" />
                            <SystemAvatar v-else :size="32" />
                        </div>
                    </template>
                    <span>{{ item.userId ? item.userName : $t('comment.system') }}</span>
                </v-tooltip>
            </div>
            <section>
                <div class="comment-info">
                    <div class="comment-from text-truncate"></div>
                    <div
                        class="comment-top-field comment-date text-truncate"
                        :class="item.outgoing ? 'outgoing-chip' : 'incoming-chip'"
                    >
                        <v-progress-circular
                            v-if="!item.sent"
                            color="var(-v-gray2-base)"
                            indeterminate
                            size="10"
                            width="2"
                        />
                        {{ commentDirection }}
                    </div>
                    <div class="comment-top-field text-truncate"></div>
                    <div class="comment-top-field comment-date text-truncate">{{ formatDate(item.dateTime) }}</div>
                </div>
            </section>
        </header>

        <section v-if="item.subject" class="comment-title comment-section comment-tries">
            <div>{{ item.subject }}</div>
            <div>
                <span v-if="item.tries" class="comment-tries-text">{{ $t('comment.tries') }} {{ item.tries }}</span>
            </div>
        </section>
        <section class="comment-content">
            <div v-dompurify-html="item.comment" class="form-table"></div>
        </section>

        <section v-if="item.attachments.length" class="comment-attachment">
            <ReadMore :items="item.attachments">
                <template #default="{ item }">
                    <FilePreview
                        :file="item"
                        :cfg="config"
                        :miniature="userSettings && userSettings.cases.imagePreviewMiniature.active"
                        class="file-preview"
                    />
                </template>
            </ReadMore>
        </section>
    </div>
</template>
<script>
    import { getDay } from '@/utils/DateFormatter';
    import { format } from 'date-fns';
    import { mapState } from 'vuex';

    import config from '@/app/config';

    import InternalType from '@/components/Cases/List/InternalType.vue';
    import SystemAvatar from '@/components/Global/SystemAvatar.vue';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';

    import FilePreview from '@/components/Cases/CaseComments/FilePreview.vue';
    import ReadMore from '@/components/Cases/CaseComments/ReadMore.vue';

    export default {
        components: {
            InternalType,
            SystemAvatar,
            CommonAvatar,
            FilePreview,
            ReadMore,
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                config,
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),

            commentStyle() {
                return {
                    justifySelf: this.item.outgoing ? 'end' : 'start',
                };
            },

            commentClass() {
                return {
                    outgoing: this.item.outgoing,
                    incoming: !this.item.outgoing,
                };
            },

            commentDirection() {
                if (!this.item.comment) {
                    return this.$t('comment.sending');
                }
                return this.item.outgoing ? this.$t('comment.outgoing') : this.$t('comment.incoming');
            },

            formattedTable() {
                if (!this.item.comment) {
                    return '';
                }

                return this.item.comment.replaceAll(/<label(.*?)>(.*?)<\/label>/g, (match, attributes, content) => {
                    return `<label${attributes}>${this.$t('label.' + content)}</label>`;
                });
            },
        },

        methods: {
            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },

            getDay,
            format,
        },
    };
</script>

<style>
    .table-container {
        display: grid;
        grid-template-rows: auto 1fr;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 32px;
        gap: 16px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }

    .table-header {
        display: flex;
        flex-direction: row;
    }

    .table-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }

    .table-row {
        display: grid;
        grid-template-rows: auto 1fr;
        gap: 8px;
    }

    label {
        color: var(--v-gray2-base);
        font-size: 0.9rem;
        text-transform: capitalize;
    }
</style>
<style scoped lang="scss">
    .comment-tries {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .comment-action-buttons {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 16px;
    }
    .comment-attachment {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .comment-top-field {
        font-size: 12px;
        gap: 4px;
        display: flex;
        align-items: center;
    }
    .comments {
        padding: 32px;
        border-radius: 12px;
        width: 100%;
        max-width: 800px;
        gap: 4px;
        background-color: rgba(245, 245, 247, 0.53);
        font-size: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }

    .comments-grid {
        display: grid;
        grid-template-columns: 1fr 1.4fr;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 8px 0px;
    }

    .comments-grid-header {
        text-align: left;
        font-size: 12px;
        color: var(--v-gray2-base);
    }

    .comments-grid-text {
        font-size: 12px;
        color: var(--v-gray4-base);
    }

    .medium-heading {
        font-size: 14px;
        font-weight: 500;
        color: var(--v-gray4-base);
        text-align: center;
        margin-bottom: 16px;
    }

    .comment-avatar {
        color: white;
        border: 0px solid var(--v-accent2-base);
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 40px;
        gap: 8px;
    }

    .comment-header {
        display: grid;
        grid-template-columns: auto 1fr;
        padding-bottom: 8px;
        gap: 8px;
    }

    .avatar-image {
        background-position: center;
        object-fit: cover;
    }

    .comment-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto;
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }

    .comment-date {
        justify-self: end;
    }

    .comment-from {
        color: var(--v-gray4-base);
        font-size: 1rem;
    }

    .comment-section {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .comment-content {
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 1rem;
    }

    .comment-title {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 1.3rem;
        font-weight: 300;
    }

    .comment-tries-text {
        font-size: 12px;
        color: var(--v-gray2-base);
    }
</style>

<i18n lang="json">
{
    "en": {
        "comment": {
            "to": "To:",
            "outgoing": "Outgoing",
            "incoming": "Incoming",
            "sending": "Sending",
            "tries": "Tries: "
        },

        "label": {
            "email": "Email",
            "confirmEmail": "Confirm email",
            "headline": "Headline",
            "message": "Message",
            "number": "Number",
            "date": "Date",
            "sender": "Sender",
            "receiver": "Receiver",
            "description": "Description",
            "damage": "Damage",
            "sub-damage": "Sub-damage",
            "total-damage": "Total damage",
            "number-damage": "Number damage",
            "weight-damage": "Weight damage",
            "cargo-content": "Cargo content",
            "insurance": "Insurance",
            "insurance-company": "Insurance company",
            "compensation-amount": "Compensation amount"
        }
    },
    "sv": {
        "comment": {
            "to": "Till:",
            "outgoing": "Utgående",
            "incoming": "Inkommande",
            "sending": "Skickar",
            "tries": "Försök: "
        },
        "label": {
            "email": "E-post",
            "confirmEmail": "Bekräfta e-post",
            "headline": "Rubrik",
            "message": "Meddelande",
            "number": "Nummer",
            "date": "Datum",
            "sender": "Avsändare",
            "receiver": "Mottagare",
            "description": "Beskrivning",
            "damage": "Skada",
            "sub-damage": "Delskada",
            "total-damage": "Totalskada",
            "number-damage": "Antal skada",
            "weight-damage": "Viktskada",
            "cargo-content": "Fraktinnehåll",
            "insurance": "Försäkring",
            "insurance-company": "Försäkringsbolag",
            "compensation-amount": "Ersättningsbelopp"
        }
    }
}
</i18n>
