<template>
    <section class="tiptap-container">
        <!-- subject-->
        <section class="subject">
            <v-text-field
                v-model="subject"
                v-bind="textFieldProps"
                :disabled="isBasic || !userSettings.cases.editSubject.active || isAnswering"
            >
                <template slot="label">
                    <span class="prepend-text">
                        {{ $t('createComments.subject') }}
                    </span>
                </template>
            </v-text-field>

            <div class="action-buttons-top-right">
                <v-tooltip top>
                    <template #activator="{ on }">
                        <v-btn :style="colorOfFieldExternal" icon v-on="on" @click="setCurrentExternalState(!external)">
                            <v-icon size="20">
                                {{ getStateIcon }}
                            </v-icon>
                        </v-btn>
                    </template>
                    {{ getStateText }}
                </v-tooltip>

                <v-tooltip top>
                    <template #activator="{ on }">
                        <v-btn
                            icon
                            :style="colorOfFieldCcs"
                            :disabled="ccButtonDisabled"
                            v-on="on"
                            @click="setShowCCs(!showCcs)"
                        >
                            <v-icon size="20">mdi-file-send-outline</v-icon>
                        </v-btn>
                    </template>
                    {{ $t('createComments.ccAndBcc') }}
                </v-tooltip>
            </div>
        </section>

        <!-- From / To -->
        <section v-if="external && !isBasic" class="from-to">
            <!-- fromEmail -->
            <v-select
                v-if="systemEmails.length != 0"
                :key="caseToLoad"
                v-model="emailFrom"
                :menu-props="{ offsetY: true }"
                v-bind="selectProps"
                :items="systemEmails"
            >
                <template #selection="{ attrs, item }">
                    <v-chip v-bind="attrs" color="primary" label small class="text-truncate">
                        <span class="pr-2 text-truncate">
                            {{ item }}
                        </span>
                    </v-chip>
                </template>
                <template slot="prepend-inner">
                    <span class="prepend-text">{{ $t('global.from') }}</span>
                </template>
            </v-select>

            <!-- toRecipients -->
            <EmailMulti v-model="toRecipients" :bind="comboProps" :validate="true">
                <template slot="prepend-inner">
                    <span class="prepend-text">{{ $t('global.to') }}</span>
                </template>
            </EmailMulti>
        </section>

        <!-- Recipients & BCC Recipients -->
        <section v-if="showCcs" class="recipients-bcc-recipients">
            <EmailMulti v-model="ccRecipients" :bind="comboProps" :validate="true">
                <template slot="prepend-inner">
                    <span class="prepend-text">
                        {{ $t('createComments.copy') }}
                    </span>
                </template>
            </EmailMulti>
            <EmailMulti v-model="bccRecipients" :bind="comboProps" :validate="true">
                <template slot="prepend-inner">
                    <span class="prepend-text">
                        {{ $t('createComments.blindCopy') }}
                    </span>
                </template>
            </EmailMulti>
        </section>
    </section>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import commentsHelper from '@/helpers/comments/comments.helper';

    export default {
        components: {
            EmailMulti: () => import('@/components/Main/Selects/EmailMulti.vue'),
        },
        data() {
            return {
                clientEmails: [],

                textFieldProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                    dense: true,
                    flat: true,
                },

                comboProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                    dense: true,
                    multiple: true,
                    chips: true,
                    flat: true,
                },
                selectProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                    dense: true,
                    chips: true,
                    flat: true,
                },
            };
        },

        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
                selectedCase: (state) => state.Cases.selectedCase,
                caseToLoad: (state) => state.Cases.caseToLoad,
                systemEmails: (state) => state.Cases.systemEmails,
                external: (state) => state.Comments.currentExternalState,
                showCcs: (state) => state.Comments.showCcs,

                // Emails
                emailToRecipients: (state) => state.Comments.emailToRecipients,
                emailCcRecipients: (state) => state.Comments.emailCcRecipients,
                emailBccRecipients: (state) => state.Comments.emailBccRecipients,
                subjectStore: (state) => state.Comments.subject,
                isForwarding: (state) => state.Comments.isForwarding,
                repliedComment: (state) => state.Comments.repliedComment,
            }),

            ...mapGetters({
                isBasic: 'Cases/isBasic',
                getLatestEmailComment: 'Comments/getLatestEmailComment',
            }),

            // Emails to

            subject: {
                get() {
                    return this.subjectStore;
                },
                set(value) {
                    this.setSubject(value);
                },
            },

            emailFrom: {
                get() {
                    return this.$store.state.Comments.emailFrom;
                },
                set(value) {
                    this.$store.dispatch('Comments/setEmailFrom', value);
                },
            },

            toRecipients: {
                get() {
                    return this.emailToRecipients;
                },
                set(value) {
                    this.$store.dispatch('Comments/setEmailToRecipients', value);
                },
            },

            ccRecipients: {
                get() {
                    return this.emailCcRecipients;
                },
                set(value) {
                    this.$store.dispatch('Comments/setEmailCcRecipients', value);
                },
            },

            bccRecipients: {
                get() {
                    return this.emailBccRecipients;
                },
                set(value) {
                    this.$store.dispatch('Comments/setEmailBccRecipients', value);
                },
            },

            colorOfFieldExternal() {
                return {
                    backgroundColor: this.external ? '#E1F1F5' : '#EFEFEF',
                };
            },

            colorOfFieldCcs() {
                return {
                    backgroundColor: this.showCcs ? '#E1F1F5' : '#EFEFEF',
                };
            },

            getStateIcon() {
                return this.external ? 'mdi-email' : 'mdi-comment-text-outline';
            },

            getStateText() {
                return this.external ? this.$t('createComments.external') : this.$t('createComments.internal');
            },

            caseRespondent() {
                return this.selectedCase?.case.respondent;
            },
            ccButtonDisabled() {
                return !this.external || Boolean(this.ccRecipients.length) || Boolean(this.bccRecipients.length);
            },
            isAnswering() {
                return Boolean(this.repliedComment);
            },
        },

        watch: {
            //  When case is loaded
            selectedCase: {
                handler(newValue, oldValue) {
                    if (oldValue && newValue.case.caseId !== oldValue.case.caseId) {
                        this.caseChanged();
                    }
                    this.retrieveWrittenSubject();
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            ...mapActions({
                setCurrentExternalState: 'Comments/setCurrentExternalState',
                setShowCCs: 'Comments/setShowCCs',
                setSubject: 'Comments/setSubject',
                setIsForwarding: 'Comments/setIsForwarding',
                setRepliedComment: 'Comments/setRepliedComment',
            }),

            // Validation

            validateEmail(mail) {
                const re = /\S+@\S+\.\S+/;
                return re.test(mail);
            },

            caseChanged() {
                // If we update the case with a event we shouldn't reset tiptap
                if (this.selectedCase?.event) {
                    return;
                }

                let newSender = null;
                let newRecipients = null;

                const latestEmailComment = this.getLatestEmailComment;
                if (latestEmailComment) {
                    newSender = commentsHelper.getSenderFromEmail(latestEmailComment);
                    newRecipients = commentsHelper.getRecipientsFromEmail({
                        email: latestEmailComment,
                        systemEmails: this.systemEmails,
                    });
                }

                const repliedComment = latestEmailComment?.id || null;
                this.setRepliedComment(repliedComment);

                this.ccRecipients = [];
                this.bccRecipients = [];
                this.emailFrom = newSender || this.systemEmails[0];
                this.toRecipients = newRecipients || this.emailToRecipients || [];
            },

            retrieveWrittenSubject() {
                if (this.selectedCase?.event) {
                    return;
                }

                // 1. Check if the user has enabled the feature
                if (!this.userSettings.cases.editSubject.active) {
                    // Set the subject to the current subject of the case
                    this.subject = this.selectedCase?.case.topic;
                    return;
                }

                // 2. get the comment with the highest date time
                try {
                    const latestEmailComment = this.selectedCase?.comments.find(
                        (comment) => comment.typeOfMessage === 'email'
                    );

                    if (latestEmailComment) {
                        this.subject = latestEmailComment.subject;
                        return;
                    }
                } catch (error) {
                    console.error('Error in retrieveWrittenSubject', error, new Date());
                }

                // 3. Check if the case has a topic
                if (this.selectedCase?.case.topic) {
                    this.subject = this.selectedCase.case.topic;
                    return;
                }

                // 4. Set the subject to empty as a fallback
                this.subject = '';
            },
        },
    };
</script>

<style scoped>
    .from-to {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
    }
    .subject {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 8px;
    }

    .recipients-bcc-recipients {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
    }

    .tiptap-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
    }

    .action-buttons-top-right {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
    }

    .prepend-text {
        font-size: 12px;
        color: var(--v-gray2-base);
    }
</style>

<i18n lang="json">
{
    "en": {
        "createComments": {
            "external": "External",
            "internal": "Internal",
            "from": "From",
            "recipient": "Recipient",
            "subject": "Subject",
            "ccAndBcc": "CC and BCC",
            "copy": "Copy",
            "blindCopy": "Blind copy"
        }
    },
    "sv": {
        "createComments": {
            "external": "Extern",
            "internal": "Intern",
            "from": "Från",
            "recipient": "Mottagare",
            "subject": "Ämne",
            "ccAndBcc": "CC och BCC",
            "copy": "Kopia",
            "blindCopy": "Hemlig kopia"
        }
    }
}
</i18n>
