module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "content": {
          "confirmationDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take over the case?"])},
          "confirmationDialogMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You are assigned to this case. Do you want to take away the write access from <b>", _interpolate(_list(0)), "</b>, who is currently working in the case? <b>", _interpolate(_list(0)), "</b> will be demoted to read-only."])}
        }
      },
      "sv": {
        "content": {
          "confirmationDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta över ärendet?"])},
          "confirmationDialogMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du är tilldelad detta ärende. Vill du ta över skrivbehörigheten från <b>", _interpolate(_list(0)), "</b>, som för närvarande arbetar i fallet? <b>", _interpolate(_list(0)), "</b> kommer att bli degraderad till läsbehörighet."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"content":{"confirmationDialogTitle":"Take over the case?","confirmationDialogMessage":"You are assigned to this case. Do you want to take away the write access from <b>{0}</b>, who is currently working in the case? <b>{0}</b> will be demoted to read-only."}},"sv":{"content":{"confirmationDialogTitle":"Ta över ärendet?","confirmationDialogMessage":"Du är tilldelad detta ärende. Vill du ta över skrivbehörigheten från <b>{0}</b>, som för närvarande arbetar i fallet? <b>{0}</b> kommer att bli degraderad till läsbehörighet."}}}')
  delete Component.options._Ctor
  
}
