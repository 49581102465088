import roles from '../../api/roles/roles';
import i18n from '../../i18n';

// State object
const state = {
    roles: [],
    allRoles: [],
};
// Getter functions
const getters = {};
// Actions
const actions = {
    async createRole({ dispatch }, payload) {
        try {
            const res = await roles.createRole(payload);
            await dispatch('getAllRoles');
            this._vm.$toasted.show(i18n.t('rolesStore.roleCreated'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async deleteRole({ dispatch }, payload) {
        try {
            const res = await roles.deleteRole(payload);
            dispatch('getAllRoles');
            this._vm.$toasted.show(i18n.t('rolesStore.roleDeleted'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async addMembersToRole({ dispatch }, payload) {
        try {
            const res = await roles.addMembersToRole(payload);
            dispatch('getAllRoles');
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async saveRoleDescription(_, payload) {
        try {
            const res = await roles.saveRoleDescription(payload);
            this._vm.$toasted.show(i18n.t('rolesStore.descriptionSaved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async deleteUserFromRole({ dispatch }, payload) {
        try {
            const res = await roles.deleteUserFromRole(payload);
            dispatch('getAllRoles');
            this._vm.$toasted.show(i18n.t('rolesStore.personDeleted'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async deleteAllUsersFromRole({ dispatch }, roleId) {
        try {
            const res = await roles.deleteAllUsersFromRole(roleId);
            dispatch('getAllRoles');
            this._vm.$toasted.show(i18n.t('rolesStore.personDeleted'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async addRoleOwnership({ dispatch }, payload) {
        try {
            const res = await roles.addRoleOwnership(payload);
            await dispatch('getAllRoles');
            this._vm.$toasted.show(i18n.t('rolesStore.chSaved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async deleteRoleOwnership({ dispatch }, payload) {
        try {
            const res = await roles.deleteRoleOwnership(payload);
            dispatch('getAllRoles');
            this._vm.$toasted.show(i18n.t('rolesStore.chSaved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async getAllRoles({ commit }) {
        try {
            const res = await roles.getAllRoles();
            commit('SET_ALL_USER_ROLES', res.data);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async getRolesByUserId({}, userId) {
        try {
            const res = await roles.getRolesByUserId(userId);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async updateLoggedIn(_, payload) {
        try {
            await roles.updateLoggedIn(payload);
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async roleOpeningHours({ dispatch }, payload) {
        try {
            const res = await roles.roleOpeningHours(payload);
            dispatch('getAllRoles');
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getRoleMembers(_, payload) {
        try {
            const res = await roles.getRoleMembers(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getAvailableUsers(_, payload) {
        try {
            const res = await roles.getAvailableUsers(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('rolesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
};

// Mutations
const mutations = {
    SET_ALL_USER_ROLES(state, data) {
        state.allRoles = data;
    },
    SET_USER_ROLES(state, data) {
        state.roles = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
