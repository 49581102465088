<template>
    <div v-if="selectedCase.subCases && selectedCase.subCases.length">
        <standard-select useSlot :dense="true" single :items="selectItems" @change="([item]) => clicked(item.value)">
            <v-btn class="mx-1 c1-btn" fab elevation="0" height="32" width="32" color="primary">
                <v-icon size="20">mdi-briefcase</v-icon>
            </v-btn>
        </standard-select>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        props: {
            selectedCase: {
                type: Object,
                required: true,
            },
        },
        computed: {
            selectItems() {
                return this.selectedCase.subCases.map((subCase) => {
                    const { caseId, topic } = subCase;
                    return {
                        name: topic,
                        value: caseId,
                        text: topic,
                        icon: 'mdi-briefcase',
                        color: 'var(--v-primary-base)',
                    };
                });
            },
        },
        methods: {
            ...mapActions('Cases', ['goToCase']),
            clicked(caseId) {
                this.goToCase({ caseId });
            },
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n lang="json">
{
    "en": {
        "subcases": "Subcases"
    },
    "sv": {
        "subcases": "Underärenden"
    }
}
</i18n>
