<template>
    <v-navigation-drawer
        ref="target"
        style="z-index: 101; position: fixed"
        :class="sideBar !== sideBarState.STANDARD ? 'mini-variant' : ''"
        height="100%"
        permanent
        :expand-on-hover="sideBar === sideBarState.STANDARD"
        mini-variant-width="72px"
        clipped
        @mouseover="mouseOver = true"
        @mouseleave="mouseOver = false"
        @transitionend="toggleTransition"
    >
        <template #prepend>
            <v-list-item class="d-flex justify-start user-box" two-line>
                <div class="user-card-container">
                    <UserCard />
                </div>
                <v-list-item-content class="ml-3">
                    <v-list-item-title color="primary">{{ loggedInUser.userName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ loggedInUser.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>

        <Transition mode="out-in" name="slide-fade">
            <v-sheet
                v-if="sideBar === sideBarState.STANDARD"
                height="99%"
                class="d-flex flex-column justify-space-between pb-5"
            >
                <v-sheet>
                    <RecursiveList isSidebar hasPopOuts />

                    <v-list>
                        <v-list-item
                            class="btn d-flex justify-start softShadow"
                            @click="$store.dispatch('Auth/logoutClient')"
                        >
                            <v-list-item-icon class="v-list-item-icon_override">
                                <v-icon color="white">mdi-logout</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title style="color: white; margin-left: 0.5em">
                                    {{ $t('sidebar.signOut') }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-sheet>
                <div class="logo-row">
                    <div class="logo-box">
                        <CustomTooltip location="right" openDelay="500" maxWidth="250">
                            <template #button>
                                <v-img src="@/assets/General/C1_icon.svg" class="logo-img" />
                            </template>
                            <template #content>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content v-show="isOpen" style="padding: 0">
                                            <div>© Customer First AB</div>
                                            <div
                                                class="text-caption text-truncate"
                                                v-html="`Made by ${developer}.`"
                                            ></div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </template>
                        </CustomTooltip>
                    </div>
                </div>
            </v-sheet>

            <v-sheet
                v-if="sideBar === sideBarState.BUILDER"
                height="99%"
                class="d-flex flex-column justify-space-between"
            >
                <BuilderMenu />
            </v-sheet>
        </Transition>
    </v-navigation-drawer>
</template>
<script>
    import { mapState } from 'vuex';
    import RecursiveList from '@/components/Global/MenuList/RecursiveList.vue';
    import BuilderMenu from '@/components/Global/BuilderMenu.vue';
    import CustomTooltip from '@/components/Global/CustomTooltip.vue';

    export default {
        name: 'Sidebar',
        components: {
            UserCard: () => import('@/components/Users/UserCard.vue'),
            RecursiveList,
            BuilderMenu,
            CustomTooltip,
        },
        data() {
            return {
                isOpen: false,
                mouseOver: false,
                developer: '',
                developersName: [
                    'Teodor',
                    'Benjamin',
                    'Edvin',
                    'Tim S',
                    'Tim A',
                    'Viktor B',
                    'Victor A',
                    'Taieb',
                    'Erik',
                    'Jesper',
                    'Markus',
                    'Samuel',
                ],
                developersPlanet: [
                    'Mercury',
                    'Venus',
                    'Earth',
                    'Mars',
                    'Jupiter',
                    'Saturn',
                    'Uranus',
                    'Neptune',
                    'Pluto',
                ],
            };
        },
        computed: {
            ...mapState({
                loggedInUser: (state) => state.Auth.userObject,
                sideBar: (state) => state.Layout.sideBar,
                sideBarState: (state) => state.Layout.sideBarState,
            }),
        },
        watch: {
            isOpen() {
                this.chooseNewDeveloper();
            },
        },

        methods: {
            returnRandomDeveloper() {
                return this.developersName[Math.floor(Math.random() * this.developersName.length)];
            },
            returnRandomPlanet() {
                return this.developersPlanet[Math.floor(Math.random() * this.developersPlanet.length)];
            },
            chooseNewDeveloper() {
                this.developer =
                    '<b>' + this.returnRandomDeveloper() + '</b> on <b>' + this.returnRandomPlanet() + '</b>';
            },
            toggleTransition() {
                this.isOpen = this.$refs.target?.isMouseover;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .user-box {
        border-bottom: 1px solid #e0e0e0;
        max-height: 64px;
        overflow: hidden;
    }
    .user-card-container {
        min-width: 40px;
    }
    .mini-variant {
        max-width: 72px;
    }
    .btn {
        padding: 0 0.6em;
        margin: 0 1em;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--v-primary-base);
    }

    .logo-row {
        display: flex;
        justify-content: center;
    }
    .logo-box {
        width: 33px;
        height: 36px;
    }
    .logo-img {
        margin: 0 !important;
        height: 100%;
        display: flex;
        align-self: center;
    }
    .v-list-item-icon_override {
        margin: 0 !important;
        height: 100%;
        display: flex;
        align-self: center;
    }
    .slide-fade-enter-active {
        transition: all 0.25s ease-in-out;
    }
    .slide-fade-leave-active {
        transition: all 0.5s ease-in-out;
    }
    .slide-fade-enter {
        transform: translateX(-10px);
        opacity: 0;
    }
    .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
<i18n lang="json">
{
    "en": {
        "sidebar": {
            "signOut": "Sign out",
            "externalLinks": "External links"
        }
    },
    "sv": {
        "sidebar": {
            "signOut": "Logga ut",
            "externalLinks": "Externa länkar"
        }
    }
}
</i18n>
