<template>
    <main ref="image" class="container" :style="containerStyle">
        <div class="header" :style="headerStyle">
            <v-avatar color="gray2" :size="headerAvatarSize">
                <v-icon :size="headerIconSize" dark>mdi-account</v-icon>
            </v-avatar>
            <div class="header-text" :style="headerTextStyle">
                {{ smsFrom || $t('smsPreview.empty') }}
            </div>
        </div>

        <section :style="contentStyle" class="content">
            <div class="chat-container" :style="chatContainerStyle">
                <div class="chat" :style="chatStyle">
                    <p v-dompurify-html="linkify(text)" class="chat-text" :style="chatTextStyle"></p>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
    import { apple1 } from '@/assets/Cases/PhonePreview/index';
    import { linkify } from '@/utils';

    export default {
        props: {
            smsText: {
                type: String,
                default: null,
            },
            smsFrom: {
                type: String,
                default: null,
            },
            height: {
                type: String,
                default: '100%',
            },
        },
        data() {
            return {
                text: this.smsText,
                imageHeight: 0,
                imageInnerWidth: 0,
                imageInnerHeight: 0,
            };
        },
        computed: {
            phonePicture() {
                return apple1;
            },
            containerStyle() {
                return {
                    backgroundImage: `url(${this.phonePicture})`,
                    height: this.height,
                    paddingTop: this.imageHeight * 0.06 + 'px',
                };
            },
            headerStyle() {
                return {
                    width: this.imageInnerWidth + 'px',
                    height: this.imageInnerHeight * 0.1 + 'px',
                };
            },
            headerAvatarSize() {
                return 0.04 * this.imageHeight;
            },
            headerIconSize() {
                return 0.025 * this.imageHeight;
            },
            headerTextStyle() {
                return {
                    fontSize: 0.02 * this.imageHeight + 'px',
                };
            },
            contentStyle() {
                return {
                    height: this.imageInnerHeight * 0.9 + 'px',
                    width: this.imageInnerWidth + 'px',
                    paddingBottom: this.imageInnerHeight * 0.03 + 'px',
                };
            },
            chatContainerStyle() {
                return {
                    paddingTop: this.imageInnerHeight * 0.03 + 'px',
                    lineHeight: this.imageInnerHeight * 0.025 + 'px',
                };
            },
            chatStyle() {
                return {
                    borderRadius: this.imageInnerHeight * 0.025 + 'px',
                    padding: `${this.imageInnerHeight * 0.02}px ${this.imageInnerHeight * 0.025}px`,
                };
            },
            chatTextStyle() {
                return {
                    fontSize: this.imageInnerHeight * 0.02 + 'px',
                };
            },
        },
        watch: {
            smsText() {
                this.text = this.smsText;
            },
        },
        mounted() {
            window.addEventListener('resize', this.resize);
            this.$nextTick(() => {
                this.resize();
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resize);
        },
        methods: {
            linkify,
            resize() {
                this.imageHeight = this.$refs.image.clientHeight;
                this.imageInnerHeight = 0.9 * this.imageHeight;
                this.imageInnerWidth = 0.43 * this.imageHeight;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        max-height: 100%;
        max-width: 100%;
        aspect-ratio: 15/25;
    }

    .chat {
        position: relative;
        background: #e5e5ea;
        width: auto;
        max-width: 75%;
        color: black;
        float: left;
    }
    .chat-text {
        margin: 0px !important;
        white-space: pre-wrap;
        direction: ltr;
    }
    .header {
        border-bottom: 1px solid #e5e5ea;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .content {
        overflow-y: auto;
        display: flex;
        justify-content: center;
    }
    .chat-container {
        clear: both;
        width: 90%;
        word-wrap: break-word;
        margin-bottom: 0px;
        overflow-y: scroll;
    }

    .header-text {
        overflow-x: hidden;
        overflow-y: hidden;
    }
</style>
<i18n lang="json">
{
    "en": {
        "smsPreview": {
            "empty": "Empty"
        }
    },
    "sv": {
        "smsPreview": {
            "empty": "Tomt"
        }
    }
}
</i18n>
