import axios from 'axios';

function getHealthCheck() {
    return axios.get(`/system/health`);
}

function getNewVersion() {
    const origin = window.location.hostname;
    const apiUrlPath = 'c1/api/version';
    const apiUrl = `https://${origin}/${apiUrlPath}/`; // Important to have trailing slash otherwise it will try to fetch over http
    return axios.get(apiUrl);
}

export default {
    getHealthCheck,
    getNewVersion,
};
