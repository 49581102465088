import axios from 'axios';

//= ===================================================//
//                 Main user functions                //
//= ===================================================//

function addUser(payload) {
    return axios.post(`/users`, payload);
}
function deleteUser(userId) {
    return axios.delete(`/users/${userId}`);
}

//= ===================================================//
//                    Get user data                   //
//= ===================================================//

function getUserDataToEdit(payload) {
    return axios({
        metod: 'get',
        url: `/users/${payload}/edit`,
    });
}
function getNextInternalNumber() {
    return axios.get(`/swyx/internalnumbers/next`);
}

function getAssignedInternalNumbers() {
    return axios.get(`/swyx/internalnumbers`);
}

function getPublicNumberSuggestions(number) {
    return axios.get(`/swyx/publicnumbers/suggestions?internalNumber=${number}
        `);
}

function getSwyxTrunks() {
    return axios.get(`/swyx/trunks`);
}

function getSwyxUsers() {
    return axios.get(`/swyx/users`);
}

function getNumberRanges(trunkIds) {
    return axios.get(`/swyx/trunk/numberranges?ids=${trunkIds}`);
}

function getSwyxSystemSettings() {
    return axios.get(`/swyx/systemsettings`);
}

//= ===================================================//
//                 User validations                   //
//= ===================================================//

function checkSwyxInternalNumberValidation(number) {
    return axios.get(`/swyx/validate/internalnumber?internalNumber=${number}`);
}
function checkSwyxPublicNumberValidation(num) {
    return axios.get(`/swyx/publicnumber/validate?publicNumber=${encodeURIComponent(num)}`);
}

function checkUserNameValidation(name) {
    return axios.get(`/swyx/validate/name?name=${name}`);
}

function checkEmailValidation(name) {
    return axios.get(`/swyx/validate/email?email=${name}`);
}

//= ===================================================//
//                        ROLES                       //
//= ===================================================//

function getRoles() {
    return axios.get(`/roles`);
}

function saveRoleChange(payload) {
    return axios.put(`/roles`, payload);
}

export default {
    addUser,
    deleteUser,
    checkSwyxPublicNumberValidation,
    getAssignedInternalNumbers,
    getNextInternalNumber,
    getSwyxTrunks,
    getNumberRanges,
    getPublicNumberSuggestions,
    checkSwyxInternalNumberValidation,
    getUserDataToEdit,
    checkUserNameValidation,
    checkEmailValidation,
    saveRoleChange,
    getRoles,
    getSwyxUsers,
    getSwyxSystemSettings,
};
