import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedCase.case.parentCase)?_c('div',{staticClass:"d-flex"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c('div',_vm._g(_vm._b({},'div',tooltip.attrs,false),tooltip.on),[_c(VBtn,{staticClass:"mx-1 c1-btn",attrs:{"fab":"","elevation":"0","height":"32","width":"32","color":"primary"},on:{"click":function($event){return _vm.clicked(_vm.selectedCase.case.parentCase.caseId)}}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v("mdi-chevron-up")])],1)],1)]}}],null,false,2957567573)},[_c('span',[_vm._v(_vm._s(_vm.$t('gotToParentCase')))])]),(
            _vm.selectedCase.case.originParent &&
            _vm.selectedCase.case.parentCase.caseId !== _vm.selectedCase.case.originParent.caseId
        )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c('div',_vm._g(_vm._b({},'div',tooltip.attrs,false),tooltip.on),[_c(VBtn,{staticClass:"mx-1 c1-btn",attrs:{"fab":"","elevation":"0","height":"32","width":"32","color":"primary"},on:{"click":function($event){return _vm.clicked(_vm.selectedCase.case.originParent.caseId)}}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v("mdi-chevron-double-up")])],1)],1)]}}],null,false,3755040077)},[_c('span',[_vm._v(_vm._s(_vm.$t('gotToSourceParentCase')))])]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }