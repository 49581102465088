<template>
    <div class="rounded pa-2" style="background: var(--v-gray3-base); overflow: hidden; min-width: 364px">
        <h3 class="mb-1" style="font-weight: 400">{{ $t('activeDialogs') }}</h3>
        <v-divider class="mb-2" />
        <div v-if="items.length > 0" style="height: 200px; overflow-x: hidden; overflow-y: scroll">
            <ActiveCommunicationItem
                v-for="(item, index) in items"
                v-if="items.length > 0"
                :key="index"
                :class="index !== items.length - 1 ? 'mb-2' : ''"
                :item="item"
            />
        </div>
        <div v-if="items.length === 0" class="justify-center pb-5 d-flex flex-column align-center">
            <v-icon color="primary" size="7rem">mdi-google-downasaur</v-icon>
            <span style="font-weight: 400; font-size: 14px" class="pt-2">{{ $t('noAvailable') }}</span>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        components: {
            ActiveCommunicationItem: () =>
                import('@/components/Global/UserActionModule/Modules/ActiveCommunications/ActiveCommunicationItem.vue'),
        },
        data() {
            return {
                subscribedData: null,
            };
        },
        sockets: {
            changeInCommunication(data) {
                if (!data || !data.settings || !data.settings[0]) return;

                if (data.settings[0].userId === this.loggedInUser.userId) {
                    this.$store.dispatch('Users/updateCurrentCommunications', data);
                }
            },
            connect() {
                if (this.isSocketReconnecting) {
                    this.$store.dispatch('Users/getCurrentCommunications');
                }
                this.subscribe();
            },
            disconnect() {
                this.unsubscribe();
            },
        },
        computed: {
            ...mapState({
                isSocketReconnecting: (state) => state.App.isReconnecting,
            }),
            items() {
                return this.$store.state.Users.currentDialogs;
                /* map get the name store request to teh backend ugly solution to map the value  */
            },
            loggedInUser() {
                return this.$store.state.Auth.userObject;
            },
        },
        watch: {
            items(newVal, oldVal) {
                // if length is 0 for both, then it's the initial load and we don't want to subscribe
                if (!newVal.length && !oldVal.length) return;

                // we want to unsubscribe from the old data and subscribe to the new data since we subscribe to userId and array of caseIds
                this.unsubscribe();
                this.subscribe();
            },
        },
        created() {
            this.$store.dispatch('Users/getCurrentCommunications');
            if (this.$socket.connected) {
                this.subscribe();
            }
        },
        destroyed() {},
        methods: {
            subscribe() {
                const roomData = {
                    userId: this.loggedInUser.userId,
                    caseIds: this.items.map((item) => item.caseId),
                    fetchData: false,
                };

                this.subscribedData = roomData;
                this.$socket.emit('activeCommunicationSubscription', roomData);
            },
            unsubscribe() {
                const roomData = this.subscribedData;
                if (!roomData) return;
                this.$socket.emit('activeCommunicationUnsubscription', roomData);
            },
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n lang="json">
{
    "en": {
        "noAvailable": "You have no active dialogs at the moment",
        "activeDialogs": "Your active dialogs"
    },
    "sv": {
        "noAvailable": "Du har inga aktiva dialoger just nu",
        "activeDialogs": "Dina aktiva dialoger"
    }
}
</i18n>
