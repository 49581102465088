import api from '@/api/call/call';
import i18n from '../../i18n';

const actions = {
    async getNumberToCall(_, payload) {
        try {
            const res = await api.getNumberToCall(payload);
            const number = res.data;

            if (!number) {
                this._vm.$toasted.show(i18n.t('casesStore.wrong'), {
                    icon: 'cancel',
                    type: 'error',
                });
                return null;
            }
            return number;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('casesStore.wrong'), {
                icon: 'cancel',
                type: 'error',
            });
            return null;
        }
    },
};
export default {
    namespaced: true,
    actions,
};
