<template>
    <div
        v-if="caseTravel.items?.length > 0"
        style="position: relative; height: 100%; border-top: 1px solid #f2f2f2"
        class="softShadow"
    >
        <div
            class="d-flex flex-column align-center justify-space-between case-travel-wrapper"
            style="height: 100%; overflow: hidden; transition: width 0.5s ease-in-out; background-color: white"
            :style="{ width: caseTravelState ? '400px' : '96px' }"
            @mouseover="setHover(true)"
            @mouseleave="setHover(false)"
        >
            <div
                v-if="tooltipData != null"
                ref="tooltip"
                style="
                    position: absolute;
                    left: 20px;
                    top: 0;
                    z-index: 999;
                    transform: translateX(-100%);
                    overflow: hidden;
                    white-space: nowrap;
                "
                class="tooltip softShadow d-flex flex-row align-center px-3"
            >
                <div class="pr-2">
                    <CommonAvatar
                        v-if="isAvatar(tooltipData)"
                        :key="tooltipData.status == 'initiated' ? selectedCase.case.userId : tooltipData.userId"
                        :size="26"
                        :userId="
                            tooltipData.status == 'initiated'
                                ? Number(selectedCase.case.userId)
                                : Number(tooltipData.userId)
                        "
                    />
                    <v-icon v-if="!isAvatar(tooltipData)" :color="tooltipData.iconColor" size="18">
                        {{ tooltipData.icon }}
                    </v-icon>
                </div>

                <div style="position: relative; display: block; font-size: 13px; line-height: 1.4em">
                    <span style="color: var(--v-gray4-base)">
                        {{ tooltipData.text[$i18n.locale] }}
                        <span v-if="selectedCase?.case?.agentName || tooltipData.userName">
                            {{ tooltipData.status == 'initiated' ? selectedCase.case.agentName : tooltipData.userName }}
                        </span>
                    </span>
                    <br />
                    <span :style="{ color: 'var(--v-gray2-base' }">{{ getDay(tooltipData.time) }}</span>
                </div>
            </div>

            <div
                class="d-flex flex-column justify-center align-center expander softShadow"
                @click="toggleCaseTravelState()"
            >
                <v-icon>
                    {{ caseTravelState ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
                </v-icon>
            </div>

            <div
                class="d-flex flex-column softShadow"
                style="
                    width: 400px;
                    height: 100%;
                    left: 0;
                    transition: width 0.5s ease-in-out;
                    background-color: white;
                    z-index: 4;
                "
                :style="{ width: caseTravelState ? '400px' : '96px' }"
            >
                <div
                    style="position: absolute; height: 100%; width: 1px; left: 48px; top: 0; z-index: 1"
                    :style="{ backgroundColor: 'var(--v-gray3-base)' }"
                ></div>
                <div
                    id="scrollDiv"
                    ref="scrollDiv"
                    class="d-flex flex-column"
                    style="
                        position: relative;
                        overflow: hidden;
                        width: 100%;
                        z-index: 50;
                        min-width: 90px;
                        flex: 1;
                        flex-grow: 1;
                        overflow-y: auto;
                        scroll-behavior: smooth;
                        overflow-y: scroll;
                        height: 100%;
                    "
                >
                    <template v-if="caseTravel">
                        <div style="width: 100%; width: 350px; height: 100%" class="py-6">
                            <div
                                v-for="(event, index) in caseTravel.items"
                                :key="index"
                                :ref="`timelineItem${index}`"
                                :icon="event.icon"
                                fill-dot
                                small
                                class="d-flex flex-column align-center pb-4"
                                style="position: relative"
                            >
                                <div v-if="!Array.isArray(event)" class="d-flex flex-row" style="width: 100%">
                                    <div class="d-flex flex-column align-center pb-3">
                                        <div
                                            style="
                                                cursor: pointer;
                                                transition: all 0.5s ease-in-out;
                                                width: 100px;
                                                z-index: 2;
                                            "
                                            :style="{ cursor: !caseTravelState ? 'pointer' : 'default' }"
                                            class="pl-8"
                                            @mouseover="showToolTip(event, index)"
                                            @mouseleave="hideToolTip()"
                                        >
                                            <CommonAvatar
                                                v-if="isAvatar(event) && caseTravelState"
                                                :key="
                                                    event.status == 'initiated'
                                                        ? selectedCase.case.userId
                                                        : event.userId
                                                "
                                                :size="35"
                                                :userId="
                                                    event.status == 'initiated'
                                                        ? Number(selectedCase.case.userId)
                                                        : Number(event.userId)
                                                "
                                                border="4px solid white"
                                            />
                                            <v-btn
                                                v-if="!isAvatar(event) || !caseTravelState"
                                                :color="event.iconColor"
                                                fab
                                                elevation="0"
                                                class="softShadow"
                                                height="32"
                                                width="32"
                                                style="border: 4px solid white"
                                                :ripple="false"
                                                @mouseleave="(e) => e.target.blur()"
                                            >
                                                <v-icon :color="'white'" size="16">
                                                    {{ event.icon }}
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div style="width: 100%">
                                        <CaseTravelItem :event="event" />
                                    </div>
                                </div>
                                <div v-else class="d-flex flex-row" style="width: 100%">
                                    <div class="d-flex flex-column align-center pb-3">
                                        <div
                                            style="
                                                cursor: pointer;
                                                transition: all 0.5s ease-in-out;
                                                width: 100px;
                                                z-index: 2;
                                            "
                                            :style="{ cursor: !caseTravelState ? 'pointer' : 'default' }"
                                            class="pl-8"
                                            @mouseover="showToolTip(event[0], index)"
                                            @mouseleave="hideToolTip()"
                                        >
                                            <v-btn
                                                :color="event[0].iconColor"
                                                fab
                                                elevation="0"
                                                class="softShadow"
                                                height="32"
                                                width="32"
                                                style="border: 4px solid white"
                                                :ripple="false"
                                                @mouseleave="(e) => e.target.blur()"
                                            >
                                                <v-icon :color="'white'" size="16">
                                                    {{ event[0].icon }}
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div style="width: 100%">
                                        <CaseTravelItem :event="event" />
                                    </div>
                                </div>
                                <div
                                    v-if="calcEventTimeUntil(event)"
                                    class="d-flex flex-row align-center justify-center"
                                    style="width: 96px; left: 0; position: absolute; height: 100%"
                                >
                                    <v-tooltip bottom>
                                        <template #activator="{ on, attrs }">
                                            <v-chip
                                                :color="'white'"
                                                class="softShadow"
                                                small
                                                style="position: absolute; margin-top: 30px"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <span v-if="Array.isArray(event)" style="font-size: 10px">
                                                    {{ getMMSS(event[0].timeUntilNextevent) }}
                                                </span>
                                                <span v-else style="font-size: 10px">
                                                    {{ getMMSS(event.timeUntilNextevent) }}
                                                </span>
                                            </v-chip>
                                        </template>
                                        <span>{{ $t('caseTravel.timeBetween') }}</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import CaseTravelItem from '@/components/Cases/CaseTravel/CaseTravelItem.vue';
    import { getMMSS, getDay } from '@/utils/caseTravel';
    import { mapState } from 'vuex';

    export default {
        components: {
            CommonAvatar: () => import('@/components/Global/CommonAvatar.vue'),
            CaseTravelItem,
        },
        data() {
            return {
                hover: false,
                tooltipData: null,
            };
        },
        computed: {
            ...mapState({
                tiptapHeight: (state) => state.Comments.tiptapHeight,
                tiptapState: (state) => state.Comments.tiptapState,
                selectedCase: (state) => state.Cases.selectedCase,
            }),

            caseTravel: {
                get() {
                    return this.$store.state.Cases.caseTravel;
                },
            },

            caseTravelState: {
                get() {
                    return this.$store.state.Cases.caseTravelState;
                },
                set(val) {
                    this.$store.commit('Cases/SET_CASE_TRAVEL_STATE', val);
                },
            },
        },

        mounted() {
            setTimeout(() => {
                const scrollDiv = document.querySelector('#scrollDiv');
                if (!scrollDiv) return;
                scrollDiv.scrollTop = scrollDiv.scrollHeight;
            }, 1000);
        },
        methods: {
            getDay,
            getMMSS,
            calcEventTimeUntil(event) {
                if (Array.isArray(event)) {
                    return event[0].timeUntilNextevent !== 0 && this.caseTravelState;
                }
                return event.timeUntilNextevent !== 0 && this.caseTravelState;
            },
            isAvatar(event) {
                return (event.profilePicture != null && event.status != 'callHungUp') || event.status == 'initiated';
            },
            toggleCaseTravelState() {
                this.caseTravelState = !this.caseTravelState;
                if (this.caseTravelState) return;
                this.$store.commit('Cases/SET_CASE_TRAVEL_MINIMIZED', false);
            },
            setHover(value) {
                this.hover = value;
            },
            showToolTip(item, index) {
                if (this.caseTravelState) return;
                const ref = 'timelineItem' + index;

                this.tooltipData = item;

                this.$nextTick(() => {
                    this.$refs.tooltip.style.visibility = 'visible';
                    this.$refs.tooltip.style.opacity = 1;
                    this.$refs.tooltip.style.top =
                        this.$refs[ref][0].offsetTop - 2 - this.$refs.scrollDiv.scrollTop + 'px';
                });
            },
            hideToolTip() {
                if (this.caseTravelState) return;
                this.$nextTick(() => {
                    this.$refs.tooltip.style.visibility = 'hidden';
                    this.$refs.tooltip.style.opacity = 0;
                });
            },
            getMaxHeight() {
                const NO_HEIGHT = 0;
                const commentGridContainer = document.querySelector('.comment-grid-container');

                if (!commentGridContainer) {
                    return NO_HEIGHT;
                }

                const commentHeader = document.querySelector('.comment-header');
                if (!commentHeader) {
                    return NO_HEIGHT;
                }

                return commentGridContainer.clientHeight - commentHeader.clientHeight;
            },
        },
    };
</script>
<style scoped lang="scss">
    .case-travel-wrapper {
        .expander {
            border-radius: 6px 0px 0px 6px !important;
            height: 64px;
            width: 32px;
            background-color: white;
            top: calc(50% - 32px);
            position: absolute;
            // right: 0;
            left: 0px;
            z-index: 1;
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
                background-color: var(--v-gray5-base) !important;
            }
        }

        &:hover {
            .expander {
                left: -32px;
            }
        }
    }

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        border-radius: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 0px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 0px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .tooltip {
        background-color: white;
        padding: 6px;
        border-radius: 6px;
        transition: all 0.3s;
        opacity: 0;
    }
</style>
<i18n lang="json">
{
    "en": {
        "caseTravel": {
            "position": "You are here",
            "timeBetween": "Time between events"
        }
    },
    "sv": {
        "caseTravel": {
            "position": "Du är här",
            "timeBetween": "Tid mellan händelser"
        }
    }
}
</i18n>
