module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending"])},
          "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tries: "])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
          "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
          "retryTooltipFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry sending the email"])},
          "retryMinutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minute"]), _normalize([_interpolate(_named("n")), " minutes"])])},
          "retryTooltipTrying": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next attempt to send is in ", _interpolate(_named("time")), ", press to retry now"])},
          "retryError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't reset tries, please try again later"])},
          "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't delete the comment, please try again later"])}
        }
      },
      "sv": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgående"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkommande"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skickar"])},
          "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök: "])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misslyckades"])},
          "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök igen"])},
          "retryTooltipFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök att skicka e-postmeddelandet igen"])},
          "retryMinutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minut"]), _normalize([_interpolate(_named("n")), " minuter"])])},
          "retryTooltipTrying": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nästa försök att skicka är om ", _interpolate(_named("time")), ", tryck för att försöka nu"])},
          "retryError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte återställa försök, försök igen senare"])},
          "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte ta bort kommentaren, försök igen senare"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"comment":{"to":"To:","outgoing":"Outgoing","incoming":"Incoming","sending":"Sending","tries":"Tries: ","failed":"Failed","retry":"Retry","retryTooltipFailed":"Retry sending the email","retryMinutes":"{n} minute | {n} minutes","retryTooltipTrying":"Next attempt to send is in {time}, press to retry now","retryError":"Couldn\u0027t reset tries, please try again later","deleteError":"Couldn\u0027t delete the comment, please try again later"}},"sv":{"comment":{"to":"Till:","outgoing":"Utgående","incoming":"Inkommande","sending":"Skickar","tries":"Försök: ","failed":"Misslyckades","retry":"Försök igen","retryTooltipFailed":"Försök att skicka e-postmeddelandet igen","retryMinutes":"{n} minut | {n} minuter","retryTooltipTrying":"Nästa försök att skicka är om {time}, tryck för att försöka nu","retryError":"Kunde inte återställa försök, försök igen senare","deleteError":"Kunde inte ta bort kommentaren, försök igen senare"}}}')
  delete Component.options._Ctor
  
}
