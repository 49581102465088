<template>
    <!-- :heading="'Dina aktiva ärenden (' + (activeUserCases.length || 0) + ')'" -->
    <!-- :displayText="'Du har inga aktiva ärenden.'" -->
    <div class="rounded pa-2" style="background: var(--v-gray3-base); min-width: 364px">
        <h3 class="mb-1" style="font-weight: 400">
            {{ $t('activeCasesInboxModule.activeCases') }} ({{ activeUserCases.length || 0 }})
        </h3>
        <v-divider class="mb-2" />
        <div v-if="activeUserCases.length > 0" style="height: 200px; overflow-x: hidden; overflow-y: scroll">
            <div
                v-for="(item, index) in activeUserCases"
                :key="index"
                ref="target"
                class="rounded pa-3 softShadow d-flex align-center justify-space-between"
                :class="index !== activeUserCases.length - 1 ? 'mb-2' : ''"
                :style="{
                    backgroundColor: hover === index ? 'var(--v-gray3-lighten)' : 'white',
                    cursor: 'pointer',
                    position: 'relative',
                }"
                @click="selectCase(item.CaseID)"
                @mouseover="hover = index"
                @mouseleave="hover = -1"
            >
                <div class="d-flex align-center">
                    <ActivityLine :item="item" small />
                    <InternalType :item="{ internalType: item.InternalType, unreadMessages: item.UnreadMessages }" />
                    <div class="ml-4 text-truncate d-flex flex-column">
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <h4
                                    style="font-weight: 400; max-width: 130px; font-size: 14px"
                                    class="text-truncate m2-1"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ item.Topic }}
                                </h4>
                            </template>
                            <span>{{ item.Topic }}</span>
                        </v-tooltip>
                        <span style="font-size: 12px; line-height: 1" :style="{ color: 'var(--v-gray1-base' }">
                            {{ $t('global.topic') }}
                        </span>
                    </div>
                </div>
                <div class="justify-center d-flex align-center">
                    <QueueChip :item="item" small />
                    <StatusBar class="ml-2 smooth-transition" :state="{ value: item.Status }" :rounded="true" />
                </div>
            </div>
        </div>
        <div v-if="activeUserCases.length === 0" class="justify-center d-flex flex-column align-center">
            <v-icon color="primary" size="10rem">mdi-google-downasaur</v-icon>
            <!-- <div class="text-h5">Du har inga aktiva dialoger just nu</div> -->
            <h3 style="font-weight: 400">{{ $t('activeCasesInboxModule.noActiveCases') }}</h3>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import { filterObjectsByKeys, getActiveFromSettings } from '@/utils';
    import { channelTypes } from '../../../../templates/cases.template';

    export default {
        components: {
            ActivityLine: () => import('@/components/Cases/List/ActivityLine.vue'),
            StatusBar: () => import('@/components/Cases/StatusBar.vue'),
            InternalType: () => import('@/components/Cases/List/InternalType.vue'),
            QueueChip: () => import('@/components/Cases/List/QueueChip.vue'),
        },
        sockets: {
            connect() {
                // Prevent duplicate requests from the created event and when socket reconnects
                if (!this.isSocketReconnecting) return;
                this.handleActiveCases();
            },
        },
        data() {
            return {
                search: '',
                totalUnreadMessages: 0,
                selectedCaseId: null,
                show: false,
                hover: -1,
            };
        },
        computed: {
            ...mapState({
                isSocketReconnecting: (state) => state.App.isReconnecting,
                channelConfig: (state) => state.System.userSettings.system.channels,
            }),
            filteredChannelTypes() {
                const channelBooleans = getActiveFromSettings(this.channelConfig);
                return filterObjectsByKeys(channelBooleans, channelTypes, 'value');
            },
            loggedInUser() {
                return this.$store.state.Auth.userObject;
            },
            currentCase() {
                return this.$store.state.Cases?.selectedCase?.caseStart;
            },
            activeUserCases: {
                get() {
                    let cases = this.$store.state.Cases?.activeUserCases;
                    cases = cases
                        .map((item) => {
                            return {
                                ...item,
                                value: item.CaseID,
                            };
                        })
                        .sort((a, b) => {
                            return b.UnreadMessages - a.UnreadMessages;
                        });
                    return cases;
                },
                set(val) {
                    this.$store.commit('Cases/SET_ACTIVE_USER_CASES', val);
                },
            },
            filterCases() {
                if (!this.search) {
                    return this.activeUserCases;
                }
                return this.activeUserCases.filter((item) => {
                    return (
                        item.Topic.toLowerCase().includes(this.search.toLowerCase()) ||
                        item.CaseID.toLowerCase().includes(this.search.toLowerCase())
                    );
                });
            },
        },
        watch: {
            currentCase: {
                handler(newVal) {
                    this.checkAndApplySelectedCase(newVal);
                },
            },
            activeUserCases: {
                handler(newVal) {
                    this.totalUnreadMessages = this.combinedUnreadMessages(newVal);

                    if (this.selectedCaseId == null) this.checkAndApplySelectedCase(this.currentCase);
                },
                immediate: true,
                deep: true,
            },
            show(newVal) {
                if (!newVal) {
                    this.search = '';
                }
            },
        },
        created() {
            this.handleActiveCases();
        },
        mounted() {
            this.checkAndApplySelectedCase(this.currentCase);
        },
        methods: {
            decideColor(unreadMessages) {
                if (unreadMessages > 0) {
                    return this.$vuetify.theme.themes.light.color1;
                }
                return 'transparent';
            },
            handleActiveCases() {
                this.$store.dispatch('Cases/subscribeToCases');
                this.$store.dispatch('Cases/getActiveCasesByUser');
            },
            async selectCase(caseID) {
                await this.$store.dispatch('Cases/isCaseBusy', caseID);
                const url = '/cases/' + caseID;
                await this.$store.dispatch('Cases/setSelectedCaseId', caseID);

                this.$router.push(url);
                this.$store.dispatch('Cases/setGoToContent', true);
                this.$store.commit('Users/SET_USER_ACTION_MODULE_OPEN', false);
                this.$store.dispatch('Cases/handleUnreadMessageResets');
            },
            checkAndApplySelectedCase(newVal) {
                this.selectedCaseId = newVal?.CaseID ?? null;
            },
            combinedUnreadMessages(cases) {
                if (!cases) {
                    return 0;
                }
                const total = cases.reduce((acc, item) => {
                    return acc + item.UnreadMessages;
                }, 0);
                if (total > 99) {
                    return '99+';
                }
                return total ?? 0;
            },

            caseTypeIcon(type, internalCreated) {
                if (type === undefined) {
                    return null;
                }

                if (type === 'email' && Number(internalCreated) === 2) {
                    return 'mdi-email';
                }

                const { icon } = this.filteredChannelTypes.find((t) => t.value === type);

                if (icon === undefined) {
                    return 'mdi-help-circle';
                }
                return icon;
            },
        },
    };
</script>
<style scoped></style>
<i18n lang="json">
{
    "en": {
        "activeCasesInboxModule": {
            "activeCases": "Your active cases",
            "noActiveCases": "You don't have any active cases."
        }
    },
    "sv": {
        "activeCasesInboxModule": {
            "activeCases": "Dina aktiva ärenden",
            "noActiveCases": "Du har inga aktiva ärenden."
        }
    }
}
</i18n>
