<template>
    <div>
        <v-row>
            <v-col v-for="(item, i) in cfg.customerParams.integrationLinks.links" :key="i" cols="6">
                <v-btn
                    style="cursor: pointer; background: white"
                    class="d-flex align-center mb-2"
                    @click="integrationExternal(item.link)"
                >
                    <v-icon :color="item.color ? item.color : 'primary'" class="ma-1" size="30">{{ item.icon }}</v-icon>
                    <span class="ma-1">{{ item.name }}</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-btn
            v-if="order == 0"
            style="min-width: 250px; height: 100%; background-color: white; border-radius: 0px"
            class="pa-0"
            elevation="0"
            @click="integrationExternal('Zen')"
        >
            <div
                style="height: 40px; width: 40px; border-radius: 20px; background-color: black !important"
                class="d-flex flex-column justify-center align-center ma-0 pa-0"
            >
                <div style="height: 16px; width: 22px">
                    <svg
                        id="Layer_1"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 965 692.6"
                        style="enable-background: new 0 0 965 692.6"
                        xml:space="preserve"
                    >
                        <g>
                            <polygon style="fill: rgb(255, 255, 255)" points="453.1,208.5 453.1,646.3 90.5,646.3 	" />
                            <path
                                style="fill: rgb(255, 255, 255)"
                                d="M453.1,51.4c0,100.1-81.2,181.3-181.3,181.3S90.5,151.5,90.5,51.4H453.1z"
                            />
                            <path
                                style="fill: rgb(255, 255, 255)"
                                d="M512.9,646.3C512.9,546.1,594,465,694.2,465s181.3,81.2,181.3,181.3H512.9z"
                            />
                            <polygon style="fill: rgb(255, 255, 255)" points="512.9,489.1 512.9,51.4 875.5,51.4 	" />
                        </g>
                    </svg>
                </div>
            </div>
            <span
                class="btnSpan mt-2"
                style="font-size: 14px; letter-spacing: 0px; font-weight: 400"
                :style="{ color: 'var(--v-gray1-base' }"
            >
                Ticket Zendesk
            </span>
        </v-btn>
        <v-btn
            v-else-if="order == 1"
            style="min-width: 250px; height: 100%; background-color: white; border-radius: 0px"
            class="pa-0"
            elevation="0"
            @click="integrationExternal('Lime')"
        >
            <div
                style="
                    height: 40px;
                    width: 40px;
                    border-radius: 20px;
                    background-color: var(--v-primary-base) !important;
                "
                class="d-flex flex-column justify-center align-center ma-0 pa-0"
            >
                <div style="height: 20px; width: 20px">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.44011 49.96921">
                        <g>
                            <path
                                d="M31.92286.25029a23.324,23.324,0,0,1,18.682,16.95c3.455,13.341-4.659,22.2-8.841,25.636-8.409,6.909-17.8,6.818-23.182,7.068a123.81635,123.81635,0,0,1-15.091-.432c-.568-.045-6.136-.25-1.932-3.068s4.682-6.5,4.727-9.545c-.067-6.659-4.408-14.768,1.615-24.746C15.49186-.52271,27.35486-.45471,31.92286.25029Z"
                                style="fill: white; fill-rule: evenodd"
                            />
                            <g>
                                <path
                                    d="M33.53686,16.47828a1.2,1.2,0,0,1,.8.273,1.318,1.318,0,0,1,.432.8h-2.55a1.2,1.2,0,0,1,.477-.773A1.364,1.364,0,0,1,33.53686,16.47828Zm2.159,1.409a2.273,2.273,0,0,0-.659-1.591,2.045,2.045,0,0,0-1.5-.614,2.159,2.159,0,0,0-1.591.636,2.25,2.25,0,0,0-.636,1.659,2.227,2.227,0,0,0,.636,1.659,2.114,2.114,0,0,0,1.545.636,2.3,2.3,0,0,0,1.273-.341,2.04594,2.04594,0,0,0,.8-1.023l-.8-.182a1.3,1.3,0,0,1-1.273.75,1.227,1.227,0,0,1-.864-.341,1.455,1.455,0,0,1-.432-.932h3.474Zm-7.977-1.409a1.477,1.477,0,0,0-.591-.591,1.159,1.159,0,0,0-.682-.2,1.523,1.523,0,0,0-1.3.614v-.5h-.909v4.364h.909v-2.165a1.7,1.7,0,0,1,.323-1.089.864.864,0,0,1,.75-.386.75.75,0,0,1,.591.3,1.01,1.01,0,0,1,.182.75v2.591h.909v-2.166a1.7,1.7,0,0,1,.227-1.091.886.886,0,0,1,.773-.386.75.75,0,0,1,.636.3,1.045,1.045,0,0,1,.273.75v2.591h.886v-2.777a1.682,1.682,0,0,0-.477-1.227,1.386,1.386,0,0,0-1.068-.477,1.613,1.613,0,0,0-1.455.8Zm-4.477,3.682v-4.364h-.909v4.364Zm-.455-6.25a.568.568,0,0,0-.59054.54453q-.00046.01173-.00046.02347a.545.545,0,0,0,.159.409.606.606,0,0,0,.818,0,.545.545,0,0,0,.159-.409.568.568,0,0,0-.563-.567Zm-1.409,6.25v-5.682h-.909v5.682Z"
                                    style="fill: #50b0a7; fill-rule: evenodd"
                                />
                                <g>
                                    <path
                                        d="M19.19686,28.09629l2.159.545a4.114,4.114,0,0,1-1.614,2.409,4.893,4.893,0,0,1-6.182-.409,4.83,4.83,0,0,1,0-6.727,4.659,4.659,0,0,1,3.364-1.318,4.545,4.545,0,0,1,2.773.886,4.455,4.455,0,0,1,1.659,2.364l-2.25.636a2.06,2.06,0,0,0-2.182-1.75,2.364,2.364,0,0,0-1.773.7,2.826,2.826,0,0,0,0,3.682,2.364,2.364,0,0,0,1.773.7A2.232,2.232,0,0,0,19.19686,28.09629Z"
                                        style="fill: #50b0a7"
                                    />
                                    <path
                                        d="M28.35486,22.79629l-.114,2.25h-.522q-3.159,0-3.159,3.8v2.841h-2.341v-8.818h2.341v1.606a3.689,3.689,0,0,1,3.159-1.727,2.06784,2.06784,0,0,1,.636.045Z"
                                        style="fill: #50b0a7"
                                    />
                                    <path
                                        d="M42.69687,23.59228a3.477,3.477,0,0,1,1,2.568v5.523h-2.323v-5.045a1.932,1.932,0,0,0-.5-1.386,1.636,1.636,0,0,0-1.25-.523,1.818,1.818,0,0,0-1.5.682,3.189,3.189,0,0,0-.545,2.023v4.25h-2.318v-5.045a1.932,1.932,0,0,0-.5-1.386,1.63607,1.63607,0,0,0-1.25-.523,1.8,1.8,0,0,0-1.477.682,3.33505,3.33505,0,0,0-.523,2.045v4.25h-2.337v-8.843h2.341v.727a3.56394,3.56394,0,0,1,2.636-1,3.789,3.789,0,0,1,2.909,1.455,3.9,3.9,0,0,1,3.227-1.455A3.318,3.318,0,0,1,42.69687,23.59228Z"
                                        style="fill: #50b0a7"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            <span
                class="btnSpan mt-2"
                style="font-size: 14px; letter-spacing: 0px; font-weight: 400"
                :style="{ color: 'var(--v-gray1-base' }"
            >
                Lime CRM
            </span>
        </v-btn>
    </div>
</template>
<script>
    import config from '@/app/config';
    import { isValidSwedishSSN } from '@/utils/SocialSecurityValidationSwedish';
    import { mapState } from 'vuex';

    export default {
        props: ['socialSecurity', 'order'],
        data() {
            return {
                cfg: config,
                personNr: '',
                validSocial: true,
                icon: 'mdi-server',
                file: 'AGA.App.exe',
                selectedIntegrationLink: {},
            };
        },
        computed: {
            ...mapState({
                selectedCase: (state) => state.Cases.clientNowOnCard,
                currentUserData: (state) => state.Users.currentUserData,
            }),
        },
        watch: {
            '$route.query': {
                handler(pop) {
                    if (pop.pop !== undefined && pop.pop !== null && pop.pop !== 'null' && pop.pop !== 'NONE') {
                        const self = this;
                        setTimeout(() => {
                            self.integrationExternal(pop.pop);
                            this.$router.push(this.$route.path);
                        }, 1000);
                    }
                },
                deep: true,
                immediate: true,
            },
            selectedCase(val) {
                this.personNr = val.data.socialSecurityNumber;
                if (this.personNr) {
                    if (!this.isValidSwedishSSN(this.personNr)) {
                        this.validSocial = false;
                        this.icon = 'mdi-server-remove';
                    } else {
                        this.validSocial = true;
                    }
                } else {
                    this.validSocial = false;
                    this.icon = 'mdi-server-remove';
                }
            },
            currentUserData: {
                deep: true,
                handler() {
                    this.selectedIntegrationLink = this.findIntegrationLink();
                },
            },
        },
        created() {
            this.selectedIntegrationLink = this.findIntegrationLink();
        },
        methods: {
            isValidSwedishSSN,
            async integrationExternal(url) {
                this.personNr = this.$props.socialSecurity || this.personNr;
                if (this.validSocial === false) {
                    this.$toasted.show(this.$t('integrationLinks.notValidSsn'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    return;
                }
                if (url === 'mrm') {
                    const res = await this.$store.dispatch('Cases/soapGetUserLink', { personNr: this.personNr }); // 196401121014
                    url = res.URL;
                    this.goToUrl(url);
                } else {
                    // important to await this promise cause window needs to be in focus to copy - Teo
                    await this.copyToClipboard(this.personNr);
                    window.location.href = 'TloSwyx:openprogram,' + this.file;
                }
            },
            async goToUrl(url) {
                window.open(url, '_blank');
            },
            async copyToClipboard(str) {
                str = str.slice(2);
                const b = '-';
                str = [str.slice(0, 6), b, str.slice(6)].join('');
                const isIE = /* @cc_on!@ */ false || !!document.documentMode;
                if (isIE) {
                    this.copyForIE(str);
                } else {
                    await this.normalCopy(str);
                }
            },
            copyForIE(str) {
                const el = document.createElement('textarea');
                el.value = str;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.append(el);
                el.select();
                document.execCommand('copy');
                el.remove();
            },
            async normalCopy(str) {
                return new Promise((resolve, reject) => {
                    navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                        if (result.state == 'granted' || result.state == 'prompt') {
                            navigator.clipboard.writeText(str).then(
                                function () {
                                    // success
                                    resolve();
                                },
                                function (error) {
                                    // error
                                    console.error('clipping failed', error);
                                    reject();
                                }
                            );
                        }
                    });
                });
            },
            findIntegrationLink() {
                let returnValue = {};
                for (const el of this.cfg.customerParams.integrationLinks.links) {
                    if (el.name == 'ÄGA') {
                        returnValue = el;
                    }
                }
                if (returnValue === {}) {
                    returnValue = this.cfg.customerParams.integrationLinks.links[0];
                }
                return returnValue;
            },
        },
    };
</script>
<style lang="scss" scoped></style>
<i18n lang="json">
{
    "en": {
        "integrationLinks": {
            "notValidSsn": "The social security number of this member is not valid."
        }
    },
    "sv": {
        "integrationLinks": {
            "notValidSsn": "Personnumret på denna medlem är inte ett giltigt personnummer."
        }
    }
}
</i18n>
