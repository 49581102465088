module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "gotToParentCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to parent case"])},
        "gotToSourceParentCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to origin parent case"])}
      },
      "sv": {
        "gotToParentCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till förälderärende"])},
        "gotToSourceParentCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till ursprungligt förälderärende"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"gotToParentCase":"Go to parent case","gotToSourceParentCase":"Go to origin parent case"},"sv":{"gotToParentCase":"Gå till förälderärende","gotToSourceParentCase":"Gå till ursprungligt förälderärende"}}')
  delete Component.options._Ctor
  
}
