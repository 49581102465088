<template>
    <div :class="{ 'disabled-button': isButtonDisabled }" :style="disabledStyle">
        <standard-select
            v-model="selectedTemplate"
            class="template-select"
            useSlot
            resetOnClick
            single
            :disabled="isButtonDisabled"
            :style="disabledStyle"
            :items="newTemplateOptions"
            @click="checkQueues"
            @change="([item]) => handleTemplateChange(item)"
        >
            <v-btn icon>
                <v-icon class="icon">mdi-text-box-plus-outline</v-icon>
            </v-btn>
        </standard-select>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { templateTypes } from '@/enums/templates.enums';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selectedTemplate: null,
                templateOptions: [],
                newTemplateOptions: [],
                chatOpen: true,
                chat: {},
            };
        },

        computed: {
            ...mapState('QueueManager', ['allQueues']),
            ...mapState('Cases', ['selectedCase']),
            ...mapState('Admin', ['chatTemplates']),

            disabledStyle() {
                return this.disabled ? { pointerEvents: 'none' } : {};
            },

            currentQueueLanguage() {
                const currentQueueId = this.selectedCase?.case?.queueId;
                return this.allQueues.find((q) => q.ID === currentQueueId)?.TemplateLanguage ?? null;
            },

            isButtonDisabled() {
                return this.disabled || !this.newTemplateOptions.length;
            },
        },

        created() {
            this.fetchChatTemplates();
        },

        methods: {
            ...mapActions('QueueManager', ['getAllQueues']),
            ...mapActions('Admin', ['getChatTemplates']),

            async fetchChatTemplates() {
                try {
                    const filters = {
                        languages: [this.currentQueueLanguage],
                        categoryIds: [templateTypes.CHAT],
                        isActive: true,
                    };
                    await this.getChatTemplates(filters);
                    this.updateTemplateOptions(this.chatTemplates);
                } catch (error) {
                    this.$toasted.error(this.$t('ChatTemplates.couldNotFetchTemplates'), {
                        type: 'error',
                    });
                }
            },

            checkQueues(newQueues) {
                if (!newQueues.length) {
                    this.getAllQueues();
                }
                this.fetchChatTemplates();
            },

            handleTemplateChange(item) {
                this.$emit('template-selected', item);
            },

            updateTemplateOptions(chatTemplates) {
                this.newTemplateOptions = chatTemplates.map((template) => ({
                    value: template.id,
                    text: template.name,
                    content: template.content,
                    active: template.isActive,
                }));

                // If no templates are available, show a no templates available message
                if (this.newTemplateOptions.length === 0) {
                    this.newTemplateOptions = [
                        {
                            value: null,
                            icon: 'mdi mdi-alert-circle-outline',
                            text: this.$t('ChatTemplates.noTemplatesAvailable'),
                        },
                    ];
                }
            },
        },
    };
</script>

<style scoped>
    .icon {
        font-size: 20px;
    }

    .circle-button.v-btn {
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        border-radius: 50% !important;
        padding: 10px !important;
        width: 60%;
        height: 60%;
        margin-left: 5px;
    }

    .circle-button.v-btn:hover {
        background-color: var(--v-gray3-base) !important;
        color: white !important;
        border: 1px solid var(--v-gray3-base) !important;
    }

    .disabled-button {
        opacity: 0.5;
    }
</style>

<i18n lang="json">
{
    "en": {
        "ChatTemplates": {
            "couldNotFetchTemplates": "Could not fetch templates",
            "noTemplatesAvailable": "No templates available"
        }
    },
    "sv": {
        "ChatTemplates": {
            "couldNotFetchTemplates": "Kunde inte hämta mallar",
            "noTemplatesAvailable": "Inga mallar tillgängliga"
        }
    }
}
</i18n>
