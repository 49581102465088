import axios from 'axios';

function searchClients(payload) {
    // '+' symbol == empty space in url-queries, thus we must do this replace to maintain it (phone numbers can have + symbols) -Teo 2022-06-14
    const formattedPayload = payload.replace('+', '%2B');
    return axios.get(`/clients?search=${formattedPayload}`);
}

function getAllClientsData(payload) {
    return axios.get(
        `/clients/list/alldata?limit=${payload.limitOffSet || 0},${payload.limitAmount || 0}&search=${
            payload.search || 0
        }&fields=${payload.fields}`
    );
}

function getClientsContacts(payload) {
    return axios.get(`/clients/${payload}`);
}

function addNewClient(payload) {
    return axios.post(`/clients`, payload);
}

function addNewContact(payload) {
    return axios.post(`/contacts/add/new`, payload);
}

function deleteClient(payload) {
    return axios.delete(`/clients/${payload}`);
}

function getClientTemplate() {
    return axios.get(`/clients/manifest/template`);
}

function getContactTemplate() {
    return axios.get(`/clients/contact/template`);
}

function updateContactInfo(payload) {
    return axios.put(`/contact/${payload.id}`, payload);
}

function setDefaultCreateClient(payload) {
    return axios.post(`/clients/defaultData`, payload);
}
function getDefaultCreateClient() {
    return axios.get(`/clients/defaultData/all`);
}

function getWallboardStatistics() {
    return axios.get(`/wallboard`);
}

function getWallboardUsers(limit) {
    return axios.get(`/wallboard/users?limit=${limit}`);
}
function getWallboardSettings() {
    return axios.get(`/wallboard/settings`);
}
function storeWallboardCard(payload) {
    return axios.post(`/wallboard/cards`, payload);
}
function updateWallboardCard(payload) {
    return axios.put(`/wallboard/cards/${payload.cardId}`, payload);
}

function updateWallboardOrder(payload) {
    return axios.put(`/wallboard/cards/order`, payload);
}
function deleteWallboardCard(id) {
    return axios.delete(`/wallboard/cards/${id}`);
}
function addQueueToWallboardCard(payload) {
    return axios.post(`/wallboard/cards/${payload.cardId}/queues/${payload.queueId}`);
}
function removeQueueFromWallboardCard(payload) {
    return axios.delete(`/wallboard/cards/${payload.cardId}/queues/${payload.queueId}`);
}
function updateCelebration(payload) {
    return axios.put(`/wallboard/cards/${payload.cardId}/celebrations`, payload);
}

export default {
    searchClients,
    getAllClientsData,
    getClientsContacts,
    addNewClient,
    addNewContact,
    deleteClient,
    getClientTemplate,
    getContactTemplate,
    updateContactInfo,
    setDefaultCreateClient,
    getDefaultCreateClient,
    getWallboardStatistics,
    getWallboardUsers,
    getWallboardSettings,
    storeWallboardCard,
    updateWallboardCard,
    updateWallboardOrder,
    deleteWallboardCard,
    addQueueToWallboardCard,
    removeQueueFromWallboardCard,
    updateCelebration,
};
