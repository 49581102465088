import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded pa-2",staticStyle:{"background":"var(--v-gray3-base)","min-width":"364px"}},[_c('h3',{staticClass:"mb-1",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.$t('activeCasesInboxModule.activeCases'))+" ("+_vm._s(_vm.activeUserCases.length || 0)+") ")]),_c(VDivider,{staticClass:"mb-2"}),(_vm.activeUserCases.length > 0)?_c('div',{staticStyle:{"height":"200px","overflow-x":"hidden","overflow-y":"scroll"}},_vm._l((_vm.activeUserCases),function(item,index){return _c('div',{key:index,ref:"target",refInFor:true,staticClass:"rounded pa-3 softShadow d-flex align-center justify-space-between",class:index !== _vm.activeUserCases.length - 1 ? 'mb-2' : '',style:({
                backgroundColor: _vm.hover === index ? 'var(--v-gray3-lighten)' : 'white',
                cursor: 'pointer',
                position: 'relative',
            }),on:{"click":function($event){return _vm.selectCase(item.CaseID)},"mouseover":function($event){_vm.hover = index},"mouseleave":function($event){_vm.hover = -1}}},[_c('div',{staticClass:"d-flex align-center"},[_c('ActivityLine',{attrs:{"item":item,"small":""}}),_c('InternalType',{attrs:{"item":{ internalType: item.InternalType, unreadMessages: item.UnreadMessages }}}),_c('div',{staticClass:"ml-4 text-truncate d-flex flex-column"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('h4',_vm._g(_vm._b({staticClass:"text-truncate m2-1",staticStyle:{"font-weight":"400","max-width":"130px","font-size":"14px"}},'h4',attrs,false),on),[_vm._v(" "+_vm._s(item.Topic)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Topic))])]),_c('span',{staticStyle:{"font-size":"12px","line-height":"1"},style:({ color: 'var(--v-gray1-base' })},[_vm._v(" "+_vm._s(_vm.$t('global.topic'))+" ")])],1)],1),_c('div',{staticClass:"justify-center d-flex align-center"},[_c('QueueChip',{attrs:{"item":item,"small":""}}),_c('StatusBar',{staticClass:"ml-2 smooth-transition",attrs:{"state":{ value: item.Status },"rounded":true}})],1)])}),0):_vm._e(),(_vm.activeUserCases.length === 0)?_c('div',{staticClass:"justify-center d-flex flex-column align-center"},[_c(VIcon,{attrs:{"color":"primary","size":"10rem"}},[_vm._v("mdi-google-downasaur")]),_c('h3',{staticStyle:{"font-weight":"400"}},[_vm._v(_vm._s(_vm.$t('activeCasesInboxModule.noActiveCases')))])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }