module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "emailForm": {
          "selFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from list"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
          "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
          "dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject: "])},
          "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data."])},
          "flipEditMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle text editing mode."])},
          "chSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change signature."])},
          "addTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add text template."])},
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach file(s)."])},
          "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be filled in."])},
          "validFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address."])},
          "blindCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blind copy"])},
          "ccAndBcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy and blind copy"])},
          "dropFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop files here"])},
          "noSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No signature"])}
        }
      },
      "sv": {
        "emailForm": {
          "selFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj från lista"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till"])},
          "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ring"])},
          "dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopia"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ämne: "])},
          "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget data."])},
          "flipEditMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla textredigeringsläge."])},
          "chSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra signatur."])},
          "addTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till textmall."])},
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bifoga fil(er)."])},
          "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält behöver vara ifyllt."])},
          "validFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vänligen ange en giltig mailadress."])},
          "blindCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemlig kopia"])},
          "ccAndBcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopia och hemlig kopia"])},
          "dropFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Släpp filer här"])},
          "noSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen signatur"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"emailForm":{"selFromList":"Select from list","to":"To","call":"Call","dialog":"Dialog","copy":"Copy","subject":"Subject: ","noData":"No data.","flipEditMode":"Toggle text editing mode.","chSignature":"Change signature.","addTemplate":"Add text template.","attachFiles":"Attach file(s).","fillField":"This field must be filled in.","validFormat":"Please enter a valid email address.","blindCopy":"Blind copy","ccAndBcc":"Copy and blind copy","dropFiles":"Drop files here","noSignature":"No signature"}},"sv":{"emailForm":{"selFromList":"Välj från lista","to":"Till","call":"Ring","dialog":"Dialog","copy":"Kopia","subject":"Ämne: ","noData":"Inget data.","flipEditMode":"Växla textredigeringsläge.","chSignature":"Ändra signatur.","addTemplate":"Lägg till textmall.","attachFiles":"Bifoga fil(er).","fillField":"Detta fält behöver vara ifyllt.","validFormat":"Vänligen ange en giltig mailadress.","blindCopy":"Hemlig kopia","ccAndBcc":"Kopia och hemlig kopia","dropFiles":"Släpp filer här","noSignature":"Ingen signatur"}}}')
  delete Component.options._Ctor
  
}
