import queueManager from '@/api/queueManager/queueManager';
import { getSortingByObjectKey } from '@/utils';
import i18n from '../../i18n';

// State object
const state = {
    allQueues: [],
    userQueues: [],
    usersQueues: [],
    selectedQueue: { tab: 0, id: '' },
    getAllChannels: [],
    allChannelsByQueue: [],
    allAgents: [],
    allAgentsInQueue: [],
    allGroupsInQueue: [],
    activeCallQueues: [],
    emailToReadArray: [],
    globalSettings: [],
};
// Getter functions
const getters = {
    getAllQueues(state) {
        return state.allQueues;
    },
    getChannels(state) {
        return state.getAllChannels;
    },
    getSelectedQueue(state) {
        return state.selectedQueue;
    },
};
// Actions
const actions = {
    setQueuesOrder({ commit }, newAllQueues) {
        return new Promise((resolve, reject) => {
            queueManager
                .setQueuesOrder(newAllQueues)
                .then((res) => {
                    commit('SET_QUEUES_ORDER', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    setSocialSettings({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .setSocialSettings(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getSocialSettings({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getSocialSettings(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getPopupTriggerInfo({ commit }, payload) {
        // Used in informationPopUp
        return new Promise((resolve, reject) => {
            queueManager
                .getPopupTriggerInfo(payload.channelId)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    savePopupTriggerInfo({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .savePopupTriggerInfo(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    searchKeys({ commit }) {
        // Used in informationPopUp
        return new Promise((resolve, reject) => {
            queueManager
                .searchKeys()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setSelectedQueue({ commit }, payload) {
        commit('SET_SELECTED_QUEUE', payload);
    },
    // Channels
    getSpecificChannel({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getSpecificChannel(payload)
                .then((res) => {
                    dispatch('Overflow/setOverflowActive', res.data.OverflowActive, { root: true });
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getExternalSurveyUrl({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getExternalSurveyUrl(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateChannel({ dispatch, rootState }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .updateChannel(payload)
                .then(async (res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });

                    dispatch('getAllChannels', payload.queueId);
                    await dispatch('getQueueIdentifierByQueueId', { type: payload.type, queueId: payload.queueId }); // väntar på denna då det är viktigt att den uppdateras
                    if (payload.channelType === 'call' && rootState.System.userSettings.userModal.showQueues.active) {
                        dispatch('getActiveCallQueues', payload.channelType);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    assignIdentifiersToQueue({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .assignIdentifiersToQueue(payload)
                .then((res) => {
                    if (res.status === 200) {
                        this._vm.$toasted.show(res.data, {
                            icon: 'mdi-content-save',
                            type: 'success',
                        });
                    } else {
                        this._vm.$toasted.show(res.data, {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                    }
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    findIdentifiers({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .findIdentifiers(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setSpecificChannelValue({ dispatch, rootState }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .setSpecificChannelValue(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    if (payload.channelType === 'call' && rootState.System.userSettings.userModal.showQueues.active) {
                        dispatch('getActiveCallQueues', payload.channelType);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    deleteIdentifiers({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .deleteIdentifiers(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.deleted'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getAllChannels({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getAllChannels(payload)
                .then((res) => {
                    commit('SET_ALL_CHANNELS', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getChannelsByQueues({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getChannelsByQueues(payload)
                .then((res) => {
                    // commit('SET_ALL_CHANNELS', res.data);
                    commit('SET_SELECTED_QUEUE', payload.queueIds);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getActiveCallQueues({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueuesByActiveChannelType(payload)
                .then((res) => {
                    commit('SET_ACTIVE_CALL_QUEUES', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.couldNotGetActiveCallQueues'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    explicitlyGetAllChannelsByQueue({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .explicitlyGetAllChannelsByQueue(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getLiveQueueManagerStatistics({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getLiveQueueManagerStatistics(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    // Queues
    getAllQueues({ commit }) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueues({})
                .then((res) => {
                    commit('SET_ALL_QUEUES', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getQueues({}, filters) {
        try {
            const res = await queueManager.getQueues(filters);
            return res.data;
        } catch (error) {
            console.error('QueueManager Store -> getQueues -> Error in API', error, new Date());
            return null;
        }
    },

    getAllQueuesWithAllInfo({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getAllQueuesWithAllInfo(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getUsersQueuesWithGroups({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getUsersQueuesWithGroups(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getQueueOnIdentifier({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueueOnIdentifier(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async getAllQueuesChosenUser({ commit }, userId) {
        const res = await queueManager.getAllQueuesChosenUser(userId);
        commit('SET_USER_QUEUES', res.data);
        return res.data;
    },

    async getAllQueuesByLoggedInUser({ commit }) {
        const res = await queueManager.getAllQueuesByLoggedInUser();
        commit('SET_USER_QUEUES', res.data);
        return res.data;
    },

    getQueuesByUserId({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueuesByUserId(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getAllQueuesByLoggedInUserAdministrative({ commit }) {
        return new Promise((resolve, reject) => {
            queueManager
                .getAllQueuesByLoggedInUserAdministrative()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getQueueTooltipInfo({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueueTooltipInfo(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getQueueCDR({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueueCDR(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getQueuesInfo({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueuesInfo(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getSpecificQueue({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .queueById(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getQueueSettingsById({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueueSettingsById(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    createQueue({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .createQueue(payload)
                .then(async (res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    await dispatch('getAllQueues');
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    saveQueue({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .saveQueue(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.updated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    dispatch('getAllQueues');
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    deleteQueue({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .deleteQueue(payload)
                .then(async (res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.deleted2'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    await dispatch('getAllQueues');
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    // AGENTS
    getAgentsInQueue({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getAgentsInQueue(payload)
                .then((res) => {
                    commit('SET_AGENTS_IN_QUEUE', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getAllAgents({ commit }) {
        return new Promise((resolve, reject) => {
            queueManager
                .getAllAgents()
                .then((res) => {
                    commit('SET_ALL_AGENTS', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getCompanyGroupsInQueue({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getCompanyGroupsInQueue(payload)
                .then((res) => {
                    commit('SET_GROUPS_IN_QUEUE', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    deleteCompanyGroupFromQueue({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .deleteCompanyGroupFromQueue(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getActiveChannelsInQueue({ commit }, queueId) {
        return new Promise((resolve, reject) => {
            queueManager
                .getActiveChannelsInQueue(queueId)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    updateGroupChannelsInQueue({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .updateGroupChannelsInQueue(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    loginLogoutToQueue({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .loginLogoutToQueue(payload)
                .then((res) => {
                    this._vm.$toasted.show(res.data.message, {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });

                    dispatch('getAllQueuesByLoggedInUser');
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    channelLoginLogoutInQueue({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .channelLoginLogoutInQueue(payload)
                .then((res) => {
                    this._vm.$toasted.show(res.data.message, {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    addGroupsToQueue({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .addGroupsToQueue(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    updateAgents({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .updateAgents(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.updated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getQueueIdentifierByQueueId({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueueIdentifierByQueueId(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getQueueIdentifierByQueueIdIntegration({}, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueueIdentifierByQueueIdIntegration(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    async getLoggedInQueuesByUserId({}, userId) {
        try {
            const res = await queueManager.getLoggedInQueuesByUserId(userId);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                icon: 'cancel',
                type: 'error',
            });
            return null;
        }
    },

    // wallboard stuff

    getQueuesOverviewData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueuesOverviewData(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getEmailToReadArray({ commit }) {
        return new Promise((resolve, reject) => {
            queueManager
                .getEmailToReadArray()
                .then((res) => {
                    resolve(res.data);
                    commit('SET_EMAIL_TO_READ_ARRAY', res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getQueueDefaultSignature(_, id) {
        return new Promise((resolve, reject) => {
            queueManager
                .getQueueDefaultSignature(id)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getGlobalSettings({ commit }) {
        try {
            const res = await queueManager.getGlobalSettings();
            commit('SET_GLOBAL_SETTINGS', res.data);
            return res.data;
        } catch (error) {
            return null;
        }
    },
    updateGlobalSettings(_, payload) {
        return new Promise((resolve, reject) => {
            queueManager
                .updateGlobalSettings(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setUserQueues({ commit }, data) {
        commit('SET_USER_QUEUES', data);
    },
    async getDisplayNumbers() {
        try {
            const res = await queueManager.getDisplayNumbers();
            return res.data || [];
        } catch (error) {
            this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                icon: 'cancel',
                type: 'error',
            });
            return [];
        }
    },
    async getAudioFilesDeletion({}, payload) {
        try {
            const res = await queueManager.getAudioFilesDeletion(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                icon: 'cancel',
                type: 'error',
            });
            return error;
        }
    },
    setAudioFilesDeletion({}, payload) {
        try {
            return queueManager.setAudioFilesDeletion(payload);
        } catch (error) {
            this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), {
                icon: 'cancel',
                type: 'error',
            });
            return error;
        }
    },
};

// Mutations
const mutations = {
    SET_QUEUES_ORDER(state, newAllQueues) {
        state.allQueues = newAllQueues;
    },
    SET_ALL_QUEUES(state, data) {
        state.allQueues = data;
    },
    SET_USER_QUEUES(state, data) {
        // looking over this logic maybe it could be done in backend instead - Teo 2022-05-04
        const sortByName = getSortingByObjectKey('Name');
        const sortByPrio = getSortingByObjectKey('Prio');

        if (!data || !data.length) {
            state.userQueues = [];
            return;
        }

        // sort stuff in this object by name
        state.userQueues = data
            .map((item) => {
                return {
                    ...item,
                    Groups: item.Groups ? item.Groups.sort(sortByPrio) : [],
                };
            })
            .sort(sortByName);
    },
    SET_ALL_CHANNELS(state, data) {
        state.getAllChannels = data;
    },
    SET_AGENTS_IN_QUEUE(state, data) {
        state.allAgentsInQueue = [];
        state.allAgentsInQueue = data;
    },
    SET_GROUPS_IN_QUEUE(state, data) {
        state.allGroupsInQueue = data;
    },
    SET_ALL_AGENTS(state, data) {
        state.allAgents = data;
    },
    SET_SELECTED_QUEUE(state, data) {
        state.selectedQueue = data;
    },
    SET_ACTIVE_CALL_QUEUES(state, data) {
        state.activeCallQueues = data;
    },
    SET_EMAIL_TO_READ_ARRAY(state, data) {
        state.emailToReadArray = data;
    },
    SET_GLOBAL_SETTINGS(state, data) {
        state.globalSettings = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
