import social from '@/api/social/social';
import { httpStatusCode } from '@/enums/response.enums';
import i18n from '../../i18n';

const state = {
    actionLoader: false,
    social: {
        facebook: {
            id: 'facebook',
            name: 'Facebook',
            icon: 'mdi-facebook',
            color: '#3b5998',
        },
        facebookmessenger: {
            id: 'facebookmessenger',
            name: 'Facebook Messenger',
            icon: 'mdi-facebook-messenger',
            color: '#0084ff',
        },
        twitter: {
            id: 'twitter',
            name: 'Twitter',
            icon: 'mdi-twitter',
            color: '#1da1f2',
        },
        instagrambusiness: {
            id: 'instagrambusiness',
            name: 'Instagram',
            icon: 'mdi-instagram',
            color: '#c32aa3',
        },
        linkedinbusiness: {
            id: 'linkedin',
            name: 'LinkedIn',
            icon: 'mdi-linkedin',
            color: '#2867B2',
        },
        youtube: {
            id: 'youtube',
            name: 'YouTube',
            icon: 'mdi-youtube',
            color: '#FF0000',
        },
        snapchat: {
            id: 'snapchat',
            name: 'Snapchat',
            icon: 'mdi-snapchat',
            color: '#FFFC00',
        },
        pinterest: {
            id: 'pinterest',
            name: 'Pinterest',
            icon: 'mdi-pinterest',
            color: '#E60023',
        },
        tiktok: {
            id: 'tiktok',
            name: 'TikTok',
            icon: 'mdi-tiktok',
            color: '#000000',
        },
        reddit: {
            id: 'reddit',
            name: 'Reddit',
            icon: 'mdi-reddit',
            color: '#FF4500',
        },
        tumblr: {
            id: 'tumblr',
            name: 'Tumblr',
            icon: 'mdi-tumblr',
            color: '#35465c',
        },
    },
};
const actions = {
    async hideComment({ dispatch, rootState }, payload) {
        try {
            dispatch('setActionLoader', true);
            const result = await social.updateComment(payload.itemId, payload.data);
            if (result.status !== 200) {
                throw new Error('Hide failed');
            }
            this._vm.$toasted.show(i18n.t('socialRequestResponses.hideSuccess'), {
                icon: 'mdi-check',
                type: 'success',
            });
            await dispatch(
                'Cases/getCaseContent',
                { caseId: rootState.Cases.selectedCase.case.caseId, load: false },
                { root: true }
            );
            dispatch('setActionLoader', false);
        } catch {
            this._vm.$toasted.show(i18n.t('socialRequestResponses.hideFailed'), {
                icon: 'mdi-cancel',
                type: 'error',
            });
            dispatch('setActionLoader', false);
        }
    },

    async showComment({ dispatch, rootState }, payload) {
        try {
            dispatch('setActionLoader', true);
            const result = await social.updateComment(payload.itemId, payload.data);
            if (result.status !== 200) {
                throw new Error('Show failed');
            }
            this._vm.$toasted.show(i18n.t('socialRequestResponses.showSuccess'), {
                icon: 'mdi-check',
                type: 'success',
            });
            await dispatch(
                'Cases/getCaseContent',
                { caseId: rootState.Cases.selectedCase.case.caseId, load: false },
                { root: true }
            );
            dispatch('setActionLoader', false);
        } catch {
            this._vm.$toasted.show(i18n.t('socialRequestResponses.showFailed'), {
                icon: 'mdi-cancel',
                type: 'error',
            });
            dispatch('setActionLoader', false);
        }
    },

    setActionLoader({ commit }, payload) {
        commit('SET_ACTION_LOADER', payload);
    },

    publish(_, payload) {
        return new Promise((resolve) => {
            social
                .publish(payload)
                .then((res) => {
                    if (res.status === httpStatusCode.CREATED || res.status === httpStatusCode.OK) {
                        this._vm.$toasted.show(i18n.t('socialRequestResponses.ok'), {
                            icon: 'mdi-check',
                            type: 'success',
                        });
                        resolve({ status: httpStatusCode.CREATED });
                        return;
                    }
                    if (res.status === httpStatusCode.FORBIDDEN) {
                        this._vm.$toasted.show(i18n.t('socialRequestResponses.wrongDeleted'), {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                        resolve({ status: httpStatusCode.FORBIDDEN });
                        return;
                    }

                    if (res.status === httpStatusCode.BAD_REQUEST) {
                        this._vm.$toasted.show(i18n.t('socialRequestResponses.error'), {
                            icon: 'mdi-cancel',
                            type: 'warning',
                        });

                        resolve({ status: httpStatusCode.BAD_REQUEST });
                        return;
                    }

                    this._vm.$toasted.show(i18n.t('socialRequestResponses.error'), {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    resolve({ status: httpStatusCode.INTERNAL_SERVER_ERROR });
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('socialRequestResponses.error'), {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    resolve({ status: httpStatusCode.INTERNAL_SERVER_ERROR });
                });
        });
    },
};
const mutations = {
    SET_ACTION_LOADER(state, payload) {
        state.actionLoader = payload;
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
