import calendar from '@/api/calendar/calendar';
import i18n from '../../i18n';
// State object
const getDefaultState = () => {
    return {
        calendarEvents: [],
        calendarEventView: false,
        calendarEventModal: false,
        eventListModal: false,
        confirmModal: false,
        expandedView: false,
    };
};

const state = getDefaultState();
// Getter functions
const getters = {
    getCalendarData() {
        return state.calendarData;
    },
};
// Actions
const actions = {
    reset({ commit }) {
        commit('RESET_STATE');
    },
    setExpandedView({ commit }, payload) {
        commit('SET_EXPANDED_VIEW', payload);
    },
    getCalendarData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            calendar
                .getCalendarData(payload)
                .then((res) => {
                    commit('SET_CALENDAR', res.data);
                    resolve(res.data.result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getUserCreatedEvents({}, payload) {
        return new Promise((resolve, reject) => {
            calendar
                .getUserCreatedEvents(payload)
                .then((res) => {
                    resolve(res.data.result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    addCalendarEvent({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            calendar
                .addCalendarEvent(payload)
                .then((res) => {
                    if (res.data && res.data.result && res.data.result.affectedRows > 0) {
                        this._vm.$toasted.show(i18n.t('calendarStore.addedEvent'), {
                            icon: 'mdi-content-save',
                            type: 'success',
                            duration: 3000,
                        });
                        dispatch('getCalendarData', { user: payload.user, isExternal: payload.isExternal });
                    }
                    resolve(res.data.result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateCalendarEvent({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            calendar
                .updateCalendarEvent(payload)
                .then((res) => {
                    if (res.data && res.data.result && res.data.result.affectedRows > 0) {
                        this._vm.$toasted.show(i18n.t('calendarStore.changesSaved'), {
                            icon: 'mdi-content-save',
                            type: 'success',
                            duration: 3000,
                        });
                        dispatch('getCalendarData', { user: payload.user, isExternal: payload.isExternal });
                    }
                    resolve(res.data.result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteCalendarEvent({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            calendar
                .deleteCalendarEvent(payload)
                .then((res) => {
                    if (res.data && res.data.result && res.data.result.affectedRows > 0) {
                        this._vm.$toasted.show(i18n.t('calendarStore.deletedEvent'), {
                            icon: 'mdi-content-save',
                            type: 'success',
                            duration: 3000,
                        });
                        dispatch('getCalendarData', { user: payload.user, isExternal: payload.isExternal });
                    }
                    resolve(res.data.result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
const mutations = {
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
    },
    SET_EXPANDED_VIEW(state, data) {
        state.expandedView = data;
    },
    SET_CALENDAR(state, data) {
        state.calendarEvents = data.result;
    },
    CLEAR_CALENDAR_EVENTS(state) {
        state.calendarEvents = [];
    },
    SET_CALENDAR_EVENT_MODAL(state, data) {
        state.calendarEventModal = data;
    },
    SET_EVENT_LIST_MODAL(state, data) {
        state.eventListModal = data;
    },
    SET_CALENDAR_EVENT_VIEW(state, data) {
        state.calendarEventView = data;
    },
    SET_CONFIRM_MODAL(state, data) {
        state.confirmModal = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
