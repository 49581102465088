import { authInterceptorErrorHandler } from './helpers';
import { httpStatusCode } from '../enums/response.enums';
import store from '../store';

export default (axios) => {
    axios.interceptors.response.use(
        // biome-ignore lint/complexity/useArrowFunction: <I dont agree>
        function (response) {
            return response;
        },
        // biome-ignore lint/complexity/useArrowFunction: <I dont agree>
        async function (error) {
            const authPromise = authInterceptorErrorHandler(error, axios);
            if (!error) {
                return authPromise;
            }

            if (error.code === 'ERR_NETWORK') {
                store.dispatch('App/setIsDisconnected', true);
                throw new Error('Network error, please check your connection and try again.');
            }

            if (error.response.status === httpStatusCode.UNAUTHORIZED) {
                // if auth fails, we need to wait for auth to finish before we can finish the promise so components can react to the auth error
                // without this here components would not get the redispatch after a refresh and be stuck in a loading state or not show the correct data even though the request was successful after a refresh - Tim Aronsson 2023-05-15
                return authPromise;
            }
            // authInterceptor also handles network errors, so we need to wait for it to finish before we can reject the promise
            await authPromise;
            throw error;
        }
    );
};
