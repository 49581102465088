<template>
    <div v-if="item.status == 'fulfilled' && !isEdit && isItem">
        <Container :dialog="statistics" width="auto" :heading="getText(item.topic)" emitClose @close="() => close()">
            <div class="py-5">
                <Chart :color="item.color" class="mb-3" :dataPoint="item.dataPoint" />
            </div>
        </Container>

        <div style="position: relative" class="itemHolder">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <div
                        class="d-flex flex-column align-center justify-center item softShadow"
                        :style="{ backgroundColor: item.color }"
                        v-bind="attrs"
                        :class="getActiveClass"
                        v-on="on"
                        @mouseenter="graph(true)"
                        @mouseleave="graph(false)"
                        @click="toggleStatistics(true)"
                    >
                        <Transition :name="active ? 'fade' : 'slide-fade'" mode="out-in">
                            <div v-if="showData" :key="1" class="d-flex flex-row align-center">
                                <Transition name="blink" mode="out-in">
                                    <v-icon :key="item.data" size="14" color="white" class="px-2">
                                        {{ item.icon }}
                                    </v-icon>
                                </Transition>
                                <div class="d-flex flex-column align-center justify-center">
                                    <Transition name="slide-fade" mode="out-in">
                                        <div :key="item.data">
                                            <span :style="{ color: 'white' }" class="px-2">
                                                {{ sanitizeData }}
                                            </span>
                                        </div>
                                    </Transition>
                                </div>
                            </div>

                            <div v-if="showOpenText" :key="2" class="d-flex flex-row align-center">
                                <v-icon color="white" size="14" class="px-2">mdi-chart-line</v-icon>
                                <span style="font-size: 14px; color: white">
                                    {{ $t('dynamicStatistics.open') }}
                                </span>
                            </div>

                            <div v-if="statistics">
                                <v-icon color="white" size="14" class="px-2">mdi-chart-line</v-icon>
                                <span style="font-size: 14px; color: white">
                                    {{ $t('dynamicStatistics.selected') }}
                                </span>
                            </div>
                        </Transition>
                    </div>
                </template>
                <span>{{ getText(item.topic) }}</span>
            </v-tooltip>
        </div>
    </div>

    <div v-else-if="item.status == 'fulfilled' && isEdit && isItem" :class="'shake'">
        <PopupMenu
            :items="choices"
            :state="[]"
            :heading="$t('dynamicStatistics.changeItem')"
            returnObject
            width="auto"
            :numberOfItems="5"
            singleClick
            closeOnSelect
            infoBox
            @input="(v) => updateItem(v)"
        >
            <template #button>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <div
                            class="d-flex flex-row align-center justify-center item softShadow"
                            :style="{ backgroundColor: item.color }"
                            v-bind="attrs"
                            v-on="on"
                            @click="getDynamicChoices(item.id, 'change')"
                        >
                            <v-icon size="14" color="white" class="px-2">{{ item.icon }}</v-icon>
                            <span :style="{ color: 'white' }" class="px-2">{{ sanitizeData }}</span>
                        </div>
                    </template>
                    <span>{{ getText(item.topic) }}</span>
                </v-tooltip>
            </template>

            <template #default="{ item }">
                <div class="d-flex flex-row align-center" @mouseover="setItemData(item)">
                    <div v-if="item.Id == -1" class="d-flex flex-column align-center justify-center deleteItem mr-3">
                        <v-icon size="12" :color="'var(--v-gray4-base)'">{{ item.Icon }}</v-icon>
                    </div>
                    <div
                        v-else
                        class="d-flex flex-column align-center justify-center previewItem mr-3"
                        :style="{ backgroundColor: item.Color }"
                    >
                        <v-icon size="12" color="white">{{ item.Icon }}</v-icon>
                    </div>

                    <span class="text-truncate itemTruncate">{{ getText(item.Topic) }}</span>
                </div>
            </template>

            <template #infoBox>
                <div v-if="hoveredItem != null" class="d-flex flex-column" style="position: relative; width: 100%">
                    <div class="previewBox d-flex flex-row">
                        <div class="d-flex flex-row">
                            <v-icon size="20" :color="'var(--v-gray4-base)'" class="mr-3">mdi-information</v-icon>
                        </div>
                        <div>
                            <span style="font-size: 12px" :color="'var(--v-gray2-base)'">
                                {{ getText(hoveredItem.description) }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </PopupMenu>
    </div>

    <div v-else-if="item.status == 'rejected'">
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <div
                    class="d-flex flex-row align-center justify-center item softShadow"
                    :style="{ backgroundColor: 'var(--v-gray1-base)' }"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon size="14" color="white" class="px-2">mdi-alert</v-icon>
                    <span style="color: white" class="px-2">{{ $t('dynamicStatistics.errorLoad') }}</span>
                </div>
            </template>
            <span>{{ $t('dynamicStatistics.errorLoad') }}</span>
        </v-tooltip>
    </div>

    <div v-else-if="item.status == 'add' && !isEdit"></div>

    <div v-else :class="'shake'">
        <PopupMenu
            :items="choices"
            :state="[]"
            :heading="$t('dynamicStatistics.addNew')"
            returnObject
            width="auto"
            :numberOfItems="choices.length > 5 ? 5 : choices.length"
            singleClick
            closeOnSelect
            infoBox
            @input="(v) => addItem(v)"
        >
            <template #button>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <div
                            class="d-flex flex-row align-center justify-center item softShadow"
                            v-bind="attrs"
                            v-on="on"
                            @click="getDynamicChoices(indexVal, 'add')"
                        >
                            <v-icon size="18" :color="'var(--v-gray2-base)'" class="px-2">mdi-plus</v-icon>
                        </div>
                    </template>
                    <span>{{ $t('dynamicStatistics.addNew') }}</span>
                </v-tooltip>
            </template>

            <template #default="{ item }">
                <div class="d-flex flex-row align-center" @mouseover="setItemData(item)">
                    <div v-if="item.Id == -1" class="d-flex flex-column align-center justify-center deleteItem mr-3">
                        <v-icon size="12" :color="'var(--v-gray4-base)'">{{ item.Icon }}</v-icon>
                    </div>

                    <div
                        v-else
                        class="d-flex flex-column align-center justify-center previewItem mr-3"
                        :style="{ backgroundColor: item.Color }"
                    >
                        <v-icon size="12" color="white">{{ item.Icon }}</v-icon>
                    </div>

                    <span class="text-truncate itemTruncate">
                        {{ getText(item.Topic) }}
                    </span>
                </div>
            </template>

            <template #infoBox>
                <div v-if="hoveredItem != null" class="d-flex flex-column" style="position: relative; width: 100%">
                    <div class="previewBox d-flex flex-row">
                        <div class="d-flex flex-row">
                            <v-icon size="20" :color="'var(--v-gray4-base)'" class="mr-3">mdi-information</v-icon>
                        </div>
                        <div>
                            <span style="font-size: 12px" :color="'var(--v-gray2-base)'">
                                {{ getText(hoveredItem.description) }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </PopupMenu>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { getTextColorByBackground } from '@/utils/Colors';
    import { debouncer } from '@/utils';

    export default {
        components: {
            PopupMenu: () => import('@/components/Cases/Navigation/PopupMenu.vue'),
            Chart: () => import('@/components/DynamicStatistics/DynamicStatisticsChart.vue'),
            Container: () => import('@/components/Global/Display/Popup/BasicPopupContainer.vue'),
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
            indexVal: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                debounceItem: null,
                active: false,
                menu: false,
                statistics: false,
            };
        },

        watch: {
            statistics(value) {
                if (!value) {
                    this.active = false;
                }
            },
        },

        created() {
            this.debounceItem = debouncer(async (item) => {
                if (item?.Id != -1) {
                    this.$store.dispatch('DynamicStatistics/getItem', item.ID);
                }
            }, 250);
        },

        methods: {
            close() {
                this.statistics = false;
            },
            async toggleStatistics(value) {
                if (!this.item.filter) {
                    return;
                }
                await this.$store.dispatch('DynamicStatistics/getCurveStatistics', this.item.dataPoint);
                this.statistics = value;
                this.graph(false);
            },
            setItemData(item) {
                this.debounceItem(item);
            },
            getDynamicChoices(id, type) {
                this.$store.dispatch('DynamicStatistics/getDynamicChoices', { id, type });
            },
            updateItem(v) {
                this.$store.dispatch('DynamicStatistics/updateItem', { value: v, index: this.indexVal });
            },
            addItem(v) {
                this.$store.dispatch('DynamicStatistics/addItem', { value: v, index: this.indexVal });
            },
            getText(v) {
                return this.$t('dynamicStatistics.' + v);
            },

            async graph(value) {
                if (!this.active) {
                    this.active = true;
                }
                if (!value) {
                    this.active = false;
                }
            },

            getTextColorByBackground,
        },

        computed: {
            showOpenText() {
                return this.active && !this.statistics && this.item.filter;
            },
            showData() {
                return (!this.active && !this.statistics) || !this.item.filter;
            },
            sanitizeData() {
                // if its null or negative we set it to 0 cause socket connection have been faulty and all events have not come through
                if (this.item.data < 0 || this.item.data === null || this.item.data === undefined) {
                    return 0;
                }
                return this.item.data;
            },
            isEdit() {
                return this.subState == 'edit';
            },
            getActiveClass() {
                if (this.active) {
                    return 'active';
                }
                return 'deActive';
            },
            isItem() {
                if (this.item == undefined) {
                    return false;
                }
                return Object.entries(this.item).length > 0;
            },
            textColor() {
                return getTextColorByBackground(this.item.color);
            },
            ...mapState({
                data: (state) => state.DynamicStatistics.data,
                currentState: (state) => state.DynamicStatistics.currentState,
                subState: (state) => state.DynamicStatistics.subState.value,
                choices: (state) => state.DynamicStatistics.choices,
                hoveredItem: (state) => state.DynamicStatistics.hoveredItem,
                curveStatistics: (state) => state.DynamicStatistics.curveStatistics,
            }),
        },
    };
</script>
<style scoped>
    .itemTruncate {
        font-size: 12px;
        max-width: 150px;
    }

    .heading {
        font-size: 20px;
        font-weight: 400;
        color: var(--v-gray2-base);
        padding-bottom: 14px;
    }

    .active {
        position: absolute !important;

        z-index: 10 !important;
    }

    .itemHolder {
        height: 40px;
        min-width: 100px;
        max-width: 100px;
        min-height: 40px;
        max-height: 40px;
    }

    .deActive {
        transition: all 0.3s ease-in-out;
        position: absolute !important;
        left: 0px;
        top: 0px;
        z-index: 1 !important;
    }

    .previewBox {
        border-radius: 6px;
        padding: 10px;
        background-color: var(--v-gray3-base);
        font-size: 12px;
        min-width: 100%;
    }

    .deleteItem {
        border-radius: 6px;
        padding: 4px;
        background-color: transparent;
    }

    .previewItem {
        border-radius: 6px;
        padding: 4px;
        max-width: 300px;
    }

    .item {
        left: 0px;
        top: 0px;
        position: relative;
        border-radius: 6px;

        min-height: 40px;
        max-height: 40px;
        min-width: 100px;
        max-width: 100px;
        cursor: pointer;
        background-color: white;
        transform: scale(1);

        z-index: 1;
    }

    .scale {
        transition: all 0.4s ease-in-out;
    }

    .item:hover {
        background-color: var(--v-gray3-base);
    }

    .shake {
        animation: tilt-shaking 0.5s infinite;
    }

    @keyframes tilt-shaking {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(2deg);
        }
        50% {
            transform: rotate(0eg);
        }
        75% {
            transform: rotate(-2deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }

    .blink-enter-active {
        transition: all 0.25s ease-in-out;
    }

    .blink-leave-active {
        transition: all 0.5s ease-in-out;
    }

    .blink-enter {
        opacity: 0;
    }

    .blink-leave-to {
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all 0.25s ease-in-out;
    }

    .slide-fade-leave-active {
        transition: all 0.5s ease-in-out;
    }

    .slide-fade-enter {
        transform: translateY(-10px);
        opacity: 0;
    }

    .slide-fade-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }

    .fade-enter-active {
        transform: scale(1);
        transition: all 0.3s ease-in-out;
    }

    .fade-leave-active {
        transform: scale(1);
        transition: all 0.3s ease-in-out;
    }

    .fade-enter {
        transform: scale(0.8);
        opacity: 0;
    }

    .fade-leave-to {
        opacity: 0;
    }

    span {
        font-size: 12px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "dynamicStatistics": {
            "changeItem": "Change statistics",
            "addNew": "Add new statistics",
            "errorLoad": "Error",
            "removeStatistics": "Remove statistics",
            "open": "Open",
            "selected": "Selected",
            "totalCallsNotAnsweredDescription": "Total number of calls from the list which were not answered, based on the user filter settings.",
            "totalCasesNotAnsweredDescription": "Total number of cases from the list which were not answered, based on the user filter settings.",
            "totalChatNotAnsweredDescription": "Total number of chats from the list which were not answered, based on the user filter settings.",
            "totalCallbackTriesDescription": "Total number of callback tries from the list, based on the user filter settings.",
            "totalAnsweredCallsDescription": "Total number of answered calls from the list, based on the user filter settings.",
            "totalAnsweredCallbacksDescription": "Total number of answered callbacks from the list, based on the user filter settings.",
            "totalAnsweredChatsDescription": "Total number of answered chats from the list, based on the user filter settings.",
            "totalAnsweredCasesDescription": "Total number of answered cases from the list, based on the user filter settings.",
            "totalSnoozedCasesDescription": "Total number of snoozed cases from the list, based on the user filter settings.",
            "totalSnoozedCasesWithUnreadMessagesDescription": "Total number of snoozed cases with unread messages from the list, based on the user filter settings.",
            "totalSocialCasesWithUnreadMessagesDescription": "Total number of social cases with unread messages from the list, based on the user filter settings.",
            "totalSmsDescription": "Total number of sms from the list, based on the user filter settings.",
            "totalCallsInQueueDescription": "Total number of calls and callbacks currently in queue.",
            "todayAnsweredCallsDescription": "Answered calls today.",
            "todayCallsNotAnsweredDescription": "Unanswered calls today."
        }
    },
    "sv": {
        "dynamicStatistics": {
            "changeItem": "Ändra statistik",
            "addNew": "Lägg till ny statistik",
            "errorLoad": "Error",
            "removeStatistics": "Ta bort statistik",
            "open": "Öppna",
            "selected": "Vald",
            "totalCallsNotAnsweredDescription": "Totalt antal samtal från listan som ej besvarats baserat på användarens filterinställningar.",
            "totalCasesNotAnsweredDescription": "Totalt antal ärenden från listan som ej besvarats baserat på användarens filterinställningar.",
            "totalChatNotAnsweredDescription": "Totalt antal chattar från listan som ej besvarats baserat på användarens filterinställningar.",
            "totalCallbackTriesDescription": "Totalt antal försök med callback från listan baserat på användarens filterinställningar.",
            "totalAnsweredCallsDescription": "Totalt antal besvarade samtal från listan baserat på användarens filterinställningar.",
            "totalAnsweredCallbacksDescription": "Totalt antal besvarade callback från listan baserat på användarens filterinställningar.",
            "totalAnsweredChatsDescription": "Totalt antal besvarade chattar från listan baserat på användarens filterinställningar.",
            "totalAnsweredCasesDescription": "Totalt antal besvarade ärenden från listan baserat på användarens filterinställningar.",
            "totalSnoozedCasesDescription": "Totalt antal snoozade ärenden från listan baserat på användarens filterinställningar.",
            "totalSnoozedCasesWithUnreadMessagesDescription": "Totalt antal snoozade ärenden med olästa meddelanden från listan baserat på användarens filterinställningar.",
            "totalSocialCasesWithUnreadMessagesDescription": "Totalt antal sociala ärenden med olästa meddelanden från listan baserat på användarens filterinställningar.",
            "totalSmsDescription": "Totalt antal sms från listan baserat på användarens filterinställningar.",
            "totalCallsInQueueDescription": "Totalt antal samtal och callbacks i kö just nu.",
            "todayAnsweredCallsDescription": "Besvarade samtal idag.",
            "todayCallsNotAnsweredDescription": "Missade samtal idag."
        }
    }
}
</i18n>
