import axios from 'axios';

function searchInDB(payload) {
    const { type, searchValue, page } = payload;
    return axios.get(`/contacts/search?type=${type}&searchValue=${searchValue}&page=${page}`);
}

function getCollectionItems(payload) {
    return axios.get(
        `/contacts/collection-items?collectionTypeId=${payload.collectionTypeId}&collectionId=${payload.collectionId}&page=${payload.page}`
    );
}

function getAllCollectionItems(payload) {
    return axios.get(
        `/contacts/collection-items-all?collectionTypeId=${payload.collectionTypeId}&collectionId=${payload.collectionId}`
    );
}

function getSearchIds(payload) {
    return axios.get(
        `/contacts/search-ids?collectionTypeId=${payload.collectionTypeId}&collectionId=${payload.collectionId}`
    );
}

function getCollectionsWithItems(page) {
    const query = page ? `?page=${page}` : '';
    return axios.get(`/contacts/collections-with-items${query}`);
}

function getAllCollectionsWithItems() {
    return axios.get(`/contacts/all-collections-with-items`);
}

function searchCollections(searchValue) {
    return axios.get(`/contacts/collections-with-items/search?searchValue=${searchValue}`);
}

function searchItems(payload) {
    const { searchValue, collectionId, collectionTypeId } = payload;

    return axios.get(
        `/contacts/items/search?collectionId=${collectionId}&collectionTypeId=${collectionTypeId}&searchValue=${searchValue}`
    );
}

function createCollection(payload) {
    return axios.post('contacts/collections', payload);
}

function addCollectionItem(payload) {
    return axios.post(`contacts/collection-items/`, payload);
}

function changeOrder(i) {
    return axios.put('contacts/collections/order', i);
}

function editCollectionName(payload) {
    return axios.put(`contacts/collection-name`, payload);
}

function deleteCollectionItem(payload) {
    return axios.delete(`/contacts/collection-item?collectionId=${payload.collectionId}&itemId=${payload.itemId}`);
}

function deleteSelectedCollections(selectedCollections) {
    return axios.delete(`contacts/collections/`, { data: selectedCollections });
}

function deleteAllCollections() {
    return axios.delete(`/contacts/collections-all`);
}

export {
    searchInDB,
    searchCollections,
    searchItems,
    changeOrder,
    editCollectionName,
    deleteSelectedCollections,
    deleteAllCollections,
    getCollectionItems,
    getAllCollectionItems,
    getSearchIds,
    getAllCollectionsWithItems,
    getCollectionsWithItems,
    createCollection,
    addCollectionItem,
    deleteCollectionItem,
};
