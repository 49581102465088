module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "overlay": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The application is locked because of the following reason(s): ", _interpolate(_named("messages")), "."])},
          "noInternet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No internet connection"])},
          "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No connection to the server"])}
        }
      },
      "sv": {
        "overlay": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Applikationen är låst på grund av följande anledning(ar): ", _interpolate(_named("messages")), "."])},
          "noInternet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen internetanslutning"])},
          "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen anslutning till servern"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"overlay":{"message":"The application is locked because of the following reason(s): {messages}.","noInternet":"No internet connection","noConnection":"No connection to the server"}},"sv":{"overlay":{"message":"Applikationen är låst på grund av följande anledning(ar): {messages}.","noInternet":"Ingen internetanslutning","noConnection":"Ingen anslutning till servern"}}}')
  delete Component.options._Ctor
  
}
