module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "noAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no active dialogs at the moment"])},
        "activeDialogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your active dialogs"])}
      },
      "sv": {
        "noAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inga aktiva dialoger just nu"])},
        "activeDialogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dina aktiva dialoger"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"noAvailable":"You have no active dialogs at the moment","activeDialogs":"Your active dialogs"},"sv":{"noAvailable":"Du har inga aktiva dialoger just nu","activeDialogs":"Dina aktiva dialoger"}}')
  delete Component.options._Ctor
  
}
