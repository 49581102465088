import axios from 'axios';

export default {
    /* C1 STATISTICS  */
    getAllStatistics({ from, to, queues, user, categories, channels, format }) {
        return axios.get(
            `/statistics?from=${from}&to=${to}&queues=${queues}&user=${user}&categories=${categories}&channels=${channels}&format=${format}`
        );
    },

    /* CMOT STATISTICS  */
    getCMOTStatistics({ from, to, queueIds, timeFormat }) {
        return axios.get(`/statistics/cmot?from=${from}&to=${to}&queueIds=${queueIds}&timeFormat=${timeFormat}`);
    },

    /* Intervals  */
    getAllDateIntervals() {
        return axios.get('/statistics/intervals');
    },

    /* SWYX STATISTICS  */
    getActiveCalls() {
        return axios.get('/swyx/statistics/activecalls');
    },
    getTimelineStatistics({ type, groupId, page, search, excludeEmptyValues, date }) {
        return axios.get(
            `statistics/timeline?type=${type}&groupId=${groupId}&page=${page}&search=${search}&excludeEmptyValues=${excludeEmptyValues}&date=${date}`
        );
    },

    aggregatedActivityStatistics({ type, id, from, to, dataType, format }) {
        return axios.get(
            `statistics/calls/presence/column?type=${type}&id=${id}&from=${from}&to=${to}&datatype=${dataType}&format=${format}`
        );
    },

    getCallPerformanceStatistics({ id, type }) {
        return axios.get(`statistics/performance/calls?id=${id}&type=${type}`);
    },
    getBigTableCallPerformanceStatistics() {
        return axios.get('statistics/performance/calls/table');
    },
    callCDRStatistics({ from, to, channel, timeFormat, queueIds }) {
        return axios.get(
            `statistics/calls/cdr?from=${from}&to=${to}&channel=${channel}&format=${timeFormat}&queueIds=${queueIds}`
        );
    },

    exportStatistics(payload) {
        return axios.post('statistics/export', payload);
    },

    getExportJobs() {
        return axios.get('statistics/export/jobs');
    },

    deleteJob(id) {
        return axios.delete(`statistics/export/jobs/${id}`);
    },

    getUserStatisticsByGroup(payload) {
        return axios.get('statistics/user/group', {
            params: payload,
        });
    },
    getUserGroupCompare({ from, to, userIds, groupId, dataType }) {
        return axios.get(
            `statistics/user/group/compare?from=${from}&to=${to}&groupId=${groupId}&userIds=${userIds}&type=${dataType}`
        );
    },
    getClientsCallStatistics({ from, to, landingNumbers, name }) {
        return axios.get(
            `statistics/client/calls/?from=${from}&to=${to}&name=${name}&landingNumbers=${landingNumbers}`
        );
    },
    getJobData(jobId) {
        return axios.get(`statistics/jobs/${jobId}/data`);
    },
    getC1QueueCallsStats({ groupId, queueId, from, to, format, period }) {
        return axios.get(
            `statistics/calls/group/${groupId}/queue/${queueId}?from=${from}&to=${to}&format=${format}&periodStart=${period.from}&periodEnd=${period.to}`
        );
    },
    getUserC1LogIns(userId) {
        return axios.get(`statistics/timeline/c1queue/logins?userId=${userId}`);
    },

    getC1QueueServiceStatistics({ queueIds, channelType, from, to }) {
        return axios.get(
            `/statistics/service/queue?queueIds=${queueIds}&channelType=${channelType}&from=${from}&to=${to}`
        );
    },
    getC1WorkingTimeStatistics(payload) {
        return axios.get(
            `/statistics/service/cases?type=${payload.type}&typeId=${payload.typeId}&compareType=${payload.compareType}&compareTypeIds=${payload.compareTypeIds}&channelType=${payload.channelType}&from=${payload.from}&to=${payload.to}&accuracyMs=${payload.accuracyMs}`
        );
    },
    getQueuesAdvanced() {
        return axios.get('/queues?IsSystemQueue=1');
    },
};
