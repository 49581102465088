module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sidebar": {
          "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
          "externalLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External links"])}
        }
      },
      "sv": {
        "sidebar": {
          "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut"])},
          "externalLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externa länkar"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"sidebar":{"signOut":"Sign out","externalLinks":"External links"}},"sv":{"sidebar":{"signOut":"Logga ut","externalLinks":"Externa länkar"}}}')
  delete Component.options._Ctor
  
}
