<template>
    <IntegrationButton :active="active" :integrationName="integration.name" @click="clickRedirectButton" />
</template>

<script>
    import { isValidSwedishSSN } from '@/utils/SocialSecurityValidationSwedish';
    import { mapState, mapActions } from 'vuex';
    import IntegrationButton from './IntegrationButton.vue';

    export default {
        components: {
            IntegrationButton,
        },
        props: {
            integration: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                image: null,
            };
        },

        computed: {
            ...mapState({
                clientNowOnCard: (state) => state.Cases.clientNowOnCard,
            }),

            identificationNumber() {
                return this.clientNowOnCard?.data?.socialSecurityNumber;
            },

            isValidIdentificationNumber() {
                try {
                    return this.isValidSwedishSSN(this.identificationNumber);
                } catch (error) {
                    return false;
                }
            },

            active() {
                return this.integration.active && this.isValidIdentificationNumber;
            },
        },
        watch: {
            '$route.query': {
                async handler({ pop }) {
                    if (pop !== undefined && pop !== null && pop !== 'null' && pop !== 'NONE') {
                        await new Promise((resolve) => {
                            setTimeout(resolve, 1000);
                        });

                        const payload = {
                            integration: {
                                name: pop,
                            },
                            identificationNumber: this.identificationNumber,
                            isValidIdentificationNumber: this.isValidIdentificationNumber,
                        };

                        await this.storeOpenIntegration(payload);
                        this.$router.push(this.$route.path);
                    }
                },
                deep: true,
                immediate: true,
            },
        },

        methods: {
            ...mapActions('Integrations', {
                storeOpenIntegration: 'openIntegration',
            }),
            isValidSwedishSSN,
            async clickRedirectButton() {
                const payload = {
                    integration: this.integration,
                    identificationNumber: this.identificationNumber,
                    isValidIdentificationNumber: this.isValidIdentificationNumber,
                };

                await this.storeOpenIntegration(payload);
            },
        },
    };
</script>

<style scoped></style>
